import Select from 'react-select';
import { Redirect } from 'react-router-dom';
import { routes } from '../../../environments/environments.ts';
import './formAyuda.css';
import { useAyuda } from './hooks/useAyuda';
import { AsteriskRequired } from '../../../components/AsteriskRequired/AsteriskRequired';
import PropTypes from 'prop-types';

export const FormAyuda = (props) => {


    const typeForm = props.location.params;

    const {
        formValue,
        stateTitle,
        optionsCategory,
        onChangeInputHandle,
        oncheckedButtonMedioRespuestaHandle,
        onChangeSelectHandle,
        onClickButtonRespuestaHandle,
        onSubmitFormHandle,

    } = useAyuda({
        descripcion: '',
        correoEnvioRespuesta: JSON.parse(localStorage.getItem('d_u')).mail,
        numeroTelefonico: '',
    },
        typeForm,
    );

    const { descripcion, correoEnvioRespuesta, numeroTelefonico, } = formValue;

    return (
        <>
            {
                (typeForm) ?  //Si trae el tipo de formulario por el cual entra al componente
                    <>
                        <div className="card mb-3">
                            <div className="card-body position-relative textoMigaDePan">
                                <div className="row">
                                    <div className="col-lg-8">
                                        <h3>Mesa de ayuda/{stateTitle}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card mb-3">
                            <div className="card-body bg-light">
                                <form onSubmit={onSubmitFormHandle}>
                                    <div className="row">
                                    {
                                        (stateTitle === 'Felicitaciones')
                                        &&
                                        <>
                                            <strong className='text-danger fs--1'>Todos los campos marcados con asteriscos son obligatorios.</strong>
                                            <div className="card-header mt-3">
                                                <h4>Felicita a tus compañeros</h4>
                                            </div>
                                            <p>No hay nada mejor que reconocer con agrado el apoyo
                                                de otro compañero. Escribe en el campo de abajo tus
                                                palabras de agradecimiento y envíaselo a su correo
                                                empresarial.
                                            </p>
                                        </>
                                        
                                    }
                                    {
                                        (stateTitle === 'Solicitud')
                                        &&
                                        <>
                                        <strong className='text-danger fs--1'>Todos los campos marcados con asteriscos son obligatorios.</strong>
                                        <div className="card-header mt-3">
                                                <h4>Realiza tus solicitudes</h4>
                                        </div>
                                        <p>Con el fin de ayudarte a simplificar
                                        tus procesos, hemos creado esta
                                        opción donde podrás hacer las
                                        solicitudes que necesites.
                                        </p>
                                        </>
                                        
                                    }
                                    {
                                        (stateTitle === 'Queja')
                                        &&
                                        <>
                                        <strong className='text-danger fs--1'>Todos los campos marcados con asteriscos son obligatorios.</strong>
                                        <div className="card-header mt-3">
                                                <h4>¿Quieres que algo mejore?</h4>
                                        </div>
                                        <p>Con el fin de ayudarte a resolver los
                                        inconvenientes, hemos creado esta
                                        opción donde podrás expresar tus
                                        inconformidades.
                                        </p>
                                        </>
                                        
                                    }
                                    

                                        {
                                            (stateTitle === 'Solicitud')
                                            &&
                                            <div className="col-12 col-lg-6 mb-3">
                                                <label className="form-label" htmlFor="descripcion"><AsteriskRequired />Categoria:   </label>
                                                <Select onChange={value => onChangeSelectHandle({ value })} options={optionsCategory} defaultValue={[optionsCategory[0]]} placeholder={"Selecciona..."} />
                                            </div>
                                        }
                                        
                                        {
                                            (stateTitle === 'Felicitaciones')
                                            &&
                                            <div className="col-12  mb-3">
                                                <div className="col-12 mb-3">
                                                    <label className="form-label" htmlFor="correoEnvio"><AsteriskRequired />Correo de la persona que vas a felicitar:  </label>
                                                    <input onChange={onChangeInputHandle}  id="correoEnvioRespuesta" name="correoEnvioRespuesta" className="form-control" placeholder="Escribir correo" type="email" />
                                                </div>
                                                <label className="form-label" htmlFor="descripcion"><AsteriskRequired />¿Qué deseas escribir? </label> 
                                                <textarea onChange={onChangeInputHandle} value={descripcion} id="descripcion" name="descripcion" className="form-control" placeholder="Descripción" maxLength="990" ></textarea>
                                            </div>
                                        }

                                        {
                                            (stateTitle !== 'Felicitaciones')
                                            &&
                                            <>
                                        <div className="col-12 col-lg-6 mb-3">
                                            <label className="form-label" htmlFor="correoEnvio"><AsteriskRequired /> Enviar respuesta a: </label>
                                            <input onChange={onChangeInputHandle} value={correoEnvioRespuesta} id="correoEnvioRespuesta" name="correoEnvioRespuesta" className="form-control" placeholder="Escribir correo" type="email" />
                                        </div>
                                                <div className='col-lg-12 col-xxl-8 col-12 pt-3 row'>
                                                        <div className="form-check divOtroMedio ms-3 d-flex col-lg-4 col-12 ">
                                                                    Otro medio de respuesta:
                                                                    <div className='form-check form-switch ms-2'>
                                                                    <input onClick={oncheckedButtonMedioRespuestaHandle} className="form-check-input" id="otroMedioRespuesta" name="otroMedioRespuesta" type="checkbox" role='switch'/>
                                                                    </div>
                                                        </div>
                                                        <div className='col-lg-5 col-12 ps-md-0 row'>
                                                        <div className='col-lg-5 me-3 col-12 ps-md-0'>
                                                            <div className="form-check ps-md-0">
                                                            <input onChange={onClickButtonRespuestaHandle} data-target="llamada" className="form-check-input " id="radioLlamadaTelefonica" type="radio" name="radioMedioRespuesta" disabled />
                                                            <label data-target="radioLlamadaTelefonica" className="form-check-label" htmlFor="radioLlamadaTelefonica">Llamada telefónica</label>
                                                        </div>
                                                        </div>
                                                        <div className='col-lg-5 col-12 ps-md-0'>
                                                            <div className="form-check ps-md-0">
                                                            <input onChange={onClickButtonRespuestaHandle} data-target="wpp" className="form-check-input " id="radioWpp" type="radio" name="radioMedioRespuesta" disabled />
                                                            <label data-target="radioWpp" className="form-check-label" htmlFor="radioWpp">WhatsApp</label>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className='col-lg-12 col-xxl-8 col-12 pt-3 row'>
                                                    <div className="form-check divOtroMedio ms-3 d-flex col-lg-4 col-12 ">
                                                        <label className="form-check-label mt-3" htmlFor="otroMedio">Otro medio de respuesta:</label>
                                                        <div className='form-check form-switch ms-2'>
                                                        <input className="form-check-input" style={{marginTop:'20px'}} type="checkbox" role='switch' id="otroMedio" onChange={() => checkOtroMedio()} />
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-5 col-12 pt-3 ps-md-0 row'>
                                                        <div className='col-lg-5 me-3 col-12 ps-md-0'>
                                                            <div className="form-check ps-md-0">
                                                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="llamadaTelefonica" value={376} disabled />
                                                                <label className="form-check-label" htmlFor="llamadaTelefonica">
                                                                    Llamada telefónica
                                                                </label>
                                                            </div>
                                                            </div>
                                                        <div className='col-lg-5 col-12 ps-md-0'>
                                                            <div className="form-check ps-md-0">
                                                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="whatsapp" value={377} disabled />
                                                                <label className="form-check-label" htmlFor="whatsapp">
                                                                WhatsApp
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                <div className="col-12 col-lg-4 mb-3" id="divNumeroTelefonico">
                                                    <label className="form-label" htmlFor="numeroTelefonico"><AsteriskRequired /> Numero Teléfonico: </label>
                                                    <input onChange={onChangeInputHandle} value={numeroTelefonico} id="numeroTelefonico" name="numeroTelefonico" className="form-control" placeholder="Numero Teléfonico" type="number" />
                                                </div>
                                                <div className="col-12 col-lg-12 mb-3">
                                                    <label className="form-label" htmlFor="descripcion"><AsteriskRequired />¿Qué deseas escribir? </label> 
                                                    <textarea onChange={onChangeInputHandle} value={descripcion} id="descripcion" name="descripcion" className="form-control" placeholder="Descripción" maxLength="990" ></textarea>
                                                </div>
                                            </>
                                        }
                                      
                                           


                                        <div className="col-12 col-lg-12 mb-3">
                                            <div className="row">
                                                <div className="col-12 col-lg-10 mb-3"></div>
                                                <div className="col-12 col-lg-2 mb-3 d-grid gap-2">
                                                    <button type="submit" className="btn succesButton btnEnviar">Enviar</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </>

                    :

                    <Redirect to={routes.ayuda.url} /> //Si no trae el tipo e formulario se redirecciona al page padre de Ayuda
            }

        </>
    )
}

FormAyuda.propTypes = {
    location: PropTypes.object
}