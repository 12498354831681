import React, { useEffect, useState } from 'react';
import { postData, getIp } from "../../components/general/General";
import { baseUrl } from "../../config/config";
import { postFetch } from '../../generalHelpers';
import { routes } from '../../environments/environments';
import './opciones.css';
import Swal from "sweetalert2/dist/sweetalert2.js";

const Opciones = () => {
    const dataUser = JSON.parse(localStorage.getItem('d_u'));
    const [datosUser, setDatosUser] = useState(null);
    const [cedulasUser, setCedulasUser] = useState(null);
    const [nombreUser, setNombreUser] = useState(null);
    const [apellidoUser, setApellidoUser] = useState(null);
    const [emailUser, setEmailUser] = useState(null);
    const [telefonoUser, setTelefonoUser] = useState(null);
    const [fotoUser, setFotoUser] = useState(null);
    const [swalFire, setSwalFire] = useState(true);
    const [celularLandbot, setCelularLandbot] = useState(null);
    
    const contrasena = localStorage.getItem('contr');
    const cedulaHabeas = parseInt(dataUser.cedula.trim());
    const cod_empresa = parseInt(dataUser.empresa.trim());
    
    useEffect(() => {
     //apenas se entre al modulo, consultar si ya aceptó usuario el Habeas data.
     getValidateSiNo(cedulaHabeas, cod_empresa, 1).then((ele) => {
        //cuando me devuelve 1, quiere decir que ya tiene habeas data.
        if (ele.length === 1) {
            //si tiene 1, es decir habeas data, no debe mostrar modal, de lo contrario si.
            if(contrasena){
                if(swalFire) {
                    Swal.fire({
                        title: 'Recuerde cambiar su contraseña.',
                        showClass: {
                          popup: 'animate__animated animate__fadeInDown'
                        },
                        hideClass: {
                          popup: 'animate__animated animate__fadeOutUp'
                        }
                      })
                      setSwalFire(false); 
                     
                }
            }
            } else {
                loadModalContratistas(cedulaHabeas,cod_empresa);
            }
        });
        
        inicializarLandbot();

        document.getElementById('root').className = 'opciones';
        window.scrollTo({ top: 0, behavior: 'smooth' });

        const url = `${baseUrl}/v1/UpdateUser/buscarDatos`
        const cedula = parseInt(dataUser.cedula.trim());

        postData(url, { CEDULA: cedula })
        .then(data => {
            
            setCedulasUser(data[0]['cod_empl']);
            setNombreUser(data[0]['nom_empl']);
            setApellidoUser(data[0]['ape_empl']);
            setEmailUser(data[0]['eee_mail']);
            setTelefonoUser(data[0]['tel_movi']);

            if (data[0]['USU_FOTO'] !== null) {
                setFotoUser(data[0]['USU_FOTO']);
            } else {
                let foto = "https://controlf.blob.core.windows.net/public/vumservice/assets/unnamed2.png";
                setFotoUser(foto);
            }

            setDatosUser(data);
            // setLoadingData(false);
        });


    }, [!datosUser]);

    //validar si tiene habeas data en la tabla de base de datos.
    async function getValidateSiNo(cedula, cod_empresa, campo) {
        const url = `${baseUrl}/v1/loginAbeasData/validateSiNo`;
        const res = await postFetch({
          url: url,
          params: { cedula: cedula, cod_empresa: cod_empresa, campo: campo },
        });
        return res;
    }
     
    //modal de habeas data.
    const msgLoginAbeas = "Para ingresar a VumOffice debes aceptar el tratamiento de tus datos personales";
    const msgTitle = "¿Deseas leer el documento?";
    const loadModalContratistas = (cedula,dataEmpresa) => {
        const url = `${baseUrl}/v1/loginAbeasData/getLoginAbeasData`;
        postFetch({ url: url, params: { cod_empresa: dataEmpresa } }).then(
          (ele) => {
            const url = ele[0].url;
            const dataModal = `<div class="mb-4"><div class="mb-2">${msgTitle}</div> <a target="_blank" download href=${url} >Haz click aquí </a></div><div class="justify-content-center "><div class="col"><button class="btn btn-primary" id="btn-Aceptar">Aceptar</button></div></div>`;
    
            const modal = Swal.fire({
              html: `<div class="text-primary mb-4">${msgLoginAbeas}</div><div class="text-justify mb-4"></div>${dataModal} `,
              showConfirmButton: false,
              allowOutsideClick: false,
              allowEscapeKey: false,
              focusConfirm: false,
            });
    
            document
              .getElementById("btn-Aceptar")
              .addEventListener("click", function () {
                saveHabeasData(cedula, dataEmpresa, 1);
                modal.close();
                if(contrasena){
                    if(swalFire) {
                        Swal.fire({
                            title: 'Recuerde cambiar su contraseña.',
                            showClass: {
                              popup: 'animate__animated animate__fadeInDown'
                            },
                            hideClass: {
                              popup: 'animate__animated animate__fadeOutUp'
                            }
                          })
                          setSwalFire(false); 
                         
                    }
                }
              });
          }
        );
      };
    
    //guardar en habeas data.
    const saveHabeasData = (cedula, dataEmpresa, estado) => {
        // para insertar como externo
        const url = `${baseUrl}/v1/loginAbeasData/insertLoginAbeasData`;
        getIp().then((ip) => {
          postFetch({
            url: url,
            params: {
              cedula: cedula,
              cod_empresa: dataEmpresa,
              estado: estado,
              ip: ip,
              tipo: 1
            },
          });
        });
      };

    const processImage = (id) => {
        const imageFile = document.getElementById(id).files[0];
        const imageUrl = URL.createObjectURL(imageFile);
        setFotoUser(imageUrl)
    }

    const actualizarContrasena =async () => {

        const url = `${baseUrl}/v1/UpdateUser/actualizarPass`;
        const cedula = parseInt(dataUser.cedula.trim());
        const pass = document.getElementById('old-password').value;
        const newPass = document.getElementById('new-password').value;
        const confirmPass = document.getElementById('confirm-password').value;
        const email = JSON.parse(localStorage.getItem('d_u'))['mail'];
        const celular = JSON.parse(localStorage.getItem('d_u'))['numeroCelular'];
        const nombres = JSON.parse(localStorage.getItem('d_u'))['nombres'];
        const apellidos = JSON.parse(localStorage.getItem('d_u'))['apellidos'];


        
        if (pass !== "") {
            if (newPass !== "") {
                if (confirmPass !== "") {
                    if (newPass === confirmPass) {

                        const resp=await validarPassword(newPass);

                        if(resp){

                            postData(url, { CEDULA: cedula, PASS: pass, NEWPASS: newPass, EMAIL: email, CELULAR: celular, NOMBRES: nombres, APELLIDOS: apellidos})
                            .then(data => {
                                if (data === 1) {
                                    Swal.fire({
                                        icon: 'success',
                                        html: `
                                            <div class="row" style="text-align: left; margin: 10px;">
                                                <div class="col-12 col-lg-12 mb-3 text-center">
                                                    <span style="font-weight: 600;font-size: 20px;">
                                                        Contraseña actualizada de manera correcta.
                                                    </span>
                                                </div>                    
                                                <div class="col-12 col-lg-12 mt-3" style="text-align: center;">
                                                    <button id="btnValidarCodigo" type="button" class="btn succesButton">Aceptar</button>
                                                </div>
                                            </div>

                                            <br/>
                                    `, showCloseButton: true,
                                        showCancelButton: false,
                                        showConfirmButton: false,
                                        allowOutsideClick: false,
                                        backdrop: true,
                                        didOpen: () => {
                                            document
                                                .getElementById("btnValidarCodigo")
                                                .addEventListener("click", () => {
                                                    //remuevo la contraseña generada automatica.
                                                    localStorage.removeItem('contr');
                                                    //seteo el campo de contraseña actual
                                                    document.getElementById('old-password').value='';
                                                    //redirigo al login, cuando la contraseña ha sido actualizada.
                                                    window.location.href = routes.login.url;
                                                    
                                                });
                                        }
                                    });
                                } else {
                                    Swal.fire({
                                        icon: 'error',
                                        html: "La información que ingresaste en el campo contraseña actual no corresponde a la contraseña que tenias asignada.",
                                        showConfirmButton: true,
                                        confirmButtonText: 'Cerrar',
                                        confirmButtonColor: '#4695ff',
                                        allowOutsideClick: false,
                                        allowEscapeKey: false,
                                        focusConfirm: false,
                                        timer: 3500,
                                        timerProgressBar: true
                                    })
                                }

                            });

                        }else{


                            Swal.fire({
                                icon: 'error',
                                html: "La contraseña no debe contener caracteres especiales, tildes, (ñ, Ñ).",
                                showConfirmButton: true,
                                confirmButtonText: 'Cerrar',
                                confirmButtonColor: '#4695ff',
                                allowOutsideClick: false,
                                allowEscapeKey: false,
                                focusConfirm: false,
                                timer: 3500,
                                timerProgressBar: true
                            })


                        }

                    } else {
                        Swal.fire({
                            icon: 'error',
                            html: "Los campos contraseña nueva y confirmación de contraseña no coinciden.",
                            showConfirmButton: true,
                            confirmButtonText: 'Cerrar',
                            confirmButtonColor: '#4695ff',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            focusConfirm: false,
                            timer: 3500,
                            timerProgressBar: true
                        })
                    }
                } else {
                    Swal.fire({
                        icon: 'error',
                        html: "El campo de confirmación de contraseña no se puede encontrar vacio.",
                        showConfirmButton: true,
                        confirmButtonText: 'Cerrar',
                        confirmButtonColor: '#4695ff',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        focusConfirm: false,
                        timer: 3500,
                        timerProgressBar: true
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    html: "El campo de contraseña nueva no se puede encontrar vacio.",
                    showConfirmButton: true,
                    confirmButtonText: 'Cerrar',
                    confirmButtonColor: '#4695ff',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    focusConfirm: false,
                    timer: 3500,
                    timerProgressBar: true
                })
            }
        } else {
            Swal.fire({
                icon: 'error',
                html: "El campo de contraseña actual no se puede encontrar vacio.",
                showConfirmButton: true,
                confirmButtonText: 'Cerrar',
                confirmButtonColor: '#4695ff',
                allowOutsideClick: false,
                allowEscapeKey: false,
                focusConfirm: false,
                timer: 3500,
                timerProgressBar: true
            })
        }



    }


    const validarPassword=async(pass)=>{

        try {
    
            let resp=true;
    
            const pattern = new RegExp('^[A-Za-z0-9]+$', 'i');
    
            if(!pattern.test(pass)){
    
                resp=false;
    
            };
      
            return resp;
            
        } catch (error) {
            // Error validando password
        };
    
    };


    const updateData = () => {
        const cedula = parseInt(dataUser['cedula'])
        const email = document.getElementById("emailActualizar").value;
        const telefono = document.getElementById("telUsuActualizar").value;
        let validacion = 0;

        const exprRegEmail = /^([a-zA-Z0-9_.-]+)@([\da-zA-Z0-9.-]+)\.([a-z.]{2,6})$/; //Expresion regular para validar los correos electronicos

        if (!exprRegEmail.test(email)){
            return  Swal.fire({
                icon: 'error',
                html: "Debe de ingresar un correo válido.",
                showConfirmButton: true,
                confirmButtonText: 'Cerrar',
                confirmButtonColor: '#4695ff',
                allowOutsideClick: false,
                allowEscapeKey: false,
                focusConfirm: false,
                timer: 3500,
                timerProgressBar: true
            })
            
        }

        let formData = new FormData();
        formData.append("CEDULA", cedula);
        formData.append("EMAIL", email);
        formData.append("TELEFONO", celularLandbot || telefono); //Si metió celular en landbot, enviamos ese mismo.
        formData.append('file', document.getElementById("profile-image").files[0]);

        const urlSave = `${baseUrl}/v1/UpdateUser/update`;
        if (document.getElementById("profile-image").value === "") {
            if (emailUser !== document.getElementById("emailActualizar").value) {
                validacion++;
            }

            if (telefonoUser !== document.getElementById("telUsuActualizar").value) {
                validacion++;
            }

            if (validacion > 0) {
                postFetch({ url: urlSave, params: formData }).then(result => {
                    Swal.fire({
                        icon: 'success',
                        html: `
                        <div class="row" style="text-align: left; margin: 10px;">
                            <div class="col-12 col-lg-12 mb-3 text-center">
                                <span style="font-weight: 600;font-size: 20px;">
                                    Datos del usuario actualizados de manera correcta.
                                </span>
                            </div>                    
                            <div class="col-12 col-lg-12 mt-3" style="text-align: center;">
                                <button id="btnValidarCodigo" type="button" class="btn succesButton">Aceptar</button>
                            </div>
                        </div>
    
                        <br/>
                `, showCloseButton: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        backdrop: true,
                        didOpen: () => {
                            document
                                .getElementById("btnValidarCodigo")
                                .addEventListener("click", () => {
                                    window.location.href = routes.opciones.url;
                                });
                        }
                    });
                })
            } else {
                Swal.fire({
                    icon: 'error',
                    html: "para realizar una actualización de datos debe cambiar la imagen, correo o telefono.",
                    showConfirmButton: true,
                    confirmButtonText: 'Cerrar',
                    confirmButtonColor: '#4695ff',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    focusConfirm: false,
                    timer: 3500,
                    timerProgressBar: true
                })
            }

        } else {
            postFetch({ url: urlSave, params: formData }).then(result => {
                Swal.fire({
                    icon: 'success',
                    html: `
                    <div class="row" style="text-align: left; margin: 10px;">
                        <div class="col-12 col-lg-12 mb-3 text-center">
                            <span style="font-weight: 600;font-size: 20px;">
                                Datos del usuario actualizados de manera correcta.
                            </span>
                        </div>                    
                        <div class="col-12 col-lg-12 mt-3" style="text-align: center;">
                            <button id="btnValidarCodigo" type="button" class="btn succesButton">Aceptar</button>
                        </div>
                    </div>

                    <br/>
            `, showCloseButton: true,
                    showCancelButton: false,
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    backdrop: true,
                    didOpen: () => {
                        document
                            .getElementById("btnValidarCodigo")
                            .addEventListener("click", () => {
                                window.location.href = routes.opciones.url;
                            });
                    }
                });
            })
        }



    }

    const inicializarLandbot = () => {
        // eslint-disable-next-line no-undef
        new Landbot.Optin({
            "url": "https://messages.landbot.io/wa/W-1684-TX7FY8OXQ7SC4AHX/opt_in",
            "label": "Si deseas mantenerte al tanto de nuestras comunicaciones, registra aquí tu número de WhatsApp iniciando con +57.",
            "customParams": {},
            "container": "#LandbotOptin__Container"
        });

        setTimeout(() => {
            let iframe = document.querySelector('#LandbotOptin__Container>div>iframe');
            let iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
            let checkBox = iframeDocument.getElementById('lb-optin-checkbox');
            let inputCel = iframeDocument.querySelector('.PhoneInputInput');
            let btn = iframeDocument.querySelector('button');
            iframeDocument.querySelector('.PhoneInputCountrySelect').setAttribute('disabled', true);
    
            btn.innerHTML = 'SUSCRIBIRSE';
    
            checkBox.addEventListener('change', (event) => {
                if(event.target.checked) {
                    document.getElementById('divCelular').classList.add('d-none');
                } else {
                    document.getElementById('divCelular').classList.remove('d-none');
                }
            });
            inputCel.addEventListener('keyup', (event) => {
                let numCompleto = event.target.value.split(' ');
                let celular = '';
                numCompleto.map((num, i) => {
                    if(i > 0) celular += num;
                    return null;
                });
    
                document.getElementById('telUsuActualizar').value = celular || '';
            });
            btn.addEventListener('click', (event) => {
                setCelularLandbot(document.getElementById('telUsuActualizar').value);
                checkBox.setAttribute('disabled', true);
                inputCel.setAttribute('disabled', true);
                setTimeout(() => {
                    btn.setAttribute('disabled', true);
                }, 1000);
            });
        }, 1000);
    }


    return <>
        <div className="card mb-3">
            <div className="card-body position-relative textoMigaDePan">
                <div className="row">
                    <div className="col-lg-8">
                        <h3>Mis datos</h3>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-lg-8 pl-lg-12">
                <div className="card mb-3">
                    <div className="card-header">
                        <h5 className="mb-0">Tus datos</h5>
                    </div>
                    <div className="card-body bg-light">
                        <div className="row g-3">
                            <div className="card-header position-relative min-vh-25 mb-7 mt-n6">
                                <div className="avatar avatar-5xl avatar-profile shadow-sm img-thumbnail rounded-circle">
                                    <div className="h-100 w-100 rounded-circle overflow-hidden position-relative"> 
                                    <img src={fotoUser} width="100" height="100" alt="" data-dz-thumbnail style={{}}></img>
                                        <input onChange={(event) => processImage(event.target.id)} className="d-none" id="profile-image" name="file" type="file" accept=".jpg,.jpeg,.png" />
                                        <label className="mb-0 overlay-icon d-flex flex-center" htmlFor="profile-image"><span className="bg-holder overlay overlay-0"></span><span className="z-index-1 text-white text-center fs--1"><span className="fas fa-camera"></span><span className="d-block">Actualizar</span></span></label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <label className="form-label" htmlFor="nroIdActualizar">Nro. Identificación:</label>
                                <input className="form-control" id="nroIdActualizar" type="text" value={cedulasUser} disabled="true" />
                            </div>
                            <div className="col-lg-4">
                                <label className="form-label" htmlFor="nomUsuActualizar">Nombres:</label>
                                <input className="form-control" id="nomUsuActualizar" type="text" value={nombreUser} maxLength="150" disabled="true" />
                            </div>
                            <div className="col-lg-4">
                                <label className="form-label" htmlFor="apeUsuActualizar">Apellidos:</label>
                                <input className="form-control" id="apeUsuActualizar" type="text" value={apellidoUser} maxLength="150" disabled="true" />
                            </div>
                            <div className="col-lg-4">
                                <label className="form-label" htmlFor="emailActualizar">Email:</label>
                                <input className="form-control" id="emailActualizar" type="text" defaultValue={emailUser} />
                            </div>
                            <div className="col-lg-4" id='divCelular'>
                                <label className="form-label" htmlFor="telUsuActualizar">Celular:</label>
                                <input className="form-control" id="telUsuActualizar" type="text" defaultValue={telefonoUser} />
                            </div>
                            <div className="col-12 d-flex justify-content-end">
                                <button className="btn btn-primary w-100" type="button" onClick={() => updateData()}>Actualizar datos</button>
                            </div>
                        </div>
                    </div>
                    <div className="card-header">
                        <h5 className="mb-0">Cambiar Contraseña</h5>
                    </div>
                    <div className="card-body bg-light">
                        <div className='row g-3'>
                            {/* renderizado condicional, si se entra desde las preguntas de seguridad, debe mostrar la contraseña, sino, se deja en blanco el input de contraseña actual */}
                            {contrasena ? 
                                <div className="col-lg-4" id="divOld-password">
                                <label className="form-label" htmlFor="old-password">Contraseña actual:</label>
                                <input className="form-control" id="old-password" type="password" disabled value={contrasena}/>
                                </div> 
                                : 
                                <div className="col-lg-4" id="divOld-password">
                                <label className="form-label" htmlFor="old-password">Contraseña actual:</label>
                                <input className="form-control" id="old-password" type="password" />
                                </div>
                            }
                            {/* fin renderizado condicional */}
                                
                            <div className="col-lg-4" id="divNew-password">
                                <label className="form-label" htmlFor="new-password">Contraseña nueva:</label>
                                <input className="form-control" id="new-password" type="password" />
                            </div>
                            <div className="col-lg-4" id="divConfirm-password">
                                <label className="form-label" htmlFor="confirm-password">Confirmar la contraseña:</label>
                                <input className="form-control" id="confirm-password" type="password" />
                            </div>
                            <div className="col-lg-4 d-none" id="divToken">
                                <label className="form-label" htmlFor="token">Codigo</label>
                                <input className="form-control" id="token" type="text" />
                            </div>
                            <div className='text-end'>
                                <button className="btn btn-primary btn-block w-100" id="botonValidar" type="button" onClick={() => actualizarContrasena()}>Actualizar contraseña </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 pl-lg-2">
                <div className="sticky-sidebar">
                    <div className="card mb-3">
                        <div id="LandbotOptin__Container" className="col-12" style={{height: "300px"}}></div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default Opciones;