import { Redirect } from 'react-router-dom';
import { useFormRrhh } from './hooks/useForm.js';
import { DataTabla } from '../../../components/DataTable/DataTabla';
import { routes } from '../../../environments/environments.ts';
import Select from 'react-select';
import './formRrhh.css';
import { AsteriskRequired } from '../../../components/AsteriskRequired/AsteriskRequired.js';
import PropTypes from 'prop-types';


export const FormRrhh = (props) => {

    const typeForm = props.location.params;

    

    const headerStyles = { backgroundColor: '#EDF2F9', color: '#344050', zIndex: 0, };

    const {
        formValue,
        stateTitle,
        optionsCategory,
        optionsTiposAuxilios,
        optionsTiposCesantias,
        rowsTable,
        onChangeInputHandle,
        oncheckedButtonMedioRespuestaHandle,
        oncheckedButtonChekCesantiasHandle,
        onChangeSelectHandle,
        onChangeSelectHandleAuxilios,
        onChangeSelectHandleCesantias,
        onSubmitFormHandle,
        actaJuramentada,
        conflictoInteres,
        onClickButtonRespuestaHandle,

    } = useFormRrhh({
        descripcion: '',
        correoEnvioRespuesta: JSON.parse(localStorage.getItem('d_u')).mail,
        numeroTelefonico: '',
        columnsDataTable: [
            { title: '#', field: 'numero', headerStyle: headerStyles },
            { title: 'Documento', field: 'documento', headerStyle: headerStyles },
            { title: 'Archivo', field: 'archivo', headerStyle: headerStyles },
        ],
        rowsDataTable: [],
        files: [],
    },
        typeForm,
    );

    const { columnsDataTable } = formValue;

    const { descripcion, correoEnvioRespuesta, numeroTelefonico, } = formValue;

    return (
        <>
            {
                (typeForm) ?  //Si trae el tipo de formulario por el cual entra al componente
                    <>
                        {/* <div className="card mb-3">
                            <div className="bg-holder d-none d-lg-block bg-card bg-image-vum">
                            </div>
                            <div className="card-body position-relative textoMigaDePan">
                                <div className="row">
                                    <div className="col-lg-8">
                                        <h3>Realizar solicitud</h3>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        <div className="card mb-3 ">
                            <div className="card-body bg-light rounded-3">
                                <strong className='text-danger fs--2'>Todos los campos marcados con asteriscos son obligatorios.</strong>
                                <form onSubmit={onSubmitFormHandle}>
                                <h3 className='mt-md-3'>Realizar solicitud</h3>

                                    <div className="row">
                                        {
                                            (stateTitle !== 'Talk to us')
                                            &&
                                            <div id="categoria" className="col-12 col-lg-6 mb-3">
                                                <label className="form-label" htmlFor="descripcion"><AsteriskRequired />Categoría: </label>
                                                <Select onChange={value => onChangeSelectHandle({ nameSelect: 'categoria', value })} options={optionsCategory} defaultValue={[optionsCategory[0]]} placeholder={"Selecciona..."} />
                                            </div>
                                        }
                                                <div id="TiposAuxilios" className="col-12 col-lg-6 mb-3 d-none">
                                                <label className="form-label" htmlFor="descripcion"><AsteriskRequired />Tipos de auxilios: </label>
                                                <Select onChange={value => onChangeSelectHandleAuxilios({ nameSelect: 'tipoAuxilio', value })} options={optionsTiposAuxilios} defaultValue={[optionsTiposAuxilios[0]]} placeholder={"Selecciona..."} />
                                            </div>

                                            <div id="TiposCesantias" className="col-12 col-lg-6 mb-3 d-none">
                                                <label className="form-label" htmlFor="descripcion"><AsteriskRequired />Tipos de Cesantias: </label>
                                                <Select onChange={value => onChangeSelectHandleCesantias({  nameSelect: 'tipoCesantia',value })} options={optionsTiposCesantias} defaultValue={[optionsTiposCesantias[0]]} placeholder={"Selecciona..."} />
                                            </div>

                                            <div id="CheckCesantias" className="col-12 col-lg-4 mb-3 pt-4 d-none">
                                                <label className="form-label" htmlFor="CheckCesantias">¿Vivienda Usada?</label>
                                                &nbsp; &nbsp;
                                                <input onClick={oncheckedButtonChekCesantiasHandle} className="form-check-input" id="check" name="CheckCesantias" type="checkbox" />
                                            </div>

                                        <div id="correo" className="col-12 col-lg-6 mb-3">
                                            <label className="form-label" htmlFor="correoEnvio"><AsteriskRequired />¿Cuál es tu correo?: </label>
                                            <input onChange={onChangeInputHandle} value={correoEnvioRespuesta} id="correoEnvioRespuesta" name="correoEnvioRespuesta" className="form-control" placeholder="Correo Envío Respuesta" type="email" />
                                        </div>

                                        <div id="formatoPrestamo" className='col-12 mb-3 d-none'>
                                            <a href="https://controlf.blob.core.windows.net/vumoffice/FO-REC-16%20SOLICITUD%20DE%20PR%C3%89STAMO%20Y-O%20AUTORIZACI%C3%93N%20DESCUENTO.pdf" download><u>Descargar formato de prestamo</u></a>
                                        </div>

                                        <div id="politicaAuxilios" className='col-12 mb-3 d-none'>
                                            <a href="https://controlf.blob.core.windows.net/vumoffice/PO-REC-02%20Pol%C3%ADtica%20de%20Auxilios.pdf" target="_blank" rel='noreferrer'><u>Leer política de auxilios</u></a>
                                        </div>

                                        <div id="politicaAuxiliosEdu" className='col-12 mb-3 d-none'>
                                            <a href="https://controlf.blob.core.windows.net/vumoffice/PO-REC-03%20Pol%C3%ADtica%20de%20Auxilios%20Educativos.pdf" target="_blank" rel='noreferrer'><u>Leer política de auxilios educativos</u></a>
                                        </div>

                                        <div id="politicaConflictoInteres" className='col-12 mb-3 d-none'>
                                            <a href="https://controlf.blob.core.windows.net/vumoffice/Pol%C3%ADtica%20Conflicto%20de%20Inter%C3%A9s%20VM%20S.A.S.pdf" target="_blank" rel='noreferrer'><u>Leer aquí política de conflicto de interés.</u></a>
                                        </div>

                                        <div className="col-12 mb-3">
                                            <label className="form-label" htmlFor="descripcion"><AsteriskRequired />Descripción: </label>
                                            <textarea onChange={onChangeInputHandle} value={descripcion} id="descripcion" name="descripcion" className="form-control" placeholder="Descripción" maxLength="990" ></textarea>
                                        </div>

                                        <div className="col-12 mb-3">
                                            <label className="form-label" htmlFor="OtroMedioRespuesta">
                                                Otro medio de respuesta: &nbsp;&nbsp;<input onClick={oncheckedButtonMedioRespuestaHandle} className="form-check-input" id="otroMedioRespuesta" name="otroMedioRespuesta" type="checkbox" />
                                            </label>
                                           
                                        </div>

                                        <div className="col-12 col-lg-4 mb-3" id="divNumeroTelefonico">
                                            <label className="form-label" htmlFor="numeroTelefonico"><AsteriskRequired />Numero Teléfonico: </label>
                                            <input onChange={onChangeInputHandle} value={numeroTelefonico} id="numeroTelefonico" name="numeroTelefonico" className="form-control" placeholder="Numero Teléfonico" type="number" />
                                        </div>

                                        <div className="col-12 col-lg-8 d-flex align-items-end pb-3 d-none" id='checkOtrosMedios'>

                                            <div className='form-check form-check-inline'>
                                                <input onChange={onClickButtonRespuestaHandle}  data-target="llamada" className='form-check-input' id="radioLlamadaTelefonica" type="radio" name="radioMedioRespuesta" disabled />
                                                <label data-target="radioLlamadaTelefonica" className="form-check-label" htmlFor="radioLlamadaTelefonica">Llamada telefónica</label>
                                            </div>
                                            <div className='form-check form-check-inline'>
                                                <input onChange={onClickButtonRespuestaHandle} data-target="wpp" className='form-check-input' id="radioWpp" type="radio" name="radioMedioRespuesta" disabled />
                                                <label data-target="radioWpp" className="form-check-label" htmlFor="radioWpp">WhatsApp</label>
                                            </div>


                                                {/* <div className="col-md-7 text-right">
                                                    <input onChange={onClickButtonRespuestaHandle} data-target="llamada" className="form-check-input inputRadioOtraRespuesta" id="radioLlamadaTelefonica" type="radio" name="radioMedioRespuesta" disabled />
                                                    <label data-target="radioLlamadaTelefonica" className="form-check-label" htmlFor="radioLlamadaTelefonica">Llamada telefónica</label>
                                                </div>
                                                <div className="col-md-5">
                                                    <input onChange={onClickButtonRespuestaHandle} data-target="wpp" className="form-check-input inputRadioOtraRespuesta" id="radioWpp" type="radio" name="radioMedioRespuesta" disabled />
                                                    <label data-target="radioWpp" className="form-check-label" htmlFor="radioWpp">WhatsApp</label>
                                                </div> */}
                                        </div>
                                        
                                        <div id="actaJuramentada" className='col-12 col-lg-4 d-none'>
                                            <br/>
                                            <button onClick={actaJuramentada} type='button' className='col-12 btn btn-info'>Firmar acta juramentada</button>
                                        </div>
                                        <div id="conflictoInteres" className='col-12 col-lg-4 d-none'>
                                            <br/>
                                            <button onClick={conflictoInteres} type='button' className='col-12 btn btn-info'>Firmar conflicto de interés</button>
                                        </div>

                                        <div id="tablaDocumentos" className=''>
                                            <div className="card-header" id="tituloDataTableDesktop">
                                                <h5 className="card-title">Sube tus archivos aquí en formato pdf </h5>
                                            </div>

                                            <DataTabla paging={false} title={<> <h5 className="card-title d-none">Sube tus archivos aquí en formato pdf o imagenes </h5> </>} columns={columnsDataTable} data={rowsTable}  />
                                        </div>
                                        <div className='col-12 text-center mt-2'>
                                            <button type="submit" className="btn succesButton col-12">Enviar</button>
                                        </div>
                                        {/*  */}

                                    </div>
                                </form>
                            </div>
                        </div>

                    </>

                    :

                    <Redirect to={routes.rrhh.url} /> //Si no trae el tipo e formulario se redirecciona al page padre de RRHH
            }

                <div className='volver'>

               

                </div>

        </>
    )

}

FormRrhh.propTypes = {
    location: PropTypes.any
}