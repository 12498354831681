import { Component, Fragment } from 'react';
import { loadDataValidate, loadingDialog, postData, putInputRequerid, simulateClick, validateInputTabs } from '../../components/general/General';
import { baseUrl } from '../../config/config';
import Swal from 'sweetalert2';

class Salud extends Component {
    constructor(props) {
        super(props)
        this.state = {
            seePregnancy: 'hidden',
            optionsTipoSangre: '',
            optionsRh: '',
            optionsEtnia: '',
            optionsFrecuencias: '',
            estadonav: 'disabled',
            sexo: '',
            datbas: '',
            disableComplementario: true
        }
    }

    componentDidMount() {
        document.getElementById('root').className = 'cv';
        this.consultaInicial();
    }

    consultaInicial = () => {
        const dataUser = JSON.parse(localStorage.getItem("d_u"));
        const cedula = parseInt(dataUser['cedula'])
        const empresa = parseInt(dataUser['empresa'])
        const url = `${baseUrl}/v1/salud/consultaInicial`;

        loadingDialog(true)
        postData(url, {cedula, empresa}).then(async (response) => {
            loadingDialog(false);
            this.setState({sexo: response.sexo, datbas: response.datbas});
            if(this.state.sexo==="F") putInputRequerid('input[name=pregnancyF]', '', 'add', 'pregnancyF');
            
            await this.asignarValoresSelects(response)
            if(response.datosSalud.length) this.loadDataPrincipal(response.datosSalud[0]);
        }).catch(err => {
            loadingDialog(false);
            Swal.fire({
                icon: 'warning',
                title: '¡Ups!',
                text: 'Ha ocurrido un error al cargar el formulario, intenta nuevamente.',
                confirmButtonColor: "#A6A6A6",
                confirmButtonText: 'Cerrar'
            })
        });
    }

    asignarValoresSelects = async (data) => {
        let sanguineos = data.gruposSanguineos.map((value, x) => <option key={x} value={value["TIP_CODIGO"]}>{value["TIP_NOMBRE"]}</option>);
        let factoresRh = data.factoresRh.map((value, x) => <option key={x} value={value["TIP_CODIGO"]}>{value["TIP_NOMBRE"]}</option>);
        let etnias = data.etnias.map((value, x) => <option key={x} value={value["TIP_CODIGO"]}>{value["TIP_NOMBRE"]}</option>);
        let frecuencias = data.frecuencias.map((value, x) => <option key={x} value={value["TIP_CODIGO"]}>{value["TIP_NOMBRE"]}</option>);

        await Promise.all([sanguineos, factoresRh, etnias, frecuencias])
        .then(res => {
            this.setState({
                optionsTipoSangre: res[0],
                optionsRh: res[1],
                optionsEtnia: res[2],
                optionsFrecuencias: res[3]
            });
        });
        return true;
    }

    loadDataPrincipal = (datos) => {
        this.frecMedico.value = datos.frecMedico;
        this.contact.value = datos.contactoEmergencia;
        this.numberContact.value = datos.celEmergencia;
        this.ciudadContact.value = datos.ciudadEmergencia;
        this.grupoSanguineo.value = datos.tipoSangre;
        this.factorRh.value = datos.rh;
        this.etnia.value = datos.etnia;

        if(datos.tieneComplementario === 1) {
            this.handlePlanComplementario(true);
            document.getElementById('radioPlansalud').checked = true;
        } else if (datos.tieneComplementario === 0) {
            this.handlePlanComplementario(false);
            document.getElementById('radioPlansalud2').checked = true;

            if(datos.quiereComplementario === 1) document.getElementById('radioQuierePlan').checked = true;
            else document.getElementById('radioQuierePlan2').checked = true;
        }

        if(datos.anteojos) document.getElementById('radioanteojos').checked = true;
        else document.getElementById('radioanteojos2').checked = true;

        if(this.state.sexo==="F") {
            if(datos.embarazo) document.getElementById('radioPregnancy').checked = true;
            else document.getElementById('radioPregnancy2').checked = true;
        }
    }

    guardarSalud = () => {
        if(validateInputTabs()) return;
        
        const dataUser = JSON.parse(localStorage.getItem("d_u"));;
        const cedula = Number(dataUser['cedula']);
        const empresa = Number(dataUser['empresa']);
        const usuLogin = dataUser.USU_LOGIN;
        const tieneComplementario = Number(document.querySelector('input[name=planSalud]:checked').value);
        if(this.numberContact.value.length < 10) {
            return Swal.fire({
                icon: 'warning',
                title: '¡Ups!',
                text: 'El número celular de emergencia no es válido.',
                confirmButtonColor: "#A6A6A6",
                confirmButtonText: 'Cerrar'
            });
        }

        const datos = {
            cedula,
            empresa,
            usuLogin,
            datbas: this.state.datbas,
            frecMedico: Number(this.frecMedico.value),
            tieneComplementario,
            quiereComplementario: !tieneComplementario ? Number(document.querySelector('input[name=quierePlanSalud]:checked').value) : null,
            anteojos: Number(document.querySelector('input[name=anteojos]:checked').value),
            embarazo: this.state.sexo==="F" ? Number(document.querySelector('input[name=pregnancyF]:checked').value) : null,
            contactoEmergencia: this.contact.value,
            celEmergencia: this.numberContact.value,
            ciudadEmergencia: this.ciudadContact.value,
            tipoSangre: Number(this.grupoSanguineo.value),
            factorRh: Number(this.factorRh.value),
            etnia: Number(this.etnia.value),
        }

        loadingDialog(true);
        const url = `${baseUrl}/v1/salud/guardarSalud`;
        postData(url, datos).then(result => {
            loadingDialog(false);
            if (result.error) {
                return Swal.fire({
                    icon: 'warning',
                    title: '¡Ups!',
                    text: result.error,
                    confirmButtonColor: "#A6A6A6",
                    confirmButtonText: 'Cerrar'
                });
            }

            Swal.fire({
                title: '',
                html: `
                <div class="text-center">
                    <img src="https://controlf.blob.core.windows.net/public/vumoffice/assets/
cv/check.png" alt="check" style="width:60px">
                    <h3 class="text-primary mt-3">¡Los datos de tu SALUD han sido guardados con éxito!</h3>
                    <h5 class="text-secondary">Te garantizamos un buen uso de tu información.</h5>
                    <a type="button" class="btn succesButton mt-3" href="/cv/vivienda">Continuar llenando "Vivienda"</a>
                </div>
                `,
                showCloseButton: false,
                showCancelButton: false,
                showConfirmButton: false
            });
            loadDataValidate();
        }).catch(err => {
            loadingDialog(false);
            Swal.fire({
                icon: 'warning',
                title: '¡Ups!',
                text: 'Ha ocurrido un error al guardar los datos de salud, intenta nuevamente.',
                confirmButtonColor: "#A6A6A6",
                confirmButtonText: 'Cerrar'
            })
        });
    }

    validateInputTabsIn = (tab) => {
        let valtab = validateInputTabs(tab)
        if (valtab === '') {
            this.setState({ estadonav: '' })
            simulateClick(tab, 0, 'click')
            setTimeout(() => {
                this.setState({ estadonav: 'disabled' })
            }, 500);
        }

    }

    vaidateBack = (tabidentificador) => {
        this.setState({ estadonav: '' })
        simulateClick(tabidentificador, 0, 'click')
        setTimeout(() => {
            this.setState({ estadonav: 'disabled' })
        }, 500);
    }

    handlePlanComplementario = (deshabilitar) => {
        let radios = document.querySelectorAll('input[name=quierePlanSalud]');
        let labelCampo = document.querySelector('label[for=quierePlanSalud]');
        if(deshabilitar) {
            if(labelCampo.getElementsByTagName('span').length) labelCampo.getElementsByTagName('span')[0].remove();
            radios[0].classList.remove('inputRequired');
        } else {
            labelCampo.innerHTML += '<span class="text-danger">*</span>';
            radios[0].classList.add('inputRequired');
        }
        radios.forEach(radio => radio.checked = false);
        this.setState({disableComplementario: deshabilitar});
    }

    render() {

        const { optionsTipoSangre, optionsRh, optionsEtnia, optionsFrecuencias, sexo, disableComplementario } = this.state;

        return <>

            <div className="card">
                <div className="card-header">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb fs-2">
                            <li className="breadcrumb-item">Mi perfil</li>
                            <li className="breadcrumb-item" aria-current="page">Salud</li>
                        </ol>
                    </nav>
                </div>
            </div>
            &nbsp;
            <div className="card">
                <div className="card-body">
                    
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="complementary" role="tabpanel" aria-labelledby="complementary-tab">
                            <div className="row g-3">
                                <div className="col-12 col-md-4">
                                    <label className='form-label' htmlFor='frecMedico'>¿Con qué frecuencia asiste al médico?<span className="text-danger">*</span></label>
                                    <select ref={inp => this.frecMedico = inp} className="form-select inputRequired" name="frecMedico" id="frecMedico">
                                        <option value="">Seleccione su respuesta</option>
                                        {optionsFrecuencias}
                                    </select>
                                </div>
                                <div className="col-12 col-md-4">
                                    <label className='form-label' htmlFor='planSalud'>¿Cuenta con plan complementario de salud?<span className="text-danger">*</span></label>
                                    <div className=" d-flex justify-content-around">
                                        <input value="1" type="radio" onChange={() => this.handlePlanComplementario(true)} name="planSalud" className="btn-check inputRequired" id="radioPlansalud" autoComplete="off" />
                                        <label className="btn btn-outline-primary w-50" htmlFor="radioPlansalud">SI</label>&nbsp;
                                        <input value="0" type="radio" onChange={() => this.handlePlanComplementario(false)} name="planSalud" className="btn-check" id="radioPlansalud2" autoComplete="off" />
                                        <label className="btn btn-outline-primary w-50" htmlFor="radioPlansalud2">NO</label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <label htmlFor="quierePlanSalud" className='form-label'>¿Le gustaría un plan complementario de salud?</label>
                                    <div className=" d-flex justify-content-around">
                                        <input disabled={disableComplementario} value="1" type="radio" name="quierePlanSalud" className="btn-check" id="radioQuierePlan" autoComplete="off" />
                                        <label className="btn btn-outline-primary w-50" htmlFor="radioQuierePlan">SI</label>&nbsp;
                                        <input disabled={disableComplementario} value="0" type="radio" name="quierePlanSalud" className="btn-check" id="radioQuierePlan2" autoComplete="off" />
                                        <label className="btn btn-outline-primary w-50" htmlFor="radioQuierePlan2">NO</label>
                                    </div>
                                </div>

                                <div className="col-12 col-md-4">
                                    <label className='form-label' htmlFor='anteojos'>¿Usa anteojos?<span className="text-danger">*</span></label>
                                    <div className=" d-flex justify-content-around">
                                        <input type="radio" name="anteojos" value="1" className="btn-check inputRequired" id="radioanteojos" autoComplete="off"></input>
                                        <label className="btn btn-outline-primary w-50" htmlFor="radioanteojos">SI</label>&nbsp;
                                        <input type="radio" value="0" name="anteojos" className="btn-check" id="radioanteojos2" autoComplete="off"></input>
                                        <label className="btn btn-outline-primary w-50" htmlFor="radioanteojos2">NO</label>
                                    </div>
                                </div>

                                <div hidden={sexo === 'M' ? 'hidden' : ''} className="col-12 col-md-4">
                                    <label className='form-label' htmlFor="pregnancyF">¿Está en embarazo?</label>
                                    <div className=" d-flex justify-content-around">
                                        <input type="radio" name="pregnancyF" value="1" className="btn-check" id="radioPregnancy" autoComplete="off"></input>
                                        <label className="btn btn-outline-primary w-50" /* onClick={() => this.seePregnancyTab(true)} */ htmlFor="radioPregnancy">SI</label>&nbsp;
                                        <input type="radio" name="pregnancyF" value="0" className="btn-check" id="radioPregnancy2" autoComplete="off"></input>
                                        <label className="btn btn-outline-primary w-50" /* onClick={() => this.seePregnancyTab(false)} */ htmlFor="radioPregnancy2">No</label>
                                    </div>
                                </div>
                                
                                {/*  ------------------------------------------------------------------------------  */}
                                <div className='col-12 mt-4'>
                                    <label className='fs-0 text-primary mb-0 fw-bold'>Contacto de emergencia</label>
                                    <hr className='text-primary my-1 fw-bold'></hr>
                                </div>
                                
                                <div className="col-12 col-md-4">
                                    <label className='form-label' htmlFor='contactoEmer'>Nombre de su contacto de emergencia:<span className="text-danger">*</span></label>
                                    <input ref={inp => this.contact = inp} type="text" className="form-control inputRequired" id="contactoEmer" name="contactoEmer" placeholder='Escriba aquí su respuesta'></input>
                                </div>
                                <div className="col-12 col-md-4">
                                    <label className='form-label' htmlFor='numeroEmer'>Celular del contacto de emergencia:<span className="text-danger">*</span></label>
                                    <input ref={inp => this.numberContact = inp} type="number" className="form-control inputRequired" id="numeroEmer" name="numeroEmer" placeholder='Escriba aquí su respuesta'></input>
                                </div>
                                <div className="col-12 col-md-4">
                                    <label className='form-label' htmlFor='ciudadEmer'>Ciudad de residencia de su contacto:<span className="text-danger">*</span></label>
                                    <input ref={inp => this.ciudadContact = inp} type="text" className="form-control inputRequired" id="ciudadEmer" name="ciudadEmer" placeholder='Escriba aquí su respuesta'></input>
                                </div>

                                {/*  ------------------------------------------------------------------------------  */}
                                <div className='col-12 mt-4'>
                                    <label className='fs-0 text-primary mb-0 fw-bold' htmlFor="labelDir-locale">Características físicas y más</label>
                                    <hr className='text-primary my-1 fw-bold'></hr>
                                </div>

                                <div className="col-12 col-md-4">
                                    <label htmlFor="grupoSanguineo" className='form-label'>Grupo sanguíneo<span className="text-danger">*</span></label>
                                    <select ref={inp => this.grupoSanguineo = inp} className="form-select inputRequired" name="grupoSanguineo" id="grupoSanguineo" >
                                        <option value="">Seleccione su respuesta</option>
                                        {optionsTipoSangre}
                                    </select>
                                </div>

                                <div className="col-12 col-md-4">
                                    <label htmlFor="factorRh" className='form-label'>Factor<span className="text-danger">*</span></label>
                                    <select ref={inp => this.factorRh = inp} className="form-select inputRequired" name="factorRh" id="factorRh" >
                                        <option value="">Seleccione su respuesta</option>
                                        {optionsRh}
                                    </select>
                                </div>
                                <div className="col-12 col-md-4">
                                    <label htmlFor="etnia" className='form-label'>Grupo étnico<span className="text-danger">*</span></label>
                                    <select ref={inp => this.etnia = inp} className="form-select inputRequired" name="etnia" id="etnia" >
                                        <option value="">Seleccione su respuesta</option>
                                        {optionsEtnia}
                                    </select>
                                </div>

                                
                                <div className='text-end mt-4'>
                                    <button onClick={() => this.guardarSalud()} className="btn succesButton col-12 col-lg-2 col-xxl-1">Guardar y continuar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }


}

export default Salud;