import './cardsOficina.css';
import { routes } from '../../environments/environments.ts';
import { Link } from 'react-router-dom';
import { registrarAccion } from "../../../src/generalHelpers";

export const CardsOficina = () => {

    let oficinas = [
        {
            imgUrl: '/assets/img/rrhh.jpg',
            nombre: 'RRHH',
            copy: '¡Estamos para ti! Encuentra en este espacio el acompañamiento para tu bienestar integral.',
            codigo:12,
            redirecTo: routes.rrhh.url,
        },
       /* {
            imgUrl: '/assets/img/desarrollo.jpg',
            nombre: 'Desarrollo',
            codigo:2,
            copy: 'Actualízate y capacítate para llevar tus conocimientos a otro nivel.',
            redirecTo: routes.desarrollo.url,
        },*/
        {
            imgUrl: 'https://controlf.blob.core.windows.net/public/vumoffice/assets/imagenesHome/UNIVERISIDAD-CORPORATIVA.jpg',
            nombre: 'Universidad corporativa',
            codigo:0,
            copy: 'Actualízate y capacítate para llevar tus conocimientos a otro nivel.',
            redirecTo: 'https://listos.vansa.co/cursos/',
            externo:true
        },
        {
            imgUrl: '/assets/img/sst.jpg',
            nombre: 'SST',
            codigo:11,
            copy: 'Queremos que seas un talento seguro, es por esto que, encontrarás en este espacio las herramientas para lograrlo.',
            redirecTo: routes.sst.url,
        },
        {
            imgUrl: '/assets/img/bienestar.jpg',
            nombre: 'Calidad de vida', //bienestar
            codigo:13,
            copy: 'Nos interesa el bienestar integral de nuestros trabajadores, ingresa aquí para conocer todos tus beneficios.',
            redirecTo: routes.bienestar.url,
        },
    ];

    return (
        <div className="container-cards">{
            oficinas.map(({ imgUrl, codigo, nombre, copy, redirecTo, externo }, idx) => {
                if (externo) {

                    return (
                        <div className='zoom' key={'accionMenu'+idx} >
                            <a href={redirecTo } target="_blank" rel="noopener noreferrer">
                                <div className='card sombraCards card-img' style={{ height: '100%' }}>
                                    <div className='container-img' style={{  }}>
                                        <img className='img-fluid imgHeaderCard' style={{  }} src={imgUrl} alt={nombre} />
    
                                        <div className='nombre'>{nombre}</div>
    
                                    </div>
                                     <div className='card-body oficinaBotomCopy' style={{ height: '90%', maxHeight: '90%' }}>
                                        <span className='card-title tittleCardOficina'>{nombre}</span> <br />
                                        <span className='card-text bodyCardOficina'>{copy}</span>
                                    </div> 
                                </div>
                            </a>
                        </div >
                    );
                    
                }else{

                    return (
                    <div onClick={()=>{registrarAccion({codigo})}} key={'accionMenu'+idx} style={{ with: '90%' }} className='zoom'>
                        <Link to={{ pathname: redirecTo }}>
                            <div className='card sombraCards card-img' style={{ height: '100%' }}>
                                <div className='container-img' style={{  }}>
                                    <img className='img-fluid imgHeaderCard' style={{  }} src={imgUrl} alt={nombre} />

                                    <div className='nombre'>{nombre}</div>

                                </div>
                                    <div className='card-body oficinaBotomCopy' style={{ height: '90%', maxHeight: '90%' }}>
                                    <span className='card-title tittleCardOficina'>{nombre}</span> <br />
                                    <span className='card-text bodyCardOficina'>{copy}</span>
                                </div> 
                            </div>
                        </Link>
                    </div>
                    );

                }
            })}
        </div>
    )
}
