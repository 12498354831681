import React, { useEffect } from "react";
import { useLocation, useHistory} from "react-router-dom";
import "./estilos-vum-office.css";
import "./dashboard.css";
import { CardsOficina } from "../../components/cards-oficina/CardsOficina";
import { ImportantBottons } from "../../components/ImportantBottons/ImportantBottons";
import { getFullNameUser } from "../../generalHelpers";
import Swal from "sweetalert2";
import { postData } from "../../components/general/General";
import { baseUrl } from "../../config/config";

export const Dashboard = () => {

  let location=useLocation();

  const history = useHistory();

  if(location.pathname !== '/home' && location.pathname !== '/' && location.pathname !== ''){
    Swal.fire({
        title: '',
        html: '<p>La opción a la que intentas acceder no esta disponible para personal con contrato inactivo</p>',
        showCancelButton: false,
        confirmButtonColor: '#2c7be5',
        confirmButtonText: 'Cerrar'       
      }).then((result) => {
        if (result.isConfirmed) {
          history.push('/home');
        }
      });
  };

  useEffect(() => {
    document.getElementById("root").className = "dashboard";
    window.scrollTo({ top: 0, behavior: "smooth" });
    /*Swal.fire({
      title: '',
      html: '<img style="width: 100%" src="https://controlf.blob.core.windows.net/vumoffice/assets/INGRESO%20VUM%20OFFICE.png" alt="MDN" />',
      showCancelButton: false,
      confirmButtonColor: '#2c7be5',
      confirmButtonText: 'Cerrar'
    });*/
  }, []);

  return (
    <div className="card-body">
      <div className="tab-content">
        <div
          className="tab-pane preview-tab-pane active"
          role="tabpanel"
          aria-labelledby="tab-dom-533ab411-31a3-4e27-87a1-2e6e0f25835c"
          id="dom-533ab411-31a3-4e27-87a1-2e6e0f25835c"
        >
          <div className="row light">
            <div className="col-md-12 text-center mb-5">
              <img
                className="imgVumHeader"
                src="https://controlf.blob.core.windows.net/public/vumoffice/assets/logos/logo_office_blanco.png"
                alt="icono-vum-office-home"
              />
            </div>

            <div className="col-md-12 text-center mb-3" id="headerMovil">
              <h3 className="tituloComoTePodemosAyudar fs-4">
                HOLA {getFullNameUser().split(' ')[0].toUpperCase()}
              </h3>
              <span className="spanNombreUsuario fs-2">¡Esta es tu oficina virtual!</span>
            </div>

            <CardsOficina />
            <ImportantBottons />
          </div>
        </div>
      </div>
    </div>
  );
};
