import React, { useEffect } from 'react';
import './rrhh.css';
import { FormRrhh } from './FormRrhh/FormRrhh';

export const Rrhh = () => {

    useEffect(() => {
        document.getElementById('root').className = 'rrhh';
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [])


    return (
        <div className="col-md-12 ps-md-5 pt-md-3 pe-md-6  mt-4 mt-md-0">

            <div className="col-md-12 ms-md-2 mb-4 d-none d-md-block">
                        <img
                            style={{width:"150px", height:"80"}}
                            src="https://controlf.blob.core.windows.net/public/vumoffice/assets/logos/logo_office_blanco.png"
                            alt="icono-vum-office-home"
                        />
            </div>           
            <div className="col-md-8 divTitulosRRHH">
                <h3 className="tituloModulo">Oficina de RRHH</h3>
                <p className="copyPage">Aquí encontrarás acompañamiento en lo que necesites para tu bienestar integral como ser humano.</p>
            </div>
            <div className='col-12 col-lg-12 col-xl-8'>

            <FormRrhh location={ {params: { typeForm: "SolicitudesRRHH" }} }/>
            </div>
        </div>

    )
}
