import { useEffect, useState, Component } from 'react';
import Select, { createFilter } from 'react-select';
import { getCities } from '../../repositories/generalInfo';
import { ColourStyles } from '../Inputs/Multiple/ColourStyles';
import { FixedSizeList as List } from "react-window";
import { AsteriskRequired } from '../AsteriskRequired/AsteriskRequired';
import PropTypes from 'prop-types';


class MenuList extends Component {
    render() {
        const height = 35;
        const { options, children, maxHeight, getValue } = this.props;
        const [value] = getValue();
        const initialOffset = options.indexOf(value) * height;

        return (
            (options.length === 0) ?
                <></>
                :
                <List
                    height={maxHeight}
                    itemCount={children.length}
                    itemSize={height}
                    initialScrollOffset={initialOffset}
                >
                    {({ index, style }) => <div title={children[index].props.data.fullLabel} style={style}>{children[index]}</div>}
                </List>
        );
    }
}

export const Cities = ({ form, disabled = false, city, setCity, handleCityUpdate, all }) => {

    const [cities, setCities] = useState([]);

    useEffect(() => {
        getCities(all).then(cities => {
            
            if (cities) {

                setCities(cities);

                if (form?.city) {
                    const city = cities.filter(currentValue => (currentValue.value === form?.city))?.[0] || '';
                    setCity(city);
                }
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <div style={{ zIndex: 11 }}>
            <label><AsteriskRequired/>Ciudad:</label>
            <Select

                components={{ MenuList }}
                captureMenuScroll={false}
                filterOption={createFilter({ ignoreAccents: false })}

                placeholder={'Seleccione...'}
                styles={ColourStyles}
                onChange={handleCityUpdate}
                isDisabled={disabled}
                value={city}
                options={cities}
            />
            <input
                tabIndex={-1}
                autoComplete="off"
                style={{
                    opacity: 0,
                    width: "20%",
                    height: 0,
                    position: "absolute"
                }}
                onChange={() => { }}
                value={city}
                required='required'
            />
        </div>
    )
}

MenuList.propTypes = {
    options: PropTypes.array.isRequired,
    children: PropTypes.any.isRequired,
    maxHeight: PropTypes.number.isRequired,
    getValue: PropTypes.func.isRequired
}

Cities.propTypes = {
    form: PropTypes.object,
    disabled: PropTypes.bool,
    city: PropTypes.any.isRequired,
    setCity: PropTypes.func.isRequired,
    handleCityUpdate: PropTypes.func.isRequired,
    all: PropTypes.bool.isRequired
}