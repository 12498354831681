import React from 'react';
import '../css/TerminosCondiciones.css';

 const PrivacidadAutorizacionTratamientoDatosPersonales = ()=>{

return (
    <div className='content-term'>

        <div className='header-term'>

            <div className='div-icon'>
            <img src="../../assets/img/logo-vum-login.png" alt="logo-vum-login" className='image'/>
            </div>

            <div className='div-title'>
                <div className='title-top'>
                    <h3 className='size1'>PROCESO:
                    <br/>
                       GESTIÓN DEL RIESGO Y MEJORA CONTINUA</h3>
                </div>
                <div className='title-bottom'>
                <h3 className='size1'>AVISO DE PRIVACIDAD Y AUTORIZACIÓN PARA EL TRATAMIENTO DE DATOS PERSONALES
                </h3>
                </div>
            </div>

            <div className='div-codigo size2'>
               <div className='title-top'>
                   <h3 className='size2'>Código 
                   <br/>
                   FO-MEJ-16
                   </h3>
               </div>
               <div className='title-bottom padding2'>
               <h3 className='size2'>Versión: 01</h3>
               </div>
            </div>

        </div>

        <div className='text-content'>
            <p className='size2'>
            Por medio de la presente, y teniendo como soporte el Decreto 1377 de 2013, reglamentario de la Ley 1581 de 2012 y demás normas que lo modifiquen, actualicen o deroguen, autorizo a LISTOS BPO S.A.S con Nit 901.216.520 en adelante “VUM” como responsable de mis datos personales y de mi núcleo familiar correspondiente a los menores de edad en los términos del Art (2.2.2.25.2.9) del Decreto 1074 de 2015, también aquellos datos sensibles obtenidos o que lleguen a obtener en el futuro como son los exámenes médicos, resultados diagnósticos, pruebas de laboratorio y datos de la salud, también datos como fotografías, videograbaciones y audios. Lo anterior por tratarse de Datos Sensibles, el Titular no está obligado a autorizar su Tratamiento.
            </p>

             <h3 className='size1'><b>TRATAMIENTO Y FINALIDADES:</b></h3>

             <p className='size2'>
             Se informa a los Titulares que la Política de “VUM” está compuesta por anexos que describen cada finalidad que autoriza el Titular de acuerdo con su vinculación a “VUM”, tales como Usuarios, Contratistas - Proveedores, Clientes, Empleados - Candidatos y otros terceros Titulares cuyos datos sean objeto de Tratamiento por parte de “VUM”, lo cual se especifica en los anexos de este Aviso.
             </p>

             <p className='size2'>Esta autorización permite a VUM no solo a consultar, recolectar y almacenar, sino también a transferir, usar, circular, suprimir, compartir, actualizar y transmitir, de acuerdo con el Procedimiento para el tratamiento de datos personales.</p>

             <p className='size2'>
             Las finalidades de esta autorización comprenden la facultad a “VUM" y a las empresas asociadas y Entidades autorizadas, para el desempeño en las funciones del Sistema de Gestión de Seguridad y Salud en el Trabajo, evaluar la situación de ingreso y egreso del personal, aptitud, prevención, control y seguimiento a la salud, suministrar la información a terceros con los cuales “VUM” tenga o tuvo relación contractual y que sea necesario entregársela para el cumplimiento del objeto contratado; así:
             </p>

             <h3 className='size1'><b>ANEXO 1. USUARIOS DE LAS PÁGINAS WEB Y/O LA APLICACIÓN</b></h3>

             <p className='size2'>
             a. Para realizar el registro en la Página Web, la Aplicación y/o Páginas Vinculadas, recibir acceso a los servicios a través de internet, o para la suscripción a los servicios de la Página Web, la Aplicación y/o alguna Página Vinculada. 
             <br/>
             b. Cumplir las obligaciones legales y/o contractuales de “VUM” y/o las Entidades Autorizadas, en desarrollo de su actividad civil y comercial.
              <br/>
              c. Garantizar la correcta operación, entrega y contratación de los bienes y servicios provistos por “VUM”.
              <br/>
              d. Lograr una eficiente comunicación con el Titular de la información, a través de diferentes medios de contacto (teléfono, email, correo físico, chat, mensajes instantáneos, redes sociales etc.), en relación con nuestros productos, servicios, promociones, alianzas, estudios, concursos, eventos, contenidos, campañas publicitarias y de mercadeo, beneficios, condiciones o políticas aplicables, canales de atención, nuestras redes sociales, así como los de las Entidades Autorizadas y y los Aliados o Clientes comerciales de “VUM”.
              <br/>
              e. Realizar gestiones relacionadas con recursos humanos y con el reclutamiento de personal. Se incluye, pero no se limita a: la intermediación entre Clientes y Usuarios para la aplicación de oferta de empleo.
              <br/>
              f. Permitir la publicación y gestión de ofertas laborales en “VUM”, así como el registro y elaboración de las hojas vida.
              <br/>
              g. Desarrollo de actividades de validación de la información publicada en “VUM”.
              <br/>
              h. Permitir a los Clientes o Aliados la consulta de hojas de vida en la base de datos de “VUM”.
              <br/>
              i. Permitir la búsqueda filtrada y la aplicación a ofertas laborales publicadas en “VUM”.
              <br/>
              j. La realización de encuestas salariales y ambiente laboral.
              <br/>
              k. Adquirir los servicios de “VUM” VIP o similares.
              <br/>
              l. Permitir la realización de pruebas para la identificación de afinidades para una oferta laboral publicada en “VUM”, tales como, pero sin limitarse a: la prueba PDA (Personal Development Analysis).
              <br/>
              m. Intermediar el contacto entre Usuarios y Clientes o Aliados, para la aplicación a ofertas de empleo publicados en los micrositios de los Clientes. En estos casos se puede presentar una recolección conjunta de los Datos Personales entre “VUM” y el Cliente o Aliado. El Cliente en este
             caso debe tratar los Datos Personales conforme a las finalidades particulares estipuladas en la presente Política, autorizadas por el Titular de los Datos Personales.
             <br/>
             n. Brindar asesoría de índole educativa, en la que se incluye, pero no se limite a: la realización de pruebas vocacionales, la intermediación entre estudiantes y/o aspirantes e instituciones educativas, realización de pruebas idiomas, de conocimiento y/o simulacros de pruebas estatales nacionales o extranjeras, tales como “Saber Once (11)” o la que haga las veces en cualquier país.
             <br/>
             o. Recolectar y publicar estadísticas respecto de las pruebas vocacionales o de diferente índole que realice “VUM”.
             <br/>
             p. Permitir el contacto, instituciones educativas o financieras para créditos educativos (Clientes o Aliados) y Usuarios que manifiesten interés en recibir asesoría personalizada respecto de los servicios ofertados por “VUM” o sus Clientes o Aliados. El Clientes o Aliados en este caso debe tratar los Datos Personales conforme a las finalidades particulares estipuladas en la presente Política, autorizadas por el Titular de los Datos Personales o su Representante Legal e caso de ser menor de edad.
             <br/>
             q. Redireccionar Datos Personales a instituciones académicas (Clientes o Aliados) según el interés del Usuario. El Cliente en este caso debe tratar los Datos Personales conforme a las finalidades particulares estipuladas en la presente Política, autorizadas por el Titular de los Datos Personales o su Representante Legal en caso de ser menor de edad.
             <br/>
             r. Intermediar el contacto entre Usuario e instituciones académicas (Clientes o Aliados) sobre las que haya interés por parte del Usuario. En estos casos se puede presentar una recolección conjunta de los Datos Personales entre “VUM” y el Clientes o Aliados. El Cliente en este caso debe tratar los Datos Personales conforme a las finalidades particulares estipuladas en la presente Política, autorizadas por el Titular de los Datos Personales.
             <br/>
             s. Brindar asesoría educativa a los Usuarios respecto de pregrados y posgrados, asesoría en programas en el exterior e idiomas.
             <br/>
             t. Identificar, recolectar y asociar a los datos, información sobre preferencias de navegación del titular en los portales de “VUM” y/o de Las Entidades Autorizadas, así como datos de georreferenciación o y/o ubicación específica que generen dispositivos móviles, para mejorar la experiencia del usuario, conocer su perfil de navegación, entregar información y/o publicidad segmentada sobre productos y/o servicios propios o de Clientes o Aliados,
            calificar la propensión y/o afinidad a productos o servicios de “VUM” y/o de Las Entidades Autorizadas y/o de los Clientes o Aliados. Para mayor detalle, ver Política de Datos de Navegación- “Cookies”.
             <br/>
             u. Informar sobre cambios en nuestros productos, servicios o beneficios, así como informar respecto de los nuevos que estén relacionados con el o los productos o servicios contratado(s) o adquirido(s).
             <br/>
             v. Entregar y ofrecer al Titular de forma general o segmentada, información, contenido y/o publicidad, de “VUM”, las Entidades Autorizadas y/o de Clientes o Aliados, y calificar la propensión y/o afinidad a productos o servicios de “VUM” y/o de Las Entidades Autorizadas y/o de Clientes o Aliados para realizar segmentaciones o perfilamiento de usuarios, por medio de analítica para los propósitos comerciales de “VUM”.
             <br/>
             . w. Elaborar y reportar información estadística, encuestas de satisfacción, estudios y análisis de mercado o de consumo, incluyendo la posibilidad de contactarlo para dichos propósitos por parte de “VUM” y/o Las Entidades Autorizadas y/o los Clientes o Aliados.
             <br/>
             x. Elaborar informes estadísticos, estudios y análisis de mercadeo o de consumo a partir de información anónima y/o anonimizada que podrán ser utilizados por “VUM” y/o las Entidades Autorizadas y/o Clientes o Aliados, quienes podrán disponer discrecionalmente de la totalidad de la información.
             <br/>
             y. Entregar información y/o publicidad segmentada sobre productos y/o servicios propios y/o de Las Entidades Autorizadas y/o de Clientes o Aliados, así como calificar la propensión y/o afinidad a productos o servicios de “VUM” y/o de Las Entidades Autorizadas y/o de Clientes o Aliados.
             <br/>
             z. Identificar, recolectar y asociar a los datos, información sobre sus hábitos de compra o intereses, tales como: la asistencia a eventos públicos o privados de “VUM” y/o Las Entidades Autorizadas y/o Clientes o Aliados.
             <br/>
             aa. Identificar, recolectar y asociar a los datos, información que obtengamos sobre su comportamiento en nuestras redes sociales, tales como hábitos de compra o intereses.
             <br/>
             bb. Hacer perfilamientos de bases de datos o generar perfiles básicos y demográficos con los Datos Personales, y realizar cruce de bases de datos de “VUM” Las Entidades Autorizadas y/o Clientes o Aliados, tales como, pero no limitados a: la Registraduría Nacional del Estado Civil y sus aliados tecnológicos certificados, operadores de información comercial o crediticia, entidades que formen parte del Sistema de Seguridad Social Integral, empresas prestadoras de servicios públicos, con miras a enriquecer las bases de datos de “VUM” y/o de las Entidades Autorizadas, con miras a estudiar la viabilidad de actividades comerciales de “VUM”.
             <br/>
             cc.. las Entidades Autorizadas y/o de los Clientes o Aliados, tales como monetizar los datos, crear perfiles de usuarios, prestar servicios personalizados o enriquecer las bases de datos de “VUM”.
                y/o de las Entidades Autorizadas y/o Clientes o Aliados y poder prestar servicios más personalizados. Todo lo anterior conforme a las autorizaciones recolectadas por “VUM”, de los Titulares.
            <br/>
                dd.. Transferir, transmitir o permitir la consulta de los datos recolectados a terceros aliados o clientes de “VUM”, dentro y fuera del país, como agencias de publicidad o marketing, entidades gubernamentales, empresas especializadas en riesgos, crédito o cobranza, operadores de información, fuentes de información, entidades financieras y educativas, con la finalidad de verificar su información, determinar hábitos de consumo y preferencias para prestar sus servicios o proveer con mayor eficiencia los mismos, adelantar programas de fidelidad o lealtad, otorgarte beneficios y realizar análisis de datos orientados a score crediticio, concesión de crédito y personalización de productos y servicios. Estos clientes terceros accederían a la información personal estrictamente necesaria para prestar sus los servicios según las finalidades indicadas anteriormente.
            <br/>
            ee.. Solicitar, capturar, consultar, actualizar, suministrar, reportar, procesar, transmitir, transferir, usar, poner en circulación y divulgar toda la información que se refiere al comportamiento crediticio, financiero y comercial de los Usuarios de “VUM”, sea positivo o negativo, cuantas veces se requiera, respecto de las transacciones comerciales con “VUM”, a las Bases de Datos o centrales de información financiera o crediticia, de Operadores de Información y/o Entidades Financieras residentes o no en Colombia, y/o que presten el mismo servicio o quien represente sus derechos. Con este tratamiento se busca que el comportamiento de las obligaciones de los Usuarios sea registrado con el objeto de suministrar información suficiente y adecuada al mercado sobre el estado de las obligaciones financieras, comerciales, crediticias y/o de servicios del Cliente. Lo anterior, en cumplimiento de la Ley 1266 de 2008 y demás normas aplicables.
            <br/>
            ff.. Transmitir sus Datos Personales dentro o fuera de Colombia, incluso a países que no cuentan con niveles superiores o iguales de protección, a terceros Encargados del Tratamiento para la prestación de servicios tales como: cloud computing, contact center, e-mail marketing y/o cualquier otro servicio que se requiera para la provisión de nuestros productos o servicios.
            <br/>
            gg.. Transferir sus Datos Personales dentro o fuera de Colombia, incluso a países que no cuentan con niveles superiores o iguales de protección, a terceros o a la(s) compañía que a futuro pueda(n) administrar y/o adquirir, total o parcialmente, a “VUM” y/o una de las Entidades Autorizadas o alguno de las unidades de negocios o activos de estas. Estos obrarán a su vez como Responsables de los Datos Personales.
            <br/>
            hh.. Transferir o transmitir a los anunciantes, Entidades Autorizadas y/o Clientes de nuestras Páginas Web, los Datos Personales que haya entregado, para que estos, limitándose a, puedan contactar a los Titulares en relación con los productos y/o servicios para los cuales entregó sus datos.
            <br/>
            ii.. Evaluar la calidad de nuestros productos y servicios, a fin de mejorarlos e implementar medidas de seguridad y control cuando así se requiera.
            <br/>
            jj. Otras finalidades relacionadas directamente con la prestación del servicio o la relación legal o contractual entre las partes, y/o aquellas finalidades provenientes de las leyes aplicables a los negocios de “VUM”.
             </p>

             <h3 className='size1'><b>ANEXO 2. FINALIDADES ESPECÍFICAS EMPLEADO – CANDIDATO</b></h3>

             <p className='size2'>
             Respecto de los Empleados y Candidatos a Empleados
             <br/>
             <br/>
             a. Gestionar datos relacionados con recursos humanos, procesos de selección, análisis organizacional, desarrollo y manejo de reportes de desempeño de los contratos laborales, gestión de las relaciones laborales, procesamiento, gestión, pago de nómina y cumplimiento de las obligaciones legales, y cualquier otra gestión relativa al desarrollo de la relación laboral entre las partes.
             <br/>
             b. Consultar antecedentes personales, laborales, disciplinarios, judiciales y/o comerciales, con las siguientes entidades o bases de datos: anteriores empleadores; instituciones de educación en donde haya cursado algún estudio o capacitación; centrales de información crediticia, como Data crédito o cualquier otra entidad que maneje este tipo de información en el país; lista ONU, OFAC o similares; antecedentes disciplinarios; antecedentes judiciales; antecedentes fiscales; y/o cualquier otro antecedente que esté en bases de datos públicas o privadas. Estas consultas podrá realizarlas “VUM” periódicamente a sus empleados.
             <br/>
             c. Realizar una visita domiciliaria en mi residencia, para lo cual autorizo el registro fotográfico respectivo, que se haga una entrevista en mi domicilio con las personas con las que conviva, se indague sobre las condiciones de la convivencia y económicas mías y de mi familia y/o de las personas con las que resida, así como la verificación de referencias personales y laborales entregadas durante la visita.
             <br/>
             d. Lograr una eficiente comunicación con el Titular de la información, a través de cualquier medio de contacto (teléfono, email, correo físico, chat, mensajes instantáneos etc.), en relación con la actividad laboral o cualquier actividad relacionada o que “VUM” considere que podría ser de interés de sus Empleados, tales como: información relativa a promociones, productos y servicios de “VUM” y/o sus Entidades Autorizadas y/o Terceros.
             <br/>
             e. Gestionar los Datos Personales del Titular y los de su núcleo familiar para realizar trámites de afiliación a las entidades promotoras de salud −EPS−, cajas de compensación familiar, administradoras de riesgo laboral −ARL−, para aplicar alivios tributarios, comunicar beneficios, actividades y demás necesarias para que “VUM” y/o sus Entidades Autorizadas cumplan su deber como empleador.
             <br/>
             f. Responder solicitudes del trabajador sobre expedición de certificados, constancias y demás documentos solicitados a “VUM” debido al vínculo laboral.
             <br/>
             g. Gestionar sus Datos Personales para garantizar una correcta asignación de las herramientas de trabajo (incluidas las herramientas TI tales como correo electrónico, computadoras, dispositivos móviles, accesos a bases datos, etc.).
             <br/>
             h. Gestionar mis Datos Personales para garantizar una correcta ejecución de lo establecido en el Reglamento Interno de Trabajo, incluidos los procesos disciplinarios e investigaciones pertinentes. i. Habilitar su ingreso a las instalaciones físicas de “VUM” y realizar el control de entradas y salidas de estas.
             <br/>
             i. Transmitir mis Datos Personales dentro o fuera de Colombia, incluso a países que no cuentan con niveles superiores o iguales de protección, a las Entidades Autorizadas y a terceros Encargados del Tratamiento para la prestación de servicios tales como: cloud computing, contact center, e-mail marketing y/o cualquier otro servicio que se requiera para el desarrollo de sus actividades en relación con la provisión de los productos o servicios de “VUM” y/o sus Entidades Autorizadas.
             <br/>
             j. Transferir mis Datos Personales dentro o fuera de Colombia, incluso a países que no cuentan con niveles superiores o iguales de protección, a terceros, a las Entidades Autorizadas para otros procesos de selección que puedan ser de interés del Titular o a la(s) compañía que a futuro pueda(n) administrar y/o adquirir, total o parcialmente, a “VUM” y/o una de las Entidades Autorizadas o alguno de las unidades de negocios o activos de estas. Estos obrarán a su vez como Responsables de los Datos Personales.
             <br/>
             k. Solicitar, capturar, consultar, actualizar, suministrar, reportar, procesar, transmitir, transferir, usar, poner en circulación y divulgar toda la información que se refiere al comportamiento crediticio, financiero y comercial de los Clientes de “VUM”, sea positivo o negativo, cuantas veces se requiera, respecto de las transacciones comerciales con “VUM”, a las Bases de Datos o centrales de información financiera o crediticia, de Operadores de Información y/o Entidades Financieras residentes o no en Colombia, y/o que presten el mismo servicio o quien represente sus derechos. Con este tratamiento se busca que el comportamiento de las obligaciones de los Empleados sea registrado con el objeto de suministrar información suficiente y adecuada al mercado sobre el estado de las obligaciones financieras, comerciales, crediticias y/o de servicios del Cliente. Lo anterior, en cumplimiento de la Ley 1266 de 2008 y demás normas aplicables.
             <br/>
             l. Otras finalidades directamente relacionadas con la prestación del servicio o la relación legal o contractual entre las partes como enviar información a las IPS o entidades autorizadas para la práctica de exámenes ocupacionales de ingreso, periódicos o de retiro/o aquellas finalidades provenientes de las leyes aplicables a los negocios de “VUM”. Sus Datos Personales y aquellos de terceros que usted suministre, tales como referencias laborales o información de sus hijos menores de edad para el cumplimiento de disposiciones de seguridad social u otras relacionadas con bienestar laboral y programas similares, cuentan con la Autorización de los Titulares para ser entregados y tratados de acuerdo con las finalidades contempladas en la presente Política.
             </p>

             <h3 className='size1'><b>ANEXO 3. FINALIDADES ESPECÍFICAS PARA CONTRATISTAS - PROVEEDORES</b></h3>
              <p className='size2'>
              Respecto de los Contratistas/Proveedores
              <br/>
              <br/>
            a. Recolectar, registrar y actualizar sus Datos Personales con la finalidad de informar, comunicar, organizar, controlar, atender, acreditar las actividades en relación con su condición de Contratista y/o Proveedor relacionado con “VUM” y/o sus Entidades Autorizadas y demás trámites asociados a cargo del responsable del Tratamiento.
            <br/>
            b. Desarrollar y aplicar procesos de selección, evaluación, elaboración de respuestas a una solicitud de información, elaborar solicitudes de cotización y propuestas, y/o adjudicación de contratos.
            <br/>
            c. Disponer de información suficiente, verificada y actualizada, para el registro e identificación de cada Proveedor.
            <br/>
            d. Utilizar, en el evento que sea necesario, los Datos Personales del colaborador y/o representante del Proveedor con el fin de mantener un contacto directo y/o establecer controles de acceso a la infraestructura lógica o física de “VUM”.
            <br/>
            e. Administrar los datos de carácter personal para realizar pagos a Proveedores, incluida la administración de los números de cuentas bancarias y/o condición tributaria para la correcta gestión de los pagos.
            <br/>
            f. Realizar procedimientos de pagos de facturas “VUM” y/o sus Entidades Autorizadas.
            <br/>
            g. Solicitar, capturar, consultar, actualizar, suministrar, reportar, procesar, transmitir, transferir, usar, poner en circulación y divulgar toda la información que se refiere al comportamiento crediticio, financiero y comercial de los Proveedores de “VUM”, sea positivo o negativo, cuantas veces se requiera, respecto de las transacciones comerciales con “VUM”, a las Bases de Datos o centrales de información financiera o crediticia, de Operadores de Información y/o Entidades Financieras residentes o no en Colombia, y/o que presten el mismo servicio o quien represente sus derechos. Con este tratamiento se busca que el comportamiento de las obligaciones de los Proveedores-Contratistas, sea registrado con el objeto de suministrar información suficiente y adecuada al mercado sobre el estado de las obligaciones financieras, comerciales, crediticias y/o de servicios del Cliente. Lo anterior, en cumplimiento de la Ley 1266 de 2008 y demás normas aplicables.
            <br/>
            h. Lograr una eficiente comunicación con el Titular de la información, a través de cualquier medio de contacto (teléfono, email, correo físico, chat, mensajes instantáneos etc.), en relación con la actividad comercial o cualquier actividad relacionada o que “VUM” considere que podría ser de interés de sus Contratistas, tales como: información relativa a promociones, productos y servicios de “VUM” y/o sus Entidades Autorizadas y/o Terceros. En todo caso, el Titular podrá solicitar no recibir información promocional o de servicios de “VUM”, diferentes a la relación contractual que vincula al Titular y a “VUM”.
            <br/>
            i. Para asignar los indicadores de retención fiscales o tributarias a que haya lugar, de acuerdo a las obligaciones del Contratista/Proveedor.
            <br/>
            j. Transmitir sus Datos Personales dentro o fuera de Colombia, incluso a países que no cuentan con niveles superiores o iguales de protección, a terceros Encargados del Tratamiento para la prestación de servicios tales como, pero no limitados a: cloud computing, contact center, e-mail marketing y/o cualquier otro servicio que se requiera para la provisión de nuestros productos o servicios.
            <br/>
            k. Transferir sus Datos Personales dentro o fuera de Colombia, incluso a países que no cuentan con niveles superiores o iguales de protección, a terceros o a la(s) compañía que a futuro pueda(n) administrar y/o adquirir, total o parcialmente, a “VUM” y/o una de las Entidades Autorizadas o alguno de las unidades de negocios o activos de estas. Estos obrarán a su vez como Responsables de los Datos Personales.
            <br/>
            l. Evaluar la calidad de los productos y servicios ofrecidos o prestados a “VUM”.
            <br/>
            m. Cumplir cualquier otra obligación legal que se encuentre a cargo de “VUM” conforme a la relación contractual y/o comercial.<br/>
            n. Otras finalidades conexas o relacionadas con el servicio o la relación legal o contractual entre las partes. “VUM” entiende que sus Datos Personales y los de terceros que usted como Contratista suministre, tales como trabajadores autorizados para llevar a cabo la gestión o servicio encomendado, referencias y certificaciones comerciales, cuentan con la Autorización de los Titulares p para ser entregados y tratados de acuerdo con las finalidades contempladas en la presente Política.
              </p>

              <h3 className='size1'><b>ANEXO 4. FINALIDADES ESPECÍFICAS PARA CLIENTES – ALIADOS</b></h3>

              <p className='size2'>

              a. Recolectar, registrar y actualizar sus Datos Personales con la finalidad de informar, comunicar, organizar, controlar, atender, acreditar las actividades en relación con su condición de Cliente y/o tercero relacionado con “VUM” y/o sus Entidades Autorizadas y demás trámites asociados a cargo del Responsable del Tratamiento. <br/>
                b. Garantizar la correcta operación, entrega y contratación de los bienes y servicios provistos por “VUM”.
                <br/>
                c. Utilizar, en el evento que sea necesario, los Datos Personales del colaborador o representante del Cliente con el fin mantener un contacto directo y/o de establecer controles de acceso a la infraestructura lógica o física de “VUM”.
                <br/>
                d. Administrar los datos de carácter personal para realizar cobros a los Clientes.
                <br/>
                e. Realizar procedimientos de cobros de facturas “VUM” y/o sus Entidades Autorizadas.
                <br/>
                f. Para realizar procedimientos y gestiones de cobranza y el ejercicio de los derechos de “VUM” y/o sus Entidades Autorizadas. “VUM” podrá solicitar, capturar, consultar, actualizar, suministrar, reportar y procesar la información que se refiere al comportamiento crediticio, financiero y comercial de sus Clientes, sea positivo o negativo, cuantas veces se requiera, respecto de las transacciones comerciales con “VUM”, a las bases de datos o centrales de información financiera o crediticia, de Operadores de Información y/o Entidades Financieras residentes o no en Colombia, y/o que presten el mismo servicio o quien represente sus derechos a fin de retrata el comportamiento financiero .
                <br/>
                g. Transmitir sus Datos Personales dentro o fuera de Colombia, incluso a países que no cuentan con niveles superiores o iguales de protección, a terceros Encargados del Tratamiento para la prestación de servicios tales como, pero no limitados a: cloud computing, contact center, e-mail marketing y/o cualquier otro servicio que se requiera para la provisión de nuestros productos o servicios.
                <br/>
                h. Transferir sus Datos Personales dentro o fuera de Colombia, incluso a países que no cuentan con niveles superiores o iguales de protección, a terceros o a la(s) compañía que a futuro pueda(n) administrar y/o adquirir, total o parcialmente, a “VUM” y/o una de las Entidades Autorizadas o alguno de las unidades de negocios o activos de estas. Estos obrarán a su vez como Responsables de los Datos Personales.
                <br/>
                i. Solicitar, capturar, consultar, actualizar, suministrar, reportar, procesar, transmitir, transferir, usar, poner en circulación y divulgar toda la información que se refiere al comportamiento crediticio, financiero y comercial de los Clientes de “VUM”, sea positivo o negativo, cuantas veces se requiera, respecto de las transacciones comerciales con “VUM”, a las Bases de Datos o centrales de información financiera o crediticia, de Operadores de Información y/o Entidades Financieras residentes o no en Colombia, y/o que presten el mismo servicio o quien represente sus derechos. Con este tratamiento se busca que el comportamiento de las obligaciones de los Proveedores-Contratistas, sea registrado con el objeto de suministrar información suficiente y adecuada al mercado sobre el estado de las obligaciones financieras, comerciales, crediticias y/o de servicios del Cliente. Lo anterior, en cumplimiento de la Ley 1266 de 2008 y demás normas aplicables.
                <br/>
                j. Evaluar la calidad de los productos y servicios ofrecidos o prestados a “VUM”.
                <br/>
                k. Suministrar herramientas de clasificación y evaluación del personal.
                <br/>
                l. Ofrecer asesoría en seguridad social, administración de nóminas, entre otros.
                <br/>
                m. Cumplir cualquier otra obligación legal que se encuentre a cargo de “VUM” conforme a la relación contractual y/o comercial.
                <br/>
                n. Otras finalidades conexas o relacionadas con el servicio o la relación legal o contractual entre las partes.
                <br/>
                <br/>
                Declaro que he sido informado que, como titular, tengo los siguientes derechos:
                <br/>
                <br/>
                • Conocer, actualizar y corregir mis Datos Personales. Puedo ejercer este derecho, entre otros, en relación con la información, parcial, inexacta, incompleta, dividida, información engañosa o cuyo tratamiento sea prohibido o no autorizado.
                <br/>
                • Requerir prueba del consentimiento otorgado para la recolección y el tratamiento de mis Datos Personales.
                <br/>
                • Ser informado por “VUM” del uso que se le han dado a mis Datos Personales.
                <br/>
                • Presentar quejas ante la Superintendencia de Industria y Comercio en el caso en que haya una violación por parte de “VUM”.
                <br/>
                • Revocar la autorización y/o solicitar la supresión de datos personales a menos que exista un deber legal contractual que haga imperativo conservar la información otorgada para el tratamiento de mis Datos Personales.
                <br/>
                • Sobre el concepto de datos sensibles del Art (6) de la Ley 1581 de 2012 y su tratamiento especial.
                <br/>
                • Solicitar ser eliminado de su base de datos.
                <br/>
                • Acceder en forma gratuita a los datos proporcionados que hayan sido objeto de tratamiento.
                <br/>
                <br/>
                Dicha autorización para adelantar el tratamiento de mis datos personales se extiende durante la totalidad del tiempo en el que pueda llegar consolidarse un vínculo o este persista por cualquier circunstancia con “VUM” y con posterioridad al finiquito de este, siempre que tal tratamiento se encuentre relacionado con las finalidades para las cuales los datos personales, fueron inicialmente suministrados.
                <br/>
                a) En ese sentido, declaro conocer que los datos personales objeto de tratamiento, serán utilizados específicamente para las finalidades derivadas de las relaciones legales, contractuales, comerciales y/o de cualquier otra que surja.
                <br/>
                b) Que se realicen las consultas necesarias en diferentes listas restrictivas como las de la autoridad o privada competente para certificar la información de antecedentes que sea requerida por parte de” VUM” y/o Entidades autorizadas.
                <br/>
                c) Todas las actuaciones administrativas que se requieran para garantizar la participación del postulante o candidato, así como la eventual ejecución de las actividades derivadas de la selección de mi postulación, como que consulten y entreguen a “VUM” la información referente a procesos judiciales que cursen o hayan cursado en mi contra.
                Se me ha informado sobre los diferentes mecanismos de comunicación, con el fin de ejercer los derechos anteriormente descritos, puedo conocer la Política de Tratamiento de Datos Personales y realizar consultas o reclamos relacionados con mis Datos Personales, a través de los siguientes canales de información:
                <br/>
                <br/>
                i) Teléfono: (0572) 6084848
                <br/>
                ii) Correo electrónico: protecciondedatos@listos.com.co
                <br/>
                iii) Correspondencia: Cl.21N NRO. 8N-21 Cali – Valle del Cauca
                <br/>
                iv) Presencial: Cl.21N NRO. 8N-21 Cali – Valle del Cauca

              </p>

        </div>

    </div>
)

};

export default PrivacidadAutorizacionTratamientoDatosPersonales;