import { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { api } from '../../../../environments/environments';
import { advertenciaFormularioVacio, getFetchWithHeader, overlay, postFetch } from '../../../../generalHelpers';

export const useAyuda = (formInitialState, typeForm) => {

    const exprRegTelefono = /^[0-9+() -?]+$/;                                                //Expresion regular para validar el formato de un teléfono
    const exprRegEmail = /^([a-zA-Z0-9_.-]+)@([\da-zA-Z0-9.-]+)\.([a-z.]{2,6})$/;        //Expresion regular para validar los correos electronicos
    const exprRegTexto = /^([\w\s\d\nÑñáéíóúÁÉÍÓÚ.,\-_@?¿%<>]){1,990}$/;                      //Expresion regular para validar texto largos de 1000 caracteres

    const [stateTitle, setStateTitle] = useState('Felicitaciones');
    useEffect(() => {
        document.getElementById('root').className = 'ayudaForm';
        window.scrollTo({ top: 0, behavior: 'smooth' });

        if (typeForm) {
            setStateTitle(validarTituloComponent(typeForm.typeForm));
            loadCategories(typeForm.typeForm);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [typeForm])

    const validarTituloComponent = (data) => {
        let response = '';

        switch (data) {
            case 'felicitaciones':
                response = 'Felicitaciones';
                break;
            case 'solicitud':
                response = 'Solicitud';
                break;
            case 'queja':
                response = 'Queja';
                break;

            default:
                break;
        }

        return response;
    }


    const [formValue, setFormValue] = useState(formInitialState);
    const onChangeInputHandle = ({ target }) => {
        setFormValue({
            ...formValue,
            [target.name]: target.value
        })
    }


    const [checkedButtonMedioRespuesta, setCheckedButtonMedioRespuesta] = useState(null)
    const oncheckedButtonMedioRespuestaHandle = () => {
        let otroMedioRespuesta = document.getElementById("otroMedioRespuesta");

        if (otroMedioRespuesta.checked) {
            setCheckedButtonMedioRespuesta(true);
            document.getElementById("radioLlamadaTelefonica").disabled = false;
            document.getElementById("radioWpp").disabled = false;
            document.getElementById("divNumeroTelefonico").style.display = 'unset';
        } else {
            setCheckedButtonMedioRespuesta(false);
            setTipoContacto(null);

            setFormValue({
                ...formValue,
                'numeroTelefonico': ''
            });

            document.getElementById("radioLlamadaTelefonica").disabled = true;
            document.getElementById("radioWpp").disabled = true;

            document.getElementById("radioLlamadaTelefonica").checked = false;
            document.getElementById("radioWpp").checked = false;

            document.getElementById("divNumeroTelefonico").style.display = 'none';
        }

    }


    const [selectCategorias, setSelectCategorias] = useState({});
    const onChangeSelectHandle = ({ value }) => {
        
        /*si la opcion seleccionada es: inclusion de beneficiarios - caja de compensacion - eps
        debe redirigir al modulo de /autogestion/inclusionBeneficiarios/radicar
        codigo 1578 en produccion.  y en pruebas codigo: 1583*/
        const valorTipo = value.value; 
        const nombreTipo = value.label;

        if(valorTipo === 1578 || nombreTipo === "INCLUSIÓN DE BENEFICIARIOS - CAJA DE COMPENSACIÓN - EPS."){
            window.location = '/autogestion/inclusionBeneficiarios/radicar';
        }else{
            setSelectCategorias(value);
        }
    }


    const [tipoContacto, setTipoContacto] = useState(null);
    const onClickButtonRespuestaHandle = ({ target }) => {

        let inputRadio = document.querySelector("#" + target.id);

        if (inputRadio.checked && target.dataset.target === "wpp") {
            setTipoContacto("wpp");
        } else if (inputRadio.checked && target.dataset.target === "llamada") {
            setTipoContacto("llamada");
        } else {
            setTipoContacto(null);
        }

    }


    const [optionsCategory, setOptionsCategory] = useState({});
    const loadCategories = (typeForm) => {

        getFetchWithHeader({
            url: api.getRequestHelpCategories,
            headers: {
                'accept': '*/*',
                'Authorization': 'Bearer ' + localStorage.getItem('a_t')
            }
        })
            .then((responseGetFetchWithHeader) => {

                let optionsCategory = [{ value: null, label: "SELECCIONE..." }];

                responseGetFetchWithHeader.data.forEach(element => {
                    optionsCategory.push({ value: element.TIP_CODIGO, label: element.TIP_NOMBRE.toUpperCase() })
                });

                setOptionsCategory(optionsCategory);

            });

    }


    const onSubmitFormHandle = (e) => {
        e.preventDefault();
        overlay(true);

        if (validarCampos()) {
            enviarFormulario();
        } else {
            advertenciaFormularioVacio();
        }
    }



    const validarCampos = () => {
        // Validamos que la descripción y correo cumplan con la expresión regular
        if(!exprRegEmail.test(formValue.correoEnvioRespuesta)) return false;

        // Si son felicitaciones, siempre es true
        if(typeForm.typeForm === 'felicitaciones') return true;

        // Si es diferente de queja y no seleccionó categoría, es false
        if(!selectCategorias.value && typeForm.typeForm !== 'queja') return false;
        
        //Si Otro medio de respuesta esta checkeado y no se ha seleccionado el tipo de contacto
        if(checkedButtonMedioRespuesta) {
            if(tipoContacto === null) return false
            else if(!exprRegTelefono.test(formValue.numeroTelefonico)) return false;
        }

        return true;
    }


    const enviarFormulario = () => {

        let allData = {

            dataForm: {
                categoria: Number(selectCategorias.value),
                categoriaTxt: String(selectCategorias.label),
                correoEnvioRespuesta: String(formValue.correoEnvioRespuesta),
                descripcion: String(formValue.descripcion).replaceAll("'", ""),
                formulario: String(typeForm.typeForm),
                numeroTelefonico: Number(formValue.numeroTelefonico),
                otroMedioRespuesta: Boolean(checkedButtonMedioRespuesta),
                tipoContacto: String(tipoContacto),
            },
            dataUser: {
                apellidos: String(JSON.parse(localStorage.getItem('d_u')).apellidos).trim(),
                cedula: String(JSON.parse(localStorage.getItem('d_u')).cedula).trim(),
                cCostos: String(JSON.parse(localStorage.getItem('d_u')).ccostos).trim(),
                empresa: String(JSON.parse(localStorage.getItem('d_u')).empresa).trim(),
                estado: String(JSON.parse(localStorage.getItem('d_u')).estado).trim(),
                nombres: String(JSON.parse(localStorage.getItem('d_u')).nombres).trim(),
                numeroCelular: String(JSON.parse(localStorage.getItem('d_u')).numeroCelular).trim(),
            }

        };
        
        let tituloFinal = "Datos guardados correctamente.";
        if(allData.dataForm.formulario === "felicitaciones"){
            tituloFinal = "Gracias por reconocer la buena labor de tus compañeros. Tus felicitaciones han sido enviadas a su correo electrónico."
        }
        if(allData.dataForm.formulario === "queja"){
            tituloFinal = "Lamentamos tu mala experiencia. Tu inconformidad ha sido notificada con éxito."
        }
        if(allData.dataForm.formulario === "solicitud"){
            tituloFinal = "Tu solicitud fue creada con éxito. Espera pronta respuesta por parte de la organización."
        }

        postFetch({
            url: api.postSaveFormHelp,
            params: { allData }
        })
            .then(() => {
                
                Swal.fire({
                    icon: 'success',
                    title: tituloFinal,
                    confirmButtonText: 'Cerrar',
                    confirmButtonColor: "#1783EE",
                }).then((result) => {
                    window.location.reload();
                })
            })
            .catch(() => {
                Swal.fire({
                    icon: 'error',
                    title: 'Hubo un error en la inserción, por favor revisa el formulario.',
                    confirmButtonText: 'Cerrar',
                    confirmButtonColor: "#A6A6A6",
                });
            });


    }



    return ({
        formValue,
        stateTitle,
        optionsCategory,
        onChangeInputHandle,
        oncheckedButtonMedioRespuestaHandle,
        onChangeSelectHandle,
        onSubmitFormHandle,
        onClickButtonRespuestaHandle,
    });

}
