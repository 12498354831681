import React from 'react';
import { Tabs, Tab, TabPanel, TabList } from 'react-tabs';
import { Redirect, Link } from 'react-router-dom';
import 'react-tabs/style/react-tabs.css';
import './encuestas.css';
import { useEncuestas } from './useEncuestas';
import { Report } from '../Report/Report';
import { Preguntas } from '../../components/Preguntas/Preguntas';
import { routes } from '../../environments/environments';
import PropTypes from 'prop-types';

export const Encuestas = ({ tipoEncuesta= '' }) => {

    const {
        encuestas, tabIndex, currentQuestions, setCurrentQuestions,
        existeProximoTab, nextTab, formEncuesta, setFormEncuesta,
        formularioEnviado, dataReport
    } = useEncuestas({tipoEncuesta});

    if (!encuestas || !encuestas.length) return <></>;

    if (!formEncuesta[tabIndex]) {
        setFormEncuesta(old_value => {
            if (!old_value[tabIndex]) old_value[tabIndex] = {};
            return old_value;
        })
    }

    const showButtonCheckIn = tipoEncuesta === 'riesgoCovid';

    const getTitle = () => {
        if(tipoEncuesta === 'casosCovid') return 'Encuesta casos Covid';
        else if(tipoEncuesta === 'cercoEpidemeologico') return 'Encuesta cerco epidemiológico';
        else if(tipoEncuesta === 'riesgoCovid') return 'Encuesta riesgo Covid';
        else return '';
    }

    const getAccionFinal = () => {
        if(tipoEncuesta === 'riesgoCovid') return <Report showButtonCheckIn={showButtonCheckIn} dataReport={dataReport} />
        else return <Redirect to={routes.home.url} />
    }

    return (
        <>
            <div className="card mb-3">
                <div className="bg-holder d-none d-lg-block bg-card"></div>
                <div className="card-header position-relative text-center text-md-start ps-md-5" style={{ paddingLeft: '3rem' }}>
                    <div className="col-12">
                        <h3>{getTitle()}</h3>
                    </div>
                </div>
            </div>

            <div className="card mb-3">
                <div className="card-body bg-light">
                    <div id="formularioEncuesta">
                        <div className="row">
                            <div className="col-12 mt-3 mb-3" id="container-questions">{
                                (!formularioEnviado) ? 
                                    <Tabs selectedIndex={tabIndex} onSelect={() => (()=>{})}>
                                        <TabList style={{ borderBottom: '0px' }}>
                                            { encuestas.map(({ COD_EC, EC_NOMBRE }) => <Tab key={COD_EC}>{EC_NOMBRE}</Tab>) }
                                        </TabList>
                                        <hr/>
                                        <div style={{ paddingTop: '2rem' }}>{
                                            encuestas.map(encuesta => {
                                                const key = encuesta.COD_EC;
                                    
                                                return (
                                                    <TabPanel key={key}>
                                                        <Preguntas currentQuestions={currentQuestions} init={currentQuestions[tabIndex]} setCurrentQuestions={setCurrentQuestions} existeProximoTab={ existeProximoTab } encuesta={ encuesta } tabIndex={tabIndex} nextTab={ nextTab } formEncuesta={ formEncuesta } setFormEncuesta={ setFormEncuesta } />
                                                    </TabPanel>
                                                );
                                            })}
                                        </div>
                                    </Tabs>
                                    :
                                    getAccionFinal()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        

            <div className='volver'>

          <Link to={{ pathname: routes.sst.url }}>
          <button
            className="btn btnVumOffice d-block w-100 mt-3 fontBtnVumOffice zoom"
          >
            <span
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span>
              <i className="fas fa-arrow-alt-circle-left"></i>
              </span>
              <span style={{ marginLeft: "0.3rem"}}>
                <b>Volver</b>
              </span>
            </span>
          </button>
          </Link>

            </div>
        </>
    )
}

Encuestas.propTypes = {
    tipoEncuesta: PropTypes.string
}