import moment from "moment";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { postData } from "../../../components/general/General";
import { baseUrl } from "../../../config/config";
import { overlay } from "../../../generalHelpers";
import Select from 'react-select';
import PropTypes from 'prop-types';
import './stylesCertificadoLaboral.css';

const UseCertificadoLaboral = ({ cedula }) => {
  const [datosTabla, setDatosTabla] = useState([]);

  // Necesario para los selectores múltiples
  const [optionsActivos, setOptionsActivos] = useState([]);
  const [optionsInactivos, setOptionsInactivos] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]); // Guardamos de forma dinámica las opciones seleccionadas
  const [downloadsDisabled, setDownloadsDisabled] = useState([]); // Guardamos de forma dinámica el disabled de cada botón de descarga

  useEffect(() => {
    consultaInicial();
  }, []);

  function consultaInicial() {
    const urlValida = `${baseUrl}/v1/workCertificate/findUserContracts`;
    postData(urlValida, { cedula: parseInt(cedula) }).then((response) => {
      setSelectedOptions(Array(response.contratos.length).fill([]));
      setDownloadsDisabled(Array(response.contratos.length).fill(true));
      setDatosTabla(response.contratos);
      setOptionsActivos(response.optionsActivos);
      setOptionsInactivos(response.optionsInactivos);
    });
  }

  const handleSelect = (selected, index) => {
    // Cambiamos el state de los elementos seleccionados.
    const paqueteCompleto = selected?.find(option => option.value === 'all')
    const optionsRevisar = datosTabla[index].ind_acti === "A" ? optionsActivos : optionsInactivos;
    setSelectedOptions(prevState => {
      const newState = [...prevState];
      if (paqueteCompleto) {
        const opcionAll = optionsRevisar.find(opt => opt.value==='all');
        newState[index] = [opcionAll];
      } else {
        newState[index] = selected;
      }
      return newState;
    });

    // Cambiamos el disabled según tenga o no elementos el select
    setDownloadsDisabled(prevState => {
      const newState = [...prevState];
      if(selected.length) newState[index] = false;
      else newState[index] = true;
      return newState;
    });
  }

  function obtenerOpcionesSeleccionadas(index) {
    const contrato = datosTabla[index];
    let documentosSeleccionados = selectedOptions[index];
    const paqueteCompleto = documentosSeleccionados[0].value==="all"; // true o false

    // El "all" cambia según si seleccionaste un contrato activo o inactivo
    if(paqueteCompleto) {
      if(contrato.ind_acti === "A") documentosSeleccionados = optionsActivos.filter(opt=>opt.value!=="all"); // Que traiga todos menos la opción "all"
      else if(contrato.ind_acti === "I") documentosSeleccionados = optionsInactivos.filter(opt=>opt.value!=="all"); // Que traiga todos menos la opción "all"
    }

    return documentosSeleccionados;
  }

  function descargarDocumentos(index) {
    const url = `${baseUrl}/v1/workCertificate/generateWorkCertificate`;
    const contrato = datosTabla[index];
    const documentosSeleccionados = obtenerOpcionesSeleccionadas(index);
    const datos = {
      cedula: parseInt(cedula),
      empresa: parseInt(contrato.cod_empr),
      contrato: parseInt(contrato.nro_cont),
      documentosGenerar: JSON.stringify(documentosSeleccionados),
      estadoContrato: contrato.ind_acti
    }
    overlay(true);
    postData(url, datos).then((result) => {
      overlay(false);
      mostrarDetallesDescarga(result);
    }).catch(err => {
      overlay(false);
      Swal.fire({
        icon: 'error',
        title: '¡Ups!',
        text: 'Ha ocurrido un error al realizar la acción, intenta de nuevo.',
        confirmButtonColor: "#A6A6A6",
        confirmButtonText: 'Cerrar'
      });
    });
  }

  function mostrarDetallesDescarga(archivos) {
    // Si es un solo archivo, lo abrimos inmediatamente o mostramos el mensaje de error en un Swal alert
    if(archivos.length === 1) {
      if(archivos[0].link) {
        window.open(archivos[0].link, '_blank');
        Swal.fire({
          icon: 'success',
          title: '¡Tu archivo está listo!',
          html: `
            <h5 class="text-secondary">
              Si el documento no se descargó automáticamente, <a href="${archivos[0].link}">haz clic aquí</a> para descargarlo de forma manual
            </h5>
          `,
          confirmButtonColor: "#A6A6A6",
          confirmButtonText: 'Cerrar'
        });
      } else {
        Swal.fire({
          icon: 'warning',
          title: 'Información',
          text: archivos[0].error || 'Error al generar el documento solicitado.',
          confirmButtonColor: "#A6A6A6",
          confirmButtonText: 'Cerrar'
        });
      }
      return;
    }

    // Proceso cuando son múltiples archivos
    // Mostramos una tabla con botones clickeables
    const filas = archivos.map(archivo => {
      let accionMostrar = '<span class="text-danger">Error al generar el documento solicitado.</span>'; // Letrero default
      if(archivo.error) accionMostrar = `<span class="text-secondary">${archivo.error}</span>`;
      else if(archivo.link) accionMostrar = `<a href="${archivo.link}" target="_blank" class="btn btn-primary w-100">Descargar</a>`;

      return `
      <tr>
        <td>${archivo.nombre}</td>
        <td>${accionMostrar}</td>
      </tr>`;
    });

    const htmlTabla = `
    <h3 class="text-start mb-3"><b>¡Tus archivos están listos!</b></h3>
    <h6 class="text-secondary text-start"><b>Asegúrate de descargar todos tus documentos antes de cerrar esta ventana</b></h6>
    <div class="table-responsive scrollbar">
      <table class="table table-striped text-start fs--1">
        <thead class="table-primary">
          <th>Documento</th>
          <th>Descargar</th>
        </thead>
        <tbody>
          ${filas.join('')}
        </tbody>
      </table>
    </div>`;

    Swal.fire({
      html: htmlTabla,
      confirmButtonColor: "#A6A6A6",
      confirmButtonText: 'Cerrar'
    });
  }

  return (
    <div className="table-responsive mb-2 overflowSelects">
      <p>Aquí puedes descargar tus certificados laborales y otros archivos seleccionándolos y pulsando el botón de descargar.</p>
        <table className="table table-striped text-center">
          <thead>
            <tr className="table-light">
              <th>Empresa</th>
              <th>Fecha inicio</th>
              <th>Contrato</th>
              <th>Estado</th>
              <th className="columnaSelect">Descargar documentos</th>
            </tr>
          </thead>
          <tbody>{
            datosTabla.map((val, index) => (
              <tr key={'fila'+index} style={{verticalAlign: 'middle'}}>
                <td>{val.nom_empr}</td>
                <td>{moment.utc(val.fec_ingr).format("yyyy-MM-DD")}</td>
                <td>{val.nro_cont}</td>
                <td>{val.ind_acti === "A" ? "ACTIVO" : "INACTIVO"}</td>
                <td className="columnaSelect">
                  <div className="d-flex justify-content-between align-items-center w-100">
                    <Select
                      styles={{
                        width: '80%',
                        option: (base) => ({
                          ...base,
                          textAlign: 'start',
                        }),
                      }}
                      key={'selector'+index}
                      isMulti
                      closeMenuOnSelect={false}
                      options={val.ind_acti === "A" ? optionsActivos : optionsInactivos}
                      className="basic-multi-select w-100"
                      classNamePrefix="select"
                      placeholder={'Seleccionar'}
                      onChange={(selected) => handleSelect(selected, index)}
                      value={selectedOptions[index]}
                    />
                    <button
                    className="btn btn-link fs-2"
                    disabled={downloadsDisabled[index]}
                    title="Descargar documentos"
                    onClick={() => descargarDocumentos(index)} >
                      <i className="fas fa-download" style={{color:"#1783EE"}}></i>
                    </button>
                  </div>
                </td>
              </tr>
            ))
          }</tbody>
        </table>
    </div>
  );
};

UseCertificadoLaboral.propTypes = {
  cedula: PropTypes.any
}

export default UseCertificadoLaboral;
