
const ConsultarOnboardingTable = ({ onboardings }) => {


  // Obtener la fecha actual
  const currentDate = new Date();

  return (
    <table className="table table-striped text-center">
      <thead>
        <tr className="table-light">
          <th>Nombre</th>
          <th>Fecha inicio</th>
          <th>Fecha fin</th>
          <th>Fecha asociación</th>
          <th>Estado</th>
          <th>Acciones</th>
        </tr>
      </thead>
      <tbody>
        {onboardings.length > 0 ? (
          onboardings.map( (onboarding) => {

            // Procesar el estado y el texto de la etiqueta
            let statusText = '';
            let statusClass = '';
            
            if (onboarding.finalizado === 1) {
              // Si está finalizado, mostrar 'FINALIZADO' con la clase de éxito
              statusText = 'FINALIZADO';
              statusClass = 'bg-success';
            } else {
              // Si no está finalizado, validar la fecha de fin
              const fechaFin = new Date(onboarding.fecha_fin);
              if (currentDate > fechaFin) {
                statusText = 'Inactivo - sin realizar';
                statusClass = 'bg-secondary';
              } else {
                statusText = 'PENDIENTE';
                statusClass = 'bg-warning';
              }
            }

            return (
              <tr key={onboarding.codigo_onboarding}>
                <td>{onboarding.nombre_onboarding}</td>
                <td>{onboarding.fecha_inicio}</td>
                <td>{onboarding.fecha_fin}</td>
                <td>{onboarding.fecha_asociacion}</td>
                <td>
                  <span className={`badge rounded-pill ${statusClass}`}>
                    {statusText}
                  </span>
                </td>
                <td>
                  <a
                    href={`${onboarding.link}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="nav-link "
                    data-bs-target=""
                    data-bs-toggle=""
                    aria-controls={`${onboarding.link}`}
                    title="Ver"
                  >
                    Ver Entrenamiento
                  </a>
                </td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan="4">No hay onboardings disponibles</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default ConsultarOnboardingTable;
