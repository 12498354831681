import React, { Component } from 'react';
import Swal from 'sweetalert2';
import { loadDataValidate, loadingDialog, postData, putInputRequerid, simulateClick, validateInputTabs } from '../../components/general/General';
import { baseUrl } from '../../config/config';
import Select from 'react-select';
class DataAdditional extends Component {
    constructor(props) {
        super(props);
        this.state = {
            datbas: '',
            optionsFrecuencia: '',

            // Esto es para el select múltiple de bienes que desea adquirir a corto plazo
            optionsVehiculos: '',
            tipoVehiculo: React.createRef(),
            disableTipoVehiculo: true,

            // Esto es para el select múltiple de bienes en menos de un año
            optionsBienesServicios: [],
            bienesServiciosYear: React.createRef(),
            disableBienesYear: false,
            valuesBienesYear: [],

            // Esto es para el select múltiple de población especial
            optionsPoblaEspecial: '',
            poblacionEspecial: React.createRef(),
            disablePoblaEspecial: false,

            // Esto es para el select múltiple de bienes que está pagando
            bienesServiciosPagando: React.createRef(),
            disableBienesPagando: false,
            valuesBienesPagando: [],

            // Esto es para el select múltiple de bienes que desea adquirir a corto plazo
            bienesServiciosCortoPlazo: React.createRef(), 
            disableBienesCortoPlazo: false,
            valuesBienesCortoPlazo: [],

            estadonav: 'disabled',

            // Esto es para el select múltiple de convenios existentes
            optionsConvenios: '',
            convenios: React.createRef(),
            disableConvenios: false,

            optionsTransportes: [],
            optionsFacilidades: [],

            breadcrumb: 'Recreación',
        }
    }

    componentDidMount() {
        document.getElementById('root').className = 'cv';
        this.consultaInicial();
    }
    
    consultaInicial = () => {
        loadingDialog(true);
        const dataUser = JSON.parse( localStorage.getItem("d_u"));
        const cedula = parseInt(dataUser['cedula']);
        const empresa = parseInt(dataUser['empresa']);
        const datosEnviar = {cedula, empresa}
        const url = `${baseUrl}/v1/datosAdicionales/consultaInicial`;
        postData(url, datosEnviar).then(async (result) => {
            this.setState({datbas: result.datbas});
            await this.asignarValoresSelects(result);
            this.loadDataPrincipal(result.respuestas);
            loadingDialog(false);
        }).catch(err => {
            loadingDialog(false);
            Swal.fire({
                icon: 'warning',
                title: '¡Ups!',
                text: 'Ha ocurrido un error al cargar el formulario, intenta nuevamente.',
                confirmButtonColor: "#A6A6A6",
                confirmButtonText: 'Cerrar'
            })
        });
    }

    asignarValoresSelects = async (result) => {
        let frecuencias = result.frecuencias.map((value, x) => <option key={x} value={value["TIP_CODIGO"]}>{value["TIP_NOMBRE"]}</option>);
        let vehiculos = result.vehiculos.map((value, x) => {return {value: value["TIP_CODIGO"], label: value["TIP_NOMBRE"]}});
        let poblacionEspecial = result.condicionesEspeciales.map((value, x) => {return {value: value["TIP_CODIGO"], label: value["TIP_NOMBRE"]}});
        let bienesServicios = result.bienesServicios.map((value, x) => {return {value: value["TIP_CODIGO"], label: value["TIP_NOMBRE"]}})
        let convenios = result.convenios.map((value, x) => {return {value: value["TIP_CODIGO"], label: value["TIP_NOMBRE"]}})
        let transportes = result.transportes.map((value, x) => <option key={'optTrans'+x} value={value["TIP_CODIGO"]}>{value["TIP_NOMBRE"]}</option>)
        let facilidadTrans = result.facilidadTrans.map((value, x) => <option key={'optfacili'+x} value={value["TIP_CODIGO"]}>{value["TIP_NOMBRE"]}</option>)
        
        // Se retorna un promise.all para que todos los inputs se coloquen antes de setear los valores en la función loadDataPrincipal
        return await Promise.all([frecuencias, vehiculos, poblacionEspecial, bienesServicios, convenios, transportes, facilidadTrans])
        .then(res => {
            this.setState({
                optionsFrecuencia: res[0],
                optionsVehiculos: res[1],
                optionsPoblaEspecial: res[2],
                optionsBienesServicios: res[3],
                optionsConvenios: res[4],
                optionsTransportes: res[5],
                optionsFacilidades: res[6],
            });
            return true;
        });
    }

    loadDataPrincipal = (formulario) => {
        try {
            let respuestas = formulario.respUnicas;
    
            /* PRIMERA PARTE DEL FORMULARIO (Recreación) */
            // Respuestas únicas
            this.hobbies.value = respuestas.pasatiempos;
            respuestas.mascota === 1 ? document.getElementById('havepets').checked = true : document.getElementById('havepets2').checked = true;
            this.cualActiRecre.value = respuestas.recreacion;
            this.frecuRecre.value = respuestas.frecRecreacion;
            this.cualInteres.value = respuestas.intereses;
            this.empcualInteres.value = respuestas.interesesEmpresa;
            
            // Respuestas condicionales de deportes
            if(respuestas.deportes) {
                document.getElementById('practiceAny').checked = true
                putInputRequerid(`#${this.practiceSport.id}`, '', 'add', this.practiceSport.id);
                putInputRequerid(`#${this.practiceSportFrecu.id}`, '', 'add', this.practiceSportFrecu.id);
                this.practiceSport.value = respuestas.deportes;
                this.practiceSportFrecu.value = respuestas.frecDeportes;
            } else {
                document.getElementById('practiceAny2').checked = true
            }
    
            /* SEGUNDA PARTE DEL FORMULARIO (Otros) */
            // Respuestas únicas
            respuestas.licencia === 1 ? document.getElementById('radiohaveLicen').checked = true : document.getElementById('radiohaveLicen2').checked = true;
            this.transporte.value = respuestas.transHabitual;
            this.transporteFacilidad.value = respuestas.transFacilidad;
            this.conveniosExtra.value = respuestas.conveniosExtra;
    
    
            // Respuestas condicionales de "vehículo" con selector múltiple de vehículos
            if(formulario.respVehiculos.length) {
                document.getElementById('radioHaveVehicule').checked = true;
                this.deshabilitarSelectMultiple('vehiculo', false);
                let selecciones = [];
                formulario.respVehiculos.forEach(element => {
                    const idxExiste = this.state.optionsVehiculos.findIndex(res => res.value === element.resp_tipo);
                    if(idxExiste !== -1) selecciones.push(this.state.optionsVehiculos[idxExiste]);
                });
                this.state.tipoVehiculo.current.select.setValue(selecciones);
            } else {
                document.getElementById('radioHaveVehicule2').checked = true
            }
    
            // Selector múltiple "bienes en menos de un año"
            if(formulario.respBienesYear.length) {
                let selecciones = [];
                formulario.respBienesYear.forEach(element => {
                    const idxExiste = this.state.optionsBienesServicios.findIndex(res => res.value === element.resp_tipo);
                    if(idxExiste !== -1) selecciones.push(this.state.optionsBienesServicios[idxExiste]);
                });
                this.setState({valuesBienesYear: selecciones});
            }
    
            // Respuestas condicionales "grupo social"
            if(respuestas.grupoSocial) {
                document.getElementById('radiogroupSocial').checked = true
                putInputRequerid(`#${this.haveGroupSocial.id}`, '', 'add', this.haveGroupSocial.id);
                this.haveGroupSocial.value = respuestas.grupoSocial;
            } else {
                document.getElementById('radiogroupSocial2').checked = true
            }
    
            // Respuestas condicionales "población especial" con selector múltiple de población especial
            if(formulario.respPoblaEspe.length) {
                let selecciones = [];
                formulario.respPoblaEspe.forEach(element => {
                    const idxExiste = this.state.optionsPoblaEspecial.findIndex(res => res.value === element.resp_tipo);
                    if(idxExiste !== -1) selecciones.push(this.state.optionsPoblaEspecial[idxExiste]);
                });
                this.state.poblacionEspecial.current.select.setValue(selecciones);
            }
    
            // Respuestas condicionales "Usted ahorra"
            if(respuestas.ahorra === 1) {
                document.getElementById('radiosaveMoney').checked = true
                putInputRequerid(`#${this.saveMoney.id}`, '', 'add', this.saveMoney.id);
                putInputRequerid(`#${this.percentMOney.id}`, '', 'add', this.percentMOney.id);
                this.percentMOney.value = respuestas.porceAhorros;
                this.saveMoney.value = respuestas.destinaAhorros;
            } else {
                document.getElementById('radiosaveMoney2').checked = true
            }
    
            // Selector múltiple de "bienes pagando actualmente"
            if(formulario.respBienesActual.length) {
                let selecciones = [];
                formulario.respBienesActual.forEach(element => {
                    const idxExiste = this.state.optionsBienesServicios.findIndex(res => res.value === element.resp_tipo);
                    if(idxExiste !== -1) selecciones.push(this.state.optionsBienesServicios[idxExiste]);
                });
                this.setState({valuesBienesPagando: selecciones});
            }
    
            // Selector múltiple de "bienes a corto plazo"
            if(formulario.respBienesCorto.length) {
                let selecciones = [];
                formulario.respBienesCorto.forEach(element => {
                    const idxExiste = this.state.optionsBienesServicios.findIndex(res => res.value === element.resp_tipo);
                    if(idxExiste !== -1) selecciones.push(this.state.optionsBienesServicios[idxExiste]);
                });
                this.setState({valuesBienesCortoPlazo: selecciones});
            }
    
            // Selector múltiple de "qué convenios quiere de la empresa"
            if(formulario.respConvenios.length) {
                let selecciones = [];
                formulario.respConvenios.forEach(element => {
                    const idxExiste = this.state.optionsConvenios.findIndex(res => res.value === element.resp_tipo);
                    if(idxExiste !== -1) selecciones.push(this.state.optionsConvenios[idxExiste]);
                });
                this.state.convenios.current.select.setValue(selecciones); // Esta es la forma de setear valores en el react-select múltiple
            }
    
    
            /* TERCERA PARTE DEL FORMULARIO (Caja de compensación) */
            // Respuestas únicas
            respuestas.conoceCaja === 1 ? document.getElementById('conoce-caja').checked = true : document.getElementById('conoce-caja2').checked = true
            respuestas.usaCaja === 1 ? document.getElementById('usa-caja').checked = true : document.getElementById('usa-caja2').checked = true
            respuestas.infoCaja === 1 ? document.getElementById('info-caja').checked = true : document.getElementById('info-caja2').checked = true
    
            // Respuestas condicionales "qué subsidio recibe" con selector múltiple de "qué subsidio recibe"
            if(respuestas.recibeSubsidio) {
                document.getElementById('recibe-caja').checked = true
                putInputRequerid(`#${this.cualSubsidioCaja.id}`, '', 'add', this.cualSubsidioCaja.id);
                this.cualSubsidioCaja.value = respuestas.recibeSubsidio
            } else {
                document.getElementById('recibe-caja2').checked = true
            }
        } catch (error) {
            console.error(error);
        }
    }

    // Si selecciona "NINGUNO" el value será solo ese, pero si no, el value será lo que envíe el evento
    verificarOpcionNinguno = (values, selector) => {
        let valuesSeleccionar = values;
        const ningunoOption = values.find(option => option.label === 'NINGUNO');
        if(ningunoOption) valuesSeleccionar = [ningunoOption];
        
        if (selector === 'bienesYear') this.setState({valuesBienesYear: valuesSeleccionar});
        else if (selector === 'bienesPagando') this.setState({valuesBienesPagando: valuesSeleccionar});
        else if (selector === 'bienesCortoPlazo') this.setState({valuesBienesCortoPlazo: valuesSeleccionar});
    };

    guardarDatosAdicionales = () => {
        if(validateInputTabs()) return;
        if(this.validarSelectsMultiples()) return;

        const dataUser = JSON.parse(localStorage.getItem("d_u"));
        const cedula = Number(dataUser['cedula']);
        const empresa = Number(dataUser['empresa']);
        const usuLogin = dataUser.USU_LOGIN;

        let datos = {
            cedula,
            empresa,
            usuLogin,
            datbas: this.state.datbas,
            pasaTiempos: this.hobbies.value,
            mascotas: Number(document.querySelector('input[name=mascota]:checked').value),
            actiRecreacion: this.cualActiRecre.value,
            frecRecreacion: this.frecuRecre.value,
            cualInteres: this.cualInteres.value,
            cualInteresEmpresa: this.empcualInteres.value,
            practicaDeporte: Number(document.querySelector('input[name=practiceSport]:checked').value),
            cualDeporte: this.practiceSport.value,
            frecDeporte: this.practiceSportFrecu.value,
            tieneVehiculo: Number(document.querySelector('input[name=haveVehicule]:checked').value),
            cualVehiculo: [],
            tieneLicencia: Number(document.querySelector('input[name=haveLicen]:checked').value),
            bienesYear: [],
            transporte: this.transporte.value,
            transporteFacilidad: this.transporteFacilidad.value,
            grupoSocial: Number(document.querySelector('input[name=groupSocial]:checked').value),
            cualGrupoSocial: this.haveGroupSocial.value,
            poblaEspecial: [],
            ahorra: Number(document.querySelector('input[name=saveMoney]:checked').value),
            destinaAhorro: this.saveMoney.value,
            porceAhorro: this.percentMOney.value ? Number(this.percentMOney.value) : '',
            bienesPagando: [],
            bienesCortoPlazo: [],
            conveniosObtener: [],
            conveniosExtra: this.conveniosExtra.value,
            conoceCaja: Number(document.querySelector('input[name=conoceBeneficiosCaja]:checked').value),
            haceUsoCaja: Number(document.querySelector('input[name=haceUsoCaja]:checked').value),
            informacionCaja: Number(document.querySelector('input[name=informacionCaja]:checked').value),
            recibeSubsidioCaja: Number(document.querySelector('input[name=recibeSubsidioCaja]:checked').value),
            cualSubsidioCaja: this.cualSubsidioCaja.value
        }

        // Si hay opciones múltiples seleccionadas, las metemos a la llave con un .map
        let valuesVehiculo = this.state.tipoVehiculo.current.select.getValue();
        let valuesConvenios = this.state.convenios.current.select.getValue();

        if(valuesVehiculo.length) datos.cualVehiculo = valuesVehiculo.map(res => Number(res.value));
        if(this.state.valuesBienesYear.length) datos.bienesYear = this.state.valuesBienesYear.map(res => Number(res.value));
        if(this.state.valuesBienesPagando.length) datos.bienesPagando = this.state.valuesBienesPagando.map(res => Number(res.value));
        if(this.state.valuesBienesCortoPlazo.length) datos.bienesCortoPlazo = this.state.valuesBienesCortoPlazo.map(res => Number(res.value));
        if(valuesConvenios.length) datos.conveniosObtener = valuesConvenios.map(res => Number(res.value));

        loadingDialog(true);
        const url = `${baseUrl}/v1/datosAdicionales/guardarDatosAdicionales`;
        postData(url, datos).then(result => {
            loadingDialog(false);
            if(result.error || result.message) {
                return Swal.fire({
                    icon: 'warning',
                    title: '¡Ups!',
                    text: result.error || "Ha ocurrido un error inesperado. Intenta nuevamente.",
                    confirmButtonColor: "#A6A6A6",
                    confirmButtonText: 'Cerrar'
                });
            }

            Swal.fire({
                title: '',
                html: `
                <div class="text-center">
                    <img src="https://controlf.blob.core.windows.net/public/vumoffice/assets/
cv/check.png" alt="check" style="width:60px">
                    <h3 class="text-primary mt-3">¡Tus DATOS ADICIONALES han sido guardados con éxito!</h3>
                    <h5 class="text-secondary">Te garantizamos un buen uso de tu información.</h5>
                    <a type="button" class="btn succesButton mt-3" href="/cv/salud">Continuar llenando "Salud"</a>
                </div>
                `,
                showCloseButton: true,
                showCancelButton: false,
                showConfirmButton: false
            });
            loadDataValidate()
        }).catch(err => {
            loadingDialog(false);
            Swal.fire({
                icon: 'error',
                title: '¡Ups!',
                text: 'No se guardaron los datos, intente nuevamente.',
                confirmButtonColor: "#A6A6A6",
                confirmButtonText: 'Cerrar'
            })
        })

    }

    validateInputTabsIn = (tab) => {
        let valtab = validateInputTabs(tab);
        if(tab === 'cajaComp-tab' && !valtab) valtab = this.validarSelectsMultiples();
        if (valtab === '') {
            this.setState({ estadonav: '' })
            simulateClick(tab, 0, 'click')
            setTimeout(() => {
                this.setState({ estadonav: 'disabled' })
            }, 500);
        }
        this.handleBreadcrumb(tab);
    }

    vaidateBack = (tabidentificador) => {
        this.setState({ estadonav: '' })
        simulateClick(tabidentificador, 0, 'click')
        setTimeout(() => {
            this.setState({ estadonav: 'disabled' })
        }, 500);
        this.handleBreadcrumb(tabidentificador);
    }

    handleBreadcrumb = (tab) => {
        if(tab==='recreacion-tab') this.setState({breadcrumb:'Recreación'});
        else if(tab==='otros-tab') this.setState({breadcrumb:'Otros'});
        else if(tab==='cajaComp-tab') this.setState({breadcrumb:'Caja de compensación'});
        else this.setState({breadcrumb:''});
    }

    deshabilitarSelectMultiple = (selector, deshabilitar) => {
        if(selector === 'vehiculo') {
            this.setState({disableTipoVehiculo: deshabilitar});
            if(deshabilitar) this.state.tipoVehiculo.current.select.setValue([]);
        }
        else if(selector === 'bienesYear') {
            this.setState({disableBienesYear: deshabilitar});
            if(deshabilitar) this.state.bienesServiciosYear.current.select.setValue([]);
        }
        else if(selector === 'poblaEspecial') {
            this.setState({disablePoblaEspecial: deshabilitar});
            if(deshabilitar) this.state.poblacionEspecial.current.select.setValue([]);
        }
        else if(selector === 'bienesPagando') {
            this.setState({disableBienesPagando: deshabilitar});
            if(deshabilitar) this.state.bienesServiciosPagando.current.select.setValue([]);
        }
        else if(selector === 'bienesCortoPlazo') {
            this.setState({disableBienesCortoPlazo: deshabilitar});
            if(deshabilitar) this.state.bienesServiciosCortoPlazo.current.select.setValue([]);
        }
        else if(selector === 'convenios') {
            this.setState({disableConvenios: deshabilitar});
            if(deshabilitar) this.state.convenios.current.select.setValue([]);
        }
    }

    validarSelectsMultiples = () => {
        let labelInput = '';
        let camposVacios = '';

        let checkVehiculo = document.querySelector('input[name=haveVehicule]:checked');
        
        if(checkVehiculo) {
            let valuesVehiculo = this.state.tipoVehiculo.current.select.getValue();
            if(checkVehiculo.value === "1" && !valuesVehiculo.length) {
                labelInput = document.querySelector('label[for=tipoVehiculo]').textContent;
                camposVacios += `<li class="list-group-item border-0">${labelInput}</li>`;
            }
        }
        if(!this.state.valuesBienesYear.length) {
            labelInput = document.querySelector('label[for=bienesYear]').textContent;
            camposVacios += `<li class="list-group-item border-0">${labelInput}</li>`;
        }
        if(!this.state.valuesBienesPagando.length) {
            labelInput = document.querySelector('label[for=bienesServiciosPagando]').textContent;
            camposVacios += `<li class="list-group-item border-0">${labelInput}</li>`;
        }
        if(!this.state.valuesBienesCortoPlazo.length) {
            labelInput = document.querySelector('label[for=bienesServiciosCortoPlazo]').textContent;
            camposVacios += `<li class="list-group-item border-0">${labelInput}</li>`;
        }
        
        if(camposVacios) {
            let list = `
            <ul class="list-group list-group-flush inline-flex">
                ${camposVacios}
            </ul>`;

            Swal.fire({
                title: "<h5 class='text-primary'>Validar las siguientes preguntas múltiples</h5>",
                html: list,
                showCancelButton: false,
                showConfirmButton: true,
                confirmButtonColor: "#A6A6A6",
                confirmButtonText: 'Cerrar'
            });
        }

        return camposVacios;
    }


    render() {
        
        const { optionsFrecuencia, optionsVehiculos, tipoVehiculo, disableTipoVehiculo, bienesServiciosYear, disableBienesYear, valuesBienesYear, optionsPoblaEspecial, poblacionEspecial, disablePoblaEspecial, optionsBienesServicios, bienesServiciosPagando, disableBienesPagando, valuesBienesPagando, bienesServiciosCortoPlazo, disableBienesCortoPlazo, valuesBienesCortoPlazo, estadonav, optionsConvenios, convenios, disableConvenios, optionsTransportes, optionsFacilidades, breadcrumb } = this.state;
        

        return <><div className="card">
            <div className="card-header">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb fs-2">
                        <li className="breadcrumb-item">Mi perfil</li>
                        <li className="breadcrumb-item">Datos adicionales</li>
                        <li className="breadcrumb-item" aria-current="page">{breadcrumb}</li>
                    </ol>
                </nav>
            </div>
        </div>
            &nbsp;
            <div className="card">
                <div className="card-body">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="recreacion-tab" data-bs-toggle="tab" data-bs-target="#recreacion" type="button" role="tab" aria-controls="recreacion" aria-selected="true">Recreación</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" disabled={estadonav} id="otros-tab" data-bs-toggle="tab" data-bs-target="#otros" type="button" role="tab" aria-controls="otros" aria-selected="true">Otros</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" disabled={estadonav} id="cajaComp-tab" data-bs-toggle="tab" data-bs-target="#cajaComp" type="button" role="tab" aria-controls="cajaComp" aria-selected="true">Caja de compensación</button>
                        </li>
                    </ul>

                    &nbsp;
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="recreacion" role="tabpanel" aria-labelledby="recreacion-tab">
                            <div className="row g-3">
                                <div className="col-12 col-md-4">
                                    <label className="form-label" htmlFor='hobbies'>¿Cuáles son sus pasatiempos?<span className="text-danger">*</span></label>
                                    <input ref={inp => this.hobbies = inp} className="form-control inputRequired" type="text" id="hobbies" name="hobbies" placeholder='Escribe aquí tu respuesta' required></input>
                                </div>
                                <div className="col-12 col-md-4">
                                    <label className="form-label" htmlFor='mascota'>¿Tiene mascotas?<span className="text-danger">*</span></label>
                                    <div className="d-flex justify-content-around">
                                        <input value="1" type="radio" name="mascota" className="btn-check inputRequired" id="havepets" autoComplete="off" />
                                        <label className="btn btn-outline-primary w-50" htmlFor="havepets">SI</label>&nbsp;
                                        <input value="0" type="radio" name="mascota" className="btn-check" id="havepets2" autoComplete="off" />
                                        <label className="btn btn-outline-primary w-50" htmlFor="havepets2">NO</label>
                                    </div>
                                </div>

                                <div className="col-12 col-md-4">
                                    <label className="form-label" htmlFor="actipractica">¿Cuáles son tus actividades de recreación?<span className="text-danger">*</span></label>
                                    <input ref={sel => this.cualActiRecre = sel} className="form-control inputRequired" type="text" id="actipractica" name="actipractica" placeholder='Escribe aquí tu respuesta' required></input>
                                </div>

                                <div className="col-12 col-md-4">
                                    <label className="form-label" htmlFor="frecuencia">¿Frecuencia con la cual te recreas?<span className="text-danger">*</span></label>
                                    <select ref={sel => this.frecuRecre = sel} className="form-select inputRequired" name="frecuencia" id="frecuencia" required>
                                        <option value="">Seleccionar</option>
                                        {optionsFrecuencia}
                                    </select>
                                </div>

                                <div className="col-12 col-md-4">
                                    <label className="form-label" htmlFor="interescual">¿Cuál es el tema de su interés?<span className="text-danger">*</span></label>
                                    <input ref={inp => this.cualInteres = inp} className="form-control inputRequired" type="text" id="interescual" name="interescual" placeholder='Escribe aquí tu respuesta' required></input>
                                </div>
                                <div className="col-12 col-md-4">
                                    <label className="form-label" htmlFor="interescualEmpresa">¿Qué actividades de su interés le gustaría que la empresa realizara?<span className="text-danger">*</span></label>
                                    <input ref={inp => this.empcualInteres = inp} className="form-control inputRequired" type="text" id="interescualEmpresa" name="interescualEmpresa" placeholder='Escribe aquí tu respuesta'></input>
                                </div>


                                <div className='col-12'>
                                    <label className='fs-0 text-primary mb-0 fw-bold' htmlFor="labelDir-locale">Actividades extra</label>
                                    <hr className='text-primary my-1 fw-bold'></hr>
                                </div>

                                <div className="col-12 col-md-4">
                                    <label htmlFor='practiceSport'>¿Practica algún deporte?<span className="text-danger">*</span></label>
                                    <div className="d-flex justify-content-around">
                                        <input value="1" type="radio" name="practiceSport" className="btn-check inputRequired" id="practiceAny" onChange={() => {
                                            putInputRequerid(`#${this.practiceSport.id}`, '', 'add', this.practiceSport.id);
                                            putInputRequerid(`#${this.practiceSportFrecu.id}`, '', 'add', this.practiceSportFrecu.id);
                                        }}></input>
                                        <label className="btn btn-outline-primary w-50" htmlFor="practiceAny">SI</label>&nbsp;
                                        <input value="0" type="radio" name="practiceSport" className="btn-check" id="practiceAny2" onChange={() => {
                                            putInputRequerid(`#${this.practiceSport.id}`, '', 'remove', this.practiceSport.id);
                                            putInputRequerid(`#${this.practiceSportFrecu.id}`, '', 'remove', this.practiceSportFrecu.id);
                                        }}></input>
                                        <label className="btn btn-outline-primary w-50" htmlFor="practiceAny2">No</label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <label htmlFor="acti-practica">¿Que deportes practica?</label>
                                    <input readOnly ref={inp => this.practiceSport = inp} className="form-control" type="text" id="acti-practica" name="acti-practica" ></input>
                                </div>
                                <div className="col-12 col-md-4">
                                    <label htmlFor="acti-deportfrecuen">¿Con qué frecuencia hace deporte?</label>
                                    <select disabled ref={inp => this.practiceSportFrecu = inp} className="form-select" name="acti-deportfrecuen" id="acti-deportfrecuen" >
                                        <option value=""></option>
                                        {optionsFrecuencia}
                                    </select>
                                </div>

                                <div className="text-end">
                                    <button onClick={() => this.validateInputTabsIn('otros-tab')} className="btn succesButton col-12 col-lg-2 col-xxl-1">Siguiente</button>
                                </div>
                            </div>
                        </div>

                        <div className="tab-pane fade" id="otros" role="tabpanel" aria-labelledby="otros-tab">

                            <div className="row g-3">
                                <div className="col-12 col-md-4">
                                    <label className="form-label" htmlFor='haveVehicule'>¿Tiene vehículo propio?<span className="text-danger">*</span></label>
                                    <div className="d-flex justify-content-around">
                                        <input value="1" type="radio" name="haveVehicule" className="btn-check inputRequired" id="radioHaveVehicule" autoComplete="off" onChange={() => {
                                            this.deshabilitarSelectMultiple('vehiculo', false);
                                        }}></input>
                                        <label className="btn btn-outline-primary w-50" htmlFor="radioHaveVehicule">SI</label>&nbsp;
                                        <input value="0" type="radio" name="haveVehicule" className="btn-check" id="radioHaveVehicule2" autoComplete="off" onChange={() => {
                                            this.deshabilitarSelectMultiple('vehiculo', true);
                                        }}></input>
                                        <label className="btn btn-outline-primary w-50" htmlFor="radioHaveVehicule2">No</label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <label className="form-label" htmlFor="tipoVehiculo">¿Qué tipo de vehículo?</label>
                                    <Select
                                        isMulti
                                        closeMenuOnSelect={false}
                                        name="tipoVehiculo"
                                        id="tipoVehiculo"
                                        options={optionsVehiculos}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder={'Seleccionar varios'}
                                        ref={tipoVehiculo}
                                        isDisabled={disableTipoVehiculo}
                                    />
                                </div>

                                <div className="col-12 col-md-4">
                                    <label className="form-label" htmlFor='haveLicen'>¿Tiene licencia de conducción?<span className="text-danger">*</span></label>
                                    <div className="d-flex justify-content-around">
                                        <input value="1" type="radio" name="haveLicen" className="btn-check inputRequired" id="radiohaveLicen" autoComplete="off"></input>
                                        <label className="btn btn-outline-primary w-50" htmlFor="radiohaveLicen">SI</label>&nbsp;
                                        <input value="0" type="radio" name="haveLicen" className="btn-check" id="radiohaveLicen2" autoComplete="off"></input>
                                        <label className="btn btn-outline-primary w-50" htmlFor="radiohaveLicen2">No</label>
                                    </div>
                                </div>
                                
                                <div className="col-12 col-md-4">
                                    <label className="form-label" htmlFor="bienesYear">¿Cuál de estos bienes o servicios proyecta adquirir en un periodo menor a un año?<span className="text-danger">*</span></label>
                                    <Select
                                        isMulti
                                        closeMenuOnSelect={false}
                                        name="bienesYear"
                                        id='bienesYear'
                                        options={optionsBienesServicios}
                                        classNamePrefix="select"
                                        placeholder={'Seleccionar varios'}
                                        ref={bienesServiciosYear}
                                        isDisabled={disableBienesYear}
                                        value={valuesBienesYear}
                                        onChange={(val) => this.verificarOpcionNinguno(val, 'bienesYear')}
                                    />
                                </div>
                                <div className="col-12 col-md-4">
                                    <label className="form-label" htmlFor="transporte">¿En qué medio de transporte se desplaza a su trabajo?<span className="text-danger">*</span></label>
                                    <select ref={sel => this.transporte = sel} className="form-select inputRequired" name="transporte" id="transporte" required>
                                        <option value="">Seleccionar</option>
                                        {optionsTransportes}
                                    </select>
                                </div>
                                <div className="col-12 col-md-4">
                                    <label className="form-label" htmlFor="transporteFacilidad">¿Qué tan fácil es acceder al servicio de transporte más utilizado por usted?<span className="text-danger">*</span></label>
                                    <select ref={sel => this.transporteFacilidad = sel} className="form-select inputRequired" name="transporteFacilidad" id="transporteFacilidad" required>
                                        <option value="">Seleccionar</option>
                                        {optionsFacilidades}
                                    </select>
                                </div>

                                <div className="col-12 col-md-4">
                                    <label className="form-label" htmlFor='groupSocial'>¿Pertence algún grupo social?<span className="text-danger">*</span></label>
                                    <div className="d-flex justify-content-around">
                                        <input value="1" type="radio" name="groupSocial" className="btn-check inputRequired" id="radiogroupSocial" autoComplete="off" onChange={() => {
                                            putInputRequerid(`#${this.haveGroupSocial.id}`, '', 'add', this.haveGroupSocial.id);
                                        }}></input>
                                        <label className="btn btn-outline-primary w-50" htmlFor="radiogroupSocial">SI</label>&nbsp;
                                        <input value="0" type="radio" name="groupSocial" className="btn-check" id="radiogroupSocial2" autoComplete="off" onChange={() => {
                                            putInputRequerid(`#${this.haveGroupSocial.id}`, '', 'remove', this.haveGroupSocial.id);
                                        }}></input>
                                        <label className="btn btn-outline-primary w-50" htmlFor="radiogroupSocial2">No</label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <label className="form-label" htmlFor="groupSocialPerte">¿Cuál es el grupo al que pertenece?</label>
                                    <input readOnly ref={input => this.haveGroupSocial = input} className="form-control " type="text" id="groupSocialPerte" name="groupSocialPerte" ></input>
                                </div>

                                <div className="col-12 col-md-4">
                                    <label className="form-label">¿Pertence a alguna población especial?</label>
                                    <Select
                                        isMulti
                                        closeMenuOnSelect={false}
                                        name="dataPoblaEspecial"
                                        options={optionsPoblaEspecial}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder={'Seleccionar varios'}
                                        ref={poblacionEspecial}
                                        isDisabled={disablePoblaEspecial}
                                    />
                                </div>


                                <div className='col-12'>
                                    <label className='fs-0 text-primary mb-0 fw-bold' htmlFor="labelDir-locale">Datos financieros</label>
                                    <hr className='text-primary my-1 fw-bold'></hr>
                                </div>


                                <div className="col-12 col-md-4">
                                    <label className='form-label' htmlFor='saveMoney'>¿Usted ahorra?<span className="text-danger">*</span></label>
                                    <div className="d-flex justify-content-around">
                                        <input value="1" type="radio" name="saveMoney" className="btn-check inputRequired" id="radiosaveMoney" autoComplete="off" onChange={() => {
                                            putInputRequerid(`#${this.saveMoney.id}`, '', 'add', this.saveMoney.id);
                                            putInputRequerid(`#${this.percentMOney.id}`, '', 'add', this.percentMOney.id);
                                        }}></input>
                                        <label className="btn btn-outline-primary w-50" htmlFor="radiosaveMoney">SI</label>&nbsp;
                                        <input value="0" type="radio" name="saveMoney" className="btn-check" id="radiosaveMoney2" autoComplete="off" onChange={() => {
                                            putInputRequerid(`#${this.saveMoney.id}`, '', 'remove', this.saveMoney.id);
                                            putInputRequerid(`#${this.percentMOney.id}`, '', 'remove', this.percentMOney.id);
                                        }}></input>
                                        <label className="btn btn-outline-primary w-50" htmlFor="radiosaveMoney2">No</label>
                                    </div>
                                </div>

                                <div className="col-12 col-md-4">
                                    <label className='form-label' htmlFor="acti-savemoneyDestiny">¿En qué destina sus ahorros?</label>
                                    <input readOnly ref={input => this.saveMoney = input} className="form-control " type="text" id="acti-savemoneyDestiny" name="acti-savemoneyDestiny" ></input>
                                </div>
                                <div className="col-12 col-md-4">
                                    <label className='form-label' htmlFor="acti-percentMOney">¿Qué % ahorra de su salario?</label>
                                    <input readOnly ref={input => this.percentMOney = input} className="form-control " type="text" id="acti-percentMOney" name="acti-percentMOney" ></input>
                                </div>

                                <div className="col-12 col-md-4">
                                    <label className='form-label' htmlFor='bienesServiciosPagando'>¿Cuáles bienes/servicios está pagando actualmente?<span className="text-danger">*</span></label>
                                    <Select
                                        isMulti
                                        closeMenuOnSelect={false}
                                        name="bienesServiciosPagando"
                                        id="bienesServiciosPagando"
                                        options={optionsBienesServicios}
                                        classNamePrefix="select"
                                        placeholder={'Seleccionar varios'}
                                        ref={bienesServiciosPagando}
                                        isDisabled={disableBienesPagando}
                                        value={valuesBienesPagando}
                                        onChange={(val) => this.verificarOpcionNinguno(val, 'bienesPagando')}
                                    />
                                </div>

                                <div className="col-12 col-md-4">
                                    <label className='form-label' htmlFor='bienesServiciosCortoPlazo'>¿Cuáles bienes/servicios desea adquirir a corto plazo?<span className="text-danger">*</span></label>
                                    <Select
                                        isMulti
                                        closeMenuOnSelect={false}
                                        name="bienesServiciosCortoPlazo"
                                        id="bienesServiciosCortoPlazo"
                                        options={optionsBienesServicios}
                                        classNamePrefix="select"
                                        placeholder={'Seleccionar varios'}
                                        ref={bienesServiciosCortoPlazo}
                                        isDisabled={disableBienesCortoPlazo}
                                        value={valuesBienesCortoPlazo}
                                        onChange={(val) => this.verificarOpcionNinguno(val, 'bienesCortoPlazo')}
                                    />
                                </div>


                                <div className='col-12 mt-4'>
                                    <label className='fs-0 text-primary mb-0 fw-bold' htmlFor="labelDir-locale">Convenios empresariales</label>
                                    <hr className='text-primary my-1 fw-bold'></hr>
                                </div>


                                <div className="col-12 col-md-6">
                                    <label className='form-label'>¿Qué convenios le gustaría obtener de la empresa?</label>
                                    <Select
                                        isMulti
                                        closeMenuOnSelect={false}
                                        name="convenios"
                                        options={optionsConvenios}
                                        classNamePrefix="select"
                                        placeholder={'Seleccionar varios'}
                                        ref={convenios}
                                        isDisabled={disableConvenios}
                                    />
                                </div>

                                <div className="col-12 col-md-6">
                                    <label className='form-label'>¿Qué otros convenios le gustaría que la empresa le ofreciera?</label>
                                    <input ref={input => this.conveniosExtra = input} className="form-control " type="text" id="conveniosExtra" name="conveniosExtra" ></input>
                                </div>

                                <div className="d-flex justify-content-between">
                                    <button onClick={() => this.vaidateBack('recreacion-tab')} className="btn succesButton col-12 col-lg-2 col-xxl-1">Atrás</button>
                                    <button onClick={() => this.validateInputTabsIn('cajaComp-tab')} className="btn succesButton col-12 col-lg-2 col-xxl-1">Siguiente</button>
                                </div>
                            </div>
                        </div>
                        

                        <div className="tab-pane fade" id="cajaComp" role="tabpanel" aria-labelledby="cajaComp-tab">
                            <div className="row g-3">
                                <div className="col-12 col-md-4">
                                    <label className='form-label' htmlFor='conoceBeneficiosCaja'>¿Conoce los beneficios de la caja de compensación?<span className="text-danger">*</span></label>
                                    <div className="d-flex justify-content-around">
                                        <input value="1" type="radio" name="conoceBeneficiosCaja" className="btn-check inputRequired" id="conoce-caja" autoComplete="off"></input>
                                        <label className="btn btn-outline-primary w-50" htmlFor="conoce-caja">SI</label>&nbsp;
                                        <input value="0" type="radio" name="conoceBeneficiosCaja" className="btn-check" id="conoce-caja2" autoComplete="off"></input>
                                        <label className="btn btn-outline-primary w-50" htmlFor="conoce-caja2">No</label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <label className='form-label' htmlFor='haceUsoCaja'>¿Hace uso regular de los beneficios que ofrece la caja de compensación familiar?<span className="text-danger">*</span></label>
                                    <div className="d-flex justify-content-around">
                                        <input value="1" type="radio" name="haceUsoCaja" className="btn-check inputRequired" id="usa-caja" autoComplete="off"></input>
                                        <label className="btn btn-outline-primary w-50" htmlFor="usa-caja">SI</label>&nbsp;
                                        <input value="0" type="radio" name="haceUsoCaja" className="btn-check" id="usa-caja2" autoComplete="off"></input>
                                        <label className="btn btn-outline-primary w-50" htmlFor="usa-caja2">No</label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <label className='form-label' htmlFor='informacionCaja'>¿Le gustaría recibir información de los beneficios que ofrece la caja de compensación?<span className="text-danger">*</span></label>
                                    <div className="d-flex justify-content-around">
                                        <input value="1" type="radio" name="informacionCaja" className="btn-check inputRequired" id="info-caja" autoComplete="off"></input>
                                        <label className="btn btn-outline-primary w-50" htmlFor="info-caja">SI</label>&nbsp;
                                        <input value="0" type="radio" name="informacionCaja" className="btn-check" id="info-caja2" autoComplete="off"></input>
                                        <label className="btn btn-outline-primary w-50" htmlFor="info-caja2">No</label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <label className='form-label' htmlFor='recibeSubsidioCaja'>¿Recibe algún tipo de subsidio que otorgue la caja de compensación?<span className="text-danger">*</span></label>
                                    <div className="d-flex justify-content-around">
                                        <input value="1" type="radio" name="recibeSubsidioCaja" className="btn-check inputRequired" id="recibe-caja" autoComplete="off" onChange={() => {
                                            putInputRequerid(`#${this.cualSubsidioCaja.id}`, '', 'add', this.cualSubsidioCaja.id);
                                        }}></input>
                                        <label className="btn btn-outline-primary w-50" htmlFor="recibe-caja">SI</label>&nbsp;
                                        <input value="0" type="radio" name="recibeSubsidioCaja" className="btn-check" id="recibe-caja2" autoComplete="off" onChange={() => {
                                            putInputRequerid(`#${this.cualSubsidioCaja.id}`, '', 'remove', this.cualSubsidioCaja.id);
                                        }}></input>
                                        <label className="btn btn-outline-primary w-50" htmlFor="recibe-caja2">No</label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <label className='form-label' htmlFor="cualSubsidioCaja">¿Cuál o cuáles?</label>
                                    <input readOnly ref={inpu => this.cualSubsidioCaja = inpu} className="form-control " type="text" id="cualSubsidioCaja" name="cualSubsidioCaja" ></input>
                                </div>

                                <div className="d-flex justify-content-between">
                                    <button onClick={() => this.vaidateBack('otros-tab')} className="btn succesButton col-12 col-lg-2 col-xxl-1">Atrás</button>
                                    <button onClick={() => this.guardarDatosAdicionales()} className="btn succesButton col-12 col-lg-2 col-xxl-1">Guardar y continuar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }



}

export default DataAdditional;