import React, { useEffect } from 'react';
import './TerminosCondiciones.css';

import PrivacidadAutorizacionTratamientoDatosPersonales from '../TerminosCondiciones/TerminosCondiciones/PrivacidadAutorizacionTratamientoDatosPersonales/PrivacidadAutorizacionTratamientoDatosPersonales';

import PoliticaTratamientoDatos from '../TerminosCondiciones/TerminosCondiciones/PoliticaTratamientoDatos/PoliticaTratamientoDatos';

import PoliticaNavegacion from '../TerminosCondiciones/TerminosCondiciones/PoliticaNavegacion/PoliticaNavegacion'; 
import TerminosCondicionesWebSiteVum from './TerminosCondiciones/TerminosCondicioneWebSiteVum/TerminosCondicionesWebSiteVum';
import { general } from '../../environments/environments';

export const TerminosCondiciones = () => {

    let webAndroid=general.webMovil;

    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    const close= () =>{
        window.close();
        window.open('/login')
    }

    return (
        <>
            <div className="card mt-3 mb-3">
                <div className="card-body position-relative text-center">
                    <img className="me-2 mt-5 mb-3" src="../../assets/img/logo-vum-login.png" alt="logo-vum-login" style={{width: "200px"}}/>
                </div>
            </div>
            <div className="card mb-3">
                <div className="card-body position-relative">
                    <div className="d-flex align-items-start row">
                        <div className='col-4'>
                            <div className="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                <button className="nav-link nav-linkPers active" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">Términos y condiciones</button>
                                <button className="nav-link nav-linkPers" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">Política de tratamiento</button>
                                <button className="nav-link nav-linkPers" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">Política de cookies y datos de navegación</button>
                                <button className="nav-link nav-linkPers" id="v-pills-settings-tab" data-bs-toggle="pill" data-bs-target="#v-pills-settings" type="button" role="tab" aria-controls="v-pills-settings" aria-selected="false">Aviso de privacidad y autorización</button>
                                <button hidden={!!webAndroid} className="nav-link nav-linkPers" data-bs-toggle="pill" type="button" role="tab" aria-selected="false" onClick={close}>Volver</button>
                            </div>
                        </div>
                        <div className='col-8'>
                            <div className="tab-content" id="v-pills-tabContent">
                                <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                    <div className="container-term">
                                    <PrivacidadAutorizacionTratamientoDatosPersonales/>
                                    </div>
                                    {/* <iframe src={terminosYCondiciones} style={{width:"100%", height:"800px"}} frameborder="0" allowfullscreen></iframe> */}
                                </div>
                                <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                                <div className="container-term">
                                    <PoliticaTratamientoDatos/>
                                    </div>
                                    {/* <iframe src={politicaTratamiento} style={{width:"100%", height:"800px"}} frameborder="0" allowfullscreen></iframe> */}
                                </div>
                                <div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                                    <div className='container-term'>
                                    <PoliticaNavegacion/>
                                    </div>
                                    {/* <iframe src={politicaCookiesDatosNavegacion} style={{width:"100%", height:"800px"}} frameborder="0" allowfullscreen></iframe> */}
                                </div>
                                <div className="tab-pane fade" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                                <div className='container-term'>
                                    <TerminosCondicionesWebSiteVum/>
                                    </div>
                                    {/* <iframe src={privacidadAutorizacionTratamientoDatosPersonales} style={{width:"100%", height:"800px"}} frameborder="0" allowfullscreen></iframe> */}
                                </div>
                            </div>
                        </div>    
                    </div>
                </div>
            </div>
        </>
    )
};