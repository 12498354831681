import chroma from 'chroma-js';

export const ColourStyles = {

    control: styles => ({ ...styles, backgroundColor: 'white' }),

    option: (styles, { data, isDisabled, isFocused, isSelected }) => {

      const color = chroma(data.color);
      let backgroundColor = null;
      let colorFinal = '#ccc'

      if(!isDisabled) {
        if(isSelected) {
          backgroundColor = data.color;
          colorFinal = chroma.contrast(color, 'white') > 2 ? 'white' : 'black';
        }
        else {
          if(isFocused) backgroundColor = color.alpha(0.1).css();
          colorFinal = data.color
        }
      }

      return {
        ...styles,
        backgroundColor: backgroundColor,
        color: colorFinal,
        cursor: isDisabled ? 'not-allowed' : 'default'
      };
    },

    multiValue: (styles, { data }) => {

      const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: color.alpha(0.1).css()
      };
    },

    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: data.color
    }),

    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data.color,
      ':hover': {
        backgroundColor: data.color,
        color: 'white'
      }
    })
};