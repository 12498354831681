import './footerLogin.css'
import { useLogin } from '../../pages/Login/hooks/useLogin';


export const Footer = () => {
    const {terminosCondiciones } = useLogin({
        identification: '',
        pass: ''
    });
    
    const fecha = new Date();
  
    return (
        
            <footer>
                <div className="row mb-3 text-center">
                    <div className="col-12 col-md-12 col-sm-auto text-center">
                        {/* imagen gris*/}
                        <img className="me-2 mt-4 anchoImagen3" src="assets/img/logos-grises.png" alt="logo-vum-login"  />
                    </div>
                </div>
                <div className="row mb-3 text-center">
                    <div className="col-12 col-md-12 col-sm-auto text-center">
                        <p className="mb-0 fontFooter3"> Copyright © {fecha.getFullYear()} todos los derechos reservados | <button onClick={terminosCondiciones} className="p-0 fontFooter3 btn btn-link" id="parrafo">Tratamiento de datos personales</button></p>
                    </div>
                </div>
            </footer>
        
    )
}