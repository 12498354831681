import React, { useState } from "react";
import UseCertificadoLaboral from "./useCertificadoLaboral";

const Certificado_laboral = () => {
  const [datauser] = useState(JSON.parse(localStorage.d_u));

  let datanewCel = datauser.numeroCelular.slice(0, 6) + "xxxx";
  let datanewmil = datauser.mail.slice(0, datauser.mail.search("@")+1) + "xxxxx";

  const divData = (
    <>
      {
        <>
            <div className="card-header mt-3">
              <h5 className="card-title">Datos personales</h5>
            </div>
            <div className="card-body bg-light">
              <form id="fomrcertLaboral">
                <div className="row">
                  <div className="col-12 col-lg-3">
                    <label htmlFor="cedula">
                      <strong>C&eacute;dula:</strong>
                    </label>
                    <div>{datauser.cedula}</div>
                  </div>
                  <div className="col-12 col-lg-3">
                    <label htmlFor="nombre">
                      <strong>Nombre:</strong>
                    </label>
                    <div>
                      {datauser.nombres} {datauser.apellidos}
                    </div>
                  </div>
                  <div className="col-12 col-lg-3">
                    <label htmlFor="celular">
                      <strong>Celular:</strong>
                    </label>
                    <div>{datanewCel}</div>
                  </div>
                  <div className="col-12 col-lg-3">
                    <label htmlFor="email">
                      <strong>Correo electr&oacute;nico:</strong>
                    </label>
                    <div>{datanewmil}</div>
                  </div>
                </div>
              </form>
            </div>
        </>
      }
    </>
  );

  return (
    <>
        <div className="card mb-3">
                <div className="card-header position-relative text-center text-md-start ">
                    <div className="col-12">
                        <h3>Mis certificados/Certificados laborales</h3>
                    </div>
                </div>
            </div>

      <div className="card">
        <div className="card-body">{divData}</div>
        <div className="w-100 d-flex justify-content-center">
        <div className=" border-top border-3 my-3" style={{width:'95%'}}></div>
        </div>
      <div className="card-body ">
        <UseCertificadoLaboral cedula={datauser.cedula}></UseCertificadoLaboral>
      </div>
      </div>
    </>
  );
};

export default Certificado_laboral;
