import './login.css';
import { useLogin } from './hooks/useLogin.js';
import { routes } from '../../environments/environments';
import { Redirect } from 'react-router-dom';
import { useState } from 'react';
import {Footer} from '../../components/FooterLogin/FooterLogin'


export const Login = () => {
    const [showPassword, setShowPassword] = useState(false);


    const { onKeyUpInputHandle, onSubmitForm, onClickContratistasHandle, validaCedulaPri } = useLogin({
        identification: '',
        pass: ''
    });

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
      };
    return (
        <>
            {
                //Si existen las cookies con la informacion del usuario d_u = data_user
                (localStorage.getItem('d_u')) ?
                    <Redirect to={routes.home.url} />
                    :
                    <main className="main login" id="top">
                        <div className="container" data-layout="container">

                            <div className="row flex-center text-center min-vh-100">
                                <img className="me-2 mt-5 mb-3" src="assets/img/logo-vum-login.png" alt="logo-vum-login" style={{ width: '250px' }} />
                                <span className="font-sans-serif fontBienvenido fs-4 d-inline-block tituloBienvenido ">¡Esta es tu oficina virtual!</span>
                               
                                <div  className="col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4 text-center" >
                                    <div >
                                        <div className="card-body ps-5 pe-5 ">
                                            <form onSubmit={onSubmitForm} >
                                                <div className="mb-3 text-center">
                                                    <label className="labelInpiut" htmlFor='identification'>Digita tu número de identificación:</label>
                                                    <input onKeyUp={onKeyUpInputHandle} name="identification" id="identification" className="form-control inputsFormulario fontFamilyToNumber" type="number" min="1" autoComplete="off" onBlur={() => { validaCedulaPri() }} />
                                                </div>
                                                <div className="mb-3 text-center" id="divPassword">
                                                    <label className="labelInpiut" htmlFor='pass'>Digita tu contraseña:</label>
                                                    <div className="position-relative">
                                                        <input
                                                        name="pass"
                                                        id="pass"
                                                        className="form-control inputsFormulario fontFamilyToNumber"
                                                        type={showPassword ? 'text' : 'password'}
                                                        min="1"
                                                        autoComplete="off"
                                                        onKeyUp={onKeyUpInputHandle}
                                                        />
                                                        <span className="position-absolute top-50 end-0 translate-middle-y me-3 me-md-5" onClick={toggleShowPassword} style={{ cursor: 'pointer' }}>
                                                        <i className={showPassword ? "bi bi-eye-slash-fill" : "bi bi-eye-fill"}></i>
                                                        </span>
                                                    </div>
                                                    <span className="labelInpiut text-decoration-underline tituloBienvenido" htmlFor='recordarClave'><a href={routes.loginReset.url} id="contr"><strong>Recordar mi contraseña</strong></a></span>
                                                </div>
                                                <div className="mb-3 vumService" id='vumService'>
                                                    <p className="mb-0 fontFooter tituloBienvenido" id="parrafo"> ¡Si cuentas con usuario de VUM Service puedes ingresar con la misma contraseña! </p>
                                                    <br/>
                                                </div>
                                                <div id="divIngresar">
                                                    <button className="btn btnIniciarSesion d-block fontLinkLabel" type="submit" name="submit">Ingresar</button>
                                                </div>
                                              
                                            </form>

                                            <div id="divContratistas" className="mb-3 text-center col-md-12 divContratistas">

                                                <button onClick={onClickContratistasHandle} className="btn btnContratistas d-block w-100 " name="submit">
                                                    <i className="fas fa-briefcase"></i> &nbsp;
                                                    Personal<br />externo
                                                </button> &nbsp;
                                                <a href="https://vumtalent.vum.com.co/candidato/listos/inicio" target="_blank" rel="noreferrer">
                                                    <button className="btn btnContratistas d-block w-100 " name="submit">
                                                        <i className="fas fa-user-tie"></i> &nbsp;Candidatos
                                                    </button>
                                                </a>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                               <Footer></Footer>
                            </div>

                        </div>
                    </main>
            }

        </>
    )
}
