import React, { useEffect, useState } from 'react';
import { baseUrl } from '../../config/config';
import { overlay, postFetch } from '../../generalHelpers';
import Swal from 'sweetalert2';
import './DesconexionLaboral.css';
import { AsteriskRequired } from '../../components/AsteriskRequired/AsteriskRequired';

export const DesconexionLaboral = () => {

    const [files, setFiles] = useState([]);
    const [urlFiles, setUrlFiles] = useState([]);
    const [dataUser] = useState(JSON.parse(localStorage.getItem("d_u")));
  
    document.addEventListener('DOMContentLoaded', function() {
        const fileInput = document.getElementById('archivo');
        const fileButton = document.getElementById('select-files-button');
        const fileChosen = document.getElementById('file-chosen');
    
        fileButton.addEventListener('click', function() {
            fileInput.click();
        });
    
        fileInput.addEventListener('change', function() {
            if (fileInput.files.length > 0) {
                let fileNames = [];
                for (let file of fileInput.files) {
                    fileNames.push(file.name);
                }
                fileChosen.textContent = fileNames.join(', ');
            } else {
                fileChosen.textContent = 'No file chosen';
            }
        });
    });

    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    const generarTicket = async () => {

        const urlSave = `${baseUrl}/v1/upload/singleUpload`;
        let i = 0;
        overlay(true);

        const validateEmail = (email) => {
            return /^[\w.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.exec(String(email).toLowerCase());
        };

        const descripcion = document.getElementById("descripcion").value;
        const correo = document.getElementById("correo").value;
        const checkBox = document.getElementById("otroMedio").checked;
        const celular = (checkBox)?document.getElementById("telMovi").value:null;

        if(files.length > 0 && descripcion && validateEmail(correo) != null && (!checkBox || (checkBox && celular))){

            for (const value of files) {
                let formData = new FormData();
                formData.append('file', value);
                formData.append('folder', 'desconexionLaboral');
                await postFetch({ url: urlSave, params: formData }).then(result => {
                    urlFiles.push(result);
                });
                i++;
            }

            if (i === files.length) {
                
                const cedula = dataUser.cedula;
                const nombre = dataUser.nombres+" "+dataUser.apellidos;
                
                const empresa = dataUser.empresa;
                const ccostos = dataUser.ccostos;
                
                const tipoDeEnvio = (checkBox)?document.querySelector('input[name="flexRadioDefault"]:checked').value:null;
                const adjuntos = urlFiles;

                const urlTicket = `${baseUrl}/v1/desconexionLaboral/consultarNivelDosCCostDto`;
                let formDataTicket = {
                    NRO_DOCUMENTO: cedula,
                    NOMBRE: nombre,
                    correo: correo,
                    celular: celular,
                    empresa: empresa,
                    cCostos: ccostos,
                    descripcion: descripcion,
                    alterno: tipoDeEnvio,
                    adjuntos: adjuntos
                };

                postFetch({ url: urlTicket, params: formDataTicket }).then(result => {
                    overlay(false);
                    if(result.error){
                        Swal.fire({
                            icon: 'error',
                            html: result.error,
                            showConfirmButton: true,
                            confirmButtonText: 'Cerrar',
                            confirmButtonColor: '#4695ff',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            focusConfirm: false,
                            timer: 5000,
                            timerProgressBar: true
                        });
                    }else{
                        Swal.fire({
                            icon: 'success',
                            html: `Se ha generado queja de desconexión laboral con radicado N° ${result.idRadicado}. Su solicitud tendrá respuesta en 5 días hábiles.`,
                            showConfirmButton: true,
                            confirmButtonText: 'Cerrar',
                            confirmButtonColor: '#4695ff',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            focusConfirm: false,
                            timerProgressBar: true,
                            willClose: () => {
                                setUrlFiles([]);
                                window.location.reload(false)
                            }
                        });
                    }
                });
            }

        } else {

            let error = "";
            if(!descripcion){
                error += "- Debe poner una descripción<br>";
            }
            if(!correo){
                error += "- Debe poner un correo<br>";
            }else if(validateEmail(correo) === null){
                error += "- Formato incorrecto de correo<br>";
            }
            if(checkBox && !celular){
                error += "- Debe poner un celular<br>";
            }
            if(files.length === 0){
                error += "- Debe adjuntar pruebas<br>";
            }

            overlay(false);

            Swal.fire({
                icon: 'error',
                html: error,
                showConfirmButton: true,
                confirmButtonText: 'Cerrar',
                confirmButtonColor: '#4695ff',
                allowOutsideClick: false,
                allowEscapeKey: false,
                focusConfirm: false,
                timer: 5000,
                timerProgressBar: true
            });

        }

    };

    const adicionarArchivo = () => {

        if (document.getElementById("archivo").files.length) {

            const filesSelected = document.getElementById("archivo").files;
            
            let error = "";

            for(const file of filesSelected){

                //let file = document.getElementById("archivo").files[0];

                let archivoBuscado = files.findIndex((element) => element.name === file.name);
                
                if (archivoBuscado === -1) {

                    files.push(file);

                    document.getElementById("divFiles").insertAdjacentHTML('beforeend', `
                        <div class='col-10' id="fileDiv${file.name}">
                            <input class='form-control' id='archivo' readOnly value="${file.name}"/>
                            <div class="offset-11 col-1 divTrashIcon">
                                <div style='cursor: pointer'>
                                <svg id="crossIcon${file.name}" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16"> 
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/> 
                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/> 
                                </svg>
                                </div>
                            </div>
                        </div>
                        `
                    );

                    document.getElementById("crossIcon" + file.name).addEventListener('click', function (event) {
                        let archivoBuscado = files.findIndex((element) => element.name === file.name);
                        files.splice(archivoBuscado, 1);
                        document.getElementById("fileDiv" + file.name).remove();
                    });

                }else{
                    error += "- "+file.name+"<br>"
                }

            }

            if (error) {
                Swal.fire({
                    icon: 'warning',
                    html: "Ya se han agregado los archivos <br><br><div style=\"text-align: left\">"+error+"</div>",
                    showConfirmButton: true,
                    confirmButtonText: 'Cerrar',
                    confirmButtonColor: '#4695ff',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    focusConfirm: false,
                    timer: 3500,
                    timerProgressBar: true
                });
            }

        } else {
            Swal.fire({
                icon: 'warning',
                html: "No se ha seleccionado un archivo",
                showConfirmButton: true,
                confirmButtonText: 'Cerrar',
                confirmButtonColor: '#4695ff',
                allowOutsideClick: false,
                allowEscapeKey: false,
                focusConfirm: false,
                timer: 3500,
                timerProgressBar: true
            });
        }

    };

    const checkOtroMedio = () => {

        const checkBox = document.getElementById("otroMedio").checked;
        document.getElementById("llamadaTelefonica").disabled = !checkBox;
        document.getElementById("whatsapp").disabled = !checkBox;
        if (!checkBox) {
            document.getElementById("divNumTelefonico").classList.add("d-none");
            document.getElementById("llamadaTelefonica").checked = checkBox;
            document.getElementById("whatsapp").checked = checkBox;
        } else {
            document.getElementById("divNumTelefonico").classList.remove("d-none");
            document.getElementById("llamadaTelefonica").checked = checkBox;
        }

    };

    return (
        <>

            <div className="card mb-3">
                <div className="card-body position-relative">
                    <div className="row">
                        <div className="col-lg-8">
                            <h3>Queja de desconexión laboral</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card mb-3">
                <div className='row p-3'>
                <strong className='text-danger mb-md-4 fs--1'>Todos los campos marcados con asteriscos son obligatorios.</strong>
                    <span className='mb-2'>Con el fin de ayudarte a resolver los inconvenientes, hemos creado esta opción donde podrás expresar tus inconformidades.</span>
                    <div className='col-lg-12 col-12'>
                        <label className="col-form-label" htmlFor='descripcion'><AsteriskRequired />Descripción del caso</label>
                        <textarea className='form-control' id='descripcion' placeholder='Describa de manera breve los hechos (Deben ser persistentes y demostrables).'></textarea>
                    </div>
                    <div className='col-lg-6 col-12'>
                        <label className="col-form-label mt-3" htmlFor='correo'><AsteriskRequired />Correo de envío de respuesta</label>
                        <input className='form-control' id='correo' defaultValue={dataUser["mail"]} />
                    </div>
                    <div className='col-lg-6 col-12 row' id='divFiles'>
                        <div className="col-lg-10 col-9">
                            <label className="col-form-label mt-3" htmlFor="archivo"><AsteriskRequired /> Adjuntar pruebas</label>
                            <div className="custom-file-container">
                                <button className="custom-file-button" id="select-files-button">Seleccionar archivos</button>
                                <input type="file" className="custom-file-input" id="archivo" multiple/>
                                <span className="custom-file-label" id="file-chosen">Ningun archivo seleccionado</span>
                            </div>
                        </div>

                        <div className='col-3 col-lg-2 d-table'>
        <div className="d-table-cell align-bottom">
            <div className="tooltip-container">
                <svg
                    style={{ color: "#2c7be5" }}
                    onClick={() => adicionarArchivo()}
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    fill="currentColor"
                    className="bi bi-plus-circle-fill plusIcon"
                    viewBox="0 0 16 16"
                >
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                </svg>
                <div className="tooltip-text">¡Pulsa aquí para agregar más archivos a la lista!</div>
            </div>
        </div>
    </div>
                    </div> 
                    <div className='col-lg-12 col-xxl-8 col-12 pt-3 row'>
                        <div className="form-check divOtroMedio ms-3 d-flex col-lg-4 col-12 ">
                            <label className="form-check-label mt-3" htmlFor="otroMedio">Otro medio de respuesta:</label>
                            <div className='form-check form-switch ms-2'>
                            <input className="form-check-input" style={{marginTop:'20px'}} type="checkbox" role='switch' id="otroMedio" onChange={() => checkOtroMedio()} />
                            </div>
                        </div>
                        <div className='col-lg-5 col-12 pt-3 ps-md-0 row'>
                            <div className='col-lg-5 me-3 col-12 ps-md-0'>
                                <div className="form-check ps-md-0">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="llamadaTelefonica" value={376} disabled />
                                    <label className="form-check-label" htmlFor="llamadaTelefonica">
                                         Llamada telefónica
                                    </label>
                                </div>
                                </div>
                            <div className='col-lg-5 col-12 ps-md-0'>
                                <div className="form-check ps-md-0">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="whatsapp" value={377} disabled />
                                    <label className="form-check-label" htmlFor="whatsapp">
                                    WhatsApp
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className='offset-6'></div> */}
                    <div className='col-12 d-none row' id='divNumTelefonico'>
                        <div className='col-lg-4 col-12'>
                            <label className="col-form-label  mt-3" htmlFor='telMovi'><AsteriskRequired />Número Teléfonico</label>
                            <input className='form-control' id='telMovi' type='number' defaultValue={dataUser["numeroCelular"]} />
                        </div>
                    </div>
                    <div className='offset-6'></div>
                    <div className='d-flex justify-content-end'>
                        <div style={{ textAlign: 'right' }} className="col-12 col-lg-2 mt-4">
                            <button className='btn btn-primary paddingBtn w-100' onClick={() => generarTicket()}>Enviar</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};