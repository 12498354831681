import { getFullNameUser } from '../../../generalHelpers';
import { MenuSecundario } from '../../Menus/MenuSecundario/MenuSecundario';
import PropTypes from 'prop-types'

export const NavbarUserProfile = ({ menu }) => {

    return (
        <ul className="navbar-nav navbar-nav-icons ms-auto flex-row align-items-center">
            <li className="nav-item tituloUsuarioCard nombreUsuarioDesktop d-none d-md-flex">¡Hola {getFullNameUser()}!</li>
            <li className="nav-item dropdown">
                <div className="nav-link pe-0" id="navbarDropdownUser" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true" style={{cursor:'pointer'}}>
                    <div className="avatar avatar-xl avatarIcon">
                        <i className="fas fa-user rounded-circle iconUser"></i>
                    </div>
                </div>
                <MenuSecundario menu={menu} key={'menu_2'} k={'menu_2'} />
            </li>

        </ul>
    )
}

NavbarUserProfile.propTypes = {
    menu: PropTypes.any,
}