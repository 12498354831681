import { CategoriaMenuPrincipal } from '../CategoriaMenuPrincipal/CategoriaMenuPrincipal';
import { Loading } from '../../Loading/Loading';
import PropTypes from 'prop-types'

export const MenuPrincipal = ({ menu, k }) => {
    if (!menu.subMenus) return <Loading/>;
    return (
        <ul className="navbar-nav flex-column mb-3" id="navbarVerticalNav" style={{ minHeight: '100%', padding: '0 0 13px 0', paddingTop: '0px' }}>{
            menu.subMenus.map((categoria, b) => (
                <CategoriaMenuPrincipal k={ k+'_'+(b+1) } key={ k+'_'+(b+1) } categoria={ categoria } />
            ))}
        </ul>
    );
};

MenuPrincipal.propTypes = {
    menu: PropTypes.object,
    k: PropTypes.string
}