import React from 'react';
import  { Redirect } from 'react-router-dom'
import { PersonalCampo } from '../../components/CheckIn/PersonalCampo/PersonalCampo';
import { Sedes } from '../../components/CheckIn/Sedes/Sedes';
import { routes } from '../../environments/environments';
import { useIngreso } from './useIngreso';
import foto from "../../assets/img/vumOfficeImgs/horas-extra.jpg";


export const Ingreso = () => {

    const {
        finished,
        formCheckIn,
        disabledSalida,
        hasMainInfo,
        tipoIngresos,
        userHasCheckIn,
        userHasRazon,
        setFormCheckIn,
        userHasCheckOut,
        handleFormSubmit,
        handleCheckUpdate,
        userDayOnSede
    } = useIngreso();

    const handleReasonChange = (newData) => {
        formCheckIn['reasonBranch'] = newData.target.value;
    };

    const getRenderInterface = () => {
        if(finished) return <Redirect to={routes.home.url} />

        else if(hasMainInfo) {
            let buttonSubmit = <></>;
            if(!userHasCheckIn) buttonSubmit = <button type='submit' className='btn btn-primary col-12 col-sm-6 col-md-4 col-xl-3' disabled={!Object.keys(formCheckIn).length}>Realizar Ingreso</button>
            else if(!userHasCheckOut) buttonSubmit = <button type='submit' className='btn btn-primary col-12 col-sm-6 col-md-4 col-xl-3' disabled={!Object.keys(formCheckIn).length || disabledSalida}>Realizar Salida</button>
            else buttonSubmit = <button type='button' className='btn btn-primary col-12 col-sm-6 col-md-4 col-xl-3' disabled={true}>Realizar Salida</button>

            let componenteFormulario = <></>;
            if(formCheckIn.typeCheckIn === 1165) componenteFormulario = <PersonalCampo form={formCheckIn} setForm={setFormCheckIn} userHasCheckIn={userHasCheckIn} />
            else if(formCheckIn.typeCheckIn === 1166) componenteFormulario = <Sedes         form={formCheckIn} setForm={setFormCheckIn} userHasCheckIn={userHasCheckIn} userHasCheckOut={userHasCheckOut}  hasUserDayOnSede={userDayOnSede} />


            return (
                <>
                <div className="card mb-3 mt-4 mt-md-0">
                    <div className="card-header position-relative text-center text-md-start ps-md-5" >
                        <div className="col-12">
                            <h3>Control de ingreso y salida</h3>
                        </div>
                    </div>
                </div>

                        <div className="card pt-3 pb-5">
                            <div className="card-body bg-light">
                            <div className="col-sm-12">
                        <img
                        id="image"
                        className="width100"
                        src={foto}
                        alt=""
                        data-dz-thumbnail
                        />
                    </div>

                        <form onSubmit={handleFormSubmit}>
                            <div className='offset-1 col-11 mb-4 mt-2'>
                                <div className="form-check" style={{paddingLeft: '0', marginBottom: '1rem'}}>
                                    <label className="form-check-label" htmlFor='tipoIngreso'>Tipo ingreso:</label>
                                </div>{
                                tipoIngresos.map(({id, title}) => (
                                    <div key={id}>
                                        <div className='form-check'>
                                            <input className='form-check-input' type='radio' name='tipoIngreso' id={id} value={id} checked={formCheckIn.typeCheckIn === id} onChange={handleCheckUpdate} disabled={userHasCheckIn} required />
                                            <label className='form-check-label' htmlFor={id}>
                                                { title }
                                            </label>
                                        </div>
                                    </div>
                                ))}
                                <div className='col-11'>
                                    {componenteFormulario}
                                    
                                    { 
                                    (!userHasRazon && formCheckIn.typeCheckIn !== 1166 && userDayOnSede?.[0]) ?
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="motivoCambio">Motivo de cambio</label>
                                            <textarea className="form-control" id="motivoCambio" name="motivoCambio" style={{height: '2rem'}} onBlur={(e) => handleReasonChange(e)} required></textarea>
                                        </div>
                                        :
                                        <></>
                                    }
                                </div>

                                <div className='col-11 text-center text-md-end'>
                                    {buttonSubmit}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </>
            )
        }
        else return <></>;
    }

    return getRenderInterface();
}