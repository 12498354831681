import { useEffect, Component } from 'react';
import Select, { createFilter } from 'react-select';
import { ColourStyles } from '../Inputs/Multiple/ColourStyles';
import { FixedSizeList as List } from "react-window";
import { overlay } from '../../generalHelpers';
import { getSalesPoints } from '../../repositories/generalInfo';
import PropTypes from 'prop-types';
import './SalesPoints.css'
import { AsteriskRequired } from '../AsteriskRequired/AsteriskRequired';


class MenuList extends Component {
    render() {
        const height = 35;
        const { options, children, maxHeight, getValue } = this.props;
        const [value] = getValue();
        const initialOffset = options.indexOf(value) * height;
        return (
            (options.length === 0) ?
                <></>
                :
                <List
                    height={maxHeight}
                    itemCount={children.length}
                    itemSize={height}
                    initialScrollOffset={initialOffset}
                >
                    {({ index, style }) => <div title={children[index].props.data.fullLabel} style={style}>{children[index]}</div>}
                </List>
        );
    }
}

export const SalesPoints = ({ form, filter, value, multiple = true, disabled = false, setSalesPoints, setSalesPoint, handleSalesPointUpdate, cargando = false, salesPoints, salesPoint }) => {

    useEffect(() => {

        if (filter) {
            overlay(true);
            getSalesPoints(filter).then(salesPoints => {
                if (salesPoints) {
                    overlay(false);
                    setSalesPoints(salesPoints);

                    if (form?.salesPoints) {
                        const salesPoint = salesPoints.filter(currentValue => (currentValue.value === form?.salesPoints))?.[0] || '';
                        setSalesPoint(salesPoint);
                    }
                }
            }).catch(e => { overlay(false); });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    /*useEffect(() => {
        setSalesPoint(value || []);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);*/


    return (
        <div>
            <label><AsteriskRequired/>Punto de venta:</label>
            <Select

                components={{ MenuList }}
                captureMenuScroll={false}
                filterOption={createFilter({ ignoreAccents: false })}
                isMulti={multiple}
                closeMenuOnSelect={!multiple}
                isDisabled={disabled}
                styles={ColourStyles}
                onChange={handleSalesPointUpdate}
                value={salesPoint}
                options={salesPoints}
                placeholder={(cargando) ? 'Cargando.....' : 'Seleccione...'}
                classNamePrefix="lp-copy-sel"
            />
            <input
                tabIndex={-1}
                autoComplete="off"
                style={{
                    opacity: 0,
                    width: "20%",
                    height: 0,
                    position: "absolute"
                }}
                onChange={() => { }}
                value={salesPoint}
                required='required'
            />
        </div>
    )
}

MenuList.propTypes = {
    options: PropTypes.array,
    children: PropTypes.any,
    maxHeight: PropTypes.number,
    getValue: PropTypes.func
}

SalesPoints.propTypes = {
    form: PropTypes.object,
    filter: PropTypes.any,
    value: PropTypes.any,
    multiple: PropTypes.bool,
    disabled: PropTypes.bool,
    setSalesPoints: PropTypes.func,
    setSalesPoint: PropTypes.func,
    handleSalesPointUpdate: PropTypes.func,
    cargando: PropTypes.bool,
    salesPoints: PropTypes.array,
    salesPoint: PropTypes.any
}