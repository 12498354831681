import React from 'react';
import '../css/TerminosCondiciones.css';

 const PoliticaNavegacion = ()=>{

return (
    <div className='content-term'>

        <div className='header-term'>

            <div className='div-icon'>
            <img src="../../assets/img/logo-vum-login.png" alt="logo-vum-login" className='image'/>
            </div>

            <div className='div-title'>
                <div className='title-top'>
                    <h3 className='size1'>PROCESO:
                    <br/>
                       GESTIÓN DEL RIESGO Y MEJORA CONTINUA</h3>
                </div>
                <div className='title-bottom'>
                <h3 className='size1'>POLÍTICA DE COOKIES Y DATOS DE NAVEGACIÓN EN MATERIA DE
PROTECCIÓN DE DATOS PERSONALES
                </h3>
                </div>
            </div>

            <div className='div-codigo size2'>
               <div className='title-top'>
                   <h3 className='size2'>Código 
                   <br/>
                   PO-MEJ-03
                   </h3>
               </div>
               <div className='title-bottom padding2'>
               <h3 className='size2'>Versión: 01</h3>
               </div>
            </div>

        </div>

        <div className='text-content'>
            <h3 className='size1'> <b>1.Definicion de cookies</b></h3>
            <p className='size2'>
          Las cookies son archivos que recogen información a través de una página web sobre los hábitos de navegación de un usuario o de su equipo y eventualmente podrían conformar una base de datos de acuerdo a la definición legal de la Ley 1581 de 2012 al recolectar datos personales conforme a las siguientes características: (i) están referidos a aspectos exclusivos y propios de una persona natural.
          <br/><br/>
         <br/> ii) permiten identificar a la persona, en mayor o menor medida, gracias a la visión de conjunto que se logre con el mismo y con otros datos;
         <br/> iii) su propiedad reside exclusivamente en el titular del mismo, situación que no se altera por su obtención por parte de un tercero de manera lícita o ilícita, y <br/> iv) su tratamiento está sometido a reglas especiales (principios) en lo relativo a su captación, administración y divulgación; caso en el cual, el responsable deberá ceñirse por las normas sobre protección de datos vigentes en Colombia, en especial la aplicación de los principios rectores para la administración de datos de legalidad, finalidad, libertad, veracidad o calidad, transparencia, acceso y circulación restringida, seguridad y confidencialidad consagrados en el artículo 4 de la Ley 1581 de 2012. Las cookies no son un virus o cualquier otro tipo de programa malicioso o malware que pueda dañar dispositivos de los usuarios. <br/><br/> Adicionalmente, como características implícitas de las cookies está el hecho de que éstas no tienen la capacidad de borrar ni leer información del dispositivo de los usuarios y tienen una fecha de expiración determinada que se establece de acuerdo con el portal que el usuario visita. Las cookies se utilizan porque forman parte del sistema de medición de las páginas webs.<br/><br/> Las cookies sirven para identificar y reconocer los usuarios, así como para conocer su comportamiento de navegación. Las cookies desempeñan un papel muy importante y contribuyen mejorar las experiencias de navegación para el usuario. Las cookies permiten recordar las preferencias y configuraciones de los usuarios y así ofrecerles una experiencia de navegación más fluida, personalizada y agradable, evitando tareas repetitivas
            </p>

             <h3 className='size1'><b>2 ¿Como Recaba “VUM” las Cookies”?</b></h3>

             <p className='size2'>Las cookies se crean y/o actualizan en el dispositivo del usuario de manera automática, cuando éste accede al portal de “VUM” y de sus compañías vinculadas y/o Entidades autorizadas, que le permite a “VUM” o a las terceras personas que este contrate, realizar seguimiento a las cookies del usuario y, por ende, a la información que estas cookies contienen u obtienen del usuario. Es importante aclarar que las cookies solo son leídas por el sitio web que las creó.
             </p>

             

             <h3 className='size1'><b>3. ¿QUÉ CLASE DE cookies UTILIZA ELEMPLEO Y PARA QUÉ LAS UTILIZA?</b></h3>

             <p className='size2'>
            <b>Al aceptar esta política, usted está acepta las finalidades que se enuncian a continuación:</b>
             
             </p>

             <h3 className='size1'><b>• COOKIES PROPIAS</b></h3>

             <p className='size2'>
             Las cookies propias son las cookies creadas y gestionadas directamente por la propia empresa que gestiona la web. Estás cookies son enviadas directamente al equipo terminal del usuario desde un equipo o dominio gestionado por el propio editor de la web. Para facilitar el proceso de registro en el mismo, así como permitir a los usuarios un acceso más rápido y seguro a los servicios seleccionados dentro del Portal. Sin estas cookies, es posible que “VUM” no les pueda ofrecer a los usuarios ciertos servicios dentro del Portal y éste puede que no funcione con normalidad.
             </p>

             <h3 className='size1'><b>• COOKIES DE TERCEROS</b></h3>
              <p className='size2'>
              Las cookies de terceros son aquellas cookies creadas y gestionadas por otra entidad que la web visitada. Están asociadas a eventos, imágenes o a elementos publicitarios provenientes de otros dominios.
              </p>

              <h3 className='size1'><b>• COOKIES DE SESIÓN</b></h3>

              <p className='size2'>Las cookies de sesión son cookies cortas que caducan al cerrar una página web, por ejemplo, al cerrar la *</p>

              <h3 className='size1'><b>• COOKIES PERSISTENTES</b></h3>

<p className='size2'>Opuestas a las cookies de sesión, las cookies persistentes son aquellas que siguen almacenadas en el ordenador durante más tiempo incluso después de la sesión (pueden ser horas, meses o años).</p>

<h3 className='size1'><b>• LAS COOKIES TÉCNICAS</b></h3>

<p className='size2'>Son aquéllas que permiten al usuario la navegación a través de una página web, plataforma o aplicación y la utilización de las diferentes opciones/servicios que existen en ella (por ejemplo: identificar una sesión, acceder a lugares de acceso restringido, recordar elementos que integran una compra, realizar el proceso de compra de un pedido, almacenar contenidos para la difusión de videos o sonido o compartir contenidos a través de redes sociales, etc.).</p>

<h3 className='size1'><b>• LAS COOKIES DE PERSONALIZACIÓN</b></h3>

<p className='size2'>
Las cookies de personalización son las que permiten recordar las preferencias de los usuarios cuando navegan en una web que ya han visitado. Las cookies de preferencias son también las que permiten cambiar el tamaño del texto, la fuente o las otras partes de las páginas web que se pueden personalizar. El objetivo de las cookies de personalización es ofrecer a los usuarios una navegación más fácil, fluida y personalizada.
</p>




<h3 className='size1'><b>• LAS COOKIES DE ANÁLISIS</b></h3>

<p className='size2'>
Son aquellas cookies que recopilan datos sobre la navegación de los usuarios en la web y que permiten a “VUM” el seguimiento y análisis del comportamiento de estos en una web. Estas cookies no recopilan información que puedan identificar a los usuarios, la información es anónima. El objetivo de las cookies de análisis es hacer seguimiento de la navegación y mejorar el funcionamiento del sitio web y la experiencia de los usuarios en función del uso que hacen del servicio. 
<br/>
<br/>
Estas cookies son de mayor importancia para “VUM” porque la información recogida permite medir la actividad de los sitios web y la elaboración de informes y perfiles de navegación de los usuarios ayudando a interpretar las necesidades de los usuarios.
<br/>
<br/>
A través de las Cookies de análisis o desempeño, “VUM” o el tercero que éste contrate, puede realizar distintos análisis y estudios de la información recolectada, con el fin de mejorar los productos y servicios que “VUM” ofrece a los usuarios.
<br/>
<br/>
“VUM” y los terceros con los que contrate servicios para el uso de Cookies, son los únicos que podrán acceder a la información almacenada en las Cookies que se han instalado dentro del equipo del usuario. La información almacenada en las Cookies no puede ser leída por otros usuarios, ni estos podrán acceder a la misma.
</p>

<h3 className='size1'><b>• LAS COOKIES PUBLICITARIAS</b></h3>

<p className='size2'>
Las cookies de publicidad son aquellas relacionadas con la publicidad que recibe el usuario tanto dentro como fuera de un site concreto. Las cookies publicitarias LAS Utiliza “VUM” para proponer a los usuarios la publicidad más atractiva y relevante para ellos, y que sean, a su vez, interesantes para los editores. Estas cookies son también las que permiten que no se repita varias veces el mismo anuncio al mismo usuario si ya lo ha visto con anterioridad. La mayoría de estas cookies publicitarias son cookies de terceros, de entidades ajenas. <br/>
<br/>Las cookies publicitarias tienen como objetivo mejorar la eficacia de los espacios publicitarios. <br/>
<br/>Para los anunciantes, las cookies de publicidad (llamadas también de “conversión”) permiten saber el número de clics que reciben sus anuncios, las visitas que han accedido a su sitio web gracias a sus campañas, las conversiones /compras realizadas por parte de un usuario etc.
</p>

<h3 className='size1'><b>• LAS COOKIES DE REDES SOCIALES Y OTROS PLUGINGS</b></h3>

<p className='size2'>
Estas cookies permiten a los usuarios interactuar con el contenido de un sitio web en las redes sociales como Facebook, Twitter, YouTube etc. Un ejemplo del uso de esta cookie seria compartir un sitio en Facebook o hacer clic en “Me gusta”. Las condiciones de uso y la información recopilada de estas cookies están reguladas por las propias plataformas sociales. 
<br/><br/>
Como hemos explicado, existen diversas categorías de tipos de cookies que permiten recopilar información de los usuarios, mejorar su experiencia de navegación por internet y proporcionar datos de hábitos de navegación a las empresas que las utilizan. Por supuesto, los usuarios tienen la posibilidad de elegir las cookies que quieren que funcionen o deshabilitarlas mediante la configuración de su navegador. Aquí te explicamos cómo desactivar y borrar las cookies de navegación.
</p>

<h3 className='size1'><b>4. EL usuario puede deshabilitar la instalación y uso de cookies que “VUM” recaba.</b></h3>

<p className='size2'>
El usuario puede deshabilitar tanto la instalación de las cookies como el uso de estas por parte de VUM. <br/><br/>Si un Usuario no quiere que su información personal sea recogida a través de cookies, puede cambiar las preferencias en su propio navegador web. Los Usuarios deben consultar a su navegador web para obtener instrucciones sobre cómo cambiar la configuración de cookies. No obstante, es importante señalar si un navegador web no acepta cookies, algunas de las funcionalidades de la Página Web, la Aplicación y/o las Páginas Vinculadas podrían no estar disponibles o no funcionar correctamente. <br/><br/>Adicionalmente, existen otras herramientas de terceros, disponibles online, que permiten a los usuarios detectar las cookies en cada sitio web que visita y gestionar su desactivación, por ejemplo:<br/><br/> • Ghostery <br/>• Vanilla Cookie Manager
</p>

<h3 className='size1'><b>5. ¿QUÉ OCURRE SI SE DESHABILITAN LAS cookies?</b></h3>

<p className='size2'>
Si el usuario deshabilita la instalación o el uso de las cookies para los Portales, podrá perder o afectar algunas funcionalidades del sitio, tales como:
<br/><br/>
a. Poder ingresar a los Portales sin necesidad de indicar en cada ocasión su usuario y contraseña, esto le ahorrará tiempo y le permitirá utilizar funcionalidades como boletines, alertas, noticias guardadas, comentarios, entre otros <br/>b. Despliegue correcto del contenido y la publicidad<br/> c. Seguridad en el uso de la información que se ingresa en los Portales<br/>. d. Mayor velocidad en el uso de algún servicio dentro de los Portales.<br/> e. Rapidez en el uso de algún servicio dentro de los Portales.
</p>

<h3 className='size1'><b>6. ¿“VUM” COMPARTE CON TERCERAS PERSONAS LA INFORMACIÓN OBTENIDA A TRAVÉS DE LAS cookies?</b></h3>

<p className='size2'>

“VUM” podrá compartir información obtenida a través de las cookies con personas externas o terceros (aliados, clientes, proveedores o empresas vinculadas a “VUM”), con el fin de mejorar la usabilidad y servicios al usuario. Así mismo, la información que se recibe a través de las cookies será utilizada por “VUM” y los terceros mencionados, para los fines descritos en el presente documento, los indicados en nuestra Política de Tratamiento de Datos ,y cualquiera de sus actualizaciones.
    
</p>

<h3 className='size1'><b>7. ALMACENAMIENTO DE LA INFORMACION OBTENIDA POR MEDIO “VUM”</b></h3>

<p className='size2'>
Podrá contratar terceras personas encargadas de almacenar y obtener la información a través de las cookies, o que incluyan cookies dentro de los portales de “VUM”, personas que podrán estar localizadas dentro de Colombia o en el exterior. Así mismo, “VUM” podrá entregar a terceros, la información que se obtenga de las cookies para crear perfiles de usuarios, ofrecer campañas personalizadas, sin que lo anterior, implique entrega de información personal. <br/><br/>Se requiere que el usuario visite las Políticas de Privacidad de los terceros contratados por “VUM”, para los fines de esta Política, los cuales pueden ser consultados en la plataforma.<br/><br/> La información obtenida a través de estas cookies, referida al equipo del usuario, podrá ser combinada con sus datos personales sólo si usted está registrado en el portal de “VUM”.
</p>

<h3 className='size1'><b>8. RECOMENDACIÓN QUE EL USUARIO DEBE TENER EN CUENTA SOBRE LA POLÍTICA DE USO DE cookies Y CANAL DE INFORMACIÓN</b></h3>

<p className='size2'>

La presente Política contiene la información necesaria que debe conocer todo usuario de los Portales sobre el uso de las cookies que realiza “VUM” o los terceros que este contrate. “VUM” podrá modificar el presente documento en cualquier momento y sin previo aviso para mantenerlos vigentes y actualizados. Por lo anterior, recomendamos a los usuarios revisar la fecha de elaboración o actualización de estos, la cual se establece al final del presente documento. <br/><br/>
Declaro que he sido informado que, como titular, tengo los siguientes derechos<br/><br/>
• Conocer, actualizar y corregir mis Datos Personales. Puedo ejercer este derecho, entre otros, en relación con la información, parcial, inexacta, incompleta, dividida, información engañosa o cuyo tratamiento sea prohibido o no autorizado.<br/>
• Requerir prueba del consentimiento otorgado para la recolección y el tratamiento de mis Datos Personales.<br/>
• Ser informado por “VUM” del uso que se le han dado a mis Datos Personales a través del uso de los Cookies.<br/>
• Presentar quejas ante la Superintendencia de Industria y Comercio en el caso en que haya una violación por parte de “VUM”<br/>
• Revocar la autorización y/o solicitar la supresión de datos personales a menos que exista un deber legal contractual que haga imperativo conservar la información otorgada para el tratamiento de mis Datos Personales.<br/>
• Sobre el concepto de datos sensibles del Art (6) de la Ley 1581 de 2012 y su tratamiento especial.<br/>
• Solicitar ser eliminado de su base de datos.<br/>
• Acceder en forma gratuita a los datos proporcionados que hayan sido objeto de tratamiento<br/><br/>

Dicha autorización para adelantar el tratamiento de mis datos personales se extiende durante la totalidad del tiempo en el que pueda llegar consolidarse un vínculo o este persista por cualquier circunstancia en la plataforma de “VUM”. y con posterioridad al finiquito de este, siempre que tal tratamiento se encuentre relacionado con las finalidades para las cuales los datos personales, fueron inicialmente suministrados.<br/><br/>

a) En ese sentido, declaro conocer que los datos personales objeto de tratamiento, serán utilizados específicamente para las finalidades derivadas de las relaciones legales, contractuales, comerciales y/o de cualquier otra que surja.<br/>
b) Que se realicen las consultas necesarias en diferentes listas restrictivas como las de la autoridad o privada competente para certificar la información de antecedentes que sea requerida por parte de “VUM”.<br/>
c) Todas las actuaciones administrativas que se requieran para garantizar la participación del postulante o candidato, así como la eventual ejecución de las actividades derivadas de la selección de mi postulación, como que consulten y entreguen a “VUM”. la información referente a procesos judiciales que cursen o hayan cursado en mi contra.
<br/><br/>
Se me ha informado sobre los diferentes mecanismos de comunicación, con el fin de ejercer los derechos anteriormente descritos, puedo conocer la Política de Tratamiento de Datos Personales y realizar consultas o reclamos relacionados con mis Datos Personales, a través de los siguientes canales de información:<br/><br/>

i) Teléfono: (0572) 6084848<br/>
iii Correo electrónico: protecciondedatos@listos.com.co<br/>
iii) Correspondencia: Cl.21N NRO. 8N-21 Cali – Valle del Cauca<br/> iv) Presencial: Cl.21 Norte #8N-21 Santa Monica Residencial Santiago de Cali (Valle del Cauca) 

<br/><br/>En caso de dudas o inquietudes acerca del uso de cookies o sobre cualquiera de los puntos detallados en esta Política, por favor escriba al siguiente correo electrónico: protecciondatos@listos.com.co
<br/><br/>El presente documento rige a partir del 01 de agosto de 2021 y hasta el momento en que expresamente se revoque o modifique. Al Aceptar, mediante cualquiera de los mecanismos de Autorización descritos arriba o cualquier otro que resulte legítimo y aceptable, habrás aceptado íntegramente nuestra Política de Privacidad.
    
</p>

        </div>

    </div>
)

};

export default PoliticaNavegacion;