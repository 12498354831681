import { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { loadingDialog } from "../../../components/general/General";
import ConsultarOnboardingTable from "../../../components/Onboarding/Consultar/ConsultarOnboardingTable";
import { baseUrl } from "../../../config/config";

const ConsultarOnboarding = () => {
  const [datauser] = useState(JSON.parse(localStorage.d_u));
  const [dataOnboarding, setDataOnboarding] = useState({
    datos:[], 
    total:0, 
    finalizados:0, 
    pendientes:0
});
  const [loading, setLoading] = useState(true);

  const obtenerOnboardings = useCallback(async () => {
    try {
      loadingDialog(true);
      const response = await axios.post(
        `${baseUrl}/v1/encuestaOnConsultar/obtenerOnboardings`,
        datauser
      );
      setDataOnboarding(response.data);
    } catch (error) {
      console.error("Error fetching onboardings:", error);
    } finally {
      setLoading(false);
      loadingDialog(false);
    }
  }, [datauser]);

  useEffect(() => {
    obtenerOnboardings();
  }, [obtenerOnboardings]);

  const divData = (
    <>
      {
        <>
          <div className="card-header mt-3">
            <h5 className="card-title">Detalles de tu entrenamiento</h5>
          </div>
          <div className="card-body bg-light">
            <form id="fomrcertLaboral">
              <div className="row">
                <div className="col-12 col-lg-4 d-flex align-items-stretch">
                  <div className="card card-entranamientosTotal w-100">
                    <div className="card-body d-flex justify-content-center align-items-center">
                      <label htmlFor="cedula" className="mb-0 me-2">
                        <strong>Tus entrenamientos:</strong>
                      </label>
                      <div className="fw-bold">{dataOnboarding.total}</div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4 d-flex align-items-stretch">
                  <div className="card card-entranamientosFinalizados w-100">
                    <div className="card-body d-flex justify-content-center align-items-center">
                      <label htmlFor="nombre" className="mb-0 me-2">
                        <strong>Módulos finalizados:</strong>
                      </label>
                      <div className="fw-bold">{dataOnboarding.finalizados}</div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4 d-flex align-items-stretch">
                  <div className="card card-entranamientosPendientes w-100">
                    <div className="card-body d-flex justify-content-center align-items-center">
                      <label htmlFor="celular" className="mb-0 me-2">
                        <strong>Módulos pendientes:</strong>
                      </label>
                      <div className="fw-bold">{dataOnboarding.pendientes}</div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </>
      }
    </>
  );

  return (
    <>
      <div className="card mb-3">
        <div className="card-header position-relative text-center text-md-start ">
          <div className="col-12">
            <h3>Mis entrenamientos</h3>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body">{divData}</div>
        <div className="w-100 d-flex justify-content-center">
          <div
            className=" border-top border-3 my-3"
            style={{ width: "95%" }}
          ></div>
        </div>
        <div className="card-body">
          {loading ? (
            <p>Cargando onboardings...</p>
          ) : (
            <ConsultarOnboardingTable onboardings={dataOnboarding.datos} />
          )}
        </div>
      </div>
    </>
  );
};

export default ConsultarOnboarding;
