import Select from 'react-select';
import { useEffect, useState } from 'react';
import { getAllBranches } from '../../../repositories/generalInfo';
import { ColourStyles   } from '../../Inputs/Multiple/ColourStyles';
import PropTypes from 'prop-types';

export const Sedes = ({ setForm, form, userHasCheckIn, hasUserDayOnSede }) => {

    const [branch, setBranch] = useState('');
    const [branches, setBranches] = useState([]);
    const [motivoCambio, setMotivoCambio] = useState("");

    useEffect(() => {
        getAllBranches().then(newBranches => {
            if (newBranches) {

                setBranches(newBranches);

                if (form?.branch) {
                    const branchChange = newBranches.filter(currentValue => ((currentValue.value === form.branch ))) || '';
                    setBranch(branchChange);
                }else if(hasUserDayOnSede?.[0]){
                    const branchChange = newBranches.filter(currentValue => (currentValue.value === hasUserDayOnSede[0].SEDE_CODIGO )) || '';
                    setBranch(branchChange);
                    setForm(old_value => ({
                        ...old_value,
                        branch: hasUserDayOnSede[0].SEDE_CODIGO
                    }));
                }
            }
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSedeUpdate = (e) => {
        setBranch(e);
        setForm(old_value => ({
            ...old_value,
            branch: e.value
        }));
        if(hasUserDayOnSede){
            if(e.value !== hasUserDayOnSede[0]?.SEDE_CODIGO && typeof hasUserDayOnSede[0] !== 'undefined'){
                setMotivoCambio(
                    <div className="mb-3">
                        <label className="form-label" htmlFor="motivoCambio">Motivo de cambio</label>
                        <textarea className="form-control" id="motivoCambio" name="motivoCambio" style={{height: '2rem'}} onBlur={(e) => handleReasonChange(e)} required></textarea>
                    </div>
                );
            }else{
                setMotivoCambio();
            }
        }
    };

    const handleReasonChange = (newData) => {
        if(newData.target.value){
            form['reasonBranch'] = newData.target.value;
        }else{
            delete form.reasonBranch;
        }
    };

    return (
        <div className='row mb-12'>
            <div className='input-group'>

                {
                    (!userHasCheckIn) ?
                    <div className='offset-md-0 col-12 col-md-6' style={{paddingRight: '1rem', marginBottom: '1rem'}}>
                    <label htmlFor='sede'>Sedes</label>
                    <Select id="sede" styles={ColourStyles} isDisabled={userHasCheckIn} onChange={handleSedeUpdate} value={ branch } options={branches} placeholder={'Seleccione...'}/>
                    <input
                        tabIndex={-1}
                        autoComplete="off"
                        style={{
                            opacity: 0,
                            width: "20%",
                            height: 0,
                            position: "absolute"
                        }}
                        onChange={ ()=>{} }
                        value={branch}
                        required='required'
                    />
                    </div>
                    :
                    <div className='offset-md-0 col-12 col-md-4' style={{paddingRight: '1rem', marginBottom: '1rem'}}>
                    <label htmlFor='sede'>Sedes</label>
                    <Select id="sede" styles={ColourStyles} isDisabled={userHasCheckIn} onChange={handleSedeUpdate} value={ branch } options={branches} placeholder={'Seleccione...'}/>
                    <input
                        tabIndex={-1}
                        autoComplete="off"
                        style={{
                            opacity: 0,
                            width: "20%",
                            height: 0,
                            position: "absolute"
                        }}
                        onChange={ ()=>{} }
                        value={branch}
                        required='required'
                    />
                    </div>
                }
                
            </div>
            {motivoCambio}
        </div>
    )
}

Sedes.propTypes = {
    setForm: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    userHasCheckIn: PropTypes.bool.isRequired,
    hasUserDayOnSede: PropTypes.array
};