import React from 'react';
import { getFullNameUser } from '../../../generalHelpers';

export const MenuSecundarioHeader = () => {

    return (
        
        <span className="dropdown-item fw-bold tituloUsuarioCard nombreUsuarioRespoonsive d-md-none d-flex">¡Hola {getFullNameUser().split(' ')[0].toUpperCase()}!</span>


    )
}
