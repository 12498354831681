import { useOpenField } from '../useOpenField';
import PropTypes from 'prop-types'

export const Text = (props) => {

    const { inputValue, handleChange } = useOpenField(props);

    if (!props.item) return <></>;

    return (
        <div className='col-12 col-md-5'>
            <input type='text' className='form-control' value={inputValue} onChange={handleChange} required />
        </div>
    )
}

Text.propTypes = {
    item: PropTypes.any,
}