import {Component} from 'react';
import moment from 'moment';
import { baseUrl } from '../../config/config';
import { postData,simulateClick, validateInputTabs, putInputRequerid, loadDataValidate, loadingDialog } from '../../components/general/General';
import Swal from 'sweetalert2';


class CV extends Component {
    constructor(props){
        super(props);
        this.state = {
            datbasCodigo: '',

            // Datos generales
            nombres: '',
            apellidos: '',
            tipo_documento: '',
            cedula: '',
            sexo: '',
            fec_nacimiento: '',
            edad: '',
            estado_civil: '',

            //Datos residenciales
            pais: '',
            depto: '',
            ciudad: '',
            barrio: '',
            direccion: '',

            // Datos corporativos
            email_personal: '',
            email_corporativo: '',
            cel_personal: '',
            cel_trabajo: '',
            nomNiv2: '',
            nomNiv4: '',
            nomNiv5: '',
            nom_cargo: '',
            antiguedad:'',
            cargos:'',
            plan_carrera: '',
            uniforme: '',
            camisa: '',
            pantalon: '',
            calzado: '',

            // Variables para la interfaz
            optionsDocumento:'',
            optionsCivil: '',
            optionsPaises:'',
            optionsDepto:'',
            optionsCity:'',
            nomenclaturaStreet:'',
            nomenclaturaBis:'',
            alphabet:["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","U","V","W","X","Y","Z"],
            nomenclaturaCardinalidad:'',
            nomenclaturaComplemento:'',
            estadonav:'disabled',
            nivel2bd:'',
            nivel4bd:'',
            nivel5bd:'',
            breadcrumb:'Datos generales'
        }
    }

    componentDidMount(){
        document.getElementById('root').className = 'cv';
        this.consultaInicial();
    }

    consultaInicial = () => {
        loadingDialog(true);
        const dataUser = JSON.parse( localStorage.getItem("d_u"));
        const cedula = parseInt(dataUser['cedula']);
        const empresa = parseInt(dataUser['empresa']);

        const url = `${baseUrl}/v1/informacionBasica/consultaInicial`;
        const dataSend = {cedula, empresa};
        postData(url, dataSend).then(async (result) =>{
            await this.asignarValoresSelects(result);
            this.loadDataPrincipal(result.datosPersonales);
            this.ordenarCargosPlanCarrera();
            loadingDialog(false);
        }).catch(err => {
            loadingDialog(false);
            Swal.fire({
                icon: 'warning',
                title: '¡Ups!',
                text: 'Ha ocurrido un error al cargar el formulario, intenta nuevamente.',
                confirmButtonColor: "#A6A6A6",
                confirmButtonText: 'Cerrar'
            })
        });
    }

    // En esta función insertamos todos los valores de los select
    asignarValoresSelects = async (result) => {
        // Básico
        let tiposDocumento = result.tiposDocumento.map((value,x) => <option  key={'optionDocu'+x} value={value["TIP_ATRIBUTO1"]}>{value["TIP_NOMBRE"]}</option>);
        let estadosCivil = result.estadoCivil.map((value,x) => <option  key={'optionCivil'+x} value={value["TIP_ATRIBUTO1"]}>{value["TIP_NOMBRE"]}</option>);
        let paises = result.paises.map((value,x) => <option  key={'optionPais'+x} value={value["cod_pais"]}>{value["nom_pais"]}</option>);

        // Direcciones
        let calles = result.nomenclaturas.calles.map((value,x) => <option  key={'optionCalle'+x} value={value["COD_NOME"]}>{value["NOM_NOME"]}</option>);
        let bis = result.nomenclaturas.bis.map((value,x) => <option  key={'optionBis'+x} value={value["COD_NOME"]}>{value["NOM_NOME"]}</option>);
        let cardinalidades = result.nomenclaturas.cardinalidades.map((value,x) => <option  key={'optionCardi'+x} value={value["COD_NOME"]}>{value["NOM_NOME"]}</option>);
        let complementos = result.nomenclaturas.complementos.map((value,x) => <option  key={'optionComp'+x} value={value["COD_NOME"]}>{value["NOM_NOME"]}</option>);

        // Indumentaria
        let camisas = result.tallasCamisa.map((value,x) => <option  key={'optionCami'+x} value={value["TIP_CODIGO"]}>{value["TIP_NOMBRE"]}</option>);
        let pantalones = result.tallasPantalon.map((value,x) => <option  key={'optionPant'+x} value={value["TIP_CODIGO"]}>{value["TIP_NOMBRE"]}</option>);
        let calzado = result.tallasCalzado.map((value,x) => <option  key={'optionCalz'+x} value={value["TIP_CODIGO"]}>{value["TIP_NOMBRE"]}</option>);

        // Se retorna un promise.all para que todos los inputs se coloquen antes de setear los valores en la función loadDataPrincipal
        return await Promise.all([tiposDocumento, estadosCivil, paises, calles, bis, cardinalidades, complementos, camisas, pantalones, calzado,])
        .then(res => {
            this.setState({
                optionsDocumento: res[0],
                optionsCivil: res[1],
                optionsPaises: res[2],
                nomenclaturaStreet: res[3],
                nomenclaturaBis: res[4],
                nomenclaturaCardinalidad: res[5],
                nomenclaturaComplemento: res[6],
                optionsCamisa: res[7],
                optionsPantalon: res[8],
                optionsCalzado: res[9],
                nivel2bd: (result.labelsNivel[0].nom_nive.toLowerCase().charAt(0).toUpperCase() + result.labelsNivel[0].nom_nive.toLowerCase().slice(1)) || '',
                nivel4bd: (result.labelsNivel[1].nom_nive.toLowerCase().charAt(0).toUpperCase() + result.labelsNivel[1].nom_nive.toLowerCase().slice(1)) || '',
                nivel5bd: (result.labelsNivel[2].nom_nive.toLowerCase().charAt(0).toUpperCase() + result.labelsNivel[2].nom_nive.toLowerCase().slice(1)) || '',
            });
            return true;
        });
    }

    loadDataPrincipal= (data) =>{
        // Si tiene plan carrera, marca "Sí" o "No" y además crea los botones con los cargos que ingresó
        if(data.plan_carrera) {
            if(data.plan_carrera === 0) putInputRequerid('#corp-12','','add','corp-12');
            if(data.plan_carrera === 1) {
                document.getElementById('btn-check-outlined').checked = true;
                if(data.cargos_carrera) {
                    document.querySelector(".cargosLi").innerHTML = ''
                    document.querySelector("#corp-12").classList.remove('inputRequired')
    
                    const cargos = data.cargos_carrera.split(',')
                    document.querySelectorAll('.smalldel ').forEach(el =>  el.removeAttribute('hidden'))
    
                    cargos.forEach(element => {
                        let input = document.createElement("button");
                        let container = document.querySelector(".cargosLi")
    
                        input.addEventListener( 'click', function(e){
                            e.target.remove()
                            if(!document.querySelectorAll('.cargosLi button').length){
                                document.querySelectorAll('.smalldel ').forEach(el =>  el.setAttribute('hidden','hidden'))
                                const labelCampo = document.querySelector(`label[for=corp-12]`)  
                                labelCampo.innerHTML += '<span class="text-danger">*</span>'
                                document.querySelector("#corp-12").classList.add('inputRequired')
                            }
                        });
    
                        input.setAttribute("type","button");
                        input.setAttribute("id",element);
                        input.setAttribute("value",element);
                        input.setAttribute("class",'btn btn-outline-primary me-1 mb-1')
    
                        input.innerHTML += `${element}`
                        container.appendChild(input);
                    });
                }
            } else {
                document.getElementById('btn-check-outlined2').checked = true;
                putInputRequerid('#corp-12','','remove','corp-12');
            }
        }

        // Si tiene indumentaria, marca "Sí" o "No"
        if(data.indumentaria) {
            if(data.indumentaria === 1) document.getElementById('checkUsesUniform').checked = true;
            if(data.indumentaria === 0) document.getElementById('checkUsesUniform2').checked = true;
        }

        this.setState({
            datbasCodigo: data.datbas_codigo,
            nombres: data.nombres,
            apellidos: data.apellidos,
            tipo_documento: data.tipo_documento,
            cedula: data.cedula,
            sexo: data.sexo,
            fec_nacimiento: data.fec_nacimiento,
            estado_civil: data.estado_civil,
            pais: data.pais,
            depto: data.depto,
            ciudad: data.ciudad,
            barrio: data.barrio,
            direccion: data.direccion,
            email_personal: data.email_personal,
            email_corporativo: data.email_corporativo,
            cel_personal: data.cel_personal,
            cel_trabajo: data.cel_trabajo,
            nomNiv2: data.nomNiv2,
            nomNiv4: data.nomNiv4,
            nomNiv5: data.nomNiv5,
            nom_cargo: data.nom_cargo,
            plan_carrera: String(data.plan_carrera),
            uniforme: String(data.indumentaria),
            camisa: data.camisa,
            pantalon: data.pantalon,
            calzado: data.calzado,
        });
        
        if(data.pais) simulateClick('pais',500,'change');
        if(data.depto) simulateClick('depto',1500,'change');

        this.calculaEdad(moment().utc(),moment.utc(data.fec_nacimiento));
        if(data.anios!=null && data.meses!=null && data.dias!=null) this.calculaAntiguedad(data.anios, data.meses, data.dias);
    }

    ordenarCargosPlanCarrera = () => {
        document.querySelector("#corp-12").addEventListener("keypress", function(event) {
            if(document.querySelector("#corp-12").value.trim()){
                if (event.keyCode === 13 ) {
                    let container = document.querySelector(".cargosLi")
                    let data = document.querySelector("#corp-12").value
                    let input = document.createElement("button");
                    input.addEventListener( 'click', function(e){
                        e.target.remove()
                        if(!document.querySelectorAll('.cargosLi button').length){
                            document.querySelectorAll('.smalldel ').forEach(el =>  el.setAttribute('hidden','hidden'))
                            const labelCampo = document.querySelector(`label[for=corp-12]`)  
                            labelCampo.innerHTML += '<span class="text-danger">*</span>'
                            document.querySelector("#corp-12").classList.add('inputRequired')
                        }
                    });
                    input.setAttribute("type","button");
                    input.setAttribute("id",data);
                    input.setAttribute("value",data);
                    input.setAttribute("class",'btn btn-outline-primary me-1 mb-1')

                    input.innerHTML += `${data}`
                    container.appendChild(input);
                    const labelCampo = document.querySelector(`label[for=corp-12]`)
                    if(document.querySelectorAll('.cargosLi button')){
                        if(document.querySelectorAll('.cargosLi button').length === 0){
                            labelCampo.innerHTML += '<span class="text-danger">*</span>'
                            document.querySelector("#corp-12").classList.add('inputRequired')
                            document.querySelectorAll('.smalldel ').forEach(el =>  el.setAttribute('hidden','hidden'))
                        }else{
                            if(labelCampo.getElementsByTagName('span').length){
                                labelCampo.getElementsByTagName('span')[0].remove()
                            }

                            // document.querySelector('.smalldel').removeAttribute('hidden')
                            document.querySelectorAll('.smalldel ').forEach(el =>  el.removeAttribute('hidden'))
                            document.querySelector("#corp-12").classList.remove('inputRequired')
                        }
                    }
                    document.querySelector("#corp-12").value = ''
                }
            }
        });
    }

    // consultar departamento según el país
    loadDeptos = () => {
        const codCountry = this.pais.value;
        if(!codCountry) return;
        
        const datos = {codPais:parseInt(codCountry)};
        const urlDpto = `${baseUrl}/v1/informacionBasica/consultaDepartamentos`;
        
        postData(urlDpto,datos).then(result =>{
            let option = result.map((value,x) => <option  key={'optionDepto'+x} value={value["cod_dpto"]}>{value["nom_mpio"]}</option>);
            this.setState({optionsDepto:option});
         });
    }

    // consultar ciudades según el departamento
    loadCiudades = () => {
        if(!this.depto.value) return;

        const datos = { codDepartamento:parseInt(this.depto.value),codPais:parseInt(this.pais.value) };
        const url = `${baseUrl}/v1/informacionBasica/consultarCiudades`;
        postData(url,datos).then(result => {
            if(!result.error){
                let option = result.map((value,x) => <option  key={'optionCity'+x} value={value["cod_mpio"]}>{value["nom_mpio"]}</option>);
                this.setState({optionsCity:option});
            }else{
                Swal.fire({
                    title: '',
                    text: result.error,
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonColor: "#A6A6A6",
                    confirmButtonText: 'Cerrar'
                });
            }
        });
    } 

    clearAddress = () => {
        this.direccion.value = '';
        this.campo1.value= '';
        this.campo2.value= '';
        this.campo3.value= '';
        this.campo4.value= '';
        this.campo5.value= '';
        this.campo6.value= '';
        this.campo7.value= '';
        this.campo8.value= '';
        this.campo9.value= '';
        this.campo10.value= '';
        this.campo11.value= '';
        this.campo12.value= '';
        this.setState({direccion: this.direccion.value});
    } 

    loadAddress = () =>{
        let dirAppend = ''
        if(this.campo1.value) dirAppend += this.campo1.value+' ';
        if(this.campo2.value) dirAppend += this.campo2.value+' ';
        if(this.campo3.value) dirAppend += this.campo3.value+' ';
        if(this.campo4.value) dirAppend += this.campo4.value+' ';
        if(this.campo5.value) dirAppend += this.campo5.value+' ';
        if(this.campo6.value) dirAppend += this.campo6.value+' ';
        if(this.campo7.value) dirAppend += this.campo7.value+' ';
        if(this.campo8.value) dirAppend += this.campo8.value+' ';
        if(this.campo9.value) dirAppend += this.campo9.value+' ';
        if(this.campo10.value) dirAppend += this.campo10.value+' ';
        if(this.campo11.value) dirAppend += this.campo11.value+' ';
        if(this.campo12.value) dirAppend += this.campo12.value+' ';
        
        this.direccion.value = dirAppend.trim();
        this.setState({direccion: this.direccion.value});
    }


    guardarDatosBasicos = () =>{
        const camposFaltantes = validateInputTabs();
        if(camposFaltantes) return;
        
        const dataUser = JSON.parse( localStorage.getItem("d_u"));
        const cedula = parseInt(dataUser.cedula);
        const empresa = parseInt(dataUser.empresa);
        const usuLogin = dataUser.USU_LOGIN;

        let cargos_carrera = []
        if(document.querySelectorAll('.cargosLi button').length){
            document.querySelectorAll('.cargosLi button').forEach(element =>{
                cargos_carrera.push(element.value)
            });
        }

        // Toca hacer las conversiones a Number por el validator que tiene el backend
        const datosEnviar = {
            usuLogin: String(usuLogin),
            cedula,
            empresa,
            datbasCodigo: this.state.datbasCodigo,
            estado_civil: this.state.estado_civil,
            pais: Number(this.state.pais),
            depto: Number(this.state.depto),
            ciudad: Number(this.state.ciudad),
            barrio: this.state.barrio,
            direccion: this.state.direccion,
            email_personal: this.state.email_personal,
            email_corporativo: this.state.email_corporativo,
            cel_personal: Number(this.state.cel_personal),
            cel_trabajo: Number(this.state.cel_trabajo),
            plan_carrera: Number(this.state.plan_carrera),
            cargos_carrera: cargos_carrera.toString(),
            uniforme: Number(this.state.uniforme),
            camisa: Number(this.state.camisa),
            pantalon: Number(this.state.pantalon),
            calzado: Number(this.state.calzado),
        }

        loadingDialog(true);
        const url = `${baseUrl}/v1/informacionBasica/guardarDatosBasicos`;
        postData(url,datosEnviar).then(result =>{
            loadingDialog(false);
            if(result.error) {
                return Swal.fire({
                    icon: 'warning',
                    title: 'Ha ocurrido un error',
                    text: result.error,
                    confirmButtonColor: "#A6A6A6",
                    confirmButtonText: 'Cerrar'
                })
            }

            loadDataValidate(); // Volvemos a cargar los colores de los iconos
            Swal.fire({
                title: '',
                html: `
                <div class="text-center">
                    <img src="https://controlf.blob.core.windows.net/public/vumoffice/assets/
cv/check.png" alt="check" style="width:60px">
                    <h3 class="text-primary mt-3">¡Tus DATOS BÁSICOS han sido guardados con éxito!</h3>
                    <h5 class="text-secondary">Te garantizamos un buen uso de tu información.</h5>
                    <a type="button" class="btn succesButton mt-3" href="/cv/educación_formal">Continuar llenando "Educación formal"</a>
                </div>
                `,
                showCloseButton: false,
                showCancelButton: false,
                showConfirmButton: false
            });
        }).catch(err => {
            loadingDialog(false);
            Swal.fire({
                icon: 'error',
                title: '¡Ups!',
                text: 'No se guardaron los datos, intente nuevamente.',
                confirmButtonColor: "#A6A6A6",
                confirmButtonText: 'Cerrar'
            })
        });
    }

    calculaEdad = (fecha,fecha_nac) => {
        let a = moment.utc(fecha);
        let b = moment.utc(fecha_nac);
        let years = a.diff(b, 'year');
        b.add(years, 'years');
        this.setState({edad:years});
    }

    calculaAntiguedad = (anios, meses, dias) => {
        let stringAnio = anios === 1 ? "año" : "años";
        let stringMes = meses === 1 ? "mes" : "meses";
        let stringDia = dias === 1 ? "día" : "días";

        const antiguedadParseada = `${anios} ${stringAnio}, ${meses} ${stringMes} y ${dias} ${stringDia}`; // 1 año, 3 meses y 14 días
        this.setState({antiguedad:antiguedadParseada});
    }

    validateInputTabsIn = (tab) => {
        let valtab =  validateInputTabs(tab)
        if(valtab === ''){
            this.setState({estadonav:''})
            simulateClick(tab,0,'click')
            setTimeout(() => {
                this.setState({estadonav:'disabled'})
            }, 500);
        }
        this.handleBreadcrumb(tab);
    }

    vaidateBack =(tabidentificador) =>{
        this.setState({estadonav:''})
        simulateClick(tabidentificador,0,'click')
        setTimeout(() => {
            this.setState({estadonav:'disabled'});
        }, 500);
        this.handleBreadcrumb(tabidentificador);
    }

    handleBreadcrumb = (tab) => {
        if(tab==='general-tab') this.setState({breadcrumb:'Datos generales'});
        else if(tab==='residencial-tab') this.setState({breadcrumb:'Datos residenciales'});
        else if(tab==='corpo-tab') this.setState({breadcrumb:'Datos corporativos'});
        else this.setState({breadcrumb:''});
    }

    infoPlanCarrera = () => {
        const informacion = `
        El plan carrera es un ascenso por mérito propio que se le ofrece al trabajador.
        Al quedar abierta una vacante, éste se puede postular y teniendo en cuenta su desempeño, evaluación y disciplina en la empresa, puede llegar a ocupar el cargo que es requerido.`;

        Swal.fire({
            title: '¿Qué es plan carrera?',
            text: informacion,
            icon: 'info',
            showCancelButton: false,
            confirmButtonColor: "#A6A6A6",
            confirmButtonText: 'Cerrar'
        });
    }
 
    render() {
        const {optionsDocumento,optionsCivil,optionsPaises,optionsDepto,nomenclaturaStreet,nomenclaturaBis,nomenclaturaCardinalidad,nomenclaturaComplemento,optionsCity,antiguedad,optionsCamisa,optionsPantalon,optionsCalzado,estadonav,breadcrumb} = this.state
        const alphabet = this.state.alphabet.map((value,x) =><option key={'optionAlph'+x} value={value}>{value}</option> );
        
        return (
        <>
            <div className="card">
                <div className='card-header'>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb fs-2">
                            <li className="breadcrumb-item">Mi perfil</li>
                            <li className="breadcrumb-item">Datos básicos</li>
                            <li className="breadcrumb-item" aria-current="page">{breadcrumb}</li>
                        </ol>
                    </nav>
                </div>
            </div>
            &nbsp;
            <div className="card">
                <div className="card-body">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button disabled={estadonav} className="nav-link active" id="general-tab" data-bs-toggle="tab" data-bs-target="#general" type="button" role="tab" aria-controls="general" aria-selected="true">Datos generales</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button disabled={estadonav} className="nav-link" id="residencial-tab" data-bs-toggle="tab" data-bs-target="#residencial" type="button" role="tab" aria-controls="residencial" aria-selected="false">Datos residenciales</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button disabled={estadonav} className="nav-link" id="corpo-tab" data-bs-toggle="tab" data-bs-target="#corpo" type="button" role="tab" aria-controls="corpo" aria-selected="false">Datos corporativos</button>
                        </li>
                    </ul>
                    
                    &nbsp;
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="general" role="tabpanel" aria-labelledby="general-tab">
                            <div className="row g-3">
                                <div className="col-12 col-md-4">
                                    <label className='form-label' htmlFor="gene-nom">Nombres:</label>
                                    <input value={this.state.nombres || ''} type="text" ref={inp => this.nombre = inp} readOnly className="form-control" id="gene-nom" name="gene-nom" required></input>
                                </div>
                                <div className="col-12 col-md-4">
                                    <label className='form-label' htmlFor="gene-ape">Apellidos:</label>
                                    <input value={this.state.apellidos || ''} type="text" ref={inp => this.apellido = inp} readOnly className="form-control" id="gene-ape" name="gene-ape" required></input>
                                </div>
                                <div className="col-12 col-md-4">
                                    <label className='form-label' htmlFor="gene-tipodocu">Tipo de documento:</label>
                                    <select value={this.state.tipo_documento || ''} ref={inp => this.tipodocu = inp} disabled className="form-select" id="gene-tipodocu" name="gene-tipodocu" required>
                                        {optionsDocumento}
                                    </select>
                                </div>
                                <div className="col-12 col-md-4">
                                    <label className='form-label' htmlFor="gene-numdocu">Número de documento:</label>
                                    <input value={this.state.cedula || ''} type="text" ref={inp => this.cedula = inp} readOnly className="form-control" id="gene-numdocu" name="gene-numdocu"></input>
                                </div>
                                <div className="col-12 col-md-4">
                                    <label className='form-label' htmlFor="optionsSex">Sexo:</label>
                                    <div className=" d-flex justify-content-around">
                                        <input checked={this.state.sexo === 'F' ? true : false } readOnly type="radio" value='F' className="btn-check input-hidden" name="optionsSex" id="optionSexF"></input>
                                        <label className="btn btn-outline-primary w-50" htmlFor="optionSexF">Mujer</label>&nbsp;
                                        <input checked={this.state.sexo === 'M' ? true : false } readOnly type="radio" value='M' className="btn-check input-hidden" name="optionsSex" id="optionSexM"></input>
                                        <label className="btn btn-outline-primary w-50" htmlFor="optionSexM">Hombre</label>
                                    </div>
                                </div>

                                <div className="col-12 col-md-4">
                                    <label className="form-label" htmlFor="gene-birth">Fecha de nacimiento:</label>
                                    <input value={this.state.fec_nacimiento || ''} ref={date => this.birth = date} className="form-control datetimepicker" id="gene-birth" readOnly name="gene-birth" type="text" />
                                </div>

                                <div className="col-12 col-md-4">
                                    <label className="form-label" htmlFor="gene-age">Edad:</label>
                                    <input value={this.state.edad || ''} readOnly className="form-control" id="gene-age" name="gene-age" type="text"></input>
                                </div>
                                
                                <div className="col-12 col-md-4">
                                    <label className='form-label' htmlFor="gene-civil">Estado civil:<span className="text-danger">*</span></label>
                                    <select value={this.state.estado_civil || ''} ref={sel => this.estadoCivil = sel} name="gene-civil" id="gene-civil" className="form-select inputRequired"
                                    onChange={(e) => this.setState({estado_civil: e.target.value})}>
                                        <option value="">Seleccionar</option>
                                        {optionsCivil}
                                    </select>
                                </div>

                                <div className="text-end">
                                    <button onClick={() => this.validateInputTabsIn('residencial-tab')} className="btn succesButton col-12 col-lg-2 col-xxl-1">Continuar</button>
                                </div>
                            </div>
                        </div>


                        <div className="tab-pane fade" id="residencial" role="tabpanel" aria-labelledby="residencial-tab">
                            <div className="row g-3">
                                <div className="col-12 col-md-4">
                                    <label className='form-label' htmlFor="pais">País:<span className="text-danger">*</span></label>
                                    <select value={this.state.pais || ''} ref={inp  => this.pais = inp} name="pais" id="pais" className="form-select inputRequired"
                                    onChange={(e) => {this.loadDeptos(); this.setState({pais: e.target.value})}} >
                                        <option value="">Seleccionar</option>
                                        {optionsPaises}
                                    </select>
                                </div>
                                <div className="col-12 col-md-4">
                                    <label className='form-label' htmlFor="depto">Departamento:<span className="text-danger">*</span></label>
                                    <select value={this.state.depto || ''} ref={inp  => this.depto= inp} name="depto" id="depto" className="form-select inputRequired"
                                    onChange={(e) => {this.loadCiudades(); this.setState({depto: e.target.value})}}>
                                        <option value="">Seleccionar</option>
                                        {optionsDepto}
                                    </select>
                                </div>
                                <div className="col-12 col-md-4">
                                    <label className='form-label' htmlFor="ciudad">Ciudad:<span className="text-danger">*</span></label>
                                    <select value={this.state.ciudad || ''} ref={inp => this.ciudad = inp} name="ciudad" id="ciudad" className="form-select inputRequired"
                                    onChange={(e) => this.setState({ciudad: e.target.value})}>
                                        <option value="">Seleccionar</option>
                                        {optionsCity}
                                    </select>
                                </div>
                                <div className="col-12 col-md-4">
                                    <label className="form-label" htmlFor="barrio">Barrio:<span className="text-danger">*</span></label>
                                    <input value={this.state.barrio || ''} ref={inp => this.barrio = inp} className="form-control inputRequired" id="barrio" name="barrio" type="text"
                                    onChange={(e) => this.setState({barrio: e.target.value})} ></input>
                                </div>

                                <div className='col-12 mt-4'>
                                    <label className='fs-0 text-primary mb-0 fw-bold'>Dirección</label>
                                    <hr className='text-primary my-1 fw-bold'></hr>
                                </div>

                                <label className='col-12 my-0'>
                                    Este es el asistente para escribir correctamente tu dirección según lo exigido por los organismos de control.
                                    <br></br>
                                    <span className='text-danger'>Solo debes llenar los campos necesarios</span> y los demás por favor los dejas en blanco.
                                </label>

                                <div className="col-12 col-md-3">
                                    <select name="dircampo1" id="dircampo1" className="form-select loadDir" ref={input => this.campo1 = input}  onChange={() => this.loadAddress()} defaultValue={""}>
                                        <option value="" disabled>Ej: Calle/Carrera/...</option>
                                        {nomenclaturaStreet}
                                    </select>
                                </div>
                                <div className="col-12 col-md-1 ps-0 pe-0">
                                    <input className="form-control loadDir" type="number" min="1" id="dircampo2" name="dircampo2" ref={input => this.campo2 = input} onChange={() => this.loadAddress()} placeholder='Ej: 4'></input>
                                </div>
                                <div className="col-12 col-md-2 pe-0">
                                    <select name="dircampo3" id="dircampo3" className="form-select loadDir" ref={input => this.campo3 = input} onChange={() => this.loadAddress()} defaultValue={""}>
                                        <option value="" disabled>Ej: A/B/C/...</option>
                                        {alphabet}
                                    </select>
                                </div>
                                <div className="col-12 col-md-2 pe-0">
                                    <select name="dircampo4" id="dircampo4" className="form-select loadDir" ref={input => this.campo4 = input} onChange={() => this.loadAddress()} defaultValue={""}>
                                        <option value="" disabled>Ej: BIS</option>
                                        {nomenclaturaBis}
                                    </select>
                                </div>
                                <div className="col-12 col-md-2 pe-0">
                                    <select name="dircampo5" id="dircampo5" className="form-select loadDir" ref={input => this.campo5 = input} onChange={() => this.loadAddress()} defaultValue={""}>
                                        <option value="" disabled>Ej: A/B/C/...</option>
                                        {alphabet}
                                    </select>
                                </div>
                                <div className="col-12 col-md-2">
                                    <select name="dircampo6" id="dircampo6" className="form-select loadDir" ref={input => this.campo6 = input} onChange={() => this.loadAddress()} defaultValue={""}>
                                        <option value="" disabled>Ej: Norte/Sur/...</option>
                                        {nomenclaturaCardinalidad}
                                    </select>
                                </div>
                                
                                <div className="col-12 col-md-2 pe-0">
                                    <div className='input-group'>
                                        <span className="input-group-text">#</span>
                                        <input type="number" min="1" className="form-control loadDir" id="dircampo7" name="dircampo7" ref={input => this.campo7 = input} onChange={() => this.loadAddress()}></input>
                                    </div>
                                </div>
                                <div className="col-12 col-md-2 pe-0">
                                    <select name="dircampo8" id="dircampo8" className="form-select loadDir" ref={input => this.campo8 = input} onChange={() => this.loadAddress()} defaultValue={""}>
                                        <option value=""></option>
                                        {alphabet}
                                    </select>
                                </div>
                                <div className="col-12 col-md-2 pe-0">
                                    <input type="number" min="1" className="form-control loadDir" id="dircampo9" name="dircampo9" ref={input => this.campo9 = input} onChange={() => this.loadAddress()}></input>
                                </div>

                                <div className="col-12 col-md-2 pe-0">
                                    <select name="dircampo10" id="dircampo10" className="form-select loadDir" ref={input => this.campo10 = input} onChange={() => this.loadAddress()} defaultValue={""}> 
                                        <option value=""></option>
                                        {nomenclaturaCardinalidad}
                                    </select>
                                </div>

                                <label className='col-12 form-label mt-4 mb-0'>Complementos de dirección: (Ej. torre B - apto 303)</label>

                                <div className="col-12 col-md-3">
                                    <select className="form-select loadDir" name="dircampo11"  id="dircampo11" ref={input => this.campo11 = input} onChange={() => this.loadAddress()} defaultValue={""}>
                                        <option value="" disabled>Ej: Manzana/Bloque/...</option>
                                        {nomenclaturaComplemento}
                                    </select>
                                </div>
                                <div className="col-12 col-md-4">
                                    <input className="form-control loadDir" type="text" id="dircampo12" name="dircampo12" ref={input => this.campo12 = input} onChange={() => this.loadAddress()} placeholder='Ej: Casa esquinera blanca'></input>
                                </div>

                                <div className="col-12">
                                    <label>&nbsp;</label>
                                    <label htmlFor="gene-addressFinal" className="form-label">
                                        ¿Su dirección es?:<span className="text-danger">*</span>
                                    </label>
                                    <div className="input-group mb-3">
                                        <input readOnly type="text" defaultValue={this.state.direccion} className="form-control inputRequired" name="gene-addressFinal" id="gene-addressFinal" ref={el => this.direccion = el}></input>&nbsp;
                                        <span className="input-group-text bg-transparent removeBorder colorTrash fw-bold pointer" id="clearAddress" onClick={() => this.clearAddress()} ><small><u>Borrar dirección</u></small></span>
                                    </div>
                                </div>
                            </div>
                            

                            <div className="py-3 d-flex justify-content-between">
                                <button onClick={() => this.vaidateBack('general-tab')} className="btn succesButton col-12 col-lg-2 col-xxl-1">Volver</button>
                                <button onClick={() => this.validateInputTabsIn('corpo-tab')} className="btn succesButton col-12 col-lg-2 col-xxl-1">Continuar</button>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="corpo" role="tabpanel" aria-labelledby="corpo-tab">
                            <div className="row g-3">
                                <div className="col-sm-4 col-md-4">
                                    <label className='form-label' htmlFor="mail_perso">Email personal:<span className="text-danger">*</span></label>
                                    <input value={this.state.email_personal || ''} className="form-control inputRequired" id="mail_perso" name="mail_perso" ref={inp => this.mail_perso = inp} placeholder='Escribe aquí tu respuesta' 
                                    onChange={(e) => this.setState({email_personal: e.target.value})}></input>
                                </div>
                                <div className="col-sm-4 col-md-4">
                                    <label className='form-label' >Email corporativo:</label>
                                    <input value={this.state.email_corporativo || ''} className="form-control" id="mail_corpo" name="mail_corpo" ref={inp => this.mail_corpo = inp} placeholder='Escribe aquí tu respuesta' 
                                    onChange={(e) => this.setState({email_corporativo: e.target.value})}></input>
                                </div>
                                <div className="col-sm-4 col-md-4">
                                    <label className='form-label' htmlFor="num_celular">Número de celular:<span className="text-danger">*</span></label>
                                    <input value={this.state.cel_personal || ''} className="form-control inputRequired" id="num_celular" name="num_celular" ref={input => this.num_celular = input} placeholder='Escribe aquí tu respuesta' type='number' 
                                    onChange={(e) => this.setState({cel_personal: e.target.value})}></input>
                                </div>
                                <div className="col-sm-4 col-md-4">
                                    <label className='form-label' htmlFor="num_celcorp">Número de celular corporativo:</label>
                                    <input value={this.state.cel_trabajo || ''} className="form-control" id="num_celcorp" name="num_celcorp" ref={input => this.num_celcorp = input} placeholder='Escribe aquí tu respuesta' type='number' 
                                    onChange={(e) => this.setState({cel_trabajo: e.target.value})}></input>
                                </div>
                                <div className="col-sm-4 col-md-4">
                                    <label className='form-label'>{this.state.nivel2bd}:</label>
                                    <input value={this.state.nomNiv2 || ''} readOnly className="form-control" id="nivel2" name="nivel2" ref={input => this.nivel2 = input} placeholder='Escribe aquí tu respuesta'></input>
                                </div>
                                <div className="col-sm-4 col-md-4">
                                    <label className='form-label'>{this.state.nivel4bd}:</label>
                                    <input value={this.state.nomNiv4 || ''} readOnly className="form-control" id="nivel4" name="nivel4" ref={input => this.nivel4 = input} placeholder='Escribe aquí tu respuesta'></input>
                                </div>
                                <div className="col-sm-4 col-md-4">
                                    <label className='form-label'>{this.state.nivel5bd}:</label>
                                    <input value={this.state.nomNiv5 || ''} readOnly className="form-control"  id="nivel5" name="nivel5" ref={input => this.nivel5 = input} placeholder='Escribe aquí tu respuesta'></input>
                                </div>
                                <div className="col-sm-4 col-md-4">
                                    <label className='form-label'>Cargo actual:</label>
                                    <input value={this.state.nom_cargo || ''} className="form-control" readOnly id="cargo" name="cargo" ref={input => this.cargo = input} placeholder='Escribe aquí tu respuesta'></input>
                                </div>
                                <div className="col-sm-4 col-md-4">
                                    <label className='form-label' htmlFor="antiguedad">Antiguedad en la empresa:</label>
                                    <input value={antiguedad || ''} className="form-control" id="antiguedad" name="antiguedad" ref={input => this.antiguedad = input} disabled></input>
                                </div>
                                <div className="col-sm-12 col-md-4">
                                    <label className='form-label' htmlFor="plancarrera">¿Usted ha sido Plan Carrera?:<span className="text-danger">*</span></label>
                                    <div className=" d-flex justify-content-around">
                                        <input checked={this.state.plan_carrera==="1"?true:false} value="1" type="radio" name="plancarrera" className="btn-check inputRequired" id="btn-check-outlined" autoComplete="off"
                                        onChange={e => {
                                            putInputRequerid('#corp-12','','add','corp-12')
                                            this.setState({plan_carrera: e.target.value})
                                        }}></input>
                                        <label className="btn btn-outline-primary w-50" htmlFor="btn-check-outlined">SI</label>&nbsp;
                                        <input checked={this.state.plan_carrera==="0"?true:false} value="0"  type="radio" name="plancarrera" className="btn-check" id="btn-check-outlined2" autoComplete="off"
                                        onChange={e =>{
                                            putInputRequerid('#corp-12','','remove','corp-12')
                                            this.setState({plan_carrera: e.target.value})
                                            document.querySelectorAll('.cargosLi button ').forEach(el =>  el.remove())
                                            document.querySelectorAll('.smalldel ').forEach(el =>  el.setAttribute('hidden','hidden'))
                                        }}></input>
                                        <label className="btn btn-outline-primary w-50" htmlFor="btn-check-outlined2">NO</label>
                                    </div>
                                    <button className='btn btn-link btn-sm ps-0' type='button' onClick={() => this.infoPlanCarrera()}>
                                        <i className="fas fa-info-circle text-primary"></i> ¿Qué es plan carrera?
                                    </button>
                                </div>
                                <div className="col-sm-4 col-md-4">
                                    <label className='form-label' htmlFor="corp-12">Mencione los cargos que ha ocupado:</label>
                                    <input  className="form-control" id="corp-12" name="corp-12" ref={input => this.corp12 = input} placeholder='Escribe aquí tu respuesta' readOnly></input>
                                    <small id="emailHelp" className="form-text text-muted">Ingrese cargo y presione enter para insertar</small>
                                </div>

                                <div className="col-sm-4 col-md-4">
                                    <label className='form-label smalldel' hidden htmlFor="corp-cargosingresados">Estos son los cargos ingresados:</label>
                                    <div className="cargosLi"></div>
                                    <small hidden  className="form-text text-muted smalldel">Para eliminar da click encima del cargo ingresado</small>
                                </div>

                                <div className='col-12'>
                                    <label className='fs-0 text-primary mb-0 fw-bold' htmlFor="labelDir-Indumentaria">Indumentaria</label>
                                    <hr className='text-primary my-1 fw-bold'></hr>
                                </div>

                                <div className="col-12 col-md-3">
                                    <label htmlFor="uniform">¿Usas uniforme?:<span className="text-danger">*</span></label>
                                    <div className=" d-flex justify-content-around">
                                        <input checked={this.state.uniforme==="1"?true:false} value="1" type="radio" name="uniform" className="btn-check inputRequired" id="checkUsesUniform" autoComplete="off"
                                        onChange={(e) => this.setState({uniforme: e.target.value})}></input>
                                        <label className="btn btn-outline-primary w-50" htmlFor="checkUsesUniform">SI</label> &nbsp;
                                        <input checked={this.state.uniforme==="0"?true:false} value="0" type="radio" name="uniform" className="btn-check" id="checkUsesUniform2" autoComplete="off"
                                        onChange={(e) => this.setState({uniforme: e.target.value})}></input>
                                        <label className="btn btn-outline-primary w-50" htmlFor="checkUsesUniform2">NO</label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-3">
                                    <label htmlFor="indu-camisa">Talla camisa:<span className="text-danger">*</span></label>
                                    <select ref={input => this.camisa = input} value={this.state.camisa || ''} name="indu-camisa" id="indu-camisa" className="form-select inputRequired" required 
                                    onChange={(e) => this.setState({camisa: e.target.value})}>
                                        <option value="">Seleccionar</option>
                                        {optionsCamisa}
                                    </select>
                                </div>
                                <div className="col-12 col-md-3">
                                    <label htmlFor="indu-pantalon">Talla pantalón:<span className="text-danger">*</span></label>
                                    <select  ref={input => this.pantalon = input} value={this.state.pantalon || ''} name="indu-pantalon" id="indu-pantalon" className="form-select inputRequired" required 
                                    onChange={(e) => this.setState({pantalon: e.target.value})}>
                                        <option value="">Seleccionar</option>
                                        {optionsPantalon}
                                    </select>
                                </div>
                                <div className="col-12 col-md-3">
                                    <label htmlFor="indu-calzado">Talla calzado:<span className="text-danger">*</span></label>
                                    <select  ref={input => this.calzado = input} value={this.state.calzado || ''} name="indu-calzado" id="indu-calzado" className="form-select inputRequired" required 
                                    onChange={(e) => this.setState({calzado: e.target.value})}>
                                        <option value="">Seleccionar</option>
                                        {optionsCalzado}
                                    </select>
                                </div>
                            </div>


                            <div className="py-3 d-flex justify-content-between">
                                <button onClick={() => this.vaidateBack('residencial-tab')} className="btn succesButton col-12 col-lg-2 col-xxl-1">Volver</button>
                                <button onClick={() => this.guardarDatosBasicos('corpo-tab')} className="btn succesButton col-12 col-lg-2">Guardar y continuar</button>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>        
        </>
      );
    }
  }

export default CV;
