import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { files } from '../../environments/environments';
import './bienestar.css';
import { registrarAccion } from "../../../src/generalHelpers";

export const Bienestar = () => {

    useEffect(() => {
        document.getElementById('root').className = 'bienestar';
    }, []);


    return (
        <>

            {/* <div className="col-md-12 cabeceraRRhh paddingRRHH">
                <h3 className="tituloBienvenidoRrhh">
                    Bienvenido a 
                    <img className="logoVumBienvenido" src="/assets/img/icono-vum-office-home.png" alt="icono-vum-office-home" />
                </h3>
            </div> */}
            <div className="col-md-12 ps-md-5 pt-md-3 pe-md-6  mt-4 mt-md-0">

            <div className="col-md-12 ms-md-2 mb-4 d-none d-md-block">
                        <img
                            style={{width:"150px", height:"80"}}
                            src="https://controlf.blob.core.windows.net/public/vumoffice/assets/
logos/logo_office_blanco.png"
                            alt="icono-vum-office-home"
                        />
            </div>      

            
            <div className="col-md-12 divTitulosRRHH">
                <h3 className="tituloModulo mt-6 mt-md-0">Oficina de Calidad de vida</h3>
                <p className="copyPage mt-2">
                    Como nuestro trabajador entendemos que tienes objetivos que alcanzar,<br/>
                    aquí te damos las herramientas para que los consigas.
                </p>
            </div>

            <div className="col-md-6 text-left mb-3 mt-md-4">
                <Link to={{ pathname: files.novedadesEmpresariales.url }} target="_blank" >
                    <button className=" btns-rrhh zoomBtn mb-3" style={{fontWeight:'bold'}} type="button">
                        Book corporativo {/*novedades empresariales*/}
                    </button>
                </Link>
               
                <Link to={{ pathname: files.aliados.url }} target="_blank" >
                    <button className=" btns-rrhh zoomBtn mb-3" style={{fontWeight:'bold'}} type="button">
                        Nuestras Alianzas {/*aliados*/}
                    </button> <br />
                </Link>
                <Link onClick={()=>{registrarAccion({codigo:"39"})}} to={{ pathname: 'mi_billetera' }}>
                    <button className=" btns-rrhh zoomBtn mb-3" style={{fontWeight:'bold'}} type="button">
                        Mi billetera {/*mi billetera*/}
                    </button>
                </Link>
               {/* <Link to={{ pathname: 'https://view.genial.ly/5f47cdece3669e23d5b676a8/learning-experience-challenges-onbording-final' }} target="_blank">
                    <button className=" btns-rrhh zoomBtn mb-3" type="button">
                        <i className="fas fa-comments imgbtnRrhh" alt="icono-onboarding" />
                        Onboarding
                    </button>
    </Link>*/}
            </div>
            </div>
        </>

    )
}
