import React from 'react';
import PropTypes from 'prop-types';

export const CartaPuntoVenta = ({form, setForm}) => {

    return (
        <></>
    )
}

CartaPuntoVenta.propTypes = {
    form: PropTypes.object.isRequired,
    setForm: PropTypes.func.isRequired,
}