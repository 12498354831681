import React from 'react';
import { DataTabla } from '../../../components/DataTable/DataTabla';
import { useIncapacidadConsultar } from './hooks/useIncapacidadConsultar';
import './incapacidadConsultar.css';

export const IncapacidadConsultar = () => {

    const dataUser = JSON.parse(localStorage.getItem('d_u'));
    const headerStyles = { backgroundColor: '#EDF2F9', color: '#344050', zIndex: 0, };

    const {
        formValue,
        rowsTable,
    } = useIncapacidadConsultar(
        {
            columnsDataTable: [
                { title: 'Consecutivo', field: 'consecutivo', headerStyle: headerStyles },
                { title: 'Tipo incapacidad', field: 'tipo_incapacidad', headerStyle: headerStyles },
                { title: 'Documentos', field: 'documentos', headerStyle: headerStyles },
                { title: 'Estado de la incapacidad', field: 'estado', headerStyle: headerStyles },
                { title: 'Actualizar y consultar estado de documentos', field: 'actualizar', headerStyle: headerStyles },
            ],
            rowsDataTable: [],
            files: [],
            loadingPage: true,
            loadingTable: false,
        },
        dataUser
    );

    const { columnsDataTable } = formValue;

    return (
        <>
            <div className="card mb-3">
                <div className="card-body position-relative textoMigaDePan">
                    <div className="row">
                        <div className="col-lg-8">
                            <h3>Mis incapacidades/Consultar</h3>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card mb-3">

                <div className="card-body bg-light">
                    <h4>Tabla de incapacidades radicadas</h4>
                    <div className='my-4'>
                        <h6 className='text-primary'><b>Significado de los estados de incapacidad:</b></h6>
                        <h6><b>RADICADO POR EMPRESA:</b> La incapacidad se encuentra subida o fue corregida después de estar rechazada.</h6>
                        <h6><b>APROBADO:</b> La incapacidad y todos los documentos subidos fueron aprobados por el área de prestaciones económicas.</h6>
                        <h6><b>RECHAZADO:</b> La incapacidad fue rechazada porque alguno de los documentos no pasó. Puedes corregir los documentos y volverlos a subir.</h6>
                        <h6><b>EN VALIDACIÓN CON EPS:</b> La incapacidad se envió a la EPS para que esta sea revisada. Si tiene documentos rechazados, debe volver a subirlos corregidos.</h6>
                        <h6><b>ANULADO:</b> La incapacidad fue invalidada por completo. No puedes subir más documentos ni pedir incapacidades en las fechas de radicación de la misma.</h6>
                    </div>
                    <DataTabla title={""} columns={columnsDataTable} data={rowsTable} />
                </div>
            </div>

        </>
    )
}
