import "flatpickr/dist/flatpickr.css";
import { Spanish } from "flatpickr/dist/l10n/es.js"
import flatpickr from "flatpickr";
import React, { useEffect, useState } from 'react';
import { baseUrl } from '../../config/config';
import { overlay, postFetch } from '../../generalHelpers';
import Select, { createFilter } from 'react-select';
import { Simple } from '../../components/Inputs/Simple/Simple';
import Swal from 'sweetalert2';
import { getAllContracts } from '../../repositories/generalInfo';
import './ComprobanteNomina.css';
import { AsteriskRequired } from "../../components/AsteriskRequired/AsteriskRequired";

export const ComprobanteNomina = () => {

    const [dataUser] = useState(JSON.parse(localStorage.getItem("d_u")));
    const [contratos, setContratos] = useState([]);
    const [contrato, setContrato] = useState(null);
    const [statefecha, setStatefecha] = useState(null);

    useEffect(() => {
        window.scroll(0, 0);
        findContracts(
            Number.parseInt(dataUser.cedula)
        );
    }, [dataUser]);

    const handleFechaUpdate = (selectedDates, dateStr) => {
        if(statefecha !== dateStr){
            setStatefecha(dateStr);
        }
    }

    const handleContratoUpdate = (e) => {
        overlay(true);
        setContrato(e);
        let fechaRango = (e.label.split(": "))[1];
        fechaRango = (fechaRango.split(" ("))[0];
        fechaRango = fechaRango.split(" - ");

        (document.querySelector("#fecha")._flatpickr).destroy();

        document.getElementById("fecha").value = "";

        flatpickr("#fecha", {
            locale: Spanish, 
            mode: "range", 
            dateFormat: "Y-m-d", 
            disableMobile: "true",
            onClose: handleFechaUpdate,
            minDate: fechaRango[0],
            maxDate: (fechaRango[1]?fechaRango[1]:null)
        });
        overlay(false);
    };

    const findContracts = (cedula) => {
        overlay(true)
        getAllContracts(cedula).then(contracts => {
            setContratos(contracts);
            const contract = contracts.filter(currentValue => (currentValue.value === contracts[0].value)) || '';
            setContrato(contract[0]);

            let fechaRango = (contracts[0].label.split(": "))[1];
            fechaRango = fechaRango.split(" - ");
            
            flatpickr("#fecha", {
                locale: Spanish, 
                mode: "range", 
                dateFormat: "Y-m-d", 
                disableMobile: "true",
                onClose: handleFechaUpdate,
                minDate: fechaRango[0],
                maxDate: (fechaRango[1]?fechaRango[1]:null)
            });
            overlay(false)
        }).catch(
            overlay(false)
        );
    };

    const generarComprobante = () => {
        overlay(true);
        let datosContrato = contrato?.value.split("-") || '';
        const params = {
            cedula: Number.parseInt(dataUser.cedula),
            nroContrato: Number.parseInt(datosContrato[0]),
            empresa: Number.parseInt(datosContrato[1]),
            rangoFechas: statefecha
        };
        const url = `${baseUrl}/v1/ComprobanteNomina/generarComprobante`;
        postFetch({ url: url, params: params })
        .then((data) => {
            overlay(false);
            if(data.error){
                Swal.fire({
                    title: '',
                    text: data.error,
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonColor: '#2c7be5',
                    confirmButtonText: 'Cerrar'
                });
            }else{
                for(const arch of data) {
                    window.open(arch);
                }
                
            }
        }).catch((error) => {
            Swal.fire({
                title: '',
                text: "Error al enviar la petición",
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#2c7be5',
                confirmButtonText: 'Cerrar'
            });
            overlay(false);
        });
    };

    return (
        <>
            <div className="card mb-3">
                <div className="card-body position-relative">
                    <div className="row">
                        <div className="col-lg-12 d-flex justify-content-center justify-content-lg-start align-items-center">
                            <h3>Mis certificados/Desprendible de nómina</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card mb-3">
                <div className='row p-3'>
                    <strong className='text-danger fs--1'>Todos los campos marcados con asteriscos son obligatorios.</strong>
                    <div className="col-12 col-md-6">
                    <label className="col-form-label mt-3" htmlFor="rangoFechas"><AsteriskRequired/>Contratos:</label>
                        <Select
                            captureMenuScroll={false}
                            filterOption={createFilter({ ignoreAccents: false })}
                            isMulti={false}
                            closeMenuOnSelect={true}
                            isDisabled={false}
                            styles={Simple}
                            onChange={handleContratoUpdate}
                            value={contrato}
                            options={contratos}
                            placeholder={""}
                        />
                    </div>
                    <div className="col-12 col-md-6">
                    <label className="col-form-label mt-3" htmlFor="rangoFechas"><AsteriskRequired/>Rango de fechas:</label>
                        <input id="fecha" className='form-control' readOnly defaultValue={()=>{}}/>
                    </div>
                    <div className='d-flex justify-content-end'>
                        <div style={{ textAlign: 'right' }} className="col-12 col-lg-2 mt-4">
                            <button className='btn btn-primary paddingBtn w-100' onClick={() => generarComprobante()}>Descargar</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};