import React from 'react'
import './footer.css'
import logoBlanco from '../../../../src/assets/img/logosBlancos/logos-blancos.png'
import { useLogin } from '../../../pages/Login/hooks/useLogin';

const Footer = () => {
    const {terminosCondiciones } = useLogin({
        identification: '',
        pass: ''
    });
    
    const fechah = new Date();
    return (
        <footer className="footer d-md-flex row ms-0 ms-md-0 justify-content-md-center items-content-md-center">
                <div className="col-12 col-md-12 col-sm-auto text-center">
                    {/* imagen */}
                    <img className="me-2 anchoImagen2" src={logoBlanco} alt="logo-vum-login"  />
                </div>
            {/* <div className="g-0 justify-content-between fs--1">
                <div className="col-12 col-md-12 col-sm-auto text-center">
                    <p className="copyFooter">&copy; Derechos reservados VUM | {fechah.getFullYear()} </p>
                </div>
            </div> */}
            <div className="row ms-1 ms-md-0 me-0 me-md-3 mb-3 text-center">
                                        <div className="col-12 col-md-12 col-sm-auto text-center">
                                            <p className="copyFooter" > Copyright © {fechah.getFullYear()} todos los derechos reservados | <button onClick={terminosCondiciones} className="p-0 fontFooter copyFooter btn btn-link" id="parrafo">Tratamiento de datos personales</button></p>
                                        </div>
                                    </div>
        </footer>
    )
}


export default Footer;