import React, { Component } from "react";
import { baseUrl } from "../../config/config";
import { postData } from "../../components/general/General";
import { DataTabla } from "../../components/DataTable/DataTabla";
import Swal from "sweetalert2";
import "./consultarHorasExtras.css";
import foto from "../../assets/img/vumOfficeImgs/horas-extra.jpg";
class ConsultarHorasExtras extends Component {
  constructor() {
    super();
    this.state = {
      dataHeaderExtras: [],
      dataExtras: [],
    };
  }

  componentDidMount() {
    const url = `${baseUrl}/v1/HorasExtras/consultarHorasExtras`;
    const userData = JSON.parse(localStorage.getItem("d_u"));

    // consultar horas extras
    postData(url, { NRO_DOCUMENTO: userData.cedula }).then((result) => {
      if (!result.error && !result.message) {
        const headerStyles = {
          backgroundColor: "#EDF2F9",
          color: "#344050",
          zIndex: 0,
        };
        this.setState({
          dataHeaderExtras: [
            {
              title: "Hora de inicio",
              field: "hora_de_inicio",
              headerStyle: headerStyles,
            },
            {
              title: "Hora de finalización",
              field: "hora_de_finalizacion",
              headerStyle: headerStyles,
            },
            { title: "Link", field: "link", headerStyle: headerStyles },
          ],
        });
        let rowsDTable = [];
        result.map((value, x) => {
          let url = value["CIN_FECHA_SALIDA"] ? (
            value["CIN_FECHA_SALIDA"]
          ) : (
            <a href={value["HOEX_LINK_INGRESO"]}>Ingrese aquí</a>
          );
          rowsDTable.push({
            hora_de_inicio: value["HOEX_FECHA_INICIO"],
            hora_de_finalizacion: value["HOEX_FECHA_FIN"],
            link: url,
          });
          return null;
        });
        this.setState({ dataExtras: rowsDTable });
      } else if (result.message) {
        Swal.fire({
          title: "<h5 class='text-primary'>Alerta</h5>",
          html: result.message,
          showCancelButton: false,
          showConfirmButton: true,
          confirmButtonColor: "#2c7be5",
          confirmButtonText: "Cerrar",
        });
      } else {
        document.getElementById("titulo").classList.add("textCenter");
        document.getElementById("titulo").innerHTML =
          "Aún no tienes horas registradas";
      }
    });
  }

  render() {
    const { dataHeaderExtras, dataExtras } = this.state;

    return (
      <>
        <div className="card">
          <div className="card-header">
            <h2 className="mb-0 mr-1 pl">Registro de horas extra</h2>
          </div>
        </div>
        &nbsp;
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-sm-12">
                <img
                  id="image"
                  className="width100"
                  src={foto}
                  alt=""
                  data-dz-thumbnail
                />
              </div>
              <hr className="my-12" />
              <div className="col-12 mt30px">
                <h4 className="blueColor" id="titulo">
                  Tabla de registro:
                </h4>
                <div className="table-responsive">
                  <DataTabla
                    title={""}
                    columns={dataHeaderExtras}
                    data={dataExtras}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ConsultarHorasExtras;
