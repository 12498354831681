import React from "react";
import { routes } from "../../environments/environments.ts";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import total from "../../assets/img/calificanos/respuesta-positiva.png";
import posi from "../../assets/img/calificanos/positiva.png";
import imparcial from "../../assets/img/calificanos/imparcial.png";
import negativa from "../../assets/img/calificanos/negativa.png";
import totalNega from "../../assets/img/calificanos/totalmente-negativa.png";
import correo from "../../assets/img/calificanos/correo.png";
import whatsapp from "../../assets/img/calificanos/whatsapp.png";
import { postData } from "../general/General";
import { baseUrl } from "../../config/config";

import fotoGracias from "../../assets/img/calificanos/gracias.png";
import fotoNo from "../../assets/img/calificanos/respuesta-negativa.png";
import { registrarAccion } from "../../../src/generalHelpers";

export const ImportantBottons = () => {
  const modalCalificanos = () => {
    registrarAccion({codigo:"64"})
    const data = `<div class="seeDivComunicate" hidden="hidden">
                <hr  class="line-calificanos" >
                <div class="container text-white mb-4">¿Cómo quieres que nos comuniquemos contigo?</div>
                <div class="d-flex  text-white justify-content-around ">
                    <div class="col"><div class="p-1 ">
                        <input type="radio" name="checkComunication" data-campobd="377" id="checkData1" class="buttonsAnswers btn-check me-1"><label for="checkData1"><img class="imgAnswers" src=${correo} /><br><p></p>Correo</label></input>
                    </div></div>
                    <div class="col"><div class="p-1 ">
                        <input type="radio" name="checkComunication" data-campobd="W" id="checkData2" class="buttonsAnswers btn-check me-1"><label for="checkData2"><img class="imgAnswers" src=${whatsapp} /><br><p></p>Whatsapp</label></input>
                    </div></div>

                </div>

                <div class="mb-2" id="divaddDataComunicate" hidden><input class="form-control " type="text" id="addDataComunicate" name="addDataComunicate"/></div>

            </div>
            <div class="d-flex  text-white justify-content-center mb-2">
                <button class="btn btn-calificanos"  id="btncalifica">Enviar respuestas</button>

            </div>
            <div class="alert " id="alertaDatosmodal" role="alert"></div>
            `;
    Swal.fire({
      title:
        '<small class="text-white text-sm">¿Cómo calificarías tu experiencia trabajando con nuestra empresa?</small>',
      html: `<div class="container text-white ">
      <div class="row justify-content-center align-items-center d-flex align-items-stretch">
        <div class="col-6 col-md-2 mb-2">
        <input type="radio" data-validateemotion="1" data-campobd="1314" name="radioCalificanos" id="radioData1" class="buttonsAnswers btn-check me-1"><label for="radioData1" class="labelTextAnswers"><img class="imgAnswers" src=${total} /><p></p>Totalmente positiva</label></input>
        </div>
        <div class="col-6 col-md-2 mb-2">
        <input type="radio" data-validateemotion="1" data-campobd="1315" name="radioCalificanos" id="radioData2" class="buttonsAnswers btn-check me-1"><label for="radioData2" class="labelTextAnswers"><img class="imgAnswers" src=${posi}  /><p></p>Positiva</label></input>
        </div>

        <div class="col-4 col-md-2 mb-2">
        <input type="radio" data-validateemotion="0" data-campobd="1316" name="radioCalificanos" id="radioData3" class="buttonsAnswers btn-check me-1"><label for="radioData3" class="labelTextAnswers"><img class="imgAnswers" src=${imparcial} /><p></p>Imparcial</label></input>
        </div>
        <div class="col-4 col-md-2 mb-2">
        <input type="radio" data-validateemotion="0" data-campobd="1317" name="radioCalificanos" id="radioData4" class="buttonsAnswers btn-check me-1"><label for="radioData4" class="labelTextAnswers"><img class="imgAnswers" src=${negativa} /><p></p>Negativa</label></input>
        </div>
        <div class="col-4 col-md-2 mb-2">
        <input type="radio" data-validateemotion="0" data-campobd="1318" name="radioCalificanos" id="radioData5" class="buttonsAnswers btn-check me-1"><label for="radioData5" class="labelTextAnswers"><img class="imgAnswers" src=${totalNega} /><p></p>Totalmente negativa</label></input>
        </div>
      </div>
    </div><div class="container">${data}</div>`,
      background: "#0F4F80",
      showCloseButton: true,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      focusConfirm: false,
    });

    const but = document.getElementById("btncalifica");
    but.addEventListener(
      "click",
      function () {
        const contaAnsw = document.querySelectorAll(
          "input[name=radioCalificanos]:checked"
        ).length;
        const contaComun = document.querySelectorAll(
          "input[name=checkComunication]:checked"
        ).length;
        const divAlert = document.getElementById("alertaDatosmodal");

        if (contaAnsw === 0) {
          divAlert.classList.add("alert-info");
          divAlert.innerHTML = "Debes seleccionar una respuesta";
          setTimeout(() => {
            divAlert.classList.remove("alert-info");
            divAlert.innerHTML = "";
          }, 2000);
          return false;
        } else {
          const radioCheckedAnswers = document.querySelector(
            "input[name=radioCalificanos]:checked"
          );
          if (radioCheckedAnswers.dataset.validateemotion === "0") {
            if (contaComun === 0) {
              divAlert.classList.add("alert-info");
              divAlert.innerHTML = "Debes seleccionar un medio de comunicación";
              setTimeout(() => {
                divAlert.classList.remove("alert-info");
                divAlert.innerHTML = "";
              }, 2000);
              return false;
            } else {
              sendQualification();
            }
          } else {
            sendQualification();
          }
        }
      },
      false
    );

    const radios = document.querySelectorAll("input[name=radioCalificanos]");
    for (let radio of radios) {
      radio.addEventListener("change", function () {
        const divHidden = document.getElementsByClassName("seeDivComunicate");
        if (this.dataset.validateemotion === "1") {
          divHidden[0].setAttribute("hidden", "hidden");
          document.getElementById("addDataComunicate").value = "";
          document
            .getElementById("divaddDataComunicate")
            .setAttribute("hidden", "hidden");

          if (
            document.querySelectorAll("input[name=checkComunication]:checked")
              .length
          ) {
            document.querySelectorAll(
              "input[name=checkComunication]:checked"
            )[0].checked = false;
          }
        } else {
          divHidden[0].removeAttribute("hidden");
        }
      });
    }

    const radComunicate = document.querySelectorAll(
      "input[name=checkComunication]"
    );
    for (let radio of radComunicate) {
      radio.addEventListener("click", function () {
        const radio = document.querySelectorAll(
          "input[name=checkComunication]:checked"
        )[0].dataset.campobd;
        const data = JSON.parse(localStorage.getItem("d_u"));
        const mail = data.mail;
        const phone = data.numeroCelular;
        const input = document.getElementById("addDataComunicate");
        document
          .getElementById("divaddDataComunicate")
          .removeAttribute("hidden");
        if (radio === "W") {
          input.value = phone;
        } else if (radio === "377") {
          input.value = mail;
        }
      });
    }
  };

  const sendQualification = () => {
    const data = JSON.parse(localStorage.getItem("d_u"));
    const input = document.getElementById("addDataComunicate");
    let noti =
      document.querySelectorAll("input[name=checkComunication]:checked")
        .length === 0
        ? ""
        : document.querySelectorAll("input[name=checkComunication]:checked")[0]
          .dataset.campobd;
    const datos = {
      OVT_CEDULA: parseInt(data.cedula),
      input_add: input.value ? input.value : "",
      sendNotification: noti,
      CALIFICACION: parseInt(
        document.querySelectorAll("input[name=radioCalificanos]:checked")[0]
          .dataset.campobd
      ),
    };
    postData(
      `${baseUrl}/v1/qualification/crearRegistroQualification`,
      datos
    ).then((ele) => {
      if (ele.ok) {
        // fotoGracias
        // fotoNo

        if (
          parseInt(
            document.querySelectorAll("input[name=radioCalificanos]:checked")[0]
              .dataset.validateemotion
          ) === 0
        ) {
          Swal.fire({
            title: "",
            html: `<img class="mb-2" src=${fotoNo} /><div class="text-white">Gracias por tu respuesta, nos comunicaremos contigo para saber cómo podemos mejorar.</div>`,
            showCancelButton: false,
            confirmButtonColor: "#74E36B",
            confirmButtonText: "Cerrar",
            background: "#0F4F80",
            allowOutsideClick: false,
            allowEscapeKey: false,
            focusConfirm: false,
          });
        } else {
          Swal.fire({
            title: "",
            html: `<img class="mb-2" src=${fotoGracias} /><div class="text-white">Tu respuesta nos alienta a esforzarnos cada día más.</div>`,
            showCancelButton: false,
            confirmButtonColor: "#74E36B",
            confirmButtonText: "Cerrar",
            background: "#0F4F80",
            allowOutsideClick: false,
            allowEscapeKey: false,
            focusConfirm: false,
          });
        }
      }
    });
  };

  return (
    <div
      className="col-md-12 text-center px-0"
      style={{
        // display: "grid",
        gap: "2rem",
        gridTemplateColumns: "repeat(auto-fill, minmax(min(100%, 47%), 1fr))",
        paddingLeft: "4",
        marginTop: "1rem",
      }}
    >

      <div className="row">
        <Link to={{ pathname: routes.ingreso.url }} onClick={()=>{registrarAccion({codigo:"53"})}} className="col-md-12 col-lg-6 col-xxl-3">
          <button
            className="btn btnVumOffice d-block w-100 mt-3 fontBtnVumOffice zoom"
            type="submit"
            name="submit"
          >
            <span
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* <span className="d-sm-none d-lg-inline">
                <i
                  className="bi bi-door-open-fill"
                  style={{ fontSize: "1.7rem" }}
                ></i>
              </span> */}
              <span style={{ marginLeft: "0.3rem" }}>
                Control de ingreso y salida
              </span>
            </span>
          </button>
        </Link>
        <div className="col-md-12 col-lg-6 col-xxl-3">
          <button
            className="btn btnVumOffice d-block w-100 mt-3 fontBtnVumOffice zoom"
            onClick={() => modalCalificanos()}
          >
            <span
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* <span className="d-sm-none d-lg-inline">
                <i
                  className="bi bi-bookmark-star-fill"
                  style={{ fontSize: "1.7rem" }}
                ></i>
              </span> */}
              <span style={{ marginLeft: "0.3rem" }}>
                Calificanos
              </span>
            </span>
          </button>
        </div>
        <Link to={{ pathname: routes.desconexionLaboral.url }} onClick={()=>{registrarAccion({codigo:"54"})}} className="col-md-12 col-lg-6 col-xxl-3">
          <button
            className="btn btnVumOffice d-block w-100 mt-3 fontBtnVumOffice zoom"
            type="submit"
            name="submit"
          >
            <span
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* <span className="d-sm-none d-lg-inline">
                <i
                  className="fas fa-plug"
                  style={{ fontSize: "1.7rem" }}
                ></i>
              </span> */}
              <span style={{ marginLeft: "0.3rem" }}>
              Desconexión laboral
              </span>
            </span>
          </button>
        </Link>
        <Link to={{ pathname: routes.ayuda.url }} onClick={()=>{registrarAccion({codigo:"42"})}} className="col-md-12 col-lg-6 col-xxl-3">
          <button
            className="btn btnVumOffice d-block w-100 mt-3 fontBtnVumOffice zoom"
            type="submit"
            name="submit"
          >
            <span
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* <span className="d-sm-none d-lg-inline">
                <i
                  className="fas fa-hands-helping"
                  style={{ fontSize: "1.7rem" }}
                ></i>
              </span> */}
              <span style={{ marginLeft: "0.3rem" }}>
                Mesa de ayuda
              </span>
            </span>
          </button>
        </Link>
      </div>
      {/* <div className="text-end" style={{marginRight: "232px"}}>
        <Link to={{ pathname: routes.ayuda.url }}>
          <button
            className="btn btnVumOffice d-block w-100 mt-3 fontBtnVumOffice zoom"
            type="submit"
            name="submit"
          >
            <span
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span>
                <i
                  className="bi bi-file-earmark-pdf"
                  style={{ fontSize: "1.7rem" }}
                ></i>
              </span>
              <span style={{ marginLeft: "0.3rem" }}>
                Reglamente interno
              </span>
            </span>
          </button>
        </Link>
      </div> */}
    </div>
  );
};
