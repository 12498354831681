import { useState, useEffect } from "react";
import { api, general, routes } from "../../../environments/environments";
import {
  getFetchWithHeader,
  overlay,
  postFetch,
} from "../../../generalHelpers";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/dist/sweetalert2.css";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import {
  generateToken,
  getUserInfoResponse,
} from "../../../repositories/Login/login";
import { getIp, obtenerInformacionDeNavegacion } from "../../../components/general/General";
import { baseUrl } from "../../../config/config";
import axios from "axios";

export const useLogin = (formInitialState = {}) => {
  const exprRegNumeros = /^[0-9+]+$/; //Expresion regular para validar el formato de solo numeros
  const exprRegTelefono = /^[0-9+() -?]+$/; //Expresion regular para validar el formato de un teléfono
  const exprRegEmail = /^([a-zA-Z0-9_.-]+)@([\da-zA-Z0-9.-]+)\.([a-z.]{2,6})$/; //Expresion regular para validar los correos electronicos
  const exprRegSoloLetras = /^[a-zA-ZÑñáéíóúÁÉÍÓÚÄËÏÖÜäëïöü\s+]+$/; //Expresion regular para validar solo letras
  const [formValue, setFormValue] = useState(formInitialState);
  const [modalInformation, setModalInformation] = useState({
    eps: null,
    documentTypes: null,
  });

  const msgLoginAbeas =
    "Para ingresar a VumOffice, debes aceptar nuestra política de tratamiento de datos personales";
  const msgTitle = "¿Deseas leer el documento?";

  const terminosCondiciones = () => {

    let webAndroid=general.webMovil;

   if(webAndroid){

    
    window.close();

    window.open('/terminos-condiciones');

   }else{
     
    window.open('/terminos-condiciones',"Términos y condiciones","width=1000,height=1000");
   }

  }

  const onKeyUpInputHandle = ({ target }) => {
    // if (!parseFloat(target.value)) return;

    setFormValue({
      ...formValue,
      [target.name]: target.value,
    });
  };

  const logOut = () => {
    overlay(true);
    localStorage.clear();
    window.location.href = routes.login.url;
  };

  const formatUserInfo = (userInfo) => {
    return JSON.stringify({
      nombres: userInfo.Nombres,
      apellidos: userInfo.Apellidos,
      cedula: userInfo.Cedula,
      genero: userInfo.Genero,
      mail: userInfo.Mail,
      numContrato: userInfo.NUMERO_CONTRATO,
      cargo: userInfo.Cargo,
      area: userInfo.Area,
      fechaIngreso: userInfo.FECHA_INGRESO,
      fechaVencimiento: userInfo.FECHA_VENCIMIENTO,
      ccostos: userInfo.C_COSTOS,
      estado: userInfo.ESTADO,
      empresa: userInfo.Empresa,
      codigo_entidad: userInfo.Entidad_cod_enti,
      entidad: userInfo.Entidad,
      jefe: userInfo.Jefe,
      numeroCelular: userInfo.Numero,
      USU_CODIGO: userInfo.USU_CODIGO,
      USU_LOGIN: userInfo.USU_LOGIN
    });
  };

  const setToken = (res) => {
    localStorage.setItem("a_t", res.access_token); // access_token
    localStorage.setItem("r_t", res.refresh_token); // refresh_token
    localStorage.setItem("tokensBlob", res.tokensBlob);
    return res;
  };

  const onSubmitForm = async (e) => {
    e.preventDefault();

    if (validarInputIdentificacion(formValue.identification)) {
      // Si la informacion es solo tipo numero

      // validaCedulaPri(formValue.identification);

      if (formValue.pass !== "") {
        overlay(true);
        localStorage.clear();

        try {
          //setear el token.
          //armar el formvalue, con el arreglo.
          setToken(await generateToken(formValue)); // Si el usuario existe colocará toda la informacion consultada en el localStorage

          const promisesArray = [getUserInfoResponse()];

          const [userInfo] = await Promise.all(promisesArray);

 
          if (userInfo?.usuarioInactivoConVariosContratos) {
            let contratosOp = `<option value="null" selected disabled>SELECCIONE...</option>`;
            userInfo.contratos.forEach((contrato, key) => {
              contratosOp += `<option style="font-size:12px" value="${key}">Contrato: #${contrato.NUMERO_CONTRATO} - ${contrato.FECHA_INGRESO} - ${contrato.FECHA_VENCIMIENTO}  (${contrato.nombre_empresa} - ${contrato.Cargo})</option>`;
            });
                
            Swal.fire({
              title: "Empleado Inactivo",
              html: `
                                <div class="row" style="text-align: left; margin: 10px;">
                                    <div class="col-12 col-lg-12 mb-3 text-center">
                                        <span style="font-weight: 600;font-size: 20px;">Por favor seleccione el contrato donde requiera iniciar el proceso.</span>
                                    </div>
            
                                    <div class="col-12 col-lg-12">
                                        <label class="form-label" htmlFor="correoEnvio">Seleccione un contrato: </label>
                                        <select id="contrato" name="contrato" class="form-control">
                                            ${contratosOp}
                                        </select>
                                    </div>

                                </div>
                                     
                                <br/>
                        `,
              showCloseButton: true,
              showCancelButton: false,
              showConfirmButton: true,
              allowOutsideClick: true,
              confirmButtonColor: "#1783EE",
              confirmButtonText: "Ingresar",
              backdrop: true,
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                const posicionContrato =
                  document.getElementById("contrato").value;

                getValidateSiNo(
                  userInfo.contratos[posicionContrato].Cedula,
                  userInfo.contratos[posicionContrato].Empresa,
                  1
                ).then((ele) => {
                  if (ele.length === 1 && ele[0].estado === 1) {
                    localStorage.setItem(
                      "d_u",
                      formatUserInfo(userInfo.contratos[posicionContrato])
                    );
                    window.location.href = routes.home.url;
                  } else {
                    getIp().then((res) => {
                      loadModal(
                        userInfo.contratos[posicionContrato].Cedula,
                        userInfo.contratos[posicionContrato].Empresa,
                        res,
                        userInfo.contratos[posicionContrato]
                      );
                    });
                  }
                });
              }
            });
          } else {

            getValidateSiNo(userInfo.Cedula, userInfo.Empresa, 1).then(async (ele) => {
              if (ele.length === 1 && ele[0].estado === 1) {
                localStorage.setItem("d_u", formatUserInfo(userInfo));
                await registrarIngreso(userInfo);
                window.location.href = routes.home.url;
              } else {
                getIp().then((res) => {
                  loadModal(userInfo.Cedula, userInfo.Empresa, res, userInfo);
                });
              }
            });
          }
        } catch (error) {
          // //Si el usuario no existe le despliega una modal de alerta para ingresar como contratista
          Swal.fire({
            title: "Contraseña Incorrecta",
            html: `
                    <div className="row">
                        <div className="col-12 col-lg-12" style="text-align: left; font-size: 16px; font-weight: 600; margin-bottom: 15px;">
                          La contraseña ingresada no es la correcta, te invitamos a realizar el proceso de recordar contraseña.
                        </div>
                    </div>
                    <br/>
                `,
            icon: "warning",
            confirmButtonText: "Cerrar",
            confirmButtonColor: "#A6A6A6",
          });

          // document.querySelector("#divContratistas").style.display = "unset";
          // document.querySelector("#divPassword").style.display = "none";
        }
      } else {
        Swal.fire({
          title: "Advertencia",
          html: `
                      <div className="row">
                          <div className="col-12 col-lg-12" style="text-align: left; font-size: 16px; font-weight: 600; margin-bottom: 15px;">
                              Revisa el formulario con las siguientes indicaciones:
                          </div>
                          <div className="col-12 col-lg-12" style="text-align: left; font-size: 15px;">
                              ◉ El campo de la contraseña no debe estar vacio <br/>
                              ◉ No se permiten carácteres especiales Eje: !"#$%&/() <br/>
                          </div>
                      </div>
                       <br/>
                      
                  `,
          icon: "warning",
          confirmButtonText: "Cerrar",
          confirmButtonColor: "#A6A6A6",
          backdrop: true,
        });
      }

    }
  };

  const validaCedulaPri = () => {
    let cedula = document.getElementById("identification").value;

    
    const url = `${baseUrl}/v1/auth/validarCedulaPrimeraVe`;
    postFetch({ url: url, params: { identification: cedula } })
      .then((ele) => {
        if (ele === 2) {

          Swal.fire({
            title: `Usuario ${cedula} no encontrado en el sistema`,
            html: `
                    <div className="row">
                        <div className="col-12 col-lg-12" style="text-align: left; font-size: 16px; font-weight: 600; margin-bottom: 15px;">
                            Si eres un personal externo puedes ingresa por las opciones habilitadas en pantalla
                        </div>
                    </div>
                    <br/>
                `,
            icon: "warning",
            confirmButtonText: "Cerrar",
            confirmButtonColor: "#A6A6A6",
          });
          document.querySelector("#vumService").style.display = "none";
          document.querySelector("#divContratistas").style.display = "unset";
          document.querySelector("#divPassword").style.display = "none";          
          document.querySelector("#divIngresar").style.display = "none";
          
        }else{
          document.querySelector("#vumService").style.display = "unset";
          document.querySelector("#divContratistas").style.display = "none";
          document.querySelector("#divPassword").style.display = "unset"; 
          document.querySelector("#divIngresar").style.display = "unset";
           
        }

      })


  }

  const loadModal = (cedula, cod_empr, ip, userInfo) => {
    const array = [1, 2, 3];
    const myVal = array.filter(function (ele) {
      return ele === parseInt(cod_empr);
    });
    const dataEmpresa = myVal.length === 0 ? 1 : cod_empr;
    const url = `${baseUrl}/v1/loginAbeasData/getLoginAbeasData`;
    postFetch({ url: url, params: { cod_empresa: dataEmpresa } }).then(
      (ele) => {
        const url = ele[0].url;

        const dataModal = `<div class="mb-4"><div class="mb-2">${msgTitle}</div> <a target="_blank" download href=${url} >Haz click aquí </a></div><div class="justify-content-center  "><div class="col"><button class="btn btn-primary" id="btn-si">Aceptar</button></div></div>`;
        const modal = Swal.fire({
          html: `<div class="text-primary mb-4">${msgLoginAbeas}</div><div class="text-justify mb-4"></div>${dataModal} `,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          focusConfirm: false,
        });

        document
          .getElementById("btn-si")
          .addEventListener("click", function () {
            saveDataLogin(cedula, cod_empr, 1, ip);
            localStorage.setItem("d_u", formatUserInfo(userInfo));
            modal.close();
          });
      }
    );
  };

  const saveDataLogin = (cedula, cod_empr, estado, ip) => {
    const url = `${baseUrl}/v1/loginAbeasData/insertLoginAbeasData`;
    postFetch({
      url: url,
      params: { cedula: cedula, cod_empresa: cod_empr, estado: estado, ip: ip, tipo: 1 },
    }).then((ele) => {
      if (ele) {
        window.location.href = routes.home.url;
      }
    });
  };

  const validarInputIdentificacion = (data) => {
    let response = false;

    if (exprRegNumeros.test(data)) {
      response = true;
    } else {
      // Si la informacion no es tipo número
      Swal.fire({
        title: "Advertencia",
        html: `
                    <div className="row">
                        <div className="col-12 col-lg-12" style="text-align: left; font-size: 16px; font-weight: 600; margin-bottom: 15px;">
                            Revisa el formulario con las siguientes indicaciones:
                        </div>
                        <div className="col-12 col-lg-12" style="text-align: left; font-size: 15px;">
                            ◉ Los campos no deben estar vacios <br/>
                            ◉ El campo solo permite números <br/>
                            ◉ No se permiten carácteres especiales Eje: !"#$%&/() <br/>
                        </div>
                    </div>
                     <br/>
                    
                `,
        icon: "warning",
        confirmButtonText: "Cerrar",
        confirmButtonColor: "#A6A6A6",
        backdrop: true,
      });
    }

    return response;
  };

  const onClickContratistasHandle = () => {
    //validar que el campo de cédula no esté vacio.
    const cedulaContratista = document.getElementById('identification').value;
    if(cedulaContratista !== ''){
      overlay(true);

      getFetchWithHeader({
        url: api.getDocumentTypes,
        headers: { accept: "*/*" },
      }).then((responseGetDocumentTypes) => {
        getFetchWithHeader({
          url: api.getAllEps,
          headers: { accept: "*/*" },
        }).then((responseGetAllEps) => {
          setModalInformation({
            eps: responseGetAllEps,
            documentTypes: responseGetDocumentTypes,
          });
        });
      });

    }else{
      Swal.fire({
        title: "Advertencia",
        html: `
                    <div className="row">
                        <div className="col-12 col-lg-12" style="text-align: left; font-size: 16px; font-weight: 600; margin-bottom: 15px;">
                            Revisa el formulario con las siguientes indicaciones:
                        </div>
                        <div className="col-12 col-lg-12" style="text-align: left; font-size: 15px;">
                            ◉ Los campos no deben estar vacios <br/>
                            ◉ El campo solo permite números <br/>
                            ◉ No se permiten carácteres especiales Eje: !"#$%&/() <br/>
                        </div>
                    </div>
                     <br/>
                    
                `,
        icon: "warning",
        confirmButtonText: "Cerrar",
        confirmButtonColor: "#A6A6A6",
        backdrop: true,
      });
    }
   
  };

  useEffect(() => {
    // (modalInformation.eps != null && modalInformation.documentTypes != null) && desplegarModalContratistas();
    modalInformation.eps != null &&
      modalInformation.documentTypes != null &&
      validatExterno();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalInformation]);

  const validatExterno = () => {
    const cedula = document.getElementById("identification").value;
    const dataEmpresa = 1;
    getValidateSiNo(cedula, dataEmpresa, 2).then((ele) => {
      if (ele.length === 1 && ele[0].estado === 1) {
        desplegarModalContratistas();
      } else {
        loadModalContratistas();
      }
    });
  };

  const loadModalContratistas = () => {
    const cedula = document.getElementById("identification").value;
    const dataEmpresa = 1;
    const url = `${baseUrl}/v1/loginAbeasData/getLoginAbeasData`;
    postFetch({ url: url, params: { cod_empresa: dataEmpresa } }).then(
      (ele) => {
        const url = ele[0].url;
        const dataModal = `<div class="mb-4"><div class="mb-2">${msgTitle}</div> <a target="_blank" download href=${url} >Haz click aquí </a></div><div class="justify-content-center "><div class="col"><button class="btn btn-primary" id="btn-siExterno">Aceptar</button></div></div>`;

        const modal = Swal.fire({
          html: `<div class="text-primary mb-4">${msgLoginAbeas}</div><div class="text-justify mb-4"></div>${dataModal} `,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          focusConfirm: false,
        });

        document
          .getElementById("btn-siExterno")
          .addEventListener("click", function () {
            saveDataLoginExternos(cedula, dataEmpresa, 1);
            modal.close();
            desplegarModalContratistas();
          });
      }
    );
  };

  const saveDataLoginExternos = (cedula, dataEmpresa, estado) => {
    // para insertar como externo
    const url = `${baseUrl}/v1/loginAbeasData/insertLoginAbeasData`;
    getIp().then((ip) => {
      postFetch({
        url: url,
        params: {
          cedula: cedula,
          cod_empresa: dataEmpresa,
          estado: estado,
          ip: ip,
          tipo: 2
        },
      });
    });
  };

  async function getValidateSiNo(cedula, cod_empresa, campo) {
    const url = `${baseUrl}/v1/loginAbeasData/validateSiNo`;
    const res = await postFetch({
      url: url,
      params: { cedula: cedula, cod_empresa: cod_empresa, campo: campo },
    });
    return res;
  }

  const desplegarModalContratistas = () => {
    let documentTypes = `<option value="null" selected disabled>SELECCIONE...</option>`;
    modalInformation.documentTypes.forEach((objDocumentTypes) => {
      documentTypes += `<option value="${objDocumentTypes.nombre_documento}">${objDocumentTypes.nombre_documento}</option>`;
    });

    let eps = `<option value="null" selected disabled>SELECCIONE...</option>`;
    modalInformation.eps.forEach((objEps) => {
      eps += `<option value="${objEps.EPS.trim()}">${objEps.EPS.trim()}</option>`;
    });

    Swal.fire({
      width: "800px",
      title: "Personal externo",
      html: `
                    <div class="row" style="text-align: left; margin: 10px;">
                        <div class="col-12 col-lg-12 mb-3 text-center">
                            <span style="font-weight: 600;font-size: 20px;">
                                Ingresa los siguientes datos para acceder al sistema.
                            </span>
                        </div>

                        <div class="col-12 col-lg-6">
                            <label class="form-label" htmlFor="correoEnvio">Tipo de Documento: </label>
                            <select id="tipoDocumento" name="tipoDocumento" class="form-control">
                                ${documentTypes}
                            </select>
                        </div>

                        <div class="col-12 col-lg-6">
                            <label class="form-label" htmlFor="cedula">Cédula:</label>
                            <input value="${formValue.identification}" id="cedula" name="cedula" class="form-control" type="text" disabled />
                        </div>

                        <div class="col-12 col-lg-6">
                            <label class="form-label" htmlFor="nombre">Nombre:</label>
                            <input id="nombre" name="nombre" class="form-control" type="text" />
                        </div>

                        <div class="col-12 col-lg-6">
                            <label class="form-label" htmlFor="apellido">Apellido:</label>
                            <input id="apellido" name="apellido" class="form-control" type="text" />
                        </div>

                        <div class="col-12 col-lg-6">
                            <label class="form-label" htmlFor="genero">Sexo:</label>
                            <select id="genero" name="genero" class="form-control">
                                <option value="null" selected disabled>SELECCIONE...</option>
                                <option value="F">FEMENINO</option>
                                <option value="M">MASCULINO</option>
                            </select>
                        </div>

                        <div class="col-12 col-lg-6">
                            <label class="form-label" htmlFor="celular">Celular:</label>
                            <input id="celular" name="celular" class="form-control" type="number" />
                        </div>

                        <div class="col-12 col-lg-6">
                            <label class="form-label" htmlFor="correo">Correo:</label>
                            <input id="correo" name="correo" class="form-control" type="email" />
                        </div>

                        <div class="col-12 col-lg-6">
                            <label class="form-label" htmlFor="eps">Eps:</label>
                            <select id="eps" name="eps" class="form-control">
                                ${eps}
                            </select>
                        </div>

                        <div class="col-12 col-lg-6">
                            <label class="form-label" htmlFor="cargo">Cargo:</label>
                            <input id="cargo" name="cargo" class="form-control" type="text" />
                        </div>

                        <div class="col-12 col-lg-6">
                            <label class="form-label" htmlFor="area">Area:</label>
                            <input id="area" name="area" class="form-control" type="text" />
                        </div>

                        <div class="col-12 col-lg-12 mt-3" style="text-align: right;">
                            <button id="btnEnviarModalContratistas" type="button" class="btn succesButton">Ingresar</button>
                        </div>
                    </div>
                         
                    <br/>
            `,
      showCloseButton: true,
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      backdrop: true,
      didOpen: () => {
        document
          .getElementById("btnEnviarModalContratistas")
          .addEventListener("click", async () => {
            let dataContratistas = {
              ingresoExterno: true,
              tipoDocumento: document.querySelector("#tipoDocumento").value,
              cedula: document.querySelector("#cedula").value,
              nombres: document.querySelector("#nombre").value,
              apellidos: document.querySelector("#apellido").value,
              genero: document.querySelector("#genero").value,
              numeroCelular: document.querySelector("#celular").value,
              mail: document.querySelector("#correo").value,
              eps: document.querySelector("#eps").value,
              cargo: document.querySelector("#cargo").value,
              area: document.querySelector("#area").value,
            };

            if (validarInfoContratista(dataContratistas)) {
              overlay(true);

              localStorage.setItem("d_u", JSON.stringify(dataContratistas)); //informacion de usuario contratista
              const dataUser = dataContratistas;

              const params = {
                apellidos: dataUser.apellidos,
                area: dataUser.area,
                cargo: dataUser.cargo,
                cedula: dataUser.cedula,
                eps: dataUser.eps,
                genero: dataUser.genero,
                externo: true,
                mail: dataUser.mail,
                nombres: dataUser.nombres,
                numeroCelular: dataUser.numeroCelular,
                tipoDocumento: dataUser.tipoDocumento,
                name: dataUser.nombres,
                last_name: dataUser.apellidos,
                identification: dataUser.cedula,
              };
      
              const tokens = await postFetch({ url: api.postLoginContratista, params: { params } });
      
              localStorage.setItem('a_t', tokens.access_token);  // access_token
              localStorage.setItem('r_t', tokens.refresh_token); // refresh_token

              window.location.href = routes.ingreso.url;
            } else {
              alertify
                .warning(
                  `
                        <div className="row">
                            <div className="col-12 col-lg-12" style="text-align: center; font-size: 18px; font-weight: 800;">
                                Error.
                            </div>
                            <div className="col-12 col-lg-12" style="text-align: left; font-size: 16px; font-weight: 600; margin-bottom: 15px;">
                                Revisa el formulario con las siguientes indicaciones:
                            </div>
                            <div className="col-12 col-lg-12" style="text-align: left; font-size: 15px;">
                                ◉ Los campos no deben estar vacios <br/>
                                ◉ No se permiten carácteres especiales Eje: !"#$%&/() <br/>
                            </div>
                        </div>
                        `
                )
                .delay(7);
            }
          });
      },
    });
  };

  const validarInfoContratista = (data) => {
    let response = false;

    if (
      data.tipoDocumento !== "null" &&
      data.genero !== "null" &&
      data.eps !== "null" &&
      exprRegNumeros.test(data.cedula) &&
      exprRegSoloLetras.test(data.nombres) &&
      exprRegSoloLetras.test(data.apellidos) &&
      exprRegTelefono.test(data.numeroCelular) &&
      exprRegEmail.test(data.mail) &&
      exprRegSoloLetras.test(data.cargo) &&
      exprRegSoloLetras.test(data.area)
    ) {
      response = true;
    }

    return response;
  };


  const registrarIngreso=async (data)=>{

    try {

      const datosNavegacion= await obtenerInformacionDeNavegacion();
      
      const datosIngreso={

        codigo_usuario:`${data.USU_CODIGO}`,
        aplicacion:'VUM OFFICE', 
        ip:datosNavegacion.otros_datos.ip,
        dispositivo:datosNavegacion.dispositivo,
        latitud:`${datosNavegacion.otros_datos.latitude}`,
        longitud:`${datosNavegacion.otros_datos.longitude}`,
        fecha:datosNavegacion.fecha,
        login_usuario:data.USU_LOGIN

      };

      const res = await axios.post(`${baseUrl}/v1/seguimiento/registrarIngreso`,datosIngreso);

      localStorage.setItem('dato1', res.data[0].codigo);

      
    } catch (error) {
      
    };

  };


  return {
    onKeyUpInputHandle,
    logOut,
    onSubmitForm,
    onClickContratistasHandle,
    validaCedulaPri,
    terminosCondiciones
  };
};
