import { routes } from '../../environments/environments';
import React, { useEffect, useState } from 'react';
import '../Login/login.css';
import Swal from "sweetalert2/dist/sweetalert2.js";
import { postData } from "../../components/general/General";
import { baseUrl } from "../../config/config";
import {generateToken,getUserInfoResponse,} from "../../repositories/Login/login";
const fechah = new Date();
const LoginQuestion = () => {

    const [questionData, setQuestionData] = useState(null);
    let rightAnswer = 0;
    let wrongAnswer = 0;
    let currentQuestionIndex = 0;
    let numPregunta = 1;
    let currenAnswer = 0;



    const printHtmlQuestion = (i) => {

        currentQuestionIndex++;
        const cuestionary = JSON.parse(localStorage.getItem("dataQuestion"));
        const q = cuestionary[i];
        let a = q.answer;
        a = a.sort((a, b) => Math.floor(Math.random() * 3) - 1);

        const htmlAnswerArray = a.map((currentA, x) => `<p><input class="form-check-input text-start" type="radio" value="${currentA}" name="flexRadioDefault" id="flexRadioDefault${x}"/> <label class="form-check-label" for="flexRadioDefault${x}">${currentA}</label></p>`);
        const htmlAnswer = htmlAnswerArray.join(' ');



        let htmlQuestionCode = `<p>${q.question}</p><div class="fontFooter">${htmlAnswer}</div>`;
        document.querySelector('.question').innerHTML = htmlQuestionCode;

        let titulo = `Pregunta ${numPregunta} de 5`;
        document.querySelector('.preg').innerHTML = titulo;

        if(numPregunta === 5){
            document.getElementById('btnVerifi').innerText = 'Verificar respuestas';
        }
        // let botones = `<button id='btnVerifi' className="btn btnIniciarSesion d-block w-100 mt-3 fontLinkLabel" type="button" name="submit">Verificar respuestas</button>`;

        // document.querySelector('.botones').innerHTML = botones;
        numPregunta++;
    }

    useEffect(() => {


        printHtmlQuestion(currentQuestionIndex);
        let idBtnRespuesta = document.getElementById("btnVerifi");

        idBtnRespuesta.addEventListener("click", function () {
            const cuestionary = JSON.parse(localStorage.getItem("dataQuestion"));
            if (currentQuestionIndex <= 4) {
                for (let i = 0; i < 4; i++) {


                    if (document.getElementById("flexRadioDefault" + i).checked) {
                        const q = cuestionary[currenAnswer];
                        const a = q.answer;
                        let respuesta = document.getElementById("flexRadioDefault" + i).value;
                        if (respuesta === a[0]) {
                            rightAnswer++;
                        } else {
                            wrongAnswer++;
                        }
                    }

                }

                currenAnswer++;
                printHtmlQuestion(currentQuestionIndex);

            } else {
                if (rightAnswer === 4) {
                    
                    //envio de correo, wpp y generación de token.
                    const url = `${baseUrl}/v1/auth/newPass`;
                    const cuestionary = JSON.parse(localStorage.getItem("dataQuestion"));
                    const cedula = cuestionary[5]['cedula'];
                    const datos = {
                        identification: parseInt(cedula)
                    };
                    postData(url, datos)
                        .then((ele) => {
                            //ejecuto metodo para loguearse, y luego redirigir al modulo de opciones, el cual es el de cambio de contraseña.
                            //cedula: cedula del usuario.   ele: token de seguridad.
                            if(ele.error){
                                Swal.fire({
                                    icon: 'error',
                                    html: ele.error,
                                    showConfirmButton: true,
                                    confirmButtonText: 'Cerrar',
                                    confirmButtonColor: '#4695ff',
                                    allowOutsideClick: false,
                                    allowEscapeKey: false,
                                    focusConfirm: false,
                                    timer: 5000,
                                    timerProgressBar: true
                                });
                            }else{
                                logueo(cedula,ele);
                            }
                        });
                } else {
                    if (wrongAnswer > 0) {
                        Swal.fire({
                            icon: 'error',
                            html: `
                            <div class="row" style="text-align: left; margin: 10px;">
                                <div class="col-12 col-lg-12 mb-3 text-center">
                                    <span style="font-weight: 600;font-size: 20px;">
                                        Algunas de tus respuestas no son correctas, por favor intenta nuevamente.
                                    </span>
                                </div>                    
                                <div class="col-12 col-lg-12 mt-3" style="text-align: center;">
                                    <button id="btnValidarCodigo" type="button" class="btn succesButton">Aceptar</button>
                                </div>
                            </div>
                                 
                            <br/>
                    `, showCloseButton: true,
                            showCancelButton: false,
                            showConfirmButton: false,
                            allowOutsideClick: false,
                            backdrop: true,
                            didOpen: () => {
                                document
                                    .getElementById("btnValidarCodigo")
                                    .addEventListener("click", () => {
                                        window.location.href = routes.loginReset.url;
                                    });
                            }
                        });
                    }
                }
            }





        });



    }, [!questionData]);

    const formatUserInfo = (userInfo) => {
        return JSON.stringify({
          nombres: userInfo.Nombres,
          apellidos: userInfo.Apellidos,
          cedula: userInfo.Cedula,
          genero: userInfo.Genero,
          mail: userInfo.Mail,
          numContrato: userInfo.NUMERO_CONTRATO,
          cargo: userInfo.Cargo,
          area: userInfo.Area,
          fechaIngreso: userInfo.FECHA_INGRESO,
          fechaVencimiento: userInfo.FECHA_VENCIMIENTO,
          ccostos: userInfo.C_COSTOS,
          estado: userInfo.ESTADO,
          empresa: userInfo.Empresa,
          entidad: userInfo.Entidad,
          jefe: userInfo.Jefe,
          numeroCelular: userInfo.Numero,
        });
      };
    
    const setToken = (res) => {
        localStorage.setItem("a_t", res.access_token); // access_token
        localStorage.setItem("r_t", res.refresh_token); // refresh_token
        localStorage.setItem("tokensBlob", res.tokensBlob);
        return res;
      };

    //metodo de logeo despues de que haya contestado bien las preguntas.
    const logueo = async (cedula, password) => {
        //debo hacer el llamado al endpoint del loguin.
        const url = `${baseUrl}/v1/auth/login`;
        const datos = {
            identification: parseInt(cedula),
            password: password  
        };
        postData(url, datos)
            .then((response) => {
                // Nada
            });
          const datosToken = {
            identification: parseInt(cedula),
            pass: password  
        };
          setToken(await generateToken(datosToken)); // Si el usuario existe colocará toda la informacion consultada en el localStorage

          const promisesArray = [getUserInfoResponse()];

          const [userInfo] = await Promise.all(promisesArray);
          localStorage.setItem("d_u", formatUserInfo(userInfo));
          localStorage.setItem("contr", password);
          window.location.href = routes.opciones.url;
    } 

    return (
        <main className="main login" id="top">
            <div className="container" data-layout="container">

                <div className="row flex-center text-center min-vh-100">
                    <img className="me-2 mt-5 mb-3" src="assets/img/logo-vum-login.png" alt="logo-vum-login" style={{ width: '200px' }} />
                    <span className="font-sans-serif fontBienvenido fs-5 d-inline-block tituloBienvenido">Responde correctamente todo</span>
                    <span className="font-sans-serif fontBienvenido fs-5 d-inline-block tituloBienvenido preg"></span>
                    <div className="col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4 text-center">
                        <div>
                            <div className="card-body p-4 p-sm-5 mt-2">
                                <form>
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-12 col-md-12 question" >

                                            </div>
                                        </div>                                        
                                    </div>

                                    <div className="mb-3 botones">                                        
                                        <button id='btnVerifi' className="btn btnIniciarSesion d-block w-100 mt-3 fontLinkLabel" type="button" name="submit">Siguiente pregunta</button>
                                        <br></br><br></br>
                                        <span className="labelInpiut"><a href={routes.login.url} id="contr"><strong>Regresar al login</strong></a></span>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <footer className="footer">
                        <div className="row mb-3 text-center">
                            {/* <div className="col-12 col-md-12 col-sm-auto text-center">
                                            <img className="me-2 mt-5 mb-3 " id="imagenLogo" src="assets/img/logos-grises.png" alt="logo-vum-login" style={{ width: '640px' }} />
                                        </div> */}
                            <div className="col-12 col-md-12 col-sm-auto text-center">
                                <p className="mb-0 fontFooter"> © Derechos reservados VUM {fechah.getFullYear()} </p>
                            </div>
                        </div>
                    </footer>
                </div>


            </div>
        </main>
    )
}

export default LoginQuestion;