import React,{Component} from 'react';
import { baseUrl } from '../../config/config';
import { postData, validateInputTabs, putInputRequerid, loadDataValidate, loadingDialog } from '../../components/general/General';
import moment from 'moment';
import Swal from 'sweetalert2';
import {  postFetch } from '../../generalHelpers';


class Education extends Component{

    constructor(){
        super()
        this.state = {
            optionsNiveles:'',
            optionsEstados:'',
            optionsPaises:'',
            dpto:'',
            valCity:'',
            valCountry:'',
            city:'',
            valDpto:'',
            optionsModalidades:'',
            dataPrincipal:'',
            tbody:'',
            setHidden:'hidden',
            ocultarTabla:'d-none',
            eduCodigo: ''
        }
    }


    componentDidMount(){
        document.getElementById('root').className = 'cv';
        this.consultaInicial(true);
    }

    consultaInicial = (carga) => {
        if(carga) loadingDialog(true);
        const dataUser = JSON.parse( localStorage.getItem("d_u"));
        const cedula = parseInt(dataUser['cedula']);
        const empresa = parseInt(dataUser['empresa']);
        const datosEnviar = {cedula, empresa}
        const url = `${baseUrl}/v1/educacion/consultaInicial`;
        postData(url, datosEnviar).then(async (result) => {
            await this.asignarValoresSelects(result);
            this.loadDataPrincipal(result.estudios);
            if(carga) loadingDialog(false);
        }).catch(err => {
            if(carga) loadingDialog(false);
            Swal.fire({
                icon: 'warning',
                title: '¡Ups!',
                text: 'Ha ocurrido un error al cargar el formulario, intenta nuevamente.',
                confirmButtonColor: "#A6A6A6",
                confirmButtonText: 'Cerrar'
            })
        });
    }

    // En esta función insertamos todos los valores de los select
    asignarValoresSelects = async (result) => {
        let nivelesEstudio = result.nivelEstudio.map((value,x) => <option  key={'optNivEst'+x} value={value["TIP_CODIGO"]}>{value["TIP_NOMBRE"]}</option>);
        let estadosEstudio = result.estadoEstudio.map((value,x) => <option  key={'optEstadoEst'+x} value={value["TIP_CODIGO"]}>{value["TIP_NOMBRE"]}</option>);
        let paises = result.paises.map((value,x) => <option  key={'optPais'+x} value={value["cod_pais"]}>{value["nom_pais"]}</option>);
        let modalidades = result.modalidadEstudio.map((value,x) => <option  key={'optModalidad'+x} value={value["TIP_CODIGO"]}>{value["TIP_NOMBRE"]}</option>);

        // Se retorna un promise.all para que todos los inputs se coloquen antes de setear los valores en la función loadDataPrincipal
        return await Promise.all([nivelesEstudio, estadosEstudio, paises, modalidades])
        .then(res => {
            this.setState({
                optionsNiveles: res[0],
                optionsEstados: res[1],
                optionsPaises: res[2],
                optionsModalidades: res[3],
            });
            return true;
        });
    }

    loadDataPrincipal = (estudios) =>{
        this.setState({tbody:''});

        if(!estudios.length) {
            this.setState({ocultarTabla:'d-none'});
            return;
        }

        const tbodyData = estudios.map((value,x) =>{
            let dataFin = value.finalizacion ? moment.utc(value.finalizacion).format('DD/MM/YYYY') : '';
            let icon = value.urlCertificado ? 'fas fa-file-pdf text-dangerpdf fs-1' : 'fas fa-file-pdf text-muted fs-1';
            let disabled = !value.urlCertificado;

            return  <tr key={'filaCert'+x} className='align-middle'>
                        <td>{value.programa}</td>
                        <td>{value.institucion}</td>
                        <td className="text-nowrap">{value.nomNivelEstudio}</td>
                        <td className="text-nowrap">{value.nomEstadoEstudio}</td>
                        <td className="text-nowrap">{dataFin}</td>
                        <td>
                            <a type='button' disabled={disabled} className="btn fs-1" href={value.urlCertificado} target='_blank' rel="noreferrer"><i className={icon}></i></a>
                        </td>
                        <td>
                            <div className='d-flex justify-content-around w-100'>
                                <button className="btn fs-1"  onClick={() => this.updateLoadPrincipal(value,1)}><i className="fas fa-pen-square colorSquare"></i></button> 
                                <button className="btn fs-1"  onClick={() => this.updateLoadPrincipal(value,2)}><i className="fas fa-trash colorTrash"></i> </button>
                            </div>
                        </td>
                    </tr>
        })

        let tabla = <table className="table table-hover table-bordered text-center">
                        <thead>
                            <tr>
                                <th scope="col">Programa académico</th>
                                <th scope="col">Institución</th>
                                <th scope="col">Nivel</th>
                                <th scope="col">Estado</th>
                                <th scope="col">Fecha de finalización</th>
                                <th>Certificado</th>
                                <th scope="col">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tbodyData}
                        </tbody>

                    </table>
        this.setState({tbody:tabla, ocultarTabla:''});
    }

    guardarEducacion = () =>{
        if(validateInputTabs()) return;
        if(!this.validateDates()) return;

        const dataUser = JSON.parse( localStorage.getItem("d_u"));
        const cedula = Number(dataUser.cedula);
        const empresa = Number(dataUser.empresa);
        const usuLogin = dataUser.USU_LOGIN;

        let formData = new FormData();
        formData.append('usuLogin', usuLogin);
        formData.append('cedula', cedula);
        formData.append('empresa', empresa);
        formData.append('nivelEstudio', Number(this.nivelEstudio.value));
        formData.append('programa', this.nombrePrograma.value);
        formData.append('estadoEstudio', Number(this.estadoEstudio.value));
        formData.append('institucion', this.institucion.value);
        formData.append('pais', Number(this.selectCountry.value));
        formData.append('fechaFinalizacion', this.fechaFinalizacion.value);
        formData.append('fechaGrado', this.fechaGrado.value);
        formData.append('modalidad', Number(this.modalidadEstudio.value));
        formData.append('file',this.filePdf.files[0]?this.filePdf.files[0]:{});

        loadingDialog(true);
        const urlSave =  `${baseUrl}/v1/educacion/guardarEducacion`;
        postFetch({url:urlSave,params:formData}).then(result => {
            loadingDialog(false);
            if(result.error || result.message) {
                return Swal.fire({
                    icon: 'warning',
                    title: '¡Ups!',
                    text: result.error || "Ha ocurrido un error inesperado. Intenta nuevamente.",
                    confirmButtonColor: "#A6A6A6",
                    confirmButtonText: 'Cerrar'
                });
            }

            Swal.fire({
                title: '',
                html: `
                <div class="text-center">
                    <img src="https://controlf.blob.core.windows.net/public/vumoffice/assets/
cv/check.png" alt="check" style="width:60px">
                    <h3 class="text-primary mt-3">¡Los datos de tu EDUCACIÓN FORMAL han sido guardados con éxito!</h3>
                    <h5 class="text-secondary">Te garantizamos un buen uso de tu información.</h5>
                    <a type="button" class="btn succesButton mt-3" href="/cv/familiares">Continuar llenando "Familiares"</a>
                </div>`,
                showCloseButton: true,
                showCancelButton: false,
                showConfirmButton: false
            });
            this.cleanInputs();
            loadDataValidate(); // Volvemos a cargar los colores de los iconos
            this.consultaInicial(false); // Reiniciamos toda la interfaz con los campos actualizados
        }).catch(err => {
            loadingDialog(false);
            Swal.fire({
                icon: 'error',
                title: '¡Ups!',
                text: 'No se guardaron los datos, intente nuevamente.',
                confirmButtonColor: "#A6A6A6",
                confirmButtonText: 'Cerrar'
            })
        })
    }

    /**
     * Si los estudios están finalizados, preguntamos obligatoriamente
     * la fecha de finalización y opcionalmente la fecha de grado
     */
    changeInputReadOnly = (data) =>{
        if(data.trim() === 'FINALIZADO'){
            this.fechaGrado.removeAttribute("readonly")
            putInputRequerid(`#${this.fechaFinalizacion.id}`,'','add',this.fechaFinalizacion.id);
            this.fechaFinalizacion.setAttribute("max",moment().utc().format('yyyy-MM-DD'))
            this.fechaGrado.setAttribute("max",moment().utc().format('yyyy-MM-DD'))
        }else{
            putInputRequerid(`#${this.fechaFinalizacion.id}`,'','remove',this.fechaFinalizacion.id);
            putInputRequerid(`#${this.fechaGrado.id}`,'','remove',this.fechaGrado.id);
            this.fechaGrado.setAttribute("readonly",true);
        }
    }

    updateLoadPrincipal = (data, accion) =>{
        this.cleanInputs();

        if(accion === 1){
            // Seteamos los inputs
            this.nivelEstudio.value = data.nivelEstudio;
            this.nombrePrograma.value = data.programa;
            this.estadoEstudio.value = data.estadoEstudio;
            this.institucion.value = data.institucion;
            this.selectCountry.value = data.pais;
            this.fechaFinalizacion.value = data.finalizacion ? moment.utc(data.finalizacion).format('yyyy-MM-DD') : '';
            this.fechaGrado.value = data.grado ? moment.utc(data.grado).format('yyyy-MM-DD') : '';
            this.modalidadEstudio.value = data.modalidad;
            this.changeInputReadOnly(data.nomEstadoEstudio);
            this.setState({eduCodigo: data.eduCodigo});

            
            document.getElementById('saveButton').classList.add('d-none');
            this.setState({setHidden:''})

            let container = document.querySelector(".inputHiddenEdit");
            container.innerHTML = ''
            let input = document.createElement("input");
            input.setAttribute("type","hidden");
            input.setAttribute("id","idDocument");
            input.setAttribute("value", data.eduCodigo);
            container.appendChild(input); 

        }else if(accion === 2){
            Swal.fire({
                title: '',
                text: "¿Desea eliminar el registro?",
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: "#E63757",
                cancelButtonColor: "#A6A6A6",
                confirmButtonText: 'Eliminar',
                cancelButtonText:'Cancelar',
              }).then((result) => {
                if (result.isConfirmed) {
                    this.eliminarEducacion(data.eduCodigo)
                }
              })

        }
    }

    updateData = () => {
        if(validateInputTabs()) return;
        if(!this.validateDates()) return;

        const dataUser = JSON.parse( localStorage.getItem("d_u"));
        const cedula = Number(dataUser.cedula);
        const usuLogin = dataUser.USU_LOGIN;
        
        let formData = new FormData();
        formData.append('usuLogin', usuLogin);
        formData.append('cedula', cedula);
        formData.append('eduCodigo', Number(this.state.eduCodigo));
        formData.append('nivelEstudio', Number(this.nivelEstudio.value));
        formData.append('programa', this.nombrePrograma.value);
        formData.append('estadoEstudio', Number(this.estadoEstudio.value));
        formData.append('institucion', this.institucion.value);
        formData.append('pais', Number(this.selectCountry.value));
        formData.append('fechaFinalizacion', this.fechaFinalizacion.value);
        formData.append('fechaGrado', this.fechaGrado.value);
        formData.append('modalidad', Number(this.modalidadEstudio.value));
        formData.append('file',this.filePdf.files[0]?this.filePdf.files[0]:{});


        const urlSave =  `${baseUrl}/v1/educacion/actualizarEducacion`;
        postFetch({url:urlSave,params:formData}).then(result => {
            if(result.error) {
                return Swal.fire({
                    icon: 'warning',
                    title: 'Ha ocurrido un error',
                    text: result.error,
                    confirmButtonColor: "#A6A6A6",
                    confirmButtonText: 'Cerrar'
                })
            }

            document.getElementById('saveButton').classList.remove('d-none');
            this.setState({setHidden:'hidden'})
            document.querySelector(".inputHiddenEdit").innerHTML = '';

            Swal.fire({
                title: '',
                text: "Se actualizó con éxito",
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: "#A6A6A6",
                confirmButtonText: 'Cerrar'
            });
            this.setState({eduCodigo: ''});
            this.cleanInputs();
            this.consultaInicial(false);
        }).catch(err => {
            Swal.fire({
                icon: 'error',
                title: 'Ha ocurrido un error',
                text: 'No se pudo actualizar el estudio seleccionado',
                confirmButtonColor: "#A6A6A6",
                confirmButtonText: 'Cerrar'
            })
        });
    }

    eliminarEducacion = (eduCodigo) => {
        const dataUser = JSON.parse( localStorage.getItem("d_u"));
        const usuLogin = dataUser.USU_LOGIN;

        const datos = {eduCodigo, usuLogin}
        const url =  `${baseUrl}/v1/educacion/eliminarEducacion`;
        postData(url, datos).then(result => {
            if(result.error) {
                return Swal.fire({
                    icon: 'warning',
                    title: 'Ha ocurrido un error',
                    text: result.error,
                    confirmButtonColor: "#A6A6A6",
                    confirmButtonText: 'Cerrar'
                })
            }

            Swal.fire({
                title: '',
                text: "Registro eliminado con éxito",
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: "#A6A6A6",
                confirmButtonText: 'Cerrar'
            });
            this.cleanInputs();
            this.consultaInicial(false);
            document.getElementById('saveButton').classList.remove('d-none');
            this.setState({setHidden:'hidden'})
            document.querySelector(".inputHiddenEdit").innerHTML = '';
        })
    }

    cleanInputs = () => {
        this.nivelEstudio.value = ''
        this.nombrePrograma.value = ''
        this.estadoEstudio.value = ''
        this.institucion.value = ''
        this.fechaFinalizacion.value = ''
        this.fechaGrado.value = ''
        this.modalidadEstudio.value = ''
        this.setState({valCity:'',valDpto:'',eduCodigo:''})
        this.selectCountry.selectedIndex  = ''
        document.querySelector('#file-upload').value = ''
    }

    validateDates = () =>{
        const stateStudy = document.getElementById('edu-state').options[document.getElementById('edu-state').selectedIndex].text
        if(stateStudy.toUpperCase() === 'FINALIZADO'){
            if(!this.fechaFinalizacion.value) {
                Swal.fire({
                    title: '',
                    text: "Debe seleccionar una fecha de finalización",
                    icon: 'info',
                    showCancelButton: false,
                    confirmButtonColor: "#A6A6A6",
                    confirmButtonText: 'Cerrar'
                })
                return false
            }
            return true
        }else{
            return true
        }
    }



render(){  
    const {optionsNiveles,optionsEstados,optionsPaises,optionsModalidades,tbody,setHidden,ocultarTabla} = this.state;

    return  <>
            <div className="card">
                <div className="card-header">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb fs-2">
                            <li className="breadcrumb-item">Mi perfil</li>
                            <li className="breadcrumb-item" aria-current="page">Educación formal</li>
                        </ol>
                    </nav>
                </div>
            </div>
            &nbsp;
            <div className="card mb-3">
                <div className="card-body active">
                    <div className="row g-3">
                        <div className="col-12 col-md-4">
                            <label className="form-label" htmlFor="edu-study">Nivel de estudio:<span className="text-danger">*</span></label>
                            <select ref={input => this.nivelEstudio = input} className="form-select inputRequired" id="edu-study" name="edu-study" defaultValue={""}>
                                <option value="" disabled>Seleccionar</option>
                                {optionsNiveles}
                            </select>
                        </div>
                        <div className="col-12 col-md-4">
                            <label className="form-label" htmlFor="edu-title">Nombre de programa académico:<span className="text-danger">*</span></label>
                            <input ref={input => this.nombrePrograma = input}  type="text" className="form-control inputRequired" id="edu-title" name="edu-title" placeholder='Ej: Administración de empresas'></input>
                        </div>
                        <div className="col-12 col-md-4">
                            <label className="form-label" htmlFor="edu-state">Estado:<span className="text-danger">*</span></label>
                            <select ref={input => this.estadoEstudio = input}  className="form-select inputRequired" id="edu-state" name="edu-state" onChange={(e)=> {
                                this.changeInputReadOnly(e.target[e.target.selectedIndex].text)
                            }} defaultValue={""}>
                                <option value="" disabled>Seleccionar</option>
                                {optionsEstados}
                            </select>
                        </div>
                        <div className="col-12 col-md-4">
                            <label className="form-label" htmlFor="edu-institute">Institución:<span className="text-danger">*</span></label>
                            <input type="text" ref={input => this.institucion = input}  className="form-control inputRequired" id="edu-institute" name="edu-institute" placeholder='Ej: Universidad Javeriana'></input>
                        </div>
                        
                        <div className="col-12 col-md-4">
                            <label className="form-label" htmlFor="country">País:<span className="text-danger">*</span></label>
                            <select ref={inputElement  => this.selectCountry = inputElement} name="country" id="country" className="form-select inputRequired" defaultValue={""} >
                            <option value="" disabled>Seleccionar</option>
                                {optionsPaises}
                            </select>
                        </div>
                        
                        <div className="col-12 col-md-4">
                            <label className="form-label" htmlFor="edu-datefin">Fecha de finalización:</label>
                            <input type="date" readOnly ref={input => this.fechaFinalizacion = input}  className="form-control datefli" id="edu-datefin" name="edu-datefin"></input>
                        </div>
                        <div className="col-12 col-md-4">
                            <label className="form-label" htmlFor="edu-grade">Fecha de grado:</label>
                            <input type="date" readOnly ref={input => this.fechaGrado = input}  className="form-control datefli" id="edu-grade" name="edu-grade"></input>
                        </div>
                        <div className="col-12 col-md-4">
                            <label className="form-label" htmlFor="edu-modal">Modalidad de estudio:<span className="text-danger">*</span></label>
                            <select className="form-select inputRequired" ref={input => this.modalidadEstudio = input}  id="edu-modal" name="edu-modal" defaultValue={""}>
                                <option value="" disabled>Seleccionar</option>
                                {optionsModalidades}
                            </select>
                        </div>
                        
                        <div className="mb-3 col-12 col-md-4">
                            <label className="form-label" htmlFor="file-upload">Certificado de estudio (PDF):</label>
                            <input className="form-control" ref={el => this.filePdf = el} id="file-upload" name="file-upload" type="file" accept="application/pdf"/>
                        </div>
                        
                        <div className="text-end">
                            <button onClick={() => this.guardarEducacion()} id="saveButton" className="btn succesButton saveButton col-12 col-lg-2 col-xxl-1">Guardar</button>
                            <button hidden={setHidden} onClick={() => this.updateData()} id="upButton" className="btn succesButton upButton col-12 col-lg-2 col-xxl-1">Actualizar</button>
                            <div className="inputHiddenEdit"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={"card " + ocultarTabla}>
                <div className="card-body">
                    <div className="table-responsive scrollbar">
                        {tbody}
                    </div>
                </div>
            </div>
        </>
}


}

export default Education;