import { routes } from '../../environments/environments';
import '../Login/login.css';
import Swal from "sweetalert2/dist/sweetalert2.js";
import { postData } from "../../components/general/General";
import { baseUrl } from "../../config/config";
import { useState } from 'react';
import { Footer } from '../../components/FooterLogin/FooterLogin';

const LoginNew = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);


    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
      };


      const toggleShowPassword2 = () => {
        setShowPassword2(!showPassword2);
      };

    let tokenValid;

    const actualizarContrasena =async  () => {
        const token = document.getElementById("token").value;
        const password = document.getElementById("password").value;
        const confirmacion = document.getElementById("confirmacion").value;

        if (token !== "" && password !== "" && confirmacion !== "") {
            if (tokenValid === 1) {
                if (password === confirmacion) {

                    const resp=await validarPassword(password);

                    if(resp){

                        const url = `${baseUrl}/v1/auth/actualizarContrasena`;
                        const datos = {
                            token: document.getElementById("token").value,
                            password: document.getElementById("password").value
                        };
                        postData(url, datos)
                            .then((ele) => {
                                if (ele['error'] === 'No se puede actualizar') {
                                    Swal.fire({
                                        icon: 'error',
                                        html: "No ha sido posible actualizar la contraseña, por favor intenta nuevamente o prueba mandando un nuevo codigo.",
                                        showConfirmButton: true,
                                        confirmButtonText: 'Cerrar',
                                        confirmButtonColor: '#4695ff',
                                        allowOutsideClick: false,
                                        allowEscapeKey: false,
                                        focusConfirm: false,
                                        timer: 3500,
                                        timerProgressBar: true
                                    });
                                } else {
                                    Swal.fire({
                                        icon: 'success',
                                        html: `
                                        <div class="row" style="text-align: left; margin: 10px;">
                                            <div class="col-12 col-lg-12 mb-3 text-center">
                                                <span style="font-weight: 600;font-size: 20px;">
                                                    Contraseña actualizada de manera correcta, ya puedes ingresar a Vum office.
                                                </span>
                                            </div>                    
                                            <div class="col-12 col-lg-12 mt-3" style="text-align: center;">
                                                <button id="btnValidarCodigo" type="button" class="btn succesButton">Aceptar</button>
                                            </div>
                                        </div>
                                            
                                        <br/>
                                `, showCloseButton: true,
                                        showCancelButton: false,
                                        showConfirmButton: false,
                                        allowOutsideClick: false,
                                        backdrop: true,
                                        didOpen: () => {
                                            document
                                                .getElementById("btnValidarCodigo")
                                                .addEventListener("click", () => {
                                                    window.location.href = routes.login.url;
                                                });
                                        }
                                    });
                                }
                                // window.location.href = routes.login.url; 
                            })
                            .catch(error => {
                                Swal.fire({
                                    icon: 'error',
                                    html: "No ha sido posible actualizar la contraseña, por favor intenta nuevamente o prueba mandando un nuevo codigo.",
                                    showConfirmButton: true,
                                    confirmButtonText: 'Cerrar',
                                    confirmButtonColor: '#4695ff',
                                    allowOutsideClick: false,
                                    allowEscapeKey: false,
                                    focusConfirm: false,
                                    timer: 3500,
                                    timerProgressBar: true
                                });
                            });


                    }else{

                        Swal.fire({
                            icon: 'error',
                            html: "La contraseña no debe contener caracteres especiales, tildes, (ñ, Ñ).",
                            showConfirmButton: true,
                            confirmButtonText: 'Cerrar',
                            confirmButtonColor: '#4695ff',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            focusConfirm: false,
                            timer: 3500,
                            timerProgressBar: true
                        });

                    }
                    
                } else {
                    Swal.fire({
                        icon: 'error',
                        html: "La contraseña y la confirmacion no coinciden, por favor verifica que ambos campos sean iguales.",
                        showConfirmButton: true,
                        confirmButtonText: 'Cerrar',
                        confirmButtonColor: '#4695ff',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        focusConfirm: false,
                        timer: 3500,
                        timerProgressBar: true
                    });
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    html: "El token ingresado no es el token asignado, por favor ingresa el token correcto e intentalo nuevamente.",
                    showConfirmButton: true,
                    confirmButtonText: 'Cerrar',
                    confirmButtonColor: '#4695ff',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    focusConfirm: false,
                    timer: 3500,
                    timerProgressBar: true
                });
            }


        } else {
            Swal.fire({
                icon: 'error',
                html: "Todos los campos son obligatorios.",
                showConfirmButton: true,
                confirmButtonText: 'Cerrar',
                confirmButtonColor: '#4695ff',
                allowOutsideClick: false,
                allowEscapeKey: false,
                focusConfirm: false,
                timer: 2500,
                timerProgressBar: true
            });
        }
    }

    const validarPassword=async(pass)=>{

        try {
    
            let resp=true;
    
            const pattern = new RegExp('^[A-Za-z0-9]+$', 'i');
    
            if(!pattern.test(pass)){
    
                resp=false;
    
            };
      
            return resp;
            
        } catch (error) {
            // Error validando password
        };
    
    };

    const validarToken = () => {
        // const token = document.getElementById("token").value;

        const url = `${baseUrl}/v1/auth/validarTokenEnviado`;
        const datos = {
            token: document.getElementById("token").value,
        };

        postData(url, datos)
            .then((ele) => {
                if (ele === 1) {
                    tokenValid = ele;
                } else {
                    Swal.fire({
                        icon: 'error',
                        html: "El token ingresado no es el asignado para su nueva clave.",
                        showConfirmButton: true,
                        confirmButtonText: 'Cerrar',
                        confirmButtonColor: '#4695ff',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        focusConfirm: false,
                        timer: 2500,
                        timerProgressBar: true
                    });
                }

            })
    }

    return(
        <main className="main login" id="top">
            <div className="container" data-layout="container">

                <div className="row flex-center text-center min-vh-100">
                    <img className="me-2 mt-5 mb-3" src="assets/img/logo-vum-login.png" alt="logo-vum-login" style={{ width: '200px' }} />
                    <span className="font-sans-serif fontBienvenido fs-4 d-inline-block tituloBienvenido">Cambio de contraseña con código de seguridad</span>
                    <div className="col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4 text-center">
                        <div>
                            <div className="card-body ps-5 pe-5">
                                <form>

                                    <div className="mb-3 text-center">
                                        <label className="labelInpiut" htmlFor='token'>Digita tu código de seguridad:</label>
                                        <input name="token" id="token" className="form-control inputsFormulario fontFamilyToNumber" type="text" min="1" autoComplete="off" onBlur={() => { validarToken() }} />
                                    </div>
                                    <div className="mb-3 text-center">
                                        <label className="labelInpiut" htmlFor='password'>Digita tu nueva contraseña:</label>
                                        <div className="position-relative">
                                                        <input
                                                        name="password"
                                                        id="password"
                                                        className="form-control inputsFormulario fontFamilyToNumber"
                                                        type={showPassword ? 'text' : 'password'}
                                                        min="1"
                                                        autoComplete="off"
                                                        />
                                                        <span className="position-absolute top-50 end-0 translate-middle-y me-3 me-md-5" onClick={toggleShowPassword} style={{ cursor: 'pointer' }}>
                                                        <i className={showPassword ? "bi bi-eye-slash-fill" : "bi bi-eye-fill"}></i>
                                                        </span>
                                                    </div>
                                    </div>
                                    <div className="mb-3 text-center">
                                        <label className="labelInpiut" htmlFor='confirmacion'>Confirma tu contraseña:</label>
                                        <div className="position-relative">
                                                        <input
                                                        name="confirmacion"
                                                        id="confirmacion"
                                                        className="form-control inputsFormulario fontFamilyToNumber"
                                                        type={showPassword2 ? 'text' : 'password'}
                                                        min="1"
                                                        autoComplete="off"
                                                        />
                                                        <span className="position-absolute top-50 end-0 translate-middle-y me-3 me-md-5" onClick={toggleShowPassword2} style={{ cursor: 'pointer' }}>
                                                        <i className={showPassword2 ? "bi bi-eye-slash-fill" : "bi bi-eye-fill"}></i>
                                                        </span>
                                                    </div>
                                        <span className="labelInpiut text-decoration-underline tituloBienvenido"><a href={routes.loginReset.url} id="contr"><strong>Reenviar código</strong></a></span>
                                    </div>

                                    <div className="mb-3">
                                        <button className="btn btnIniciarSesion d-block w-100 mt-3 fontLinkLabel " type="button" name="submit" onClick={() => actualizarContrasena()}>Actualizar</button>
                                    </div>
                                    <div className="mb-3 text-center">
                                        <span className="labelInpiut tituloBienvenido"><a href={routes.loginQuestion.url} id="contr"><strong>Si tienes problemas para recibir el token, realiza las preguntas de seguridad</strong> <strong className='text-decoration-underline'>haciendo clic aquí.</strong></a></span>
                                    </div>
                                    

                                </form>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>

            </div>
        </main>
    )
}

export default LoginNew;