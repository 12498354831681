import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { makeModal } from "../../generalHelpers";
import { saveCheckIn, saveCheckOut, findUserDayOnSede } from "../../repositories/CheckInCheckOut/CheckInCheckOut";
import { getCheckInAndCheckOutInfo } from "../../repositories/generalInfo";
import { Redirect } from 'react-router-dom'
import { routes } from "../../environments/environments";

export const useIngreso = () => {

    const [dataUser, setDataUser] = useState({});
    const [loadingDataUser, setLoadingDataUser] = useState(true);

    const [mainInfo, setMainInfo] = useState({});
    const [finished, setFinished] = useState(false);
    const [formCheckIn, setFormCheckIn] = useState({});
    const [disabledSalida, setDisabledSalida] = useState(false); 

    const [userDayOnSede, setUserDayOnSede] = useState(null);
    const [loadingUserDayOnSede, setLoadingUserDayOnSede] = useState(true);

    const hasMainInfo = !!(Object.keys(mainInfo || {}).length);
    const userHasSurvey = !!(Object.keys(mainInfo?.hasSurvey || {}).length);
    const userHasCheckIn = !!(Object.keys(mainInfo?.hasCheckIn || {}).length);
    const userHasRazon = mainInfo?.hasCheckIn?.CIN_RAZON_NO_SEDE;
    const userHasCheckOut = !!(Object.keys(mainInfo?.hasCheckOut || {}).length);

    useEffect(() => {
        setDataUser(JSON.parse(localStorage.getItem("d_u")));
        if(setDataUser){
            setLoadingDataUser(false);
        }
        const success = (pos) => {
            setFormCheckIn(oldData => ({
                ...oldData,
                longitude: pos?.coords?.longitude || null,
                latitude: pos?.coords?.latitude || null
            }));
        };

        navigator.geolocation.getCurrentPosition(success, () => { });
        getCheckInAndCheckOutInfo().then(setMainInfo);

        if(!loadingDataUser){
            let objCedula = {
                'documentId': dataUser.cedula
            };
            (async () => {
                let response = await findUserDayOnSede(objCedula);
                setUserDayOnSede(response.data);
                setLoadingUserDayOnSede(false);
            })();
        }
    }, [loadingUserDayOnSede, loadingDataUser, dataUser.cedula]);

    useEffect(() => {
        if (hasMainInfo) {

            setFormCheckIn(oldData => {

                const a = {
                    ...oldData,
                    branch: mainInfo?.hasCheckIn?.SED_CODIGO || '',
                    city: mainInfo?.hasCheckIn?.CIN_CIU_CODIGO || '',
                    reason: mainInfo?.hasCheckIn?.CIN_MOTIVO_INGRESO || '',
                    salesPoints: mainInfo?.hasCheckIn?.CIN_PDV_CODIGO || '',
                    temperature: mainInfo?.hasCheckIn?.CIN_TEMPERATURA || '',
                    typeCheckIn: mainInfo?.hasCheckIn?.CIN_TIPO_INGRESO || '',
                    temperatureCheckOut: mainInfo?.hasCheckOut?.CIN_TEMPERATURA_SALIDA || '',
                };

                return a;
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mainInfo]);

    
    useEffect(() => {
        if (mainInfo?.hasCheckIn?.CIN_PDV_ACTIVIDAD != null) {
            makeModal({ 
                text: 'Usted tiene marcación de ingreso por Vum Trade, no es necesario realizar la marcación de salida por Vum Office', 
                icon: 'error', 
                confirmButtonText : "Salir", 
                successAnswerFunction : () => {window.location.href = routes.home.url},
                
            })
            setDisabledSalida(true);
        }
    }, [hasMainInfo]);

    const handleCheckUpdate = (e) => {
        setFormCheckIn(oldValue => ({ typeCheckIn: parseFloat(e?.target?.value || 0), longitude: oldValue?.longitude ?? null, latitude: oldValue?.latitude ?? null }));
    };
    
    let tipoIngresos = [
        {
            id: 1164, // CIN_TIPO_INGRESO
            title: 'Home Office'
        },
        {
            id: 1165,
            title: 'Personal campo'
        },
        {
            id: 1166,
            title: 'Sede'
        }
    ];

    let datosLogin = JSON.parse(localStorage.getItem('d_u'));
    if(datosLogin.estado === "I" || datosLogin.ingresoExterno === true) {
        tipoIngresos = [{id:1166, title: 'Sede'}]
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        if (!userHasCheckIn) {

            const params = {
                typeEntrance: formCheckIn?.typeCheckIn,
                temperature: parseFloat(formCheckIn?.temperature),
                longitude: formCheckIn?.longitude,
                latitude: formCheckIn?.latitude,
                reason: formCheckIn?.reason,
                branch: (formCheckIn.typeCheckIn === 1164) ? '21' : formCheckIn?.branch,
                city: formCheckIn?.city,
                salePoint: formCheckIn?.salesPoints?.value,
                otherSalePoint: '',
                reasonBranch: formCheckIn?.reasonBranch
            };

            return saveCheckIn({ params })
                .then(() => makeModal({ text: 'Ingreso registrado con exito!', icon: 'success' }))
                .then(() => setFinished(true))
                .catch(err => { console.warn(err); makeModal({ text: err.toString(), icon: 'error', confirmButtonText: 'Aceptar', successAnswerFunction: ()=>{} }) });

        } else if (!userHasCheckOut) {

            const params = {
                typeEntrance: formCheckIn?.typeCheckIn,
                temperature: parseFloat(formCheckIn?.temperatureCheckOut)
            };

            return saveCheckOut({ params })
                .then(() => makeModal({ text: 'Salida registrada con exito!', icon: 'success' }))
                .then(() => setFinished(true))
                .catch(err => { console.warn(err); makeModal({ text: err.toString(), icon: 'error', confirmButtonText: 'Aceptar' }) });
        }
    };

    const handleRedirectIngreso = () => {
        if (JSON.parse(localStorage.getItem('d_u')).ingresoExterno === true) {
            localStorage.clear();
            window.location.href = routes.login.url;
        }else {
            return <Redirect to='/' />;
        }
    }

    if (hasMainInfo && userHasSurvey && userHasCheckIn && userHasCheckOut && !finished ) {  
        Swal.fire({
            title: '',
            text: 'Usted ya realizó el ingreso y salida',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#2c7be5',
            confirmButtonText: 'Cerrar'
        }).then(setFinished(true));
    }

    return {
        mainInfo,
        finished,
        hasMainInfo,
        formCheckIn,
        disabledSalida,
        setFinished,
        tipoIngresos,
        userHasSurvey,
        userHasCheckIn,
        userHasRazon,
        setFormCheckIn,
        userHasCheckOut,
        handleFormSubmit,
        handleCheckUpdate,
        handleRedirectIngreso,
        userDayOnSede
    }
};