import { useState, useEffect } from 'react';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css';
import { overlay, postFetch } from '../../../../generalHelpers';
import { api } from '../../../../environments/environments';
import SignaturePad from 'signature_pad';
import moment from 'moment';

export const useFormRrhh = (formInitialState, typeForm) => {          //typeForm = es el tipo de formulario que llega desde el dashboard de RRHH: (estamosParaTi, talkToUs, SolicitudesRRHH)

    const exprRegTelefono = /^[0-9+() -?]+$/;                                                //Expresion regular para validar el formato de un teléfono
    const exprRegEmail = /^([a-zA-Z0-9_.-]+)@([\da-zA-Z0-9.-]+)\.([a-z.]{2,6})$/;        //Expresion regular para validar los correos electronicos
    const exprRegTexto = /^([\w\s\d\nÑñáéíóúÁÉÍÓÚ.,\-_@?¿%<>]){1,990}$/;                      //Expresion regular para validar texto largos de 1000 caracteres

    const [stateTitle, setStateTitle] = useState('Estamos para ti')

    useEffect(() => {
        document.getElementById('root').className = 'rrhhForm';
        window.scrollTo({ top: 0, behavior: 'smooth' });

        if (typeForm) {
            setStateTitle(validarTituloComponent(typeForm.typeForm));
            loadCategories(typeForm.typeForm);
            //cargar los tipos de auxilios
            loadTiposAuxilios(typeForm.typeForm);
            //cargar los tipos de cesantias
            // loadTiposCesantias(typeForm.typeForm);
            setFilesState({ files: [] });
            document.getElementById("tablaDocumentos").classList.add("d-none");
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [typeForm])

    const [dataCarta, setDataCarta] = useState({});
    //funcion para la validacion de datos del acta juramentada
    const actaJuramentada = async () => {
        let signaturePad = null;
        let hoy = moment();
        let minFecha = hoy.format('YYYY') + '-01-01'; // 2023-01-01
        let maxFecha = hoy.format('YYYY-MM-DD'); // 2023-11-30
        
        Swal.fire({
            title: 'Firmar acta juramentada',
            icon: '',
            html: `
            <h6 class="text-start form-label mt-3 mb-3">
            Antes de firmar <a href="https://controlf.blob.core.windows.net/vumoffice/DECLARACION%20JURAMENTADA.pdf" target="_blank"><u>lee aquí acta juramentada.</u></a>
            </h6>
            <form class="row g-3 w-100">
                <div class="text-start col-12 form-group d-flex flex-wrap justify-content-center">
                    <label class="form-label col-12" for="motivo">Motivo:</label>
                    <br/>
                    <input class="form-control col-12 " type="text" id="motivo" name="motivo" required>
                </div>
                <div class="text-start col-12 form-group d-flex flex-wrap justify-content-center">
                    <label class="form-label col-12" for="fecha">Fecha a Requerir:</label>
                    <br/>
                <input class="form-control col-12" type="date" id="fecha" name="fecha" min="${minFecha}" max="${maxFecha}" required>
                </div>
                <div class="col-12 form-group">
                    <label class="form-label" for="firma">Firma:</label>
                    <br/>
                    <canvas style="width: '300px';
                    height: '150px';
                    border: 1px solid #000000;" id="signature_pad" class="canvas"></canvas>
                </div>
            </form>`,
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            cancelButtonColor: '#A6A6A6',
            confirmButtonText: 'Guardar',
            confirmButtonColor: "#3085d6",
        }).then(async (result) => {
            if (result.isConfirmed) {
                //validar campo motivo y firma
                let motivo = document.getElementById('motivo').value;
                let firmaValida = validarFirma(signaturePad.toData());
                let fecha = document.getElementById('fecha').value;
                let errores = "";

                if (signaturePad.isEmpty()) {
                    errores += '◉ Por favor realizar la firma digital <br/>'
                } else if (!firmaValida) {
                    errores += '◉ Por favor ingresar una firma v\u00E1lida <br/>'
                } 
                 if (!motivo){
                    errores += '◉ Por favor ingresar un motivo<br/>';
                } 
                 if (!fecha) {
                    errores += '◉ Por favor ingresar una fecha<br/>';
                 }

                if(errores) {
                    return Swal.fire({
                        title: "Advertencia",
                        html: `
                                <div className="row">
                                    <div className="col-12 col-lg-12" style="text-align: left; font-size: 16px; font-weight: 600; margin-bottom: 15px;">
                                        Revisa el formulario con las siguientes indicaciones:
                                    </div>
                                    <div className="col-12 col-lg-12" style="text-align: left; font-size: 15px;">
                                        ${errores}
                                    </div>
                                </div>
                                 <br/>
                                
                            `,
                        icon: "warning",
                        confirmButtonText: "Cerrar",
                        confirmButtonColor: "#A6A6A6",
                    });
                }
                const imgFirma = signaturePad.toDataURL();
                // Creamos el objeto con los valores obtenidos del swal alert
                setDataCarta({
                    motivo: motivo,
                    firma: String(imgFirma),
                    fecha
                });
                
                Swal.fire({
                    icon: 'success',
                    title: 'Datos guardados para la firma del documento.',
                    confirmButtonText: 'Cerrar',
                    confirmButtonColor: "#A6A6A6",
                });
                
                
            }
        });
        
        if(!signaturePad) {
            const canvas = document.getElementById('signature_pad');
            signaturePad = new SignaturePad(canvas, {
                minWidth: 0.3,
                maxWidth: 1,
                minDistance: 2.5
            });
        }
    }

    //funcion para validar los datos del conflicto de interes

    const conflictoInteres = async () => {
        let signaturePad = null;

        Swal.fire({
            title: 'Firmar conflicto de intereses',
            icon: '',
            html: `
            <h6 class="text-start form-label mt-3 mb-3">
                Antes de firmar <a href="https://controlf.blob.core.windows.net/vumoffice/FO-REC-35%20DECLARACI%C3%93N%20CONFLICTO%20DE%20INTER%C3%89S.pdf" target="_blank"><u>leer aquí DECLARACIÓN DE CONFLICTO DE INTERÉS.</u></a>
            </h6>
            <form class="row g-3 w-100">
                <div class="text-start col-12 form-group">
                    <label class="form-label col-12" for="parientes">¿Tiene parientes en cualquier grado de CONSANGUINIDAD trabajando en una de las empresas cliente, empresa usuaria y/o proveedores?</label>
                    <br/>
                    <select class="form-control" id="opciones1" name="opciones1">
                        <option value="">Seleccionar...</option>
                        <option value="1">Sí</option>
                        <option value="0">No</option>
                    </select>
                </div>
                <div id="divCedula" class="text-start col-12 form-group d-none">
                    <label class="form-label col-12" for="cedula">Cédula del pariente:</label>
                    <br/>
                    <input class="form-control col-12" type="number" id="cedula" name="cedula" required>
                </div>
                <div id="divParentesco" class="text-start col-12 form-group d-none">
                    <label class="form-label col-12" for="parentesco">Parentesco:</label>
                    <br/>
                    <select class="form-control" id="parentesco" name="parentesco">
                        <option value="">Seleccionar...</option>
                        <option value="Padres">Padres</option>
                        <option value="Hijos">Hijos</option>
                        <option value="Hermanos">Hermanos</option>
                        <option value="Sobrinos">Sobrinos</option>
                        <option value="Primos">Primos</option>
                        <option value="Esposo(a)">Esposo(a)</option>
                        <option value="Compañero(a) permanente">Compañero(a) permanente</option>
                        <option value="Suegros">Suegros</option>
                        <option value="Cuñados">Cuñados</option>
                        <option value="Hijo adoptivo">Hijo adoptivo</option>
                        <option value="Padre adoptante">Padre adoptante</option>
                        <option value="Hijo de crianza">Hijo de crianza</option>
                    </select>
                </div>
                <div class="text-start col-12 form-group">
                    <label class="form-label col-12" for="pareja">¿Posee algun vinculo sentimental o en cualquiera de los casos de aquellas relaciones que hayan terminado con alguna persona en las empresas cliente, empresa usuaria y/o proveedores?</label>
                    <br/>
                    <select class="form-control" id="opciones2" name="opciones2">
                        <option value="">Seleccionar...</option>
                        <option value="1">Sí</option>
                        <option value="0">No</option>
                    </select>
                </div>
                <div id="divCedulaP" class="text-start col-12 form-group d-none">
                    <label class="form-label col-12" for="cedulaP">Cédula del pariente:</label>
                    <br/>
                    <input class="form-control col-12" type="number" id="cedulaP" name="cedulaP" required>
                </div>
                <div id="divParentescoP" class="text-start col-12 form-group d-none">
                    <label class="form-label col-12" for="parentescoP">Parentesco:</label>
                    <br/>
                    <select class="form-control" id="parentescoP" name="parentescoP">
                        <option value="">Seleccionar...</option>
                        <option value="Pareja">Pareja</option>
                        <option value="Novio(a)">Novio(a)</option>
                        <option value="Compañero(a)">Compañero(a)</option>
                        <option value="Ex">Ex</option>
                    </select>
                </div>
                <div class="col-12 form-group">
                    <label class="form-label" for="firma">Firma:</label>
                    <br/>
                    <canvas style="width: '300px';
                    height: '150px';
                    border: 1px solid #000000;" id="signature_pad" class="canvas"></canvas>
                </div>
            </form>`,
            didOpen: () => {

                //funcion para CONSANGUINIDAD
                let cedula = document.getElementById("divCedula");
                let parentesco = document.getElementById("divParentesco");
                document.getElementById("opciones1").addEventListener("change", () => { 
                    //Aqui va la funcion de cuando se cambie el select
                    const parientes = document.getElementById('opciones1');
                    if (parientes.value === "1"){
                        cedula.classList.remove("d-none");
                        parentesco.classList.remove("d-none");
                    } else {
                        cedula.classList.add("d-none");
                        parentesco.classList.add("d-none");
                    }

                 }, false);
                 //Funcion para vinculo sentimental
                 //En este las variables llevan la P al final para diferenciar que es la cedula y el parentesco de la Pareja
                let cedulaP = document.getElementById("divCedulaP");
                let parentescoP = document.getElementById("divParentescoP");
                 document.getElementById("opciones2").addEventListener("change", () => { 
                      //Aqui va la funcion de cuando se cambie el select
                      const pareja = document.getElementById('opciones2');
                      if (pareja.value === "1"){
                        cedulaP.classList.remove("d-none");
                        parentescoP.classList.remove("d-none");
                      } else {
                        cedulaP.classList.add("d-none");
                        parentescoP.classList.add("d-none");
                      }
                 }, false);

                 document.getElementById("cedula").addEventListener("blur", (event) => {onBlurInputParentesco(event.target.value);}, false);
                 document.getElementById("cedulaP").addEventListener("blur", (event) => {onBlurInputParentesco(event.target.value);}, false);
                 
            },
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            cancelButtonColor: '#A6A6A6',
            confirmButtonText: 'Guardar',
            confirmButtonColor: "#3085d6",
        }).then(async (result) => {
            if (result.isConfirmed) {
                //aquí validamos los campos del select  si se marco SI en los selects los campos de los selects deben de venir con algun valor
                let opcion1 = document.getElementById('opciones1').value; //Select de CONSANGUINIDAD
                let cedula = document.getElementById("cedula").value; //Cedula pariente CONSANGUINIDAD
                let parentesco = document.getElementById("parentesco").value; //parentesco CONSANGUINIDAD
                let opcion2 = document.getElementById('opciones2').value; //Select de vinculo sentimental
                let cedulaP = document.getElementById("cedulaP").value; //cedula vinculo sentimental
                let parentescoP = document.getElementById("parentescoP").value; //parentesco vinculo sentimental
                let firmaValida = validarFirma(signaturePad.toData());
                let errores = "";

                if (signaturePad.isEmpty()) {
                    errores += '◉ Por favor realizar la firma digital <br/>'
                } else if (!firmaValida) {
                    errores += '◉ Por favor ingresar una firma v\u00E1lida <br/>'
                } 
                 if (opcion1 === "" && opcion2 === ""){
                    errores += '◉ Por validar los campos de repuesta SI y NO, los cuales no pueden estar vacios <br/>';
                } 
                if (opcion1 === "1" && !cedula) {
                    errores += '◉ Por favor validar el campo cedula de CONSAGUINIDAD <br/>';
                } 
                if (opcion1 === "1" && !parentesco) {
                    errores += '◉ Por validar el campo parentesco de CONSAGUINIDAD <br/>';
                }
                if (opcion2 === "1" && !cedulaP) {
                    errores += '◉ Por validar el campo cedula de vinculo sentimental <br/>';
                }
                if (opcion2 === "1" && !parentescoP) {
                    errores += '◉ Por validar el campo parentesco de vinculo sentimental <br/>';
                }

                if(errores) {
                    return Swal.fire({
                        title: "Advertencia",
                        html: `
                                <div className="row">
                                    <div className="col-12 col-lg-12" style="text-align: left; font-size: 16px; font-weight: 600; margin-bottom: 15px;">
                                        Revisa el formulario con las siguientes indicaciones:
                                    </div>
                                    <div className="col-12 col-lg-12" style="text-align: left; font-size: 15px;">
                                        ${errores}
                                    </div>
                                </div>
                                 <br/>
                                
                            `,
                        icon: "warning",
                        confirmButtonText: "Cerrar",
                        confirmButtonColor: "#A6A6A6",
                    });
                }

                const imgFirma = signaturePad.toDataURL();
                // Creamos el objeto con los valores obtenidos del swal alert
                setDataCarta({
                    opcion1: opcion1,
                    cedula: cedula,
                    parentesco: parentesco,
                    opcion2: opcion2,
                    cedulaP: cedulaP,
                    parentescoP: parentescoP,
                    firma: String(imgFirma)
                });
 
                 Swal.fire({
                    icon: 'success',
                    title: 'Datos guardados para la firma del documento.',
                    confirmButtonText: 'Cerrar',
                    confirmButtonColor: "#A6A6A6",
                }); 
            }
        });
        
        if(!signaturePad) {
            const canvas = document.getElementById('signature_pad');
            signaturePad = new SignaturePad(canvas, {
                minWidth: 0.3,
                maxWidth: 1,
                minDistance: 2.5
            });
        }
    } 

    const validarFirma = (trazos) => {
        let valido = false;
        for (const element of trazos) {
            if (element.points.length > 3) {
                valido = true;
                break;
            }
        }
        return valido;
    }

    const validarTituloComponent = (data) => {
        let response = '';

        switch (data) {
            case 'estamosParaTi':
                response = 'Estamos para ti';
                break;
            case 'talkToUs':
                response = 'Talk to us';
                break;
            case 'SolicitudesRRHH':
                response = 'Solicitudes de RRHH';
                break;

            default:
                break;
        }

        return response;
    }

    const [formValue, setFormValue] = useState(formInitialState);
    const onChangeInputHandle = ({ target }) => {
        setFormValue({
            ...formValue,
            [target.name]: target.value
        })
    }

    const onBlurInputParentesco = (cedula) => {
        const cedulaLogged = String(JSON.parse(localStorage.getItem('d_u')).cedula).trim();
        if(cedulaLogged == cedula) return Swal.fire('Acaba de ingresar su propia cédula. Por favor ingresar una válida.');
        
        postFetch({
            url: api.getParentesco, //url o endpoint del backend.
            params: {cedula},
        }).then((response) => {
            if(!response){

                return Swal.fire({
                    title: "Advertencia",
                    html: `
                            <div className="row">
                                <div className="col-12 col-lg-12" style="text-align: left; font-size: 16px; font-weight: 600; margin-bottom: 15px;">
                                    Revisa el formulario con las siguientes indicaciones:
                                </div>
                                <div className="col-12 col-lg-12" style="text-align: left; font-size: 15px;">
                                    ◉ La cédula ingresada <b>${cedula}</b> no existe.
                                </div>
                            </div>
                             <br/>
                            
                        `,
                    icon: "warning",
                    confirmButtonText: "Cerrar",
                    confirmButtonColor: "#A6A6A6",
                });
               
            }

        }); 
    }

    const [checkedButtonMedioRespuesta, setCheckedButtonMedioRespuesta] = useState(null)
    const oncheckedButtonMedioRespuestaHandle = () => {
        let otroMedioRespuesta = document.getElementById("otroMedioRespuesta");
        let checkOtrosMedios = document.getElementById("checkOtrosMedios");


        if (otroMedioRespuesta.checked) {
            setCheckedButtonMedioRespuesta(true);
            checkOtrosMedios.classList.remove("d-none");
            document.getElementById("radioLlamadaTelefonica").disabled = false;
            document.getElementById("radioWpp").disabled = false;
            document.getElementById("divNumeroTelefonico").style.display = 'unset';
        } else {
            setCheckedButtonMedioRespuesta(false);
            setTipoContacto(null);

            setFormValue({
                ...formValue,
                'numeroTelefonico': ''
            });

            checkOtrosMedios.classList.add("d-none");

            document.getElementById("radioLlamadaTelefonica").checked = false;
            document.getElementById("radioWpp").checked = false;

            document.getElementById("divNumeroTelefonico").style.display = 'none';
        }

    }

    const [selectCategorias, setSelectCategorias] = useState({});
    const onChangeSelectHandle = ({ nameSelect, value }) => {

        setSelectCategorias({...selectCategorias, [nameSelect]: value.value});

        const objDataForm = {
            tipoCategoria: Number(value.value),
        };

        if(filesState){
            setFilesState({
           ...filesState,
           files: filesState.files.splice(0, filesState.files.length),
         });
         }

        postFetch({
            url: api.getcategorias, //url o endpoint del backend.
            params: objDataForm,
            }).then((resgetcategorias) => {
                actualizarRowsTable(resgetcategorias);
            }); 

        // aqui se inicializa la tabla vacia
        setRowsTable([]);
        let tablaDocumentos = document.getElementById("tablaDocumentos");
        tablaDocumentos.classList.add("d-none");

        let elementoTiposAuxilios = document.getElementById("TiposAuxilios");
        let elementoTiposCesantias = document.getElementById("TiposCesantias");
        let checkCompraVivienda = document.getElementById("CheckCesantias");
        let actaJuramentada = document.getElementById("actaJuramentada");
        let conflictoInteres = document.getElementById("conflictoInteres");
        let politicaConflictoInteres = document.getElementById("politicaConflictoInteres");
        let politicaAuxilios = document.getElementById("politicaAuxilios");
        let politicaAuxiliosEdu = document.getElementById("politicaAuxiliosEdu");
        let formatoPrestamo = document.getElementById("formatoPrestamo");
        

        
        if (value.value === 898) { //898: Tipos auxilios
            elementoTiposAuxilios.classList.remove("d-none");
            politicaAuxilios.classList.remove("d-none");
            politicaAuxiliosEdu.classList.add("d-none");
            elementoTiposCesantias.classList.add("d-none");
            checkCompraVivienda.classList.add("d-none");
            
        }else if (value.value === 1996 || value.value === 1997) { // 1996: Tipo cesantias, 1997: Cesantias causadas
            loadTiposCesantias(typeForm.typeForm, value.value);

            elementoTiposCesantias.classList.remove("d-none");
            elementoTiposAuxilios.classList.add("d-none");
            politicaAuxilios.classList.add("d-none");
            politicaAuxiliosEdu.classList.add("d-none")

        } else if(value.value === 894){ //tipo de prestamos
            elementoTiposAuxilios.classList.add("d-none");
            politicaAuxilios.classList.add("d-none");
            politicaAuxiliosEdu.classList.add("d-none");
            elementoTiposCesantias.classList.add("d-none");
            checkCompraVivienda.classList.add("d-none");
        } else{ //ninguna
            elementoTiposAuxilios.classList.add("d-none");
            politicaAuxilios.classList.add("d-none");
            politicaAuxiliosEdu.classList.add("d-none")
            elementoTiposCesantias.classList.add("d-none");
            checkCompraVivienda.classList.add("d-none");
        }
        //habilitacion del acta juramentada en retiro de cesantias
        if(value.value === 1996) actaJuramentada.classList.remove("d-none");
        else actaJuramentada.classList.add("d-none");

        //habilitacion del conflicto de interes
        if(value.value === 902) {
            conflictoInteres.classList.remove("d-none");
            politicaConflictoInteres.classList.remove("d-none");
        }
        else {
            conflictoInteres.classList.add("d-none");
            politicaConflictoInteres.classList.add("d-none");
        }

        //mostrar link de descarga del formato
        if (value.value === 894){
            formatoPrestamo.classList.remove("d-none");
        }
        else{
            formatoPrestamo.classList.add("d-none");
        }
        

        setSelectCategorias(value);
    }

    const [selectCesantias, setSelectCesantias] = useState({});
    const onChangeSelectHandleCesantias =  ({ nameSelect, value }) => {


        let checkCompraVivienda = document.getElementById("CheckCesantias");
        
        setSelectCesantias({...selectCesantias, [nameSelect]: value.value});

        const objDataForm = {
            tipoCesantia: Number(value.value),
        };

        if(filesState){
            setFilesState({
           ...filesState,
           files: filesState.files.splice(0, filesState.files.length),
         });
         }

        postFetch({
            url: api.getDocumentsCesantias, //url o endpoint del backend.
            params: objDataForm,
            }).then((resGetDocumentsCesantias) => {
                actualizarRowsTable(resGetDocumentsCesantias);
            }); 

            if (value.value === 2008) {
                Swal.fire({
                    title: 'Terminos y condiciones',
                    icon: '',
                    html: '<div style="text-align:left"> <h5> <b>1.</b> Fotocopia de la Cédula ampliada al 150%. <br> <b>2.</b> Carta aclaratoria donde indique para que se requieren las cesantías, en que fondo se encuentra y cual es el monto a retirar (Valor Cerrado). <br> <b>3.</b> Promesa de compraventa del inmueble autenticada por el vendedor y el comprador. <br> <b>4.</b> Si la vivienda es usada certificado de tradición y libertad no mayor a un mes y fotocopia de la cédula del vendedor. <br> <b>5.</b> Extracto saldo de cesantiasn expedida por el fondo. <br> <b>6.</b> Declaración juramentada. </h5> </div> ',
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: "#A6A6A6",
                }).then((result) => {
                    document.querySelector('[for=CheckCesantias]').innerHTML = "¿Vivienda Usada?"
                    checkCompraVivienda.classList.remove("d-none");
                    document.getElementById("check").checked = false;
                })
    
            }else if (value.value === 2009) {
                Swal.fire({
                    title: 'Terminos y condiciones',
                    icon: '',
                    html:'<div style="text-align:left"> <h5> <b>1.</b> Fotocopia de la cédula ampliada al 150%. <br> <b>2.</b> Carta aclaratoria donde indique para que requiere las cesantías, en que fondo se encuentran y cual es el monto a retirar (Valor cerrado). <br> <b>3.</b> Certificado de tradición y libertad no mayor a un mes. En el caso que la vivienda este a nombre del conyugue, debe adjuntar certificado de matriminoio y/o declaración juramentada de convivencia de notaria. <br> <b>4.</b> Contrato civil de obra original autenticado, por ambas partes. <br> <b>5.</b> Fotocopia de la cédula de la persona que va a la mejora en la vivienda ampliada al 150%. <br> <b>6.</b> Cotización de materiales. <br> <b>7.</b> Extracto saldo de cesantías expedido por el fondo. <br> <b>8.</b> Declaración juramentada. </h5> </div> ',
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: "#A6A6A6",
                }).then((result) => {
                    document.querySelector('[for=CheckCesantias]').innerHTML = "¿Vivienda a nombre del cónyuge?"
                    checkCompraVivienda.classList.remove("d-none");
                    document.getElementById("check").checked = false;
                    
                })
    
            }else if (value.value === 2010) {
                 Swal.fire({
                    title: 'Terminos y condiciones',
                    icon: '',
                    html:'<div style="text-align:left"> <h5> <b>1.</b> Fotocopia de la cédula ampliada al 150%. <br> <b>2.</b> Carta aclaratoria donde indique para que requiere las cesantías, en que fondo se encuentran y cual es el monto a retirar (Valor cerrado). <br> <b>3.</b> Recibo de pago que no pase la fecha límite de pago. <br> <b>4.</b> Certificado de tradición y libertad no mayor a un mes.<br> <b>5.</b> Extracto saldo de cesantías expedidas por el fondo. <br> <b>6.</b> Declaración juramentada <br> </h5> </div> ',
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: "#A6A6A6",
                    }).then((result) => {
                        
                        checkCompraVivienda.classList.add("d-none");
                        document.getElementById("check").checked = false;
                    })
    
            }
    }
    
    const [selectAuxiliosType, setSelectAuxiliosType] = useState({});
    const onChangeSelectHandleAuxilios = ({ nameSelect, value }) => {
    
        setSelectAuxiliosType({...selectAuxiliosType, [nameSelect]: value.value});

        let politicaAuxiliosEdu = document.getElementById("politicaAuxiliosEdu");
        let politicaAuxilios = document.getElementById("politicaAuxilios");

        if(filesState){
            setFilesState({
           ...filesState,
           files: filesState.files.splice(0, filesState.files.length),
         });
         }

        postFetch({
            url: api.getDocumentsRRHH, //url o endpoint del backend.
            params: {tipoAuxilio: Number(value.value)},
            }).then((resGetDocumentsRrhh) => {
                actualizarRowsTable(resGetDocumentsRrhh);
            }); 
            
        if (value.value === 1992) {
            Swal.fire({
                title: 'Terminos y condiciones',
                icon: '',
                html: '<div style="text-align:left"> <h5> <b>1.</b> Aplica únicamente para trabajadores de planta (no familiares). <br> <b>2.</b> Contar con 1 año de antigüedad en la organización (se tendrá en cuenta la antigüedad de contratos anteriores, no aplica contrato de aprendizaje). <br> <b>3.</b> Tener contrato fijo o indefinido. <br> <b>4.</b> Este auxilio se podrá solicitar y disfrutar únicamente 1 vez al año. <br> <b>5.</b> Se debe adjuntar factura y fórmula de la compra de gafas, la cual deberá estar a nombre del trabajador (estos son los documentos). <br> </h5> </div> ',
                confirmButtonText: 'Aceptar',
                confirmButtonColor: "#A6A6A6",

            }).then((result) => {
                politicaAuxiliosEdu.classList.add("d-none");
                politicaAuxilios.classList.remove("d-none")
              
            })

        }else if (value.value === 1993) {
            Swal.fire({
                title: 'Terminos y condiciones',
                icon: '',
                html:'<div style="text-align:left"> <h5> <b>1.</b> Aplica únicamente para trabajadores de planta. <br> <b>2.</b> Aplica para fallecimiento de familiares en primer grado de consanguinidad (padre, madre, hijo (s)), primero de afinidad (únicamente conyugue o compañero permanente) y segundo grado consanguinidad (únicamente hermanos y abuelos). <br> <b>3.</b> Contar con 1 año de antigüedad en la organización (se tendrá en cuenta la antigüedad de contratos anteriores, no aplica contrato de aprendizaje). <br> <b>4.</b> Tener contrato fijo o indefinido. <br> <b>5.</b> Se debe adjuntar acta de defunción, copia de la cédula del trabajador y copia del documento de la persona fallecida para comprobar parentesco (estos son los documentos). <br> </h5> </div> ',
                confirmButtonText: 'Aceptar',
                confirmButtonColor: "#A6A6A6",
            }).then((result) => {
                politicaAuxiliosEdu.classList.add("d-none");
                politicaAuxilios.classList.remove("d-none")
              
            })

        }else if (value.value === 1994) {
             Swal.fire({
                title: 'Terminos y condiciones',
                icon: '',
                html:'<div style="text-align:left"> <h5> <b>1.</b> Aplica únicamente para trabajadores de planta (no familiares). <br> <b>2.</b> Contar con 1 año de antigüedad en la organización (se tendrá en cuenta la antigüedad de contratos anteriores, no aplica contrato de aprendizaje). <br> <b>3.</b> Tener contrato fijo o indefinido. <br> <b>4.</b> Este auxilio se podrá solicitar y disfrutar únicamente 1 vez al año.<br> <b>5.</b> Aplica solo para postgrados (especialización – maestrías). <br> <b>6.</b> Se debe adjuntar orden de pago de matrícula (estos son los documentos). <br> </h5> </div> ',
                confirmButtonText: 'Aceptar',
                confirmButtonColor: "#A6A6A6",
                }).then((result) => {
                    politicaAuxiliosEdu.classList.remove("d-none");
                    politicaAuxilios.classList.add("d-none")
                })

        }else {
            politicaAuxiliosEdu.classList.add("d-none");
            politicaAuxilios.classList.add("d-none")
        }
    
    }
    
    const [filesState, setFilesState] = useState({});
    const onChangeInputFileHandle = ({ tipoArchivo, documento, target, id }) => {
        if (
          target.target.files[0].type === "application/pdf" ||
          target.target.files[0].type === "image/jpeg" ||
          target.target.files[0].type === "image/png" ||
          target.target.files[0].type === "image/jpg"
        ) {
          let buscarArchivo = filesState.files.findIndex((element) => element.idFile === id);
          if(buscarArchivo === -1){
            filesState.files.push({
              idFile: id,
              fileInfo: target.target.files[0],
              tipoDocumento: documento,
              codigoTipoArchivo: tipoArchivo,
            });
          }else{
            filesState.files[buscarArchivo] = {
              idFile: id,
              fileInfo: target.target.files[0],
              tipoDocumento: documento,
              codigoTipoArchivo: tipoArchivo,
            };
          }
          setFilesState({ ...filesState, files: filesState.files });
        } else {
          document.getElementById(target.target.id).value = "";
    
          Swal.fire({
            icon: "error",
            title: "Solo se permiten subir archivos tipo pdf o imagenes",
            confirmButtonText: "Cerrar",
            confirmButtonColor: "#A6A6A6",
          });
        }
      };

    const [rowsTable, setRowsTable] = useState(formValue.rowsDataTable);
    const actualizarRowsTable = (data) => {
      setRowsTable();
      const tablaDocumentos = document.getElementById("tablaDocumentos");
        if(data.length) tablaDocumentos.classList.remove("d-none");
        else if(!data.length) tablaDocumentos.classList.add("d-none");
      
      let rowsDTable = [];
      data.forEach((element, key) => {
      let numberRow = key + 1;  
      rowsDTable.push({
              numero: numberRow,
              documento: element.TIP_NOMBRE.toUpperCase(),
              archivo: (
                  <input
                    onChange={(values) => {
                      onChangeInputFileHandle({
                        tipoArchivo: element.TIP_CODIGO,
                        documento: element.TIP_NOMBRE.toUpperCase(),
                        target: values,
                        id: `file_${numberRow}`
                      });
                    }}
                    key={'filaArchivo'+key}
                    id={`file_${numberRow}`}
                    name={`file_${numberRow}`}
                    className="form-control"
                    type="file"
                    accept="image/*,.pdf"
                   required= {true}
                  />
              ),
            });
            numberRow++;
      });
  
      setRowsTable(rowsDTable);
    };

    const oncheckedButtonChekCesantiasHandle = () => {
        const checkCesantias = document.getElementById("check");
        let elementoTiposCesantias = selectCesantias.tipoCesantia;
        

        let rowsTableTemp =  rowsTable;
        const numberRow = rowsTable.length;

        
         if (elementoTiposCesantias === 2008 && checkCesantias.checked) {

            rowsTableTemp =  [...rowsTable, 
                {
                    numero: numberRow + 1,
                    documento: `CERTIFICADO TRADICIÓN Y LIBERTAD`,
                    tipoArchivo: 2067,
                    archivo: (
                        <input
                        onChange={(values) => {
                            onChangeInputFileHandle({
                            tipoArchivo: 2067,
                            documento: `CERTIFICADO TRADICIÓN Y LIBERTAD`,
                            target: values,
                            });
                        }}
                        key={numberRow}
                        id={`file_${numberRow}`}
                        name={`file_${numberRow}`}
                        className="form-control"
                        type="file"
                        accept="image/*,.pdf"
                       required= {true}
                        />
                    ),
                },
                {
                    numero: numberRow + 2,
                    documento: `CÉDULA VENDEDOR`,
                    tipoArchivo: 2068,
                    archivo: (
                        <input
                        onChange={(values) => {
                            onChangeInputFileHandle({
                            tipoArchivo: 2068,
                            documento: `CÉDULA VENDEDOR`,
                            target: values,
                            });
                        }}
                        key={numberRow + 1}
                        id={`file_${numberRow + 1}`}
                        name={`file_${numberRow + 1}`}
                        className="form-control"
                        type="file"
                        accept="image/*,.pdf"
                        required= {true}
                        />
                    ),
                },
            ];

            setRowsTable(rowsTableTemp);

        } else if (elementoTiposCesantias === 2009 && checkCesantias.checked) {

      
            rowsTableTemp =  [...rowsTable, 
                {
                    numero: numberRow + 1,
                    documento: `CERTI. MATRIMONIO Y/O DECLARACIÓN JURAMENTADA DE CONVIVENCIA DE NOTARIA`,
                    tipoArchivo: 2085,
                    archivo: (
                        <input
                        onChange={(values) => {
                            onChangeInputFileHandle({
                            tipoArchivo: 2085,
                            documento: `CERTI. MATRIMONIO Y/O DECLARACIÓN JURAMENTADA DE CONVIVENCIA DE NOTARIA`,
                            target: values,
                            });
                        }}
                        key={numberRow}
                        id={`file_${numberRow}`}
                        name={`file_${numberRow}`}
                        className="form-control"
                        type="file"
                        accept="image/*,.pdf"
                        required= {true}
                        />
                    ),
                }
            ];
            setRowsTable(rowsTableTemp);

        }else {

            let rowsTableTemp =  rowsTable;
            rowsTableTemp = rowsTableTemp.filter(({tipoArchivo})=> Number(tipoArchivo) !== 2067 && Number(tipoArchivo) !== 2068 && Number(tipoArchivo) !== 2085 );
            setRowsTable(rowsTableTemp);

        }


    }

    const onSubmitFormHandle = (e) => {
        e.preventDefault();
        const errores = validarCampos();
        
        if(errores) {
            Swal.fire({
                title: "Advertencia",
                html: `
                        <div className="row">
                            <div className="col-12 col-lg-12" style="text-align: left; font-size: 16px; font-weight: 600; margin-bottom: 15px;">
                                Revisa el formulario con las siguientes indicaciones:
                            </div>
                            <div className="col-12 col-lg-12" style="text-align: left; font-size: 15px;">
                                ${errores}
                            </div>
                        </div>
                         <br/>
                        
                    `,
                icon: "warning",
                confirmButtonText: "Cerrar",
                confirmButtonColor: "#A6A6A6",
              });
        } else {
            overlay(true);
            enviarFormulario();

        }
    } 


    const   validarCampos = () => {

        let response = "";

        if(!exprRegTexto.test(formValue.descripcion)) {
            response += "◉ La descripción no es válida <br />";
        }
        if(!exprRegEmail.test(formValue.correoEnvioRespuesta)) {
            response += "◉ El email no es válido <br />";
        }

        if (
            (selectCategorias.value === undefined || selectCategorias.value === null) && //Si el campo de categorias es undefined  o null
            typeForm.typeForm !== "talkToUs" //Si el formulario es diferente de talkToUs
        ) {
            response += "◉ Debe seleccionar una categoría <br />";
        } 

        if (selectCategorias.value === 1996){
            if ((dataCarta.motivo === '') || (dataCarta.firma === null || dataCarta.firma === undefined)){
                response += "◉ Debe firmar el acta e ingresar un motivo <br />"
            }
            if ((selectCesantias.tipoCesantia === undefined || selectCesantias.tipoCesantia === null) && 
            typeForm.typeForm !== "talkToUs" 
            && (selectAuxiliosType.tipoAuxilio === undefined || selectAuxiliosType.tipoAuxilio === null)){
                response += "◉ Debe seleccionar un tipo de cesantias <br />"
            }
        }

        if (selectCategorias.value === 902){
            if(dataCarta.opcion1) {
                if(dataCarta.opcion1 === "1" && (!dataCarta.cedula || !dataCarta.parentesco)) {
                    response += "Debe llenar los campos del familiar"
                }
            } else {
                response += "◉ Validar el campo de repuesta SI y NO del familiar.  <br />"
            }
            if(dataCarta.opcion2) {
                if(dataCarta.opcion2 === "1" && (!dataCarta.cedulaP || !dataCarta.parentescoP)) {
                    response += "Debe llenar los campos de la pareja"
                }
            } else {
                response += "◉ Validar el campo de repuesta SI y NO de la pareja.  <br />"
            }
            if (!dataCarta.firma){
                response += "◉ Debe de ingresar una firma para el conflicto de interés. <br />"
            }
        }

        if ( selectCategorias.value === 898 &&
            (selectAuxiliosType.tipoAuxilio === undefined || selectAuxiliosType.tipoAuxilio === null) && //Si el campo de tipos de auxilio es undefined  o null
            typeForm.typeForm !== "talkToUs" && (selectCesantias.tipoCesantia === undefined || selectCesantias.tipoCesantia === null) //Si el formulario es diferente de talkToUs
        ) {
            response += "◉ Debe seleccionar un tipo de auxilio <br />"
        } 

        if (checkedButtonMedioRespuesta && tipoContacto === null) { //Si Otro medio de respuesta esta checkeado y no se ha seleccionado el tipo de contacto
            response += "◉ Debe seleccionar un tipo de contacto <br />"
        } else if (checkedButtonMedioRespuesta && tipoContacto !== null) { //Si Otro medio de respuesta esta checkeado y se ha seleccionado el tipo de contacto
            if (!exprRegTelefono.test(formValue.numeroTelefonico)) {
                response += "◉ El número celular ingresado es inválido. <br />"
            }
        }

        return response;

    }

    const enviarFormulario = () => {
        let allData = {
            dataForm: {
                formulario: String(typeForm.typeForm),
                categoria: Number(selectCategorias.value),
                descripcion: String(formValue.descripcion).replace("'", ""),
                correoEnvioRespuesta: String(formValue.correoEnvioRespuesta),
                otroMedioRespuesta: Boolean(checkedButtonMedioRespuesta),
                numeroTelefonico: Number(formValue.numeroTelefonico),
                tipoContacto: String(tipoContacto),
                tipoCesantia: Number(selectCesantias.tipoCesantia),
                tipoAuxilio: Number(selectAuxiliosType.tipoAuxilio),
                checkCesantias: document.getElementById("check").checked
            
            },
            dataUser: {
                cedula: String(JSON.parse(localStorage.getItem('d_u')).cedula).trim(),
                nombres: String(JSON.parse(localStorage.getItem('d_u')).nombres).trim(),
                apellidos: String(JSON.parse(localStorage.getItem('d_u')).apellidos).trim(),
                empresa: String(JSON.parse(localStorage.getItem('d_u')).empresa).trim(),
                cCostos: String(JSON.parse(localStorage.getItem('d_u')).ccostos).trim(),
                numeroCelular: String(JSON.parse(localStorage.getItem('d_u')).numeroCelular).trim(),
                // ciudad: String(JSON.parse(localStorage.getItem('d_u')).ciudad).trim(),
                // nomEmpresa: String(JSON.parse(localStorage.getItem('d_u')).nomEmpresa).trim(),
            },
        };

        if(dataCarta.firma) allData.dataCarta = dataCarta;
        const dataForm = new FormData();
        dataForm.append("allData", JSON.stringify(allData));
        
        
        filesState.files.forEach((file) => {
            dataForm.append("file", file.fileInfo);
            dataForm.append("filesNames", file.tipoDocumento);
            dataForm.append("filesCodigos", file.codigoTipoArchivo);
        });

        postFetch({
            url: api.postSaveFormRRHH,
            params:     dataForm
        })
            .then((res) => {
                if(res.urlActa) {
                    window.open(res.urlActa, '_blank');
                }
                Swal.fire({
                    icon: 'success',
                    title: 'Tu solicitud ha sido recibida con éxito, será validada por parte de RRHH. Pronto recibirás una respuesta a tu correo electrónico.',
                    confirmButtonText: 'Cerrar',
                    confirmButtonColor: "#A6A6A6",
                }).then((result) => {
                    window.location.reload();
                })
            })
            .catch(() => {

                Swal.fire({
                    icon: 'error',
                    title: 'Hubo un error en la inserción, por favor revisa el formulario.',
                    confirmButtonText: 'Cerrar',
                    confirmButtonColor: "#A6A6A6",
                });
            });


    }


    const [tipoContacto, setTipoContacto] = useState(null);
    const onClickButtonRespuestaHandle = ({ target }) => {

        let inputRadio = document.querySelector("#" + target.id);

        if (inputRadio.checked && target.dataset.target === "wpp") {
            setTipoContacto("wpp");
        } else if (inputRadio.checked && target.dataset.target === "llamada") {
            setTipoContacto("llamada");
        } else {
            setTipoContacto(null);
        }

    }

    const [optionsCategory, setOptionsCategory] = useState({});
    const loadCategories = (typeForm) => {

        if (typeForm !== 'talkToUs') {

            let urlApi = api.getresourceRequestCategory;

            if(typeForm === 'estamosParaTi') urlApi = api.getForYouCategory;

            postFetch({
                url: urlApi,
                params: {cedula: String(JSON.parse(localStorage.getItem('d_u')).cedula).trim()}
            })
                .then((responseGetFetchWithHeader) => {

                    let optionsCategory = [{ value: null, label: "SELECCIONE..." }];

                    if (responseGetFetchWithHeader?.data) {

                        responseGetFetchWithHeader.data.forEach(element => {
                            optionsCategory.push({ value: element.codigo, label: element.nombre.toUpperCase() })
                        });
                    }

                    setOptionsCategory(optionsCategory);

                });

        }
    }

    const [optionsTiposAuxilios, setOptionsTiposAuxilios] = useState({});
    const loadTiposAuxilios = (typeForm) => {

        if (typeForm !== 'talkToUs') {

            let urlApi = api.getForYouTiposAuxilios;

            postFetch({
                url: urlApi,
                params: {cedula: String(JSON.parse(localStorage.getItem('d_u')).cedula).trim()}
            })
                .then((responseGetFetchWithHeader) => {

                    let optionsAuxilios = [{ value: null, label: "SELECCIONE..." }];

                    if (responseGetFetchWithHeader?.data) {

                        responseGetFetchWithHeader.data.forEach(element => {
                            optionsAuxilios.push({ value: element.codigo, label: element.nombre.toUpperCase() })
                        });
                    }

                    setOptionsTiposAuxilios(optionsAuxilios);

                });

        }
    }


    const [optionsTiposCesantias, setOptionsTiposCesantias] = useState({});
    const loadTiposCesantias = (typeForm, categoria) => {

        if (typeForm !== 'talkToUs') {

            let urlApi = null;

            const categoriaCesantias = Number(categoria);


            if(typeForm === 'estamosParaTi') {
                if (categoriaCesantias === 1996 ) urlApi = api.getForYouTiposCesantias;
                else urlApi = api.getForYouTiposCesantias2;
            }
            
            overlay(true);

            postFetch({
                url: urlApi,
                params: {cedula: String(JSON.parse(localStorage.getItem('d_u')).cedula).trim()}
            })
                .then((responseGetFetchWithHeader) => {

                    let optionsCesantias = [{ value: null, label: "SELECCIONE..." }];

                    if (responseGetFetchWithHeader?.data) {

                        responseGetFetchWithHeader.data.forEach(element => {
                            optionsCesantias.push({ value: element.codigo, label: element.nombre.toUpperCase() })
                        });
                    }

                    setOptionsTiposCesantias(optionsCesantias);
                    overlay(false);

                });

        }
    }

    return ({
        formValue,
        stateTitle,
        optionsCategory,
        optionsTiposAuxilios,
        optionsTiposCesantias,
        rowsTable,
        onChangeInputHandle,
        oncheckedButtonMedioRespuestaHandle,
        oncheckedButtonChekCesantiasHandle,
        onChangeSelectHandle,
        onChangeSelectHandleAuxilios,
        onChangeSelectHandleCesantias,
        onSubmitFormHandle,
        actaJuramentada,
        conflictoInteres, 
        onClickButtonRespuestaHandle,
    });


}
