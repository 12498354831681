import axios from "axios";
import Swal from "sweetalert2";
import { baseUrl } from "../../config/config";

    async function postData(url = '', data = {}) {  
        const response = await fetch(url, {
        method: 'POST', 
        mode: 'cors', 
        cache: 'no-cache',
        credentials: 'same-origin', 
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(data)
      }).then(res => {
        if(res.status >= 400) throw new Error("Error al realizar la petición");
        return res
      });
      return response.json();
    }
    

    async function getData(url = '') {  
        const response = await fetch(url, {
          method: 'GET', 
          mode: 'cors', 
          cache: 'no-cache',
          credentials: 'same-origin', 
          headers: {
            'Content-Type': 'application/json'
          },
          redirect: 'follow',
          referrerPolicy: 'no-referrer'
        });
        return response.json();
      }


     function simulateClick(id,tiempo,evento) {
        let event = new Event(evento, {bubbles: true});
        let elem = document.getElementById(id); 
        setTimeout(() =>{           
            elem.dispatchEvent(event);
        },tiempo)
      }



    function putInputRequerid(identificador,tipo,accion,label){
      const campo =  document.querySelector(identificador)
      const labelCampo = document.querySelector(`label[for=${label}]`)
      if(labelCampo.getElementsByTagName('span').length){
        labelCampo.getElementsByTagName('span')[0].remove()
      }
      switch (campo.type) {
            case 'text':
            case 'number':
            case 'date':
                   if(accion === 'add'){
                       campo.classList.add('inputRequired')
                       campo.removeAttribute('readOnly')
                       labelCampo.innerHTML += '<span class="text-danger">*</span>'
                   }else  if(accion === 'remove'){
                       campo.classList.remove('inputRequired')
                       campo.setAttribute('readOnly','readOnly')
                       campo.value= ''
                   }
            break;

               case 'select-one':
                if(accion === 'add'){
                    campo.classList.add('inputRequired')
                    // labelCampo.classList.add('text-danger')
                    campo.removeAttribute('disabled')
                    labelCampo.innerHTML += '<span class="text-danger">*</span>'
                }else  if(accion === 'remove'){
                    campo.classList.remove('inputRequired')
                    campo.setAttribute('disabled','disabled')
                    campo.value= ''
                    
                }

                break;

               case 'radio':
                if(accion === 'add'){
                    campo.classList.add('inputRequired')
                    labelCampo.innerHTML += '<span class="text-danger">*</span>'
                }else  if(accion === 'remove'){
                    campo.classList.remove('inputRequired')
                    campo.value= ''
                }

               break;
      
          default:
              break;
      }
      
   }

    function validateInputTabs(tabidentificador){
    let labelValidate = ''
    document.querySelectorAll('.active .inputRequired').forEach(element =>{
        const type = element.type
        const val = element.value.trim()
        const id = element.id
        let label =''
        const name = element.name
        
        if(type === 'radio'){
            label = document.querySelector(`label[for=${name}]`)
        }else{
            label = document.querySelector(`label[for=${id}]`)
        }
        let labelast = label.textContent.substring(0, label.textContent.lastIndexOf("*"));

        switch (type) {
            case 'text':
            case 'select-one':
            case 'date':
            case 'file':
            case 'password':
            case 'email':
                if(val === ''){
                    labelValidate += `<li class="list-group-item border-0">${labelast }</li>`
                }
            break;
            case 'number':
                if(!val){
                    labelValidate += `<li class="list-group-item border-0">${labelast }</li>`
                }
            break;
            case 'select-multiple':
            
            break;

            case 'radio': // Pendiente de optimización porque dice que un "let" no debería ir en un case
              let vali =  document.querySelector(`input[name=${name}]:checked`)? document.querySelector(`input[name=${name}]:checked`).value.trim():''
              if(vali === ''){
                labelValidate += `<li class="list-group-item border-0">${labelast }</li>`
              }
            break;
        
            default:
                break;
            }
        })


        if(labelValidate){
            let list = `<ul class="list-group list-group-flush inline-flex">
                            ${labelValidate}
                        </ul>`
                Swal.fire({
                    title: "<h5 class='text-primary'>Validar las siguientes preguntas</h5>",
                    html: list,
                    showCancelButton: false,
                    showConfirmButton: true,
                    confirmButtonColor: "#A6A6A6",
                    confirmButtonText: 'Cerrar'
                });
            
        }

        return labelValidate;

    }

    function loadDataValidate(){
        const url = `${baseUrl}/v1/menuOV/formulariosCompletados`;
        const dataUser = JSON.parse( localStorage.getItem("d_u"));
        const cedula = parseInt(dataUser['cedula']);
        const empresa = parseInt(dataUser['empresa']);
        const datos = { NRO_DOCUMENTO:cedula,CODIGO_EMPRESA:empresa };

        postData(url,datos).then(res =>{
            if(res.error) return;
            
            if(res.datbas_codigo) {
                document.getElementById('colorCheck1').classList.remove('checkGrey');
                document.getElementById('colorCheck1').classList.add('checkGreen');
            }
            if(res.edu_codigo) {
                document.getElementById('colorCheck2').classList.remove('checkGrey');
                document.getElementById('colorCheck2').classList.add('checkGreen');
            }
            if(res.fami_codigo) {
                document.getElementById('colorCheck3').classList.remove('checkGrey');
                document.getElementById('colorCheck3').classList.add('checkGreen');
            }
            if(res.datad_codigo) {
                document.getElementById('colorCheck4').classList.remove('checkGrey');
                document.getElementById('colorCheck4').classList.add('checkGreen');
            }
            if(res.salud_codigo) {
                document.getElementById('colorCheck5').classList.remove('checkGrey');
                document.getElementById('colorCheck5').classList.add('checkGreen');
            }
            if(res.vivi_codigo) {
                document.getElementById('colorCheck6').classList.remove('checkGrey');
                document.getElementById('colorCheck6').classList.add('checkGreen');
            }

            // Si la persona no ha completado "datos básicos" lo redirigimos allá a la fuerza para que lo haga.
            let urlActual = window.location.href.split('/')[4];
            if(urlActual !== 'datos_b%C3%A1sicos' && !res.datbas_codigo) {
                Swal.fire({
                    title: 'No puedes llenar este formulario',
                    text: "Para continuar con el proceso primero debes llenar todos los “datos básicos”.",
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: "#1783EE",
                    confirmButtonText: 'Ir a "datos básicos"',
                    willClose: () => {
                        window.location.href = '/cv/datos_básicos';
                    }
                });
            }
        })

      }

    async  function getFileFromAzure(url){
        axios({
            method: 'get',
            url: url, // blob url eg. blob:http://127.0.0.1:8000/e89c5d87-a634-4540-974c-30dc476825cc
            responseType: 'blob',
            headers: {
                'Content-Type': 'application/file'
              },
        }).then(function(response){
             let reader = new FileReader();
             reader.readAsDataURL(response.data); 
             reader.onloadend = function() {
                 let base64data = reader.result;
                 return base64data
             }
    
        })

      }

      async function getIp(){
        const response = await fetch('https://api.ipify.org/?format=json');
        const data = await response.json();
        if(data){
            return data.ip
        }
      }

      async function obtenerInformacionDeNavegacion(){
        const response = await fetch('https://api.ipify.org/?format=json');
        const data = await response.json();
        if(data){
            return {
                otros_datos:data,
                fecha:formatDate(new Date()),
                dispositivo:navigator.userAgent,
            }
        };
      };


      const formatDate = (date)=>{

        let mes=date.getMonth()+1;
    
        let dia=date.getDate();
    
        let hora = date.getHours();
    
        let minutos=date.getMinutes();
    
        let second=date.getSeconds();
    
        if(mes<10){
            mes=`0${mes}`;
        }
    
        if(dia<10){
            dia=`0${dia}`;
        }
    
    
        if(hora<10){
            hora=`0${hora}`;
        }
    
        if(minutos<10){
            minutos=`0${minutos}`;
        }
    
        if(second<10){
            second=`0${second}`;
        }
    
        let formatted_date = date.getFullYear() + "-" + mes + "-" + dia + " " + hora + ":" + minutos + ":" + second;
         return formatted_date;
      };

    const loadingDialog = (show = false) => {
        if (show === true) {
            Swal.fire({
            title: "<h3>Cargando...</h3>",
            html: "<div class='spinner-border text-primary' style='width: 3rem; height: 3rem; margin: 10px; margin-top: unset;' role='status'><span class='sr-only'>Loading...</span></div>",
            showCancelButton: false,
            showConfirmButton: false,
            });
        } else if (show === false) {
            Swal.close();
        }
    };

        
    export {postData,getData,simulateClick,putInputRequerid,validateInputTabs,loadDataValidate,getFileFromAzure,getIp, obtenerInformacionDeNavegacion, formatDate, loadingDialog};