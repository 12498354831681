import {Component,Fragment} from 'react';
import { baseUrl } from '../../config/config';
import { postData, simulateClick, validateInputTabs, loadDataValidate, putInputRequerid, loadingDialog } from '../../components/general/General';
import moment from 'moment';
import Swal from 'sweetalert2';
import '../../cssGeneral/general.css';
class Family extends Component{
    constructor(props){
        super(props)
        this.state = {
            breadcrumb: 'Condiciones familiares',
            tablaHijos: '',
            estadonav: true,
            disableActiHijos: true,
            datbas: null,
            famiCodigo: null,
            optionsCantPersonas: '',
            optionsIngresoPromedio: '',
            optionsOrigenIngresos: '',
            optionsEstadosEstudio: '',
            optionsModalidadesEstudio: '',
            optionsNivelesEstudio: '',
            optionsTipoDocumento: '',
            hideHijos: 'hidden',
            disableEducacion: false,
            hijoCodigo: null
        }
    }

    componentDidMount(){
        document.getElementById('root').className = 'cv';
        this.consultaInicial();
    }

    consultaInicial = () => {
        const dataUser = JSON.parse(localStorage.getItem("d_u"));
        const cedula = Number(dataUser['cedula']);
        const empresa = Number(dataUser['empresa']);
        const url = `${baseUrl}/v1/familiar/consultaInicial`;

        loadingDialog(true)
        postData(url, {cedula, empresa}).then(async (response) => {
            loadingDialog(false);
            this.setState({datbas: response.datbas});
            await this.asignarValoresSelects(response);
            if(response.condicionesFamiliares.length) this.loadDataPrincipal(response.condicionesFamiliares[0]);
            if(response.hijosRegistrados.length) this.cargarTablaHijos(response.hijosRegistrados);
        }).catch(err => {
            loadingDialog(false);
            Swal.fire({
                icon: 'warning',
                title: '¡Ups!',
                text: 'Ha ocurrido un error al cargar el formulario, intenta nuevamente.',
                confirmButtonColor: "#A6A6A6",
                confirmButtonText: 'Cerrar'
            })
        });
    }

    // En esta función insertamos todos los valores de los select
    asignarValoresSelects = async (result) => {
        const cantPersonas = result.cantPersonas.map((value,x) => <option  key={'optPers'+x} value={value["TIP_CODIGO"]}>{value["TIP_NOMBRE"]}</option>);
        const ingresoPromedio = result.ingresoPromedio.map((value,x) => <option  key={'optIngre'+x} value={value["TIP_CODIGO"]}>{value["TIP_NOMBRE"]}</option>);
        const origenIngresos = result.origenIngresos.map((value,x) => <option  key={'optOrgIngr'+x} value={value["TIP_CODIGO"]}>{value["TIP_NOMBRE"]}</option>);
        const tiposDocumento = result.tiposDocumento.map((value,x) => <option  key={'optTipDocu'+x} value={value["TIP_CODIGO"]}>{value["TIP_NOMBRE"]}</option>);
        const estadosEstudio = result.estadosEstudio.map((value,x) => <option  key={'optEstEstu'+x} value={value["TIP_CODIGO"]}>{value["TIP_NOMBRE"]}</option>);
        const modalidades = result.modalidadesEstudio.map((value,x) => <option  key={'optModa'+x} value={value["TIP_CODIGO"]}>{value["TIP_NOMBRE"]}</option>);
        const nivelesEstudio = result.nivelesEstudio.map((value,x) => <option  key={'optNivEstu'+x} value={value["TIP_CODIGO"]}>{value["TIP_NOMBRE"]}</option>);

        // Se retorna un promise.all para que todos los inputs se coloquen antes de setear los valores en la función loadDataPrincipal
        return await Promise.all([cantPersonas, ingresoPromedio, origenIngresos, tiposDocumento, estadosEstudio, modalidades, nivelesEstudio])
        .then(res => {
            this.setState({
                optionsCantPersonas: res[0],
                optionsIngresoPromedio: res[1],
                optionsOrigenIngresos: res[2],
                optionsTipoDocumento: res[3],
                optionsEstadosEstudio: res[4],
                optionsModalidadesEstudio: res[5],
                optionsNivelesEstudio: res[6],
            });
            return true;
        });
    }
    
    loadDataPrincipal = (data) =>{
        this.setState({famiCodigo: data.famiCodigo});
        this.dependen.value = data.dependen;
        this.perVive.value = data.viven;
        this.ingresoPromedio.value = data.ingresoPromedio;
        this.ingresoOrigen.value = data.ingresoOrigen;
        if(data.tieneHijos===1) {
            document.getElementById('radioHijos').checked=true;
            if(data.actiHijos===1) {
                this.handleHijosGeneral(true, true);
                document.getElementById('radioActiHijos').checked=true;
                this.actiEmpresa.value = data.actiEmpresa;
            } else {
                this.handleHijosGeneral(true, false);
                document.getElementById('radioActiHijos2').checked=true
            }
            this.mostrarTabHijos(true, false);
        } else {
            document.getElementById('radioHijos2').checked=true;
        }

    }

    cargarTablaHijos = (hijos) => {
        let filas = '';
        if(hijos.length) {
            filas = hijos.map((hijo,x) => {
                return <Fragment key={'filaHijos'+x}>
                    <tr>
                        <td className="text-nowrap">{hijo.documento}</td>
                        <td className="text-nowrap">{hijo.nombres} {hijo.apellidos}</td>
                        <td className="text-nowrap">{hijo.nacimiento}</td>
                        <td className="text-center">
                            <button className="btn"  onClick={() => this.llenarInputsHijo(hijo)}><i className="fas fa-pen-square colorSquare"></i> </button> 
                            <button className="btn"  onClick={() => this.eliminarHijo(hijo)}><i className="fas fa-trash colorTrash"></i> </button>
                        </td>
                    </tr>
                </Fragment>
            });
        }

        const tabla =
            <table className="table table-hover table-striped table-bordered text-center">
                <thead>
                    <tr>
                        <th>Identificación</th>
                        <th>Nombres</th>
                        <th>Nacimiento</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {filas}
                </tbody>

            </table>
        this.setState({tablaHijos:tabla});
    }

    calculaEdad = (fecha,fecha_nac) => {
        let a = moment.utc(fecha);
        let b = moment.utc(fecha_nac);
        let years = a.diff(b, 'year');
        b.add(years, 'years');
        this.edad.value = years + ' años';
    }

    handleHijosGeneral = (tiene, quiereActividades) => {
        let radiosActiHijos = document.querySelectorAll('input[name=actiHijos]');
        let labelActiHijos = document.querySelector('label[for=actiHijos]');

        if(labelActiHijos.getElementsByTagName('span').length) labelActiHijos.getElementsByTagName('span')[0].remove();

        if(tiene) {
            labelActiHijos.innerHTML += '<span class="text-danger">*</span>';
            radiosActiHijos[0].classList.add('inputRequired');
            
            radiosActiHijos.forEach(radio => radio.removeAttribute('disabled'));
            if(quiereActividades) putInputRequerid('#actiEmpresa', '', 'add', 'actiEmpresa');
            else putInputRequerid('#actiEmpresa', '', 'remove', 'actiEmpresa');
            this.setState({disableActiHijos: false});
        } else {
            radiosActiHijos[0].classList.remove('inputRequired');
            putInputRequerid('#actiEmpresa', '', 'remove', 'actiEmpresa');
            radiosActiHijos.forEach(radio => radio.setAttribute('disabled', true));
            this.setState({disableActiHijos: true})
        }
        if(quiereActividades===undefined) radiosActiHijos.forEach(radio => radio.checked = false);
    }

    mostrarTabHijos = (habilitarTab, cambiarTab) => {
        const checkHijos = Number(document.querySelector('input[name=tieneHijos]').checked);

        if(checkHijos && habilitarTab) {
            this.setState({estadonav: false});
            if(cambiarTab) simulateClick('hijos-tab', 0, 'click')
        }
        else this.setState({estadonav: true});
    }

    guardarCondicionesFamiliares = () => {
        if(validateInputTabs()) return;

        const dataUser = JSON.parse(localStorage.getItem("d_u"));
        const cedula = Number(dataUser['cedula']);
        const empresa = Number(dataUser['empresa']);
        const usuLogin = dataUser.USU_LOGIN;
        const tieneHijos = Number(document.querySelector('input[name=tieneHijos]').checked);
        const url = `${baseUrl}/v1/familiar/guardarCondicionesFamiliares`;

        const datos = {
            cedula,
            empresa,
            usuLogin,
            datbas: this.state.datbas,
            famiCodigo: this.state.famiCodigo || null,
            dependen: Number(this.dependen.value),
            perVive: Number(this.perVive.value),
            ingresoPromedio: Number(this.ingresoPromedio.value),
            ingresoOrigen: Number(this.ingresoOrigen.value),
            tieneHijos,
            actiHijos: tieneHijos ? Number(document.querySelector('input[name=actiHijos]').checked) : null,
            actiEmpresa: tieneHijos ? this.actiEmpresa.value.trim() : null,
        }

        loadingDialog(true)
        postData(url, datos).then(async (result) => {
            loadingDialog(false);
            if (result.error) {
                return Swal.fire({
                    icon: 'warning',
                    title: '¡Ups!',
                    text: result.error,
                    confirmButtonColor: "#A6A6A6",
                    confirmButtonText: 'Cerrar'
                });
            }
            if(tieneHijos) {
                this.mostrarTabHijos(true, true);
                Swal.fire({
                    title: '',
                    html: `
                    <div class="text-center">
                        <img src="https://controlf.blob.core.windows.net/public/vumoffice/assets/
cv/check.png" alt="check" style="width:60px">
                        <h3 class="text-primary mt-3">¡Los datos de tus CONDICIONES FAMILIARES han sido guardados con éxito!</h3>
                        <h5 class="text-secondary">Te garantizamos un buen uso de tu información.</h5>
                    </div>`,
                    confirmButtonColor: "#1783EE",
                    confirmButtonText: 'Registrar hijos'
                });
            }
            else {
                this.mostrarTabHijos(false, false);
                Swal.fire({
                    title: '',
                    html: `
                    <div class="text-center">
                        <img src="https://controlf.blob.core.windows.net/public/vumoffice/assets/
cv/check.png" alt="check" style="width:60px">
                        <h3 class="text-primary mt-3">¡Los datos de tus CONDICIONES FAMILIARES han sido guardados con éxito!</h3>
                        <h5 class="text-secondary">Te garantizamos un buen uso de tu información.</h5>
                        <a type="button" class="btn succesButton mt-3" href="/cv/datos_adicionales">Continuar llenando "Datos adicionales"</a>
                    </div>`,
                    showCloseButton: false,
                    showCancelButton: false,
                    showConfirmButton: false
                });
            }
            this.setState({famiCodigo: result});
            loadDataValidate();
        }).catch(err => {
            loadingDialog(false);
            Swal.fire({
                icon: 'warning',
                title: '¡Ups!',
                text: 'Ha ocurrido un error al cargar el formulario, intenta nuevamente.',
                confirmButtonColor: "#A6A6A6",
                confirmButtonText: 'Cerrar'
            })
        });
    }

    mostrarFormHijo = (mostrar) => {
        this.limpiarFormHijo();
        if(mostrar) this.setState({hideHijos:''});
        else this.setState({hideHijos:'hidden'});
    }

    handleEscolaridadHijo = () => {
        if(this.nivelEstudio.value==='1490') {
            putInputRequerid('#estadoEstudios', '', 'remove', 'estadoEstudios');
            putInputRequerid('#institucion', '', 'remove', 'institucion');
            this.setState({disableEducacion: true});
            this.programaAcademico.value = '';
            this.modalidad.value = '';
        } else {
            putInputRequerid('#estadoEstudios', '', 'add', 'estadoEstudios');
            putInputRequerid('#institucion', '', 'add', 'institucion');
            this.setState({disableEducacion: false});
        }
    }

    llenarInputsHijo = (hijo) => {
        this.mostrarFormHijo(true);
        this.setState({hijoCodigo: hijo.hijoCodigo});
        this.nombres.value = hijo.nombres;
        this.apellidos.value = hijo.apellidos;
        hijo.sexo==='M' ? document.getElementById('radioSexo').checked=true : document.getElementById('radioSexo2').checked=true;
        this.tipoDocumento.value = hijo.tipoDocumento;
        this.identificacion.value = hijo.documento;
        this.nacimiento.value = hijo.nacimiento;
        this.calculaEdad(moment().utc(),moment.utc(hijo.nacimiento));
        this.nivelEstudio.value = hijo.nivelEstudio;
        this.estadoEstudios.value = hijo.estadoEstudio || '';
        this.handleEscolaridadHijo();
        this.institucion.value = hijo.institucion || '';
        this.programaAcademico.value = hijo.programa || '';
        this.modalidad.value = hijo.modalidad || '';
    }

    limpiarFormHijo = () => {
        this.setState({hijoCodigo: null});
        this.nombres.value = '';
        this.apellidos.value = '';
        document.querySelectorAll('input[name=sexo]').forEach(radio => radio.checked=false)
        this.tipoDocumento.value = '';
        this.identificacion.value = '';
        this.nacimiento.value = '';
        this.edad.value = '0 años'
        this.nivelEstudio.value = '';
        this.estadoEstudios.value = '';
        this.institucion.value = '';
        this.programaAcademico.value = '';
        this.modalidad.value = '';
    }

    guardarHijo = () => {
        if(validateInputTabs()) return;

        const dataUser = JSON.parse(localStorage.getItem("d_u"));
        const usuLogin = dataUser.USU_LOGIN;
        const url = `${baseUrl}/v1/familiar/guardarHijo`;

        const datos = {
            usuLogin,
            datbas: this.state.datbas,
            famiCodigo: this.state.famiCodigo,
            hijoCodigo: this.state.hijoCodigo,
            nombres: this.nombres.value,
            apellidos: this.apellidos.value,
            sexo: null, // Este lo definimos más abajo
            tipoDocumento: Number(this.tipoDocumento.value),
            identificacion: this.identificacion.value,
            nacimiento: this.nacimiento.value,
            nivelEstudio: Number(this.nivelEstudio.value),
            estadoEstudios: this.estadoEstudios.value ? Number(this.estadoEstudios.value) : null,
            institucion: this.institucion.value || null,
            programaAcademico: this.programaAcademico.value || null,
            modalidad: this.modalidad.value ? Number(this.modalidad.value) : null,
        }

        // Con esto ponemos el sexo que fue checkeado porque el value son letras, entonces no podemos hacerlo normal
        document.querySelectorAll('input[name=sexo]').forEach(radio => radio.checked?datos.sexo=radio.value:'');

        loadingDialog(true)
        postData(url, datos).then(async (result) => {
            loadingDialog(false);
            if (result.error) {
                return Swal.fire({
                    icon: 'warning',
                    title: '¡Ups!',
                    text: result.error,
                    confirmButtonColor: "#A6A6A6",
                    confirmButtonText: 'Cerrar'
                });
            }
            Swal.fire('','Hijo guardado con éxito','success');
            this.mostrarFormHijo(false);
            this.cargarTablaHijos(result);
        }).catch(err => {
            loadingDialog(false);
            Swal.fire({
                icon: 'warning',
                title: '¡Ups!',
                text: 'Ha ocurrido un error al guardar los datos. Intente nuevamente.',
                confirmButtonColor: "#A6A6A6",
                confirmButtonText: 'Cerrar'
            })
        });
    }

    eliminarHijo = (hijo) => {
        const dataUser = JSON.parse(localStorage.getItem("d_u"));
        const usuLogin = dataUser.USU_LOGIN;
        const url = `${baseUrl}/v1/familiar/eliminarHijo`;

        Swal.fire({
            title: '',
            text: `¿Desea eliminar a ${hijo.nombres}?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: "#1783EE",
            cancelButtonColor: "#A6A6A6",
            confirmButtonText: '¡Eliminar!',
            cancelButtonText:'Cerrar'
        }).then((result) => {
            if (result.isConfirmed) {
                const datos = {hijoCodigo: hijo.hijoCodigo, usuLogin, datbas:this.state.datbas};
                postData(url,datos).then(result => {
                    if (result.error) {
                        return Swal.fire({
                            icon: 'warning',
                            title: '¡Ups!',
                            text: result.error,
                            confirmButtonColor: "#A6A6A6",
                            confirmButtonText: 'Cerrar'
                        });
                    }
                    Swal.fire('','Hijo eliminado con éxito','success');
                    if(result.length) this.cargarTablaHijos(result);
                    else this.setState({tablaHijos: ''});
                }).catch(err => {
                    loadingDialog(false);
                    Swal.fire({
                        icon: 'warning',
                        title: '¡Ups!',
                        text: `Ha ocurrido un error al eliminar a ${hijo.nombres} los datos. Intente nuevamente.`,
                        confirmButtonColor: "#A6A6A6",
                        confirmButtonText: 'Cerrar'
                    })
                });
            }
        });
    }


    updateLoadPrincipal = (data,num) => { 
        if(num === 1){
            simulateClick('identity-tab',0,'click')
            this.campos1.value = data.NOM_FAMI
            this.campos2.value = data.APE_FAMI
            if(data.SEX_FAMI === 'F'){
                document.getElementById('option1').checked = true
            }else if(data.SEX_FAMI === 'M'){
                document.getElementById('option2').checked = true
            }

            this.campos3.value = data.TIP_IDEN
            this.campos4.value = data.COD_FAMI
            this.campos4.setAttribute("readonly",'readonly')
            this.campos5.value = moment.utc(data.FEC_NACI).format('yyyy-MM-DD')

            this.calculaEdad(moment.utc(),moment.utc(data.FEC_NACI));

            this.campos7.value = data.GRA_ESCO?data.GRA_ESCO:''
            this.campos8.value = data.TIP_RELA?data.TIP_RELA:''

            if(data.FAM_DEPE === 'S'){
                document.getElementById('econo1').checked = true
            }else if(data.FAM_DEPE === 'N'){
                document.getElementById('econo2').checked = true
            }

            if(data.FAMILIAR_IN_HOME === 'S'){
                document.getElementById('livewith1').checked = true
                this.loadDirFromUserPrincipal(true)
            }else if(data.FAMILIAR_IN_HOME === 'N'){
                document.getElementById('livewith2').checked = true
            }

            if(data.PARTICIPAR_ACTIV === 'S'){
                document.getElementById('play1').checked = true
            }else if(data.PARTICIPAR_ACTIV === 'N'){
                document.getElementById('play2').checked = true
            }
            this.campos9.value = data.TRA_ESTU?data.TRA_ESTU:''
            this.campos10.value = data.HOB_FAMI?data.HOB_FAMI:''
            this.eps.value = data.BEN_EEPS === 'S' ? this.eps.checked = true :this.eps.checked = false
            this.caja.value = data.BEN_CACO === 'S'? this.caja.checked = true :this.caja.checked = false

            if(data.EST_DISC === 'S'){
                document.getElementById('discapacidad1').checked = true
                this.campos14.removeAttribute('disabled');
                this.campos14.value = data.TIP_DISC?data.TIP_DISC:''
                putInputRequerid(`#${this.campos14.id}`,'','add',this.campos14.id)
            }else if(data.EST_DISC === 'N'){
                document.getElementById('discapacidad2').checked = true
            }

            if(data.CONTACTO_EMER === 'S'){
                document.getElementById('contactEmergy1').checked = true
                this.campos13.removeAttribute('disabled')
                this.campos13.value = data.TEL_FAMI
            }else{
                document.getElementById('contactEmergy2').checked = true
            }


            if(data.PAI_FAMI){
                this.setState({
                    valCountry:data.PAI_FAMI,
                    valDpto: data.DTO_FAMI,
                    valCity:data.MPI_FAMI
                })
                simulateClick(this.selectCountry.id,0,'change')
    
                setTimeout(() => {
                    simulateClick(this.selectDpto.id,0,'change')
                    setTimeout(() => {
                        simulateClick(this.selectCity.id,0,'change')
                    }, 500);
                }, 500);
            }

            


            this.inputAddressFinal.value = data.DIR_FAMI

        }else if(num === 2){
            
        }
    
    }

    saveDataFamily = ()  =>{
        if(!validateInputTabs()){
            const campos1 = this.campos1.value?this.campos1.value:'';
            const campos2 = this.campos2.value?this.campos2.value:'';
            const campos3 = this.campos3.value?this.campos3.value:'';
            const campos4 = this.campos4.value?this.campos4.value:'';
            const campos5 = this.campos5.value?this.campos5.value:'';
          //  const campos6 = this.campos6.value?this.campos6.value:'';
            const campos7 = this.campos7.value?this.campos7.value:'';
            const campos8 = this.campos8.value?this.campos8.value:'';
            const dedicaFamiliar = this.campos9.value?this.campos9.value:'';
            const campos10 = this.campos10.value?this.campos10.value:'';
           
            const contacEmergency = document.querySelector('input[name=contactEmergy]:checked').value
        
            const campos13 = this.campos13.value?this.campos13.value:'';
            const dir = this.inputAddressFinal.value
            const sex = document.querySelector('input[name=options]:checked').value
            // const vive = document.querySelector('input[name=life]:checked').value
            const depende = document.querySelector('input[name=econo]:checked').value
            const viveconel = document.querySelector('input[name=livewith]:checked').value
            const presentadiscapacidad = document.querySelector('input[name=discapacidad]:checked').value
            const play = document.querySelector('input[name=play]:checked').value
            const tipoDiscapaci = this.campos14.value?parseInt(this.campos14.value):0
    
            const selectCountry = this.selectCountry.value?this.selectCountry.value:''
            const selectDpto = this.selectDpto.value?this.selectDpto.value:''
            const selectCity = this.selectCity.value?this.selectCity.value:''
    
            const dataUser = JSON.parse( localStorage.getItem("d_u"));
            const cedula = parseInt(dataUser['cedula'])
            const empresa = parseInt(dataUser['empresa'])
            const beneficiarioEPS = document.getElementById("checkEPS").checked?'S':'N';
            const beneficiarioCaja = document.getElementById("checkcAJA").checked?'S':'N';
    
    
            const datos = {
                "COD_EMPL": cedula,
                "COD_EMPR": empresa,
                "TIP_IDEN": campos3,
                "COD_FAMI": parseInt(campos4),
                "NOM_FAMI": campos1,
                "APE_FAMI": campos2,
                "TIP_RELA": campos8,
                "SEX_FAMI": sex,
                "FEC_NACI": campos5,
                "EST_VIDA": 'S',
                "FAM_DEPE": depende,
                "EST_DISC": presentadiscapacidad,
                "TIP_DISC": (tipoDiscapaci),
                "CONTACTO_EMER": contacEmergency,
                "FAMILIAR_IN_HOME": viveconel,
                "MPI_FAMI": parseInt(selectCity),
                "DIR_FAMI": dir,
                "TEL_FAMI": campos13,
                "TRA_ESTU": dedicaFamiliar,
                "GRA_ESCO": campos7,
                "BEN_CACO": beneficiarioCaja,
                "BEN_EEPS": beneficiarioEPS,
                "PARTICIPAR_ACTIV": play,
                "HOB_FAMI": campos10,
                "PAI_FAMI": parseInt(selectCountry),
                "DTO_FAMI": parseInt(selectDpto)
              }
            const url = `${baseUrl}/v1/familiar/crearFamiliar`;
            
            postData(url,datos).then(result =>{
                if(result.ok){
                    Swal.fire({
                        title: '',
                        text: "Se registro con éxito",
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonColor: "#A6A6A6",
                        confirmButtonText: 'Cerrar'
                    })
                    this.loadDataPrincipal()
                    this.cleanInputs()
                }
            })
    
        }
    }

    render(){
        
        const {breadcrumb,optionsCantPersonas,optionsIngresoPromedio,optionsOrigenIngresos,optionsEstadosEstudio,optionsModalidadesEstudio,optionsNivelesEstudio,optionsTipoDocumento,estadonav,disableActiHijos, tablaHijos, hideHijos, disableEducacion} = this.state;
        return (
        <> 
            <div className="card">
                <div className='card-header'>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb fs-2">
                            <li className="breadcrumb-item">Mi perfil</li>
                            <li className="breadcrumb-item">Datos básicos</li>
                            <li className="breadcrumb-item" aria-current="page">{breadcrumb}</li>
                        </ol>
                    </nav>
                </div>
            </div>
            &nbsp;
            <div className="card">
                <div className="card-body">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link btn btn-primary active"  id="condiciones-tab" data-bs-toggle="tab" data-bs-target="#condiciones" type="button" role="tab" aria-controls="condiciones" aria-selected="true" onClick={()=>this.setState({breadcrumb: 'Condiciones familiares'})}>Condiciones familiares</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button disabled={estadonav} className="nav-link btn btn-primary" id="hijos-tab" data-bs-toggle="tab" data-bs-target="#hijos" type="button" role="tab" aria-controls="hijos" aria-selected="true" onClick={()=>this.setState({breadcrumb: 'Datos de hijos'})}>Datos de hijos</button>
                        </li>
                    </ul>
                    
                    &nbsp;
                    <div className="tab-content" id="myTabContent">
                        {/* PANEL DE CONDICIONES FAMILIARES */}
                        <div className="tab-pane fade show active" id="condiciones" role="tabpanel" aria-labelledby="condiciones-tab">
                            <div className="row g-4">
                                <div className="col-12 col-md-4">
                                    <label className='form-label' htmlFor="dependen">Número de personas que dependen económicamente de usted:<span className="text-danger">*</span></label>
                                    <select  ref={input => this.dependen = input} className="form-select inputRequired" id="dependen" name="dependen">
                                        <option value="">Seleccione su respuesta</option>
                                        {optionsCantPersonas}
                                    </select>
                                </div>
                                <div className="col-12 col-md-4">
                                    <label className='form-label' htmlFor="perVive">Número de personas con las que vive actualmente:<span className="text-danger">*</span></label>
                                    <select  ref={input => this.perVive = input} className="form-select inputRequired" id="perVive" name="perVive">
                                        <option value="">Seleccione su respuesta</option>
                                        {optionsCantPersonas}
                                    </select>
                                </div>
                                <div className="col-12 col-md-4">
                                    <label className='form-label' htmlFor="ingresoPromedio">¿Cuál es el ingreso promedio dentro de su hogar?:<span className="text-danger">*</span></label>
                                    <select  ref={input => this.ingresoPromedio = input} className="form-select inputRequired" id="ingresoPromedio" name="ingresoPromedio">
                                        <option value="">Seleccione su respuesta</option>
                                        {optionsIngresoPromedio}
                                    </select>
                                </div>
                                <div className="col-12 col-md-4">
                                    <label className='form-label' htmlFor="ingresoOrigen">Los ingresos de su hogar provienen de:<span className="text-danger">*</span></label>
                                    <select  ref={input => this.ingresoOrigen = input} className="form-select inputRequired" id="ingresoOrigen" name="ingresoOrigen">
                                        <option value="">Seleccione su respuesta</option>
                                        {optionsOrigenIngresos}
                                    </select>
                                </div>

                                <div className='col-12' style={{'marginBottom': '-1em'}}>
                                    <label className='fs-0 text-primary mb-0 fw-bold' htmlFor="labelDir-Indumentaria">Información general sobre hijos</label>
                                    <hr className='text-primary my-1 fw-bold'></hr>
                                </div>

                                <div className="col-12 col-md-4">
                                    <label className='form-label' htmlFor="tieneHijos">¿Tiene hijos?:<span className="text-danger">*</span></label>
                                    <div className=" d-flex justify-content-around">
                                        <input type="radio" value='1' className="btn-check input-hidden inputRequired" name="tieneHijos" id="radioHijos" onClick={() => this.handleHijosGeneral(true, undefined)}></input>
                                        <label className="btn btn-outline-primary w-50" htmlFor="radioHijos">SI</label>&nbsp;
                                        <input type="radio" value='0' className="btn-check input-hidden" name="tieneHijos" id="radioHijos2" onClick={() => this.handleHijosGeneral(false, undefined)}></input>
                                        <label className="btn btn-outline-primary w-50" htmlFor="radioHijos2">NO</label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <label className='form-label' htmlFor="actiHijos">¿Le gustaría que sus hijos participen en actividades de la empresa?:</label>
                                    <div className=" d-flex justify-content-around">
                                        <input disabled={disableActiHijos} type="radio" value='1' className="btn-check input-hidden" name="actiHijos" id="radioActiHijos" onClick={() => this.handleHijosGeneral(true, true)}></input>
                                        <label className="btn btn-outline-primary w-50" htmlFor="radioActiHijos">SI</label>&nbsp;
                                        <input disabled={disableActiHijos} type="radio" value='0' className="btn-check input-hidden" name="actiHijos" id="radioActiHijos2" onClick={() => this.handleHijosGeneral(true, false)}></input>
                                        <label className="btn btn-outline-primary w-50" htmlFor="radioActiHijos2">NO</label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <label className='form-label' htmlFor="actiEmpresa">¿Qué actividades le gustaría que la empresa organizara para sus hijos?:</label>
                                    <input readOnly ref={inp =>this.actiEmpresa = inp} type="text" className="form-control" id="actiEmpresa" name="actiEmpresa" placeholder='Escriba aquí su respuesta'></input>
                                </div>

                                <div className="text-end">
                                    <button onClick={() => this.guardarCondicionesFamiliares()} className="btn succesButton col-12 col-md-2 col-xxl-1">Guardar y continuar</button>
                                </div>
                            </div>
                        </div>

                        {/* PANEL DE DATOS DE HIJOS */}
                        <div className="tab-pane fade" id="hijos" role="tabpanel" aria-labelledby="hijos-tab">
                            {/* TABLA DE HIJOS Y BOTÓN DE AGREGAR HIJOS */}
                            <div className='row g-3' hidden={!hideHijos ? 'hidden' : ''}>
                                <div className='col-12'>
                                    {tablaHijos}
                                </div>
                                <div className='text-end'>
                                    <button className='btn succesButton col-12 col-md-3 col-xxl-2' onClick={()=>this.mostrarFormHijo(true)}>Agregar hijos</button>
                                </div>
                            </div>

                            {/* FORMULARIO DE CREACIÓN Y EDICIÓN DE HIJOS */}
                            <div className="row g-3" hidden={hideHijos}>
                                <div className="col-12 col-md-4">
                                    <label className='form-label' htmlFor="iden-nombre">Nombres:<span className="text-danger">*</span></label>
                                    <input ref={inp =>this.nombres = inp} type="text" className="form-control inputRequired" id="iden-nombre" name="iden-nombre" placeholder='Escriba aquí su respuesta'></input>
                                </div>
                                <div className="col-12 col-md-4">
                                    <label className='form-label' htmlFor="iden-apellido">Apellidos:<span className="text-danger">*</span></label>
                                    <input ref={inp =>this.apellidos = inp} type="text" className="form-control inputRequired" id="iden-apellido" name="iden-apellido" placeholder='Escriba aquí su respuesta'></input>
                                </div>
                                <div className="col-12 col-md-4">
                                    <label className='form-label' htmlFor="sexo">Sexo:<span className="text-danger">*</span></label>
                                    <div className=" d-flex justify-content-around">
                                        <input  type="radio" value='M' className="btn-check input-hidden" name="sexo" id="radioSexo"></input>
                                        <label className="btn btn-outline-primary w-50" htmlFor="radioSexo">Hombre</label>&nbsp;
                                        <input  type="radio" value='F' className="btn-check input-hidden inputRequired " name="sexo" id="radioSexo2" ></input>
                                        <label className="btn btn-outline-primary w-50" htmlFor="radioSexo2">Mujer</label>&nbsp;
                                    </div>
                                </div>

                                <div className="col-12 col-md-4">
                                    <label className='form-label' htmlFor="tipoDocumento">Tipo de documento:<span className="text-danger">*</span></label>
                                    <select ref={sel =>this.tipoDocumento = sel} name="tipoDocumento" id="tipoDocumento" className="form-select inputRequired">
                                        <option value="">Seleccione su respuesta</option>
                                        {optionsTipoDocumento}
                                    </select>
                                </div>

                                <div className="col-12 col-md-4">
                                    <label className='form-label' htmlFor="identificacion">Identificación:<span className="text-danger">*</span></label>
                                    <input ref={inp =>this.identificacion = inp} type="number" className="form-control inputRequired" id="identificacion" name="identificacion" placeholder='Escriba aquí su respuesta'></input>
                                </div>
                                <div className="col-12 col-md-4">
                                    <label className='form-label' htmlFor="nacimiento">Fecha de nacimiento:<span className="text-danger">*</span></label>
                                    <input ref={inp =>this.nacimiento = inp} max={moment().utc().format('yyyy-MM-DD')} type="date" className="form-control inputRequired" id="nacimiento" name="nacimiento" onChange={(e) =>{this.calculaEdad(moment().utc(),moment.utc(e.target.value))}}></input>
                                </div>

                                <div className="col-12 col-md-4">
                                    <label className='form-label' htmlFor='edad'>Edad:</label>
                                    <input ref={inp =>this.edad = inp} readOnly type="text" className="form-control" id="edad" name="edad" placeholder='0 años'></input>
                                </div>

                                <div className="col-12 col-md-4">
                                    <label className='form-label' htmlFor="nivelEstudio">Nivel académico:<span className="text-danger">*</span></label>
                                    <select ref={sel =>this.nivelEstudio = sel} className="form-select inputRequired" id="nivelEstudio" name="nivelEstudio" onChange={()=>this.handleEscolaridadHijo()}>
                                        <option value="">Seleccione su respuesta</option>
                                        {optionsNivelesEstudio}
                                    </select>
                                </div>
                                <div className="col-12 col-md-4">
                                    <label className='form-label' htmlFor="estadoEstudios">Estado de los estudios:<span className="text-danger">*</span></label>
                                    <select ref={sel =>this.estadoEstudios = sel} className="form-select inputRequired" id="estadoEstudios" name="estadoEstudios">
                                        <option value="">Seleccione su respuesta</option>
                                        {optionsEstadosEstudio}
                                    </select>
                                </div>
                                <div className="col-12 col-md-4">
                                    <label className='form-label' htmlFor="institucion">Institución:<span className="text-danger">*</span></label>
                                    <input ref={inp =>this.institucion = inp} type="text" className="form-control inputRequired" id="institucion" name="institucion" placeholder='Escriba aquí su respuesta'></input>
                                </div>
                                <div className="col-12 col-md-4">
                                    <label className='form-label' htmlFor="programaAcademico">Nombre del programa académico:</label>
                                    <input ref={inp =>this.programaAcademico = inp} type="text" className="form-control" id="programaAcademico" name="programaAcademico" placeholder='Escriba aquí su respuesta' disabled={disableEducacion}></input>
                                </div>
                                <div className="col-12 col-md-4">
                                    <label className='form-label' htmlFor="modalidad">Modalidad de estudio:</label>
                                    <select  ref={input =>this.modalidad = input} className="form-select" id="modalidad" name="modalidad" disabled={disableEducacion}>
                                        <option value="">Seleccione su respuesta</option>
                                        {optionsModalidadesEstudio}
                                    </select>
                                </div>

                                <div className="col-12 d-flex justify-content-between mt-4">
                                    <button onClick={()=>this.mostrarFormHijo(false)} className="btn closeButton col-12 col-md-2 col-xxl-1">Cancelar</button>
                                    <button onClick={()=>this.guardarHijo()} className="btn succesButton col-12 col-md-2 col-xxl-1">Guardar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
}

export default Family;