import React, { useEffect } from 'react';
import { registrarAccion } from '../../generalHelpers';
import './sst.css';

export const Sst = () => {

    useEffect(() => {
        document.getElementById('root').className = 'sst';
    }, []);


    return (
        <>
           
            <div className="col-md-8 ps-md-5 pe-md-5 pt-md-3 divTitulosRRHH mt-4 mt-md-0">
                    <div className="col-md-12 ms-md-2 mb-4 d-none d-md-flex">
                        <img
                            style={{width:"150px", height:"80"}}
                            src="https://controlf.blob.core.windows.net/public/vumoffice/assets/
logos/logo_office_blanco.png"
                            alt="icono-vum-office-home"
                        />
                    </div>
                    <h3 className="tituloModulo mt-4 mb-4">Oficina de SST</h3>
                    <p className="copyPage">
                    Cuando ocurra un presunto accidente de
                    trabajo el colaborador deberá informar
                    inmediatamente lo ocurrido a su jefe inmediato
                    y este a su vez deberá notificarle a SST de la
                    empresa contratante, para esto se dispone de
                    dos canales de comunicación:
                    </p>
                    
                    <div className="mt-3 mb-3 d-flex align-items-center justify-content-center justify-content-md-start gap-2">
                        <h5 className="mb-0 copyPage">Cel:</h5>
                        <h5 className="mb-0 copyPage">321 976 6112</h5>
                    </div>


                    <div className='mt-3 mb-4 mb-md-3 d-md-flex align-items-center justify-content-start gap-2'>
                        <h5 className="copyPage">Correo electrónico:</h5>
                        <h5 className="copyPage">reportesarl@listos.com.co.</h5>
                    </div>
            </div>

            <div className="col-md-8 row mb-3 ms-md-4 ps-xxl-10 pe-xxl-10">
                <div className='col-md-6 col-12 text-center'>

                <a onClick={() => { registrarAccion({codigo:"55"}); }}
                    href="https://controlf.blob.core.windows.net/vumoffice/ejemplo/SST-directoria-farmacias-arl-axa-acolpatria.pdf"
                    target="_blank"
                    rel="noopener noreferrer"> 
                        <button className="btns-rrhh zoomBtn mb-3" style={{fontWeight:'bold'}} type="button">
                            Directorio de farmacias
                        </button>
                </a>
                </div>
                <div className='col-12 col-md-6 text-center'>


                <a onClick={() => { registrarAccion({ codigo: "56" }); }}
                    href="https://controlf.blob.core.windows.net/vumoffice/ejemplo/SST-clinicas-en-convenio-axa-colpatria.pdf"
                    target="_blank"
                    rel="noopener noreferrer"> 
                        <button className="btns-rrhh zoomBtn mb-3" style={{fontWeight:'bold'}} type="button">
                            Clínicas en convenio
                        </button>
                </a>
                </div>

            </div>
        </>
    )
}
