import { routes } from '../../environments/environments';
import '../Login/login.css';
import Swal from "sweetalert2/dist/sweetalert2.js";
import { postData } from "../../components/general/General";
import { baseUrl } from "../../config/config";
import { overlay } from "../../generalHelpers";
import { Footer } from '../../components/FooterLogin/FooterLogin';

const LoginReset = () => {

    const enviarCodigo = () => {

        Swal.fire({
            html: `
            <div class="row" style="text-align: left; margin: 10px;">
                <div class="col-12 col-lg-12 mb-3 text-center">
                    <span style="font-weight: 600;font-size: 20px;">
                        Ingresa tu número de identificación.
                    </span>
                </div>

                <div class="col-12 col-lg-12">                    
                    <input id="cedula" name="cedula" class="form-control" type="number" />
                </div>

                <div class="col-12 col-lg-12 mt-3" style="text-align: center;">
                    <button id="btnValidarCodigo" type="button" class="btn succesButton">Enviar codigo</button>
                </div>
            </div>
                 
            <br/>
            `,
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            backdrop: true,
            didOpen: () => {
                document
                    .getElementById("btnValidarCodigo")
                    .addEventListener("click", () => {
                        const url = `${baseUrl}/v1/auth/consultEmail`;
                        const cedula = document.getElementById("cedula").value;
                        const datos = {
                            identification: parseInt(cedula)
                        };
                        overlay(true);
                        postData(url, datos)
                            .then((ele) => {

                                if (ele.estado === 1) {
                                    Swal.fire({
                                        icon: 'success',
                                        html: `
                                <div class="row" style="text-align: left; margin: 10px;">
                                    <div class="col-12 col-lg-12 mb-3 text-center">
                                        <span style="font-weight: 600;font-size: 20px;">
                                        Al correo ${ele.email} y/o celular ${ele.celular}, se ha enviado el token para la asignación de la nueva contraseña de ingreso a VUM Office.
                                        </span>
                                    </div>                    
                                    <div class="col-12 col-lg-12 mt-3" style="text-align: center;">
                                        <button id="btnValidarCodigo" type="button" class="btn succesButton">Aceptar</button>
                                    </div>
                                </div>
                                     
                                <br/>
                        `, showCloseButton: true,
                                        showCancelButton: false,
                                        showConfirmButton: false,
                                        allowOutsideClick: false,
                                        backdrop: true,
                                        didOpen: () => {
                                            document
                                                .getElementById("btnValidarCodigo")
                                                .addEventListener("click", () => {
                                                    //metodo para consultar las preguntas de esa cédula y guardar el local storage
                                                    consultarPreguntas(cedula);
                                                });
                                        }
                                    });
                                } else {
                                    if (ele.estado === 2) {
                                        Swal.fire({
                                            icon: 'success',
                                            html: `
                                    <div class="row" style="text-align: left; margin: 10px;">
                                        <div class="col-12 col-lg-12 mb-3 text-center">
                                            <span style="font-weight: 600;font-size: 20px;">
                                            Al correo ${ele.email} y/o celular ${ele.celular}, se ha enviado el token para la asignación de la nueva contraseña de ingreso a VUM Office.
                                            </span>
                                        </div>                    
                                        <div class="col-12 col-lg-12 mt-3" style="text-align: center;">
                                            <button id="btnValidarCodigo" type="button" class="btn succesButton">Aceptar</button>
                                        </div>
                                    </div>
                                         
                                    <br/>
                            `, showCloseButton: true,
                                            showCancelButton: false,
                                            showConfirmButton: false,
                                            allowOutsideClick: false,
                                            backdrop: true,
                                            didOpen: () => {
                                                document
                                                    .getElementById("btnValidarCodigo")
                                                    .addEventListener("click", () => {                                                        
                                                        window.location.href = routes.loginNew.url;
                                                    });
                                            }
                                        });
                                    } else {
                                        if (ele.estado === 3) {
                                            Swal.fire({
                                                icon: 'error',
                                                html: `
                                        <div class="row" style="text-align: left; margin: 10px;">
                                            <div class="col-12 col-lg-12 mb-3 text-center">
                                                <span style="font-weight: 600;font-size: 20px;">
                                                El número de cédula que se ha ingresado no se encuentra registrado en nuestras Bases de datos, por favor realizar el ingreso a Vum Office como personal externo.
                                                </span>
                                            </div>                    
                                            <div class="col-12 col-lg-12 mt-3" style="text-align: center;">
                                                <button id="btnValidarCodigo" type="button" class="btn succesButton">Aceptar</button>
                                            </div>
                                        </div>
                                             
                                        <br/>
                                `, showCloseButton: true,
                                                showCancelButton: false,
                                                showConfirmButton: false,
                                                allowOutsideClick: false,
                                                backdrop: true,
                                                didOpen: () => {
                                                    document
                                                        .getElementById("btnValidarCodigo")
                                                        .addEventListener("click", () => {
                                                            window.location.href = routes.login.url;
                                                        });
                                                }
                                            });
                                        }
                                    }
                                }

                            });
                    });
            }
        });
    };
    
    const consultarPreguntas = (cedula) => {
        const url = `${baseUrl}/v1/auth/preguntasSeguridad`;
        const datos = {
            identification: parseInt(cedula)
        };
        overlay(true);
        postData(url, datos)
            .then((ele) => {
                if (ele === 2) {

                    Swal.fire({
                        icon: 'error',
                        html: `
                <div class="row" style="text-align: left; margin: 10px;">
                    <div class="col-12 col-lg-12 mb-3 text-center">
                        <span style="font-weight: 600;font-size: 20px;">
                        El número de cédula que se ha ingresado no se encuentra registrado en nuestras Bases de datos, por favor realizar el ingreso a Vum Office como personal externo.
                        </span>
                    </div>                    
                    <div class="col-12 col-lg-12 mt-3" style="text-align: center;">
                        <button id="btnValidarCodigo" type="button" class="btn succesButton">Aceptar</button>
                    </div>
                </div>
                        
                <br/>
        `, showCloseButton: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        backdrop: true,
                        didOpen: () => {
                            document
                                .getElementById("btnValidarCodigo")
                                .addEventListener("click", () => {
                                    window.location.href = routes.login.url;
                                });
                        }
                    });
                } else {
                    localStorage.clear();
                    localStorage.setItem('dataQuestion', JSON.stringify(ele));
                    window.location.href = routes.loginNew.url;
                }
            });

    }

    const preguntasSeguridad = () => {
        Swal.fire({
            html: `
            <div class="row" style="text-align: left; margin: 10px;">
                <div class="col-12 col-lg-12 mb-3 text-center">
                    <span style="font-weight: 600;font-size: 20px;">
                        Ingresa tu número de identificación.
                    </span>
                </div>

                <div class="col-12 col-lg-12">                    
                    <input id="cedula" name="cedula" class="form-control" type="number" />
                </div>

                <div class="col-12 col-lg-12 mt-3" style="text-align: center;">
                    <button id="btnValidarCodigo" type="button" class="btn succesButton">Aceptar</button>
                </div>
            </div>
                 
            <br/>
    `, showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            backdrop: true,
            didOpen: () => {
                document
                    .getElementById("btnValidarCodigo")
                    .addEventListener("click", () => {
                        const url = `${baseUrl}/v1/auth/preguntasSeguridad`;
                        const cedula = document.getElementById("cedula").value;
                        const datos = {
                            identification: parseInt(cedula)
                        };
                        overlay(true);
                        postData(url, datos)
                            .then((ele) => {

                                if (ele === 2) {

                                    Swal.fire({
                                        icon: 'error',
                                        html: `
                                <div class="row" style="text-align: left; margin: 10px;">
                                    <div class="col-12 col-lg-12 mb-3 text-center">
                                        <span style="font-weight: 600;font-size: 20px;">
                                        El número de cédula que se ha ingresado no se encuentra registrado en nuestras Bases de datos, por favor realizar el ingreso a Vum Office como personal externo.
                                        </span>
                                    </div>                    
                                    <div class="col-12 col-lg-12 mt-3" style="text-align: center;">
                                        <button id="btnValidarCodigo" type="button" class="btn succesButton">Aceptar</button>
                                    </div>
                                </div>
                                     
                                <br/>
                        `, showCloseButton: true,
                                        showCancelButton: false,
                                        showConfirmButton: false,
                                        allowOutsideClick: false,
                                        backdrop: true,
                                        didOpen: () => {
                                            document
                                                .getElementById("btnValidarCodigo")
                                                .addEventListener("click", () => {
                                                    window.location.href = routes.login.url;
                                                });
                                        }
                                    });

                                } else {
                                    localStorage.clear();
                                    localStorage.setItem('dataQuestion', JSON.stringify(ele));
                                    
                                    window.location.href = routes.loginQuestion.url;
                                }


                            });

                    });
            }
        });
    };

    return (
        <main className="main login" id="top">
            <div className="container" data-layout="container">

                <div className="row flex-center text-center min-vh-100">
                    <img className="me-2 mt-5 mb-3" src="assets/img/logo-vum-login.png" alt="logo-vum-login" style={{ width: '200px' }} />
                    <span className="font-sans-serif fontBienvenido fs-3 d-inline-block tituloBienvenido">Generación de contraseña</span>
                    <div className="col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4 text-center">
                        <div>
                            <div className="card-body ps-4 pe-4">
                                <form>
                                    <div className="mb-3">
                                        <p className="mb-0 fontFooter" id="parrafo">Pensando en ti hemos mejorado nuestra seguridad.</p>
                                        <p className="mb-0 fontFooter" id="parrafo">¿Con qué método deseas generar tu nueva contraseña?</p>
                                    </div>
                                    <div className="mb-3">
                                        <button className="btn btnIniciarSesion d-block w-100 mt-3 fontLinkLabel" type="button" name="submit" onClick={() => enviarCodigo()}>Enviar código de seguridad</button>
                                    </div>
                                    <div className="mb-3">
                                        <button className="btn btnIniciarSesion d-block w-100 mt-3 fontLinkLabel" type="button" name="submit" onClick={() => preguntasSeguridad()}>Preguntas de seguridad</button>
                                        <br></br><br></br>
                                        <span className="labelInpiut text-decoration-underline tituloBienvenido"><a href={routes.login.url} id="contr"><strong>Regresar al login</strong></a></span>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                   <Footer></Footer>
                </div>


            </div>
        </main>
    )
}

export default LoginReset;