// import axios from 'axios';
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { api } from "../../../../environments/environments";
import {
  advertenciaFormularioVacio,
  overlay,
  postFetch,
} from "../../../../generalHelpers";

export const useIncapacidadRadicar = (formInitialState, dataUser) => {
  const exprRegNumeros = /^[0-9+]+$/; //Expresion regular para validar el formato de solo numeros
  const exprRegTelefono = /^[0-9+() -?]+$/; //Expresion regular para validar el formato de un teléfono
  const exprRegEmail = /^([a-zA-Z0-9_.-]+)@([\da-zA-Z0-9.-]+)\.([a-z.]{2,6})$/; //Expresion regular para validar los correos electronicos
  const exprRegSoloLetras = /^[a-zA-ZÑñáéíóúÁÉÍÓÚÄËÏÖÜäëïöü\s+]+$/; //Expresion regular para validar solo letras

  useEffect(() => {
    document.getElementById("root").className = "incapacidadRadicar";
    window.scrollTo({ top: 0, behavior: "smooth" });
    loadTipoIncapacidad();
    loadOtherEntenty();
    setFilesState({ files: [] });
  }, []);

  const [valorPrueba, setValorPrueba] = useState({});
  const [contadorPopups, setContadorPopups] = useState(0);
  const [formValue, setFormValue] = useState(formInitialState);
  const onChangeInputHandle = ({ target }) => {
    setFormValue({
      ...formValue,
      [target.name]: target.value,
    });
  };

  useEffect(() => {
    onChangeTipoIncapacidadHandle(valorPrueba);
  },[valorPrueba])

  const onChangeSelectHandle = ({ nameSelect, value }) => {
    setFormValue({
      ...formValue,
      [nameSelect]: value,
    });

    setValorPrueba({
      ...valorPrueba,
      [nameSelect]: value,
    }); 
  };

  const [stateOtraEntidadCheck, setStateOtraEntidadCheck] = useState(true);
  const onCheckedOtherEntity = () => {

    setStateOtraEntidadCheck(!stateOtraEntidadCheck);
    setFormValue({
      ...formValue,
      otraEntidad: stateOtraEntidadCheck,
    });
  };

  const [stateInputCheck, setStateInputCheck] = useState(true);
  const onCheckedInputCheck = () => {
    setStateInputCheck(!stateInputCheck);
    setFormValue({
      ...formValue,
      prorroga: stateInputCheck,
    });
  };

  const [optionsOtherEntity, setOptionsOtherEntity] = useState({});
  const loadOtherEntenty = () => {
    postFetch({
      url: api.getEpsIncapacidad,
      params: {
        codigoEmpresaUsuario: dataUser.empresa,
      },
    }).then((responseGetEpsIncapacidad) => {
      let optionsCategory = [{ value: null, label: "SELECCIONE...", disabled: true}];

      responseGetEpsIncapacidad.forEach((element) => {
        //codigo y nombre de eps incapacidad.
        optionsCategory.push({
          value: element.cod_enti,
          label: element.nom_enti.toUpperCase(),
        });
      });

      setOptionsOtherEntity(optionsCategory);
    });
  };

  const [optionsTipoIncapacidad, setOptionsTipoIncapacidad] = useState({});
  const loadTipoIncapacidad = () => {
    postFetch({
      url: api.getTypesIncapacity,
      params: { empresa: Number(dataUser.empresa) },
    }).then((responseGetTipoIncapacidad) => {
      let optionsCategory = [{ value: null, label: "SELECCIONE...", disabled: true}];

      responseGetTipoIncapacidad.forEach((element) => {
        optionsCategory.push({
          value: element.TIP_CODIGO,
          label: element.TIP_NOMBRE.toUpperCase(),
        });
      });

      setOptionsTipoIncapacidad(optionsCategory);
    });
  };

  const onChangeTipoIncapacidadHandle = ({ otraEntidad = null, tipoIncapacidad = null }) => {

    //validar si el check de otra entidad está activo y no hay nada seleccionado.
    if(Boolean(!stateOtraEntidadCheck) && otraEntidad == null){
      setRowsTable();
      return Swal.fire({
        icon: "error",

        html: `Selecciona otra entidad`,

        confirmButtonText: "Cerrar",
        confirmButtonColor: "#A6A6A6",
      }).then((result) => {
        
      });
      
  }

    overlay(true);

    let objDataForm = {
      checkOtraEntidad: Boolean(!stateOtraEntidadCheck),
      otraEntidadSaludTotalEps: 0,
      eps: Number(dataUser.codigo_entidad),
      empresa: Number(dataUser.empresa),
      tipoIncapacidad: Number(tipoIncapacidad?.value),
    };

    if (otraEntidad && tipoIncapacidad) {  //Cuando vengan embas
      //validar si otra entidad es diferente a value: 2.
      if (Boolean(!stateOtraEntidadCheck) && otraEntidad.value == 2) { //Si viene 2 es SALUD TOTAL
        objDataForm.otraEntidadSaludTotalEps = 2;
      }else if(Boolean(!stateOtraEntidadCheck) && otraEntidad.value != 2){
        objDataForm.otraEntidadSaludTotalEps = 0
      }

      if(tipoIncapacidad.value == 510 || tipoIncapacidad.value == 511 || tipoIncapacidad.value == 498 || tipoIncapacidad.value == 504 || tipoIncapacidad.value == 510 || tipoIncapacidad.value == 516 || tipoIncapacidad.value == 522 || tipoIncapacidad.value == 511 || tipoIncapacidad.value == 505 || tipoIncapacidad.value == 499 || tipoIncapacidad.value == 523 || tipoIncapacidad.value == 517) {
        
        if (Number(dataUser.codigo_entidad) == 2 ||(Boolean(!stateOtraEntidadCheck) && otraEntidad.value == 2)) {
          if((Boolean(!stateOtraEntidadCheck) && otraEntidad.value !== 2)){
             //añadirle la clase de d-none al div con id: formatoSaludTotal.
          document.getElementById("formatoSaludTotal").classList.add("d-none");     

          }else{
            //quitarle la clase de d-none al div con id: formatoSaludTotal.
            document.getElementById("formatoSaludTotal").classList.remove("d-none");
          }
          //quitarle la clase de d-none al div con id: formatoSaludTotal.
          //document.getElementById("formatoSaludTotal").classList.remove("d-none");
        } else {
          //añadirle la clase de d-none al div con id: formatoSaludTotal.
          document.getElementById("formatoSaludTotal").classList.add("d-none");
        }
      } else {
        //añadirle la clase de d-none al div con id: formatoSaludTotal.
        document.getElementById("formatoSaludTotal").classList.add("d-none");
      }
      
    } else if (!otraEntidad && tipoIncapacidad) {
      if(tipoIncapacidad.value == 510 || tipoIncapacidad.value == 511 || tipoIncapacidad.value == 498 || tipoIncapacidad.value == 504 || tipoIncapacidad.value == 510 || tipoIncapacidad.value == 516 || tipoIncapacidad.value == 522 || tipoIncapacidad.value == 511 || tipoIncapacidad.value == 505 || tipoIncapacidad.value == 499 || tipoIncapacidad.value == 523 || tipoIncapacidad.value == 517) {
        if (
          Number(dataUser.codigo_entidad) == 2 ||
          (Boolean(!stateOtraEntidadCheck) && otraEntidad.value == 2)
        ) {
          //quitarle la clase de d-none al div con id: formatoSaludTotal.
          document.getElementById("formatoSaludTotal").classList.remove("d-none");
        } else {
          //añadirle la clase de d-none al div con id: formatoSaludTotal.
          document.getElementById("formatoSaludTotal").classList.add("d-none");
        }
      } else {
        //añadirle la clase de d-none al div con id: formatoSaludTotal.
        document.getElementById("formatoSaludTotal").classList.add("d-none");
      }
    }

    setFormValue({
      ...formValue,
      tipoIncapacidad: tipoIncapacidad,
    });

    if(filesState){
       setFilesState({
      ...filesState,
      files: filesState.files.splice(0, filesState.files.length),
    });
    }

    postFetch({
      url: api.getDocumentsIncapacity,
      params: objDataForm,
    }).then((resGetDocumentsIncapacity) => {
      actualizarRowsTable(resGetDocumentsIncapacity);
    });

  };

  const [filesState, setFilesState] = useState({files: []});
  const onChangeInputFileHandle = ({ tipoArchivo, documento, target, id }) => {
    if (
      target.target.files[0].type === "application/pdf" ||
      target.target.files[0].type === "image/jpeg" ||
      target.target.files[0].type === "image/png" ||
      target.target.files[0].type === "image/jpg"
    ) {
      let buscarArchivo = filesState.files.findIndex((element) => element.idFile === id);
      if(buscarArchivo === -1){
        filesState.files.push({
          idFile: id,
          fileInfo: target.target.files[0],
          tipoDocumento: documento,
          codigoTipoArchivo: tipoArchivo,
        });
      }else{
        filesState.files[buscarArchivo] = {
          idFile: id,
          fileInfo: target.target.files[0],
          tipoDocumento: documento,
          codigoTipoArchivo: tipoArchivo,
        };
      }
      setFilesState({ ...filesState, files: filesState.files });
    } else {
      document.getElementById(target.target.id).value = "";

      Swal.fire({
        icon: "error",
        title: "Solo se permiten subir archivos tipo pdf o imagenes",
        confirmButtonText: "Cerrar",
        confirmButtonColor: "#A6A6A6",
      });
    }
  };

  const [rowsTable, setRowsTable] = useState(formValue.rowsDataTable);
  const actualizarRowsTable = (data) => {
    setRowsTable();
    let rowsDTable = [];

    data.forEach((element, key) => {
      let requerido = true;
      if(element.TIP_NOMBRE == "REPORTE DE TRANSITO Y CROQUIS (ACTA JURAMENTADA)" || element.TIP_NOMBRE == "FURAT" || element.TIP_NOMBRE == "PRELICENCIA (SI ES EL CASO)"){
        requerido = false;
      }

      let numberRow = key + 1;
      rowsDTable.push({
        numero: numberRow,
        documento: element.TIP_NOMBRE.toUpperCase(),
        archivo: (
            <input
              onChange={(values) => {
                onChangeInputFileHandle({
                  tipoArchivo: element.TIP_CODIGO,
                  documento: element.TIP_NOMBRE.toUpperCase(),
                  target: values,
                  id: `file_${numberRow}`
                });
              }}
              key={'inputFile'+key}
              id={`file_${numberRow}`}
              name={`file_${numberRow}`}
              className="form-control"
              type="file"
              accept="image/*,.pdf"
              required={requerido}
            />
        ),
      });
    });

    overlay(false);
    setRowsTable(rowsDTable);
  };

  const onSubmitFormIncapacidad = (e) => {
    e.preventDefault();
    overlay(true);

    const allData = {
      dataUser: {
        cedula: formValue.cedula,
        usuCodigoRadicador: JSON.parse(localStorage.getItem("d_u"))["USU_CODIGO"],
        usuLogin: JSON.parse(localStorage.getItem("d_u"))["USU_LOGIN"],
        nombres: formValue.nombreUsuario,
        correoElectronico: formValue.correoElectronico,
        eps: formValue.codigo_entidad,
        telefono: formValue.telefono,
        empresa: dataUser.empresa.trim(),
        numeroContrato: JSON.parse(localStorage.getItem("d_u"))["numContrato"],
      },
      dataForm: {
        tipoIncapacidad: formValue?.tipoIncapacidad?.value || "null",
        prorroga: formValue?.prorroga || false,
        otraEntidad: {
          status: Boolean(!stateOtraEntidadCheck),
          //como valor de la otra entidad coloco el value, y no el label.
          value: formValue?.otraEntidad?.value || "null",
        },
        rangoFechas: {
          fechaInicio: formValue.fechaInicio,
          fechaFin: formValue.fechaFin,
        },
      },
    };

    if (!validarDatosFormulario(allData)) {
      advertenciaFormularioVacio();
    } else {
      const dataForm = new FormData();
      dataForm.append("allData", JSON.stringify(allData));

      filesState.files.forEach((file) => {
        dataForm.append("file", file.fileInfo);
        dataForm.append("filesNames", file.tipoDocumento);
        dataForm.append("filesCodigos", file.codigoTipoArchivo);
      });

      postFetch({
        url: api.postSaveDisabilityFiling,
        params: dataForm,
      })
        .then((data) => {

          if(data === "Radico"){
            Swal.fire({
              icon: "error",
              title: "Acción prohibida",
              html: `Ya existe una incapacidad radicada dentro del rango de fechas seleccionado. <br>
              Por favor valide las fechas y, en caso de que ya tenga una incapacidad y se encuentre rechazada, ingrese a la opción "Consultar" y modifique los documentos requeridos.`,
  
              confirmButtonText: "Cerrar",
              confirmButtonColor: "#A6A6A6",
            }).then((result) => {
              //window.location.reload();
            });

          }else{
          Swal.fire({
            icon: "success",

            html: `<div class="text-start">
              <b>Su incapacidad fue radicada correctamente.<br><br>
              Por favor tenga en cuenta lo siguiente:</b>
              <br>
              <ul>
                <li>Su incapacidad fue radicada con el consecutivo <b>#${data.INCAP_CODIGO}</b></li>
                <li>La fecha de solicitud es <b>${data.FECHA_CREACION}</b></li>
                <li>La incapacidad se encuentra en estado <b>pendiente</b> y tendrá un tiempo de auditoría máximo de <b>tres (3) días hábiles</b> contando a partir del día siguiente de la fecha de la solicitud.</li>
                <li>Cuando su incapacidad sea validada por el equipo y su estado cambie, se le informará a través del correo electrónico que tiene registrado.</li>
              </ul>
              Tenga en cuenta que podrá acceder en cualquier momento al módulo "Consultar" para ver el estado del consecutivo asignado.
            </div>`,

            confirmButtonText: "Cerrar",
            confirmButtonColor: "#A6A6A6",
          }).then((result) => {
            window.location.reload();
          });
          }
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title:
              "Hubo un error en la inserción, por favor revisa el formulario.",
            confirmButtonText: "Cerrar",
            confirmButtonColor: "#A6A6A6",
          });
        });
    }
  };

  useEffect(() => {
    let fechaini = new Date(formValue.fechaInicio);
    let fechafin = new Date(formValue.fechaFin);
    let diasdif = fechafin.getTime() - fechaini.getTime();
    let contdias = Math.round(diasdif / (1000 * 60 * 60 * 24));
    let inputFileHistoria = null;

    // Se coloca después de un segundo porque cuando se cierra el icono de carga, se cierra inmediatamente el dialogo
    // Y mostrará el diálogo máximo 2 vececs nomás
    setTimeout(() => {
      inputFileHistoria = document.getElementById('file_2');

      if (contdias <= 1 && formValue?.tipoIncapacidad?.label === "ENFERMEDAD GENERAL") {
        if(contadorPopups < 2) {
          Swal.fire({
            title: "Advertencia",
            html: `
              <div className="row">
                  <div className="col-12 col-lg-12" style="text-align: left; font-size: 16px; font-weight: 600; margin-bottom: 15px;">
                    Recuerde que <strong>NO</strong> es obligación subir la historia clínica si cumple con las siguientes características: <br><br>
                    <ul>
                      <li>El tiempo de la incapacidad es menor o igual a dos (2) días.</li>
                      <li>El tipo de incapacidad es por ENFERMEDAD GENERAL.</li>
                    </ul> <br>
                    Tenga en cuenta que en caso de ser una <strong>prórroga</strong> de incapacidad, ahora sí es obligatorio adjuntar la historia clínica correspondiente.
                  </div>
              </div>
              <br/>
            `,
            icon: "warning",
            confirmButtonText: "Cerrar",
            confirmButtonColor: "#A6A6A6",
          });
          setContadorPopups(contadorPopups+1);
        }
        if(inputFileHistoria) document.getElementById('file_2').removeAttribute('required'); // Se quita el requerido del file_2 que es el de la historia clínica
        if(formValue.prorroga) {
          // En caso de tener prórroga se vuelve requerido de nuevo
          if(inputFileHistoria) document.getElementById('file_2').setAttribute('required', true);
        }
      } else {
        if(inputFileHistoria) document.getElementById('file_2').setAttribute('required', true);
      }
    }, 1500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValue.tipoIncapacidad, formValue.fechaFin, formValue.fechaInicio, formValue.prorroga]);

  const validarDatosFormulario = (allData) => {
    const { correoElectronico, telefono } = allData.dataUser;
    const { otraEntidad, rangoFechas, tipoIncapacidad } = allData.dataForm;
    const { fechaFin, fechaInicio } = rangoFechas;

    if (
      exprRegTelefono.test(telefono) &&
      exprRegEmail.test(correoElectronico) &&
      exprRegNumeros.test(tipoIncapacidad) &&
      validarfechas(fechaInicio, fechaFin) &&
      filesState.files.length !== 0
    ) {
      if (otraEntidad.status === true) {
        return exprRegSoloLetras.test(otraEntidad.label);
      }
      return true;
    } else {
      return false;
    }
  };

  function validarfechas(fechaInicial, fechaFinal) {
    let arrFechaInicial = fechaInicial.split("-");
    let arrFechaFinal = fechaFinal.split("-");

    let yearIni = arrFechaInicial[0];
    let mesIni = arrFechaInicial[1];
    let diaIni = arrFechaInicial[2];

    let yearFin = arrFechaFinal[0];
    let mesFin = arrFechaFinal[1];
    let diaFin = arrFechaFinal[2];

    let fechaIni = yearIni + mesIni + diaIni;
    let fechaFin = yearFin + mesFin + diaFin;

    return fechaIni <= fechaFin;
  }

  return {
    formValue,
    optionsOtherEntity,
    optionsTipoIncapacidad,
    stateOtraEntidadCheck,
    rowsTable,
    onChangeInputHandle,
    onCheckedOtherEntity,
    onChangeTipoIncapacidadHandle,
    onSubmitFormIncapacidad,
    onChangeSelectHandle,
    onCheckedInputCheck,
  };
};
