import React, { useState } from 'react';
import { overlay } from '../../../generalHelpers';
import { getSalesPoints } from '../../../repositories/generalInfo';
import { Cities } from '../../Cities/Cities';
import { SalesPoints } from '../../SalesPoints/SalesPoints';
import Swal from 'sweetalert2';
import PropTypes from 'prop-types';
import './PersonalCampo.css'

export const PersonalCampo = ({ form, setForm, userHasCheckIn }) => {

    const [salesPoints, setSalesPoints] = useState([]);
    const [salesPoint, setSalesPoint] = useState([]);
    const [cargando, setCargando] = useState(false);
    const [city, setCity] = useState('');
    const [disabledSalesPoints, setDisabledSalesPoints] = useState(true);

    const handleSalesPointUpdate = (e) => {
        setSalesPoint(e || []);
        setForm(old_data => ({ ...old_data, salesPoints: e }));
    };

    const handleCityUpdate = (e) => {
        setCity(e);
        setForm(old_data => ({ ...old_data, city: e.value }));
    };

    const searchSalesPoint = () => {

        const pista = document.getElementById("inputPista").value;
        if (city && pista) {
            overlay(true);
            getSalesPoints(city.value, pista, true).then(salesPoints => {
                if (salesPoints.error) {

                    Swal.fire({
                        icon: 'error',
                        html: salesPoints.error,
                        showConfirmButton: true,
                        confirmButtonText: 'Cerrar',
                        confirmButtonColor: '#4695ff',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        focusConfirm: false,
                        timer: 5000,
                        timerProgressBar: true
                    });

                }else if(salesPoints.length){
                    
                    overlay(false);
                    setCargando(false)
                    setSalesPoints(salesPoints);

                    const salesPoint = salesPoints.filter(currentValue => (currentValue.value === salesPoints))?.[0] || '';
                    setSalesPoint(salesPoint);
                    setDisabledSalesPoints(false);

                }else{
                    Swal.fire({
                        icon: 'error',
                        html: `El punto de venta ${pista} no se ha encontrado`,
                        showConfirmButton: true,
                        confirmButtonText: 'Cerrar',
                        confirmButtonColor: '#4695ff',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        focusConfirm: false,
                        timer: 5000,
                        timerProgressBar: true
                    });
                }
            }).catch(e => { overlay(false); });
        }

    };

    return (
        <div className='row mb-12'>
            <div className='input-group'>
                {(!userHasCheckIn)?
                    <div className='offset-md-0 col-12 col-md-4' style={{ paddingRight: '1rem', marginBottom: '1rem' }}>
                        <Cities form={form} disabled={userHasCheckIn} city={city} setCity={setCity} handleCityUpdate={handleCityUpdate} all={true}/>
                    </div>
                    :
                    <div className='offset-md-0 col-12 col-md-6' style={{ paddingRight: '1rem', marginBottom: '1rem' }}>
                        <Cities form={form} disabled={userHasCheckIn} city={city} setCity={setCity} handleCityUpdate={handleCityUpdate} all={true}/>
                    </div>
                }
                {(!userHasCheckIn)?
                <div className='offset-md-0 col-12 col-md-4' style={{ paddingRight: '1rem', marginBottom: '1rem' }}>
                    <label htmlFor="inputPista">Buscar</label>
                    <div className="input-group mb-3">
                        <input type="text" id='inputPista' className="form-control" style={{ height: '38px' }} placeholder="Punto de venta..." aria-label="Recipient's username" aria-describedby="basic-addon2" />
                        <div className="input-group-append">
                            <button className="input-group-text" style={{ background: "#2c7be5", color: "white" }} id="basic-addon2" onClick={() => searchSalesPoint()} >
                                <i className="findIcon bi bi-search" style={{ height: '26px' }} data-toggle="tooltip" data-placement="right" title="Buscar"></i>
                            </button>
                        </div>
                    </div>
                </div>
                :
                <></>
                }
                {(!userHasCheckIn)?
                    <div className='offset-md-0 col-12 col-md-4' style={{ paddingRight: '1rem', marginBottom: '1rem' }}>
                        <SalesPoints form={form} filter={form?.city} setSalesPoints={setSalesPoints} multiple={false} disabled={disabledSalesPoints} setSalesPoint={setSalesPoint} handleSalesPointUpdate={handleSalesPointUpdate} cargando={cargando} salesPoints={salesPoints} salesPoint={salesPoint} />
                    </div>
                    :
                    <div className='offset-md-0 col-12 col-md-6' style={{ paddingRight: '1rem', marginBottom: '1rem' }}>
                        <SalesPoints form={form} filter={form?.city} setSalesPoints={setSalesPoints} multiple={false} disabled={userHasCheckIn} setSalesPoint={setSalesPoint} handleSalesPointUpdate={handleSalesPointUpdate} cargando={cargando} salesPoints={salesPoints} salesPoint={salesPoint} />
                    </div>
                }
            </div>
        </div>
    )
}

PersonalCampo.propTypes = {
    form: PropTypes.object.isRequired,
    setForm: PropTypes.func.isRequired,
    userHasCheckIn: PropTypes.bool.isRequired,
}