import React, { Component } from 'react';
import Swal from 'sweetalert2';
import { loadDataValidate, postData, validateInputTabs, loadingDialog } from '../../components/general/General';
import { baseUrl } from '../../config/config';
import Select from 'react-select'

class LivingPlace extends Component{


    constructor(props){
        super(props);
        this.state = {
            datbas: '',
            optionsTiposVivienda : '',
            optionsPerimetros : '',
            optionsEstratos : '',
            optionsServicios : [],
            servicios: React.createRef(),
            valuesServicios: [],
        }
    }


    componentDidMount(){
        document.getElementById('root').className = 'cv';
        this.consultaInicial();
    }

    consultaInicial = () => {
        const dataUser = JSON.parse(localStorage.getItem("d_u"));
        const cedula = parseInt(dataUser['cedula'])
        const empresa = parseInt(dataUser['empresa'])
        const url = `${baseUrl}/v1/vivienda/consultaInicial`;

        loadingDialog(true)
        postData(url, {cedula, empresa}).then(async (response) => {
            loadingDialog(false);
            this.setState({datbas: response.datbas});
            
            await this.asignarValoresSelects(response);
            if(response.datosVivienda.length) this.loadDataPrincipal(response.datosVivienda[0], response.respMultiples);
        }).catch(err => {
            loadingDialog(false);
            Swal.fire({
                icon: 'warning',
                title: '¡Ups!',
                text: 'Ha ocurrido un error al cargar el formulario, intenta nuevamente.',
                confirmButtonColor: "#A6A6A6",
                confirmButtonText: 'Cerrar'
            })
        });
    }

    asignarValoresSelects = async (data) => {
        let tiposVivienda = data.tiposVivienda.map((value, x) => <option key={'optTipo'+x} value={value["TIP_CODIGO"]}>{value["TIP_NOMBRE"]}</option>);
        let perimetros = data.perimetros.map((value, x) => <option key={'optPeri'+x} value={value["TIP_CODIGO"]}>{value["TIP_NOMBRE"]}</option>);
        let estratos = data.estratos.map((value, x) => <option key={'optEstr'+x} value={value["TIP_CODIGO"]}>{value["TIP_NOMBRE"]}</option>);
        let servicios = data.servicios.map((value, x) => {return {value: value["TIP_CODIGO"], label: value["TIP_NOMBRE"]}})

        await Promise.all([tiposVivienda, perimetros, estratos, servicios])
        .then(res => {
            this.setState({
                optionsTiposVivienda: res[0],
                optionsPerimetros: res[1],
                optionsEstratos: res[2],
                optionsServicios: res[3]
            });
        });
        return true;
    }

    loadDataPrincipal = (datos, serviciosMarcados) =>{
        this.tipoVivienda.value = datos.tipoVivienda;;
        this.perimetro.value = datos.perimetro;
        this.estrato.value = datos.estrato;

        if(datos.propia === 1) document.getElementById('radioPropia').checked = true;
        else if(datos.propia === 0) document.getElementById('radioPropia2').checked = true;

        if(datos.credito === 1) document.getElementById('radioCredito').checked = true;
        else if(datos.credito === 0) document.getElementById('radioCredito2').checked = true;
        
        // Selector múltiple de servicios
        if(serviciosMarcados.length) {
            let selecciones = [];
            serviciosMarcados.forEach(element => {
                const idxExiste = this.state.optionsServicios.findIndex(res => res.value === element.resp_tipo);
                if(idxExiste !== -1) selecciones.push(this.state.optionsServicios[idxExiste]);
            });
            this.setState({valuesServicios: selecciones}); // Esta es la forma de setear valores en el react-select múltiple
        }
    }

    guardarVivienda = () =>{
        if(validateInputTabs()) return;
        if(this.validarSelectsMultiples()) return;
        
        const dataUser = JSON.parse(localStorage.getItem("d_u"));;
        const cedula = Number(dataUser['cedula']);
        const empresa = Number(dataUser['empresa']);
        const usuLogin = dataUser.USU_LOGIN;
        const servicios = this.state.valuesServicios.map(res => res.value);
        
        const datos = {
            cedula,
            empresa,
            usuLogin,
            datbas: Number(this.state.datbas),
            tipoVivienda: Number(this.tipoVivienda.value),
            perimetro: Number(this.perimetro.value),
            estrato: Number(this.estrato.value),
            propia: Number(document.querySelector('input[name=viviendaPropia]:checked').value),
            credito: Number(document.querySelector('input[name=credito]:checked').value),
            servicios
        };

        loadingDialog(true);
        const url = `${baseUrl}/v1/vivienda/guardarVivienda`;
        postData(url, datos).then(result => {
            loadingDialog(false);
            if (result.error) {
                return Swal.fire({
                    icon: 'warning',
                    title: '¡Ups!',
                    text: result.error,
                    confirmButtonColor: "#A6A6A6",
                    confirmButtonText: 'Cerrar'
                });
            }

            Swal.fire({
                title: '',
                html: `
                <div class="text-center">
                    <img src="https://controlf.blob.core.windows.net/public/vumoffice/assets/
cv/check.png" alt="check" style="width:60px">
                    <h3 class="text-primary mt-3">¡Los datos de tu VIVIENDA han sido guardados con éxito!</h3>
                    <h5 class="text-secondary">Has terminado de llenar tu perfil.</h5>
                    <a type="button" class="btn succesButton mt-3" href="/home">Volver a Vum Office</a>
                </div>
                `,
                showCloseButton: false,
                showCancelButton: false,
                showConfirmButton: false
            });
            loadDataValidate();
        }).catch(err => {
            loadingDialog(false);
            Swal.fire({
                icon: 'warning',
                title: '¡Ups!',
                text: 'Ha ocurrido un error al guardar los datos de salud, intenta nuevamente.',
                confirmButtonColor: "#A6A6A6",
                confirmButtonText: 'Cerrar'
            })
        });
        loadDataValidate();
    }

    validarSelectsMultiples = () => {
        let labelInput = '';
        let camposVacios = '';

        if(!this.state.valuesServicios.length) {
            labelInput = document.querySelector('label[for=bienesYear]').textContent;
            camposVacios += `<li class="list-group-item border-0">${labelInput}</li>`;
        }
        
        if(camposVacios) {
            let list = `
            <ul class="list-group list-group-flush inline-flex">
                ${camposVacios}
            </ul>`;

            Swal.fire({
                title: "<h5 class='text-primary'>Validar las siguientes preguntas múltiples</h5>",
                html: list,
                showCancelButton: false,
                showConfirmButton: true,
                confirmButtonColor: "#A6A6A6",
                confirmButtonText: 'Cerrar'
            });
        }

        return camposVacios;
    }

    render(){
        const {optionsTiposVivienda, optionsPerimetros, optionsEstratos, optionsServicios, servicios, valuesServicios} = this.state;

        return <>
            <div className="card">
                <div  className="card-header">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb fs-2">
                            <li className="breadcrumb-item">Mi perfil</li>
                            <li className="breadcrumb-item" aria-current="page">Vivienda</li>
                        </ol>
                    </nav>
                </div>
            </div>
            &nbsp;
            <div className="card">
                <div className="card-body active">
                    <div className="row g-3">
                        <div className="col-12 col-md-4">
                            <label className='form-label' htmlFor="typeLyving">Tipo de vivienda en la que reside actualmente:<span className="text-danger">*</span></label>
                            <select ref={inp => this.tipoVivienda = inp} className="form-select inputRequired" name="typeLyving"  id="typeLyving" required>
                                <option value="">Seleccione su respuesta</option>
                                {optionsTiposVivienda}
                            </select>
                        </div>
                        <div className="col-12 col-md-4">
                            <label className='form-label' htmlFor="typePerimeter">Perímetro:<span className="text-danger">*</span></label>
                            <select ref={inp => this.perimetro = inp} className="form-select inputRequired" name="typePerimeter"  id="typePerimeter" required>
                                <option value="">Seleccione su respuesta</option>
                                {optionsPerimetros}
                            </select>
                        </div>
                        <div className="col-12 col-md-4">
                            <label className='form-label' htmlFor="typeStratum">Estrato:<span className="text-danger">*</span></label>
                            <select ref={inp => this.estrato = inp} className="form-select inputRequired" name="typeStratum"  id="typeStratum" required>
                                <option value="">Seleccione su respuesta</option>
                                {optionsEstratos}
                            </select>
                        </div>
                        <div className="col-12 col-md-4 ">
                            <label className='form-label' htmlFor="viviendaPropia">¿Cuenta con vivienda propia?<span className="text-danger">*</span></label>
                            <div className=" d-flex justify-content-around">
                                <input type="radio" name="viviendaPropia" value="1" className="form-check btn-check inputRequired" id="radioPropia" autoComplete="off"></input>
                                <label className="btn btn-outline-primary w-50" htmlFor="radioPropia">SI</label>&nbsp;
                                <input type="radio" name="viviendaPropia"  value="0"  className="form-check btn-check " id="radioPropia2" autoComplete="off"></input>
                                <label className="btn btn-outline-primary w-50" htmlFor="radioPropia2">No</label>
                            </div>                                      
                        </div>

                        <div className="col-12 col-md-4">
                            <label className='form-label' htmlFor="credito">¿Tiene algún crédito de vivienda vigente?<span className="text-danger">*</span></label>
                            <div className=" d-flex justify-content-around ">
                                <input type="radio" name="credito" value="1" className="btn-check inputRequired" id="radioCredito" autoComplete="off"></input>
                                <label className="btn btn-outline-primary w-50" htmlFor="radioCredito">SI</label>&nbsp;
                                <input type="radio" name="credito" value="0" className="btn-check " id="radioCredito2" autoComplete="off"></input>
                                <label className="btn btn-outline-primary w-50" htmlFor="radioCredito2">NO</label>
                            </div>
                        </div>

                        <div className="col-12 col-md-4">
                            <label className='form-label' htmlFor='servicios'>¿Con qué servicios cuenta?<span className="text-danger">*</span></label>
                            <Select
                                isMulti
                                closeMenuOnSelect={false}
                                name="servicios"
                                options={optionsServicios}
                                className=""
                                classNamePrefix="select"
                                placeholder={'Seleccione varios'}
                                ref={servicios}
                                value={valuesServicios}
                                onChange={(vals) => this.setState({valuesServicios: vals})}
                            />
                        </div>

                        <div className="text-end">
                            <button onClick={() => this.guardarVivienda()} className="btn succesButton col-12 col-lg-2 col-xxl-1">Guardar</button>
                        </div>
                    </div>

                </div>
            </div>

        </>
    }

    }

export default LivingPlace;