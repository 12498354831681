import React, { useState } from 'react';
import { CartaIngresoMateriales } from '../../../components/CartaIngresoMateriales/CartaIngresoMateriales';
import { CartaPuntoVenta } from '../../../components/CartaPuntoVenta/CartaPuntoVenta';
import { CartaPuntoVentaFueraHorario } from '../../../components/CartaPuntoVentaFueraHorario/CartaPuntoVentaFueraHorario';
import { Cities } from '../../../components/Cities/Cities';
import { SalesPoints } from '../../../components/SalesPointsPC/SalesPoints';
import { UnrelatedSalesPoints } from '../../../components/UnrelatedSalesPoints/UnrelatedSalesPoints';
import { makeModal, overlay } from '../../../generalHelpers';
import { getSalesPointsPC } from '../../../repositories/generalInfo';
import { ResquestApproval } from '../../../repositories/PresentationCard/PresentationCard';
import { useCartaPresentacion } from './useCartaPresentacion';

import dotenv from "dotenv";
dotenv.config({
  path: `${__dirname}/../../.env`,
});

export const CartaPresentacion = () => {

    // const [cpPuntoVenta, setCpPuntoVenta] = useState(false);

    const { formPresentationCard, setFormPresentationCard } = useCartaPresentacion();
    const { typeCard } = formPresentationCard;
    const [salesPoints, setSalesPoints] = useState([]);
    const [salesPoint, setSalesPoint] = useState([]);
    const [cargando, setCargando] = useState(false);
    const [city, setCity] = useState('');
    const [disabledSalesPoints1, setDisabledSalesPoints1] = useState(false);
    const [disabledSalesPoints2, setDisabledSalesPoints2] = useState(false);

    const handleCityUpdate = (e) => {
        setCity(e);
        setFormPresentationCard(e.value);
        handleCityChange(e.value);
        overlay(true);
            setCargando(true);
            handleSalesPointUpdate();
            setSalesPoints([]);
            getSalesPointsPC(e.value).then(salesPoints => {
                if (salesPoints) {
                    overlay(false);
                    setCargando(false)
                    setSalesPoints(salesPoints);
                    // Aquí se quitó un condicional redundante
                    const salesPoint = salesPoints.filter(currentValue => (currentValue.value === salesPoints))?.[0] || '';
                    setSalesPoint(salesPoint);
                }
            }).catch(e => { overlay(false); });
    };

    const handleSalesPointUpdate = (e) => {
        setSalesPoint(e || []);
        handleSalesPointChange(e || []);
        if (typeof e != 'undefined') {
            if (e.length > 0){
                setDisabledSalesPoints2(true)
            }
            else {
                setDisabledSalesPoints2(false)
            }
        }
    };

    /*useEffect(() => {
        if (formPresentationCard?.city) {
            overlay(true);
            setCargando(true);
            handleSalesPointUpdate();
            setSalesPoints([]);
            getSalesPoints(formPresentationCard?.city).then(salesPoints => {
                if (salesPoints) {
                    overlay(false);
                    setCargando(false)
                    setSalesPoints(salesPoints);

                    if (salesPoints) {
                        const salesPoint = salesPoints.filter(currentValue => (currentValue.value === salesPoints))?.[0] || '';
                        setSalesPoint(salesPoint);
                    }
                }
            }).catch(e => { overlay(false); });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [!formPresentationCard.city]);*/

    const tipoCartas = [
        {
            id: 'cartaPuntoVenta',
            title: 'Carta de presentación a punto de venta'
        },
        {
            id: 'cartaPuntoVentaFueraHorario',
            title: 'Carta de presentación a punto de venta con ingreso fuera de horario'
        },
        {
            id: 'cartaIngresoMateriales',
            title: 'Carta de ingreso/retiro de materiales a punto de venta'
        }
    ];

    const formatRequestBody = (data) => {
        const {
            typeCard= '',
            city='',
            materials=[],
            checkInTime='',
            salesPoints=[],
            checkOutTime='',
            unrelatedsalesPoints=[]
        } = data;

        let response = {
            typeCard,
            city,
            salesPoints: salesPoints.map(({ value }) => value),
            unrelatedsalesPoints: unrelatedsalesPoints.map(({ value }) => value)
        };

        if (data.typeCard === 'cartaIngresoMateriales') {
            if (!materials?.length) throw new Error('No puede generar la carta sin incluir materiales!');

            Object.assign(response, {
                materials: materials.map(({ accion, cantidad, material }) => ({ accion, cantidad, material }))
            });
        }

        if (data.typeCard === 'cartaPuntoVentaFueraHorario') {
            Object.assign(response, {
                checkInTime,
                checkOutTime
            });
        }

        return response;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        try {

            const params = formatRequestBody(formPresentationCard);
    
            overlay(true);
            if(!params.typeCard){
                overlay(false);
                const options = { text: '¡Debes escoger un tipo de carta!', icon: 'error' };
                return makeModal(options);
            }
    
            ResquestApproval({ params })
                .then(response => {
                    //if (!response || !Object.keys(response)?.length) throw new Error();
    
                    overlay(false);
                    const options = { text: response.message, icon: 'success', successAnswerFunction: ()=>{ response.needApproval?window.location.reload():window.open(process.env.REACT_APP_URL_AZURE+"/"+response.file); } };

                    return makeModal(options);
                })
                .catch(err => {
                    overlay(false);
                    const options = { text: '¡Ocurrio un error al crear la carta!', icon: 'error' };

                    return makeModal(options);
                })
        } catch (e) {
            const options = { text: e.message, icon: 'warning' };
                    
            return makeModal(options);
        }

    };

    const handleChangeTypeCard = (e) => {
        setFormPresentationCard(old_data => ({ city: old_data?.city || '', materials: old_data?.materials || [], salesPoints: old_data?.salesPoints || [], unrelatedsalesPoints: old_data?.unrelatedsalesPoints || [], typeCard: e.target.value }));
    };

    const handleCityChange = (newData) => {
        setFormPresentationCard(old_data => ({ ...old_data, city: newData, salesPoints: [], unrelatedsalesPoints: [] }));
    };

    const handleSalesPointChange = (newData) => {
        setFormPresentationCard(old_data => ({ ...old_data, salesPoints: newData, unrelatedsalesPoints: [] }));
    };

    const handleUnrelatedSalesPointChange = (newData) => {
        setFormPresentationCard(old_data => ({ ...old_data, salesPoints: [], unrelatedsalesPoints: newData }));
        if (typeof newData != 'undefined') {
            if (newData.length > 0){
                setDisabledSalesPoints1(true)
            }
            else {
                setDisabledSalesPoints1(false)
            }
        }
    };

    const getRenderTipoCarta = () => {
        if(typeCard === 'cartaPuntoVenta') return <CartaPuntoVenta form={formPresentationCard} setForm={setFormPresentationCard} />
        else if(typeCard === 'cartaIngresoMateriales') return <CartaIngresoMateriales form={formPresentationCard} setForm={setFormPresentationCard} />
        else if(typeCard === 'cartaPuntoVentaFueraHorario') return <CartaPuntoVentaFueraHorario form={formPresentationCard} setForm={setFormPresentationCard} />
        else return <></>;
    }


    return (
        <>
            <div className="card mb-3">
                <div className="card-header position-relative text-center text-md-start">
                    <div className="col-12">
                        <h3>Mis certificados/Carta de presentaciónn</h3>
                    </div>
                </div>
            </div>

            <div className="card mb-3">
                <div className="card-body bg-light" style={{ padding: '1rem 1rem' }}>
                <strong className='text-danger fs--1'>Todos los campos marcados con asteriscos son obligatorios.</strong>
                    <form onSubmit={handleSubmit}>
                        <div className='col-12 mt-2'>
                            <div className='input-group containerCardsFilter mb-5' style={{ marginBottom: '1rem' }}>
                                <Cities setForm={handleCityChange} city={city} setCity={setCity} handleCityUpdate={handleCityUpdate} all={false}/>
                                <SalesPoints disabled={disabledSalesPoints1} setSalesPoint={setSalesPoint} handleSalesPointUpdate={handleSalesPointUpdate} cargando={cargando} salesPoints={salesPoints} salesPoint={salesPoint} />
                                <UnrelatedSalesPoints disabled={disabledSalesPoints2} filter={(formPresentationCard?.city)} setForm={handleUnrelatedSalesPointChange} value={formPresentationCard?.unrelatedsalesPoints || []} />
                            </div>
                        </div>
                        <div className=' col-12 mb-4'>
                            <div className="form-check" style={{ paddingLeft: '0', marginBottom: '1rem' }}>
                                <label className="form-check-label" htmlFor='tipoDeCarta'>Tipo de carta:</label>
                            </div>{
                                tipoCartas.map(({ id, title }) => (
                                    <div className='form-check' key={id}>
                                        <input id={id} type='radio' name='carta_presentacion' className='form-check-input' value={id} checked={formPresentationCard?.typeCard === id} onChange={handleChangeTypeCard} />
                                        <label htmlFor={id} className='form-check-label'>{title}</label>
                                    </div>
                                ))}
                        </div>
                                <div style={{ padding: '1rem 5rem' }}>
                                    {getRenderTipoCarta()}
                                </div>
                                    
                                    <div className='d-flex justify-content-md-end justify-content-center'>
                                        <div style={{ textAlign: 'right' }} className="col-12 col-lg-3 mt-2">
                                            <button type='submit' className='btn succesButton w-100'>Solicitar aprobación</button>
                                        </div>
                                    </div>
                    </form>
                </div>
            </div>
        </>
    )
}
